import Button from '@components/Button';
import TextField from '@components/TextField';
import { withStyles } from '@material-ui/core/styles';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);
  
  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/guides/${params.id}/status`, {
      status: !status,
    })
    .then((response) => {
      setLoading(false);
      setStatus(response.data);
    })
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Lista de guias - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [q, setQ] = useState("");

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            person_search
          </Icon>
          <span>Lista de guias</span>
        </div>
        {permissions.includes(props?.theme?.permission) &&<Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('/admin/guias/cadastro')}
          customcolor={props.theme.color}
        >
          <Icon className="mr-2">add</Icon>
          Novo
        </Button>}
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por um guia"
              width="280px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(ev) => setQ(ev.target.value)}
            />
          </div>
        </div>
      </div>
      <Table
        params={{
          q
        }}
        path="/guides"
        columns={[
          {
            headerName: 'Nome',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
          },
          {
            headerName: 'E-mail',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            flex: 1,
          },
          {
            headerName: 'Telefone',
            headerClassName: 'super-app-theme--header',
            field: 'phone',
            flex: 1,
          },
          {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            preventRedirect: true,
            renderCell: (params) => {
              const row = params.row;

              return (<div className="flex items-center pr-4 space-x-5">
                {permissions.includes(props?.theme?.permission) ?
                <RowSwitch
                  status={row.status}
                  id={row.id}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                :
                <>{row.status ? 'Ativo' : 'Inativo' }</>
            }
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default Index;
