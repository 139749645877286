import { useState } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Alert from '@material-ui/lab/Alert';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";

const Create = (props) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({
    name: null,
    email: null,
    phone: null,
    role: null,
    commission: null,
    status: 'Liberado',
  })

  const bradecrumbsConfig = [
    {
      title: 'Lista de usuários',
      active: true,
      route: '/admin/usuarios'
    },
    {
      title: 'Novo usuário',
    }
  ]

  const changeState = (ev) => {
    setUser({
      ...user,
      [ev.target.name]: ev.target.value
    })
  }

  const submitUser = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/users", user)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/usuarios');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo usuário</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          bgColor="transparent"
          onClick={() => history.push('/admin/usuarios')}
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        isEditing={false}
        user={user}
        changeState={changeState}
        submit={submitUser}
      />
    </div>
  </>
}

export default Create
