import Modal from '@components/Modal';
import Button from '@components/Button';
import TextField from '@components/TextField';
import { apiClient } from '@services/api';
import { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

const EditRegionModal = (props) => {
  const [regionName, setRegionName] = useState(null);
  const history = useHistory();
  let ref = useRef(null);

  useEffect(() => {
    setRegionName(props?.region?.region_name);
  }, [props?.region]);

  const handleSubmit = (e) => {
    e.preventDefault();
    apiClient.httpInstance.put(`/translados/${props?.region?.id}`, {
      region_name: regionName,
      status: true
    })
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        ref.current.click();

        history.push(`/admin/traslados/detalhe/${response.data.id}`);
      }
    })
  }

  return <Modal open={props.open} close={props.close}>
    <form onSubmit={handleSubmit}>
      <div className="flex justify-between">
        <span>Edite a região</span>
        <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
      </div>
      <div className="mt-3">
        <TextField
          className="inputRounded"
          placeholder="Digite o nome da região"
          variant="outlined"
          type="text"
          name="region"
          value={regionName}
          onChange={(e) => setRegionName(e.target.value)}
          required></TextField>
          <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="15px"
            type="submit"
            customcolor={props.theme.color}>
              Salvar
          </Button>
      </div>
    </form>
  </Modal>
}

export default EditRegionModal;
