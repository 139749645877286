import BreadCrumbs from '@components/Breadcrumbs'
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import FormCompany from './partials/FormCompany'

const EditCompanyProfile = (props) => {
  document.title = `Editar perfil da empresa - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [oldLogo, setOldLogo] = useState(null)
  const [company, setCompany] = useState({
    logo: null,
    name: null,
    rut: null,
    phone: null,
    address: null,
    number: null,
    complement: null,
    district: null,
    city: null,
    state: null,
    country: null,
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const url = `/companies/${props.user.data.company_id}`
  const bradecrumbsConfig = [
    {
      title: 'Configurações',
      active: true,
      route: '/admin/configuracoes'
    },
    {
      title: 'Edição',
    }
  ]

  const getCompanyById = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCompany(response.data);
        setOldLogo(response.data.logo)
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }
  const changeState = (ev) => {
    let value = ev.target.value
    setCompany(
      {
        ...company,
        [ev.target.name]: value

      }
    )
  }

  const changeFile = (data) => {
    setCompany(
      {
        ...company,
        logo: data
      }
    )
  }
  const submitCompany = (ev) => {
    ev.preventDefault();
    const formData = new FormData()

    if (oldLogo !== company.logo) {
      formData.append('logo', company.logo)
    }
    formData.append('name', company.name)
    formData.append('surname', company.surname)
    formData.append('rut', company.rut)
    formData.append('phone', company.phone)
    formData.append('address', company.address)
    formData.append('number', company.number)
    formData.append('complement', company.complement)
    formData.append('district', company.district)
    formData.append('city', company.city)
    formData.append('state', company.state)
    formData.append('country', company.country)
    formData.append('mail_from_address', company.mail_from_address)
    formData.append('mail_from_address_secondary', company.mail_from_address_secondary)
    formData.append('mail_from_name', company.mail_from_name)
    formData.append('company_id', props.user.data.company_id)
    formData.append('_method', 'PUT')

    apiClient
      .httpInstance.post(`/companies/${props.user.data.company_id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/configuracoes');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response?.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      });
  }

  useEffect(() => {
    getCompanyById(url);
  }, [url]);
  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
          </div>
          <FormCompany
            {...props}
            submit={submitCompany}
            company={company}
            changeState={changeState}
            changeFile={changeFile}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(EditCompanyProfile);
