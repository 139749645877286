import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const PaymentsList = (props) => {
  const [changeDate, setChangeDate] = useState(false)
  const [date, setDate] = useState(null)
  const [tourId, setTourId] = useState(null)
  const [index, setIndex] = useState(null)
  const classes = useStyles();

  const payType = {
    'card': 'Cartão',
    'money': 'Dinheiro'
  }

  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="my-0 py-1 font-bold leading-5 text-blue border-0">
              Data do pagamento
            </TableCell>
            <TableCell className="my-0 py-1 font-bold leading-5 text-blue border-0">
              Forma de pagamento
            </TableCell>
            <TableCell className="my-0 py-1 font-bold leading-5 text-blue border-0">
              Valor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataPayments?.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell className="my-0 py-1 leading-5 text-blue border-0" component="th" scope="row">
                <Moment format="DD/MM/YYYY" date={row.created_at} />
              </TableCell>
              <TableCell className="my-0 py-1 leading-5 text-blue border-0" component="th" scope="row">
                {payType[row.payment_method]}
              </TableCell>
              <TableCell className="my-0 py-1 leading-5 text-blue border-0" component="th" scope="row">
                <NumberFormat
                  value={Number(row.total) || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="ml-2">{value}</span>}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default PaymentsList;
