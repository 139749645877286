import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";


const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#fff",
        backgroundColor: "rgba(23, 43, 77, 0.60)",
        fontSize: 14,
        padding: 16
      }
    }
  }
});

const CustomTooltip = (props) => {
  return <>
    <MuiThemeProvider theme={theme}>
      <Tooltip title={props.title} placement={props.placement}>
        <Icon>{props.icon}</Icon>
      </Tooltip>
    </MuiThemeProvider>
  </>
}

export default CustomTooltip;
