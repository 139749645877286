import Button from '@components/Button';
import PaginateSelect from '@components/PaginateSelect';
import Select from '@components/Select';
import SelectSearch from 'react-select'
import { components } from "react-select";
import TextField from '@components/TextField';
import RadioButton from '@components/RadioButton';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';

const Form = (props) => {
  const [cuposOptions, setCuposOptions] = useState([])
  const [openMenuDriver, setOpenMenuDriver] = useState(false)
  const [openMenuGuide, setOpenMenuGuide] = useState(false)
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState('');
  const [guides, setGuides] = useState([]);
  const [guide, setGuide] = useState('');

  const radioButtonData = [
    {
      value: 'fixed',
      label: 'Fixo'
    },
    {
      value: 'interval',
      label: 'Intervalo'
    }
  ];

  const getCupos = () => {
    apiClient
      .httpInstance.get('/vehicles')
      .then(response => {
        if (response.status === 200) {
          setCuposOptions(response.data.data);
        }
      });
  }

  const getDrivers = () => {
    apiClient.httpInstance.get(`/drivers?status=true&pageSize=1000`).then(response => {
      if (response.status === 200) {
        let options = []
        response.data.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name })
          if (props.vehicle?.driver_id === element.id) {
            setDriver({ value: element.id, name: element.name, label: element.name.toUpperCase() })
          }
        })
        setDrivers(options)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const getGuides = () => {
    apiClient.httpInstance.get(`/guides?status=true&pageSize=1000`).then(response => {
      if (response.status === 200) {
        let options = []
        response.data.data.map((element) => {
          options.push({ value: element.id, name: element.name, label: element.name })
          if (props.vehicle?.guide_id === element.id) {
            setGuide({ value: element.id, name: element.name, label: element.name.toUpperCase() })
          }
        })
        setGuides(options)
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const handleSelect = (ev, type) => {
    ev.label = ev.label.toUpperCase()
    if (type === 'driver') {
      setDriver(ev)
      props.changeState({ target: { name: 'driver_id', value: ev.value } })
    }
    if (type === 'guide') {
      setGuide(ev)
      props.changeState({ target: { name: 'guide_id', value: ev.value } })
    }
    return ev.value
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    getCupos()
    getDrivers()
    getGuides()
  }, [])

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex flex-wrap mb-4">
          <div className="flex-none sm:p-2 mt-2 md:mt-0 sm:w-40 min-w-max">
            <TextField
              className="inputRounded"
              label="Apelido"
              variant="outlined"
              type="text"
              name="nickname"
              value={props.vehicle.nickname}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex flex-wrap sm:p-2 mt-5 md:mt-0 sm:w-40 min-w-max">
            <div style={SearchStyle1}>
              <span style={LabelStyle}>Motorista</span>
              <span style={SpanSelect}><SelectSearch
                styles={customStyles}
                placeholder="Motorista"
                menuIsOpen={openMenuDriver}
                onMenuOpen={() => setOpenMenuDriver(true)}
                onMenuClose={() => setOpenMenuDriver(false)}
                options={drivers.map(driver => ({
                  value: driver?.value,
                  name: driver?.name?.toLowerCase(),
                  label: driver?.name?.toLowerCase(),
                }))}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                allowSelectAll={false}
                showSelectedItems={true}
                width='280px'
                value={driver}
                onChange={(ev) => handleSelect(ev, 'driver')}
                name="driver_id"
              /></span>
            </div>
          </div>
          <div className="flex flex-wrap sm:p-2 mt-5 md:mt-0 sm:w-40 min-w-max">
            <div style={SearchStyle2}>
              <span style={LabelStyle}>Guia</span>
              <span style={SpanSelect}><SelectSearch
                styles={customStyles}
                placeholder="Guia"
                menuIsOpen={openMenuGuide}
                onMenuOpen={() => setOpenMenuGuide(true)}
                onMenuClose={() => setOpenMenuGuide(false)}
                options={guides.map(guide => ({
                  value: guide?.value,
                  name: guide?.name?.toLowerCase(),
                  label: guide?.name?.toLowerCase(),
                }))}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                allowSelectAll={false}
                showSelectedItems={true}
                width='280px'
                value={guide}
                onChange={(ev) => handleSelect(ev, 'guide')}
                name="guide_id"
              /></span>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mb-4">
          <div className="flex-none sm:p-2 mt-3 md:mt-0 sm:w-40 min-w-max">
            <Select
              withoutAll
              label="Vagas"
              width="100px"
              value={props.idVehicle}
              onChange={(ev) => props.changeIdVehicle(ev.target.value)}
              required
              items={{
                field: {
                  value: 'id',
                  text: 'seats'
                },
                data: cuposOptions
              }}
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 sm:w-40 min-w-max">
            <TextField
              className="inputRounded"
              label="Placa"
              variant="outlined"
              type="text"
              name="license_plate"
              width="300px"
              value={props.vehicle.license_plate}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
        </div>

        <div className="flex-1 space-y-1">
          <RadioButton
            {...props}
            data={radioButtonData}
            name="type_time"
            change={(ev) => props.changeState(ev)}
            value={props.vehicle.type_time}
            required
          />
          <span
            style={{ fontSize: '10px' }}
            id="labelRadioTransfer">Selecione o tipo de horário*
          </span>
        </div>

      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '320px',
    color: state.selectProps.menuColor,
    padding: 10,
    paddingBottom: '10px',
    display: 'flex',
    fontSize: '14px',
    borderRadius: '20px',
    marginTop: '15px',
    marginLeft: '-9px',
    boxShadow: '0 0 0 10px hsl(0deg 0% 0% / 4%), 0 4px 18px hsl(0deg 0% 0% / 10%)',
  }),

  option: (provided, state) => ({
    ...provided,
    background: '#fff',
    cursor: 'pointer',
    color: '#000',
    textTransform: 'capitalize',
    width: '280px',
  }),

  input: (provided, state) => ({
    ...provided,
    marginLeft: '125px',
  }),

  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: '2px',
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: 'white'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: '300px',
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  },

  multiValue: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
}

const SearchStyle1 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10006',
}

const SearchStyle2 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10004'
}

const LabelStyle = {
  transform: 'translate(6px, -6px) scale(0.75)',
  top: '-22px',
  left: '0',
  position: 'relative',
  transformOrigin: 'top left',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '0px 4px',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1',
  letterSpacing: '0.00938em',
  background: '#fff',
  zIndex: '10005',
}

const SpanSelect = {
  marginLeft: '-250px',
}