import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { apiClient } from '@services/api';

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/parceiros/agencias'
  },
  {
    title: 'Nova agência parceira',
  }
]);

const Create = (props) => {
  document.title = `Cadastrar agência parceira - ${process.env.REACT_APP_NAME}`
  const [error] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage] = useState("");
  const history = useHistory();
  const [agency, setAgency] = useState({
    name: '',
    social_reason: '',
    contact_person: '',
    telephone: '',
    email: '',
    observations: '',
    percentage: 0,
    operational_cost: 'true',
    status: 'true',
  });
  const url = `/admin/parceiros/agencias`;

  const changeState = (e) => {
    setAgency(
      {
        ...agency,
        [e.target.name]: e.target.value
      }
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient.httpInstance.post("/agencies", {
      ...agency
    })
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          history.push(url);
        }, 2000);
      }
    })
    .catch(error => {
      if(error) {
        setErrors(error.response.data);
        setTimeout(() => {setErrors("")}, 4000);
      }
    });
  }

  return <>
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading && <><BreadCrumbs items={breadcrumbsConfig()} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Nova agência parceira</span>
        <Link color="inherit" to={url}>
          <Button
            textcolor="#212121"
            padding="6px 15px"
            width="200"
            variant="outlined"
            bgColor="transparent"
          >
            <Icon className="mr-2">
              arrow_back
            </Icon>
            Cancelar
          </Button>
        </Link>
      </div>
      <div className="flex flex-wrap items-center justify-between">
        {success && <Alert className="mt-5" severity="success" color="success">
          Agência cadastrado com sucesso
        </Alert>}
      </div>
      <Form
        {...props}
        submit={handleSubmit}
        errors={errors}
        agency={agency}
        isEditing={false}
        changeState={changeState} />
    </div></>}
  </>
}

export default Create;
