import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import CommissionTable from './commission/CommissionTable'
import DeleteModal from '@components/DeleteModal';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes da região - ${process.env.REACT_APP_NAME}`
  const { region_id } = useParams();
  const [region, setRegion] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  let url = `/regions/${region_id}`

  const getRegionDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setRegion(response.data);
        setLoading(false)
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/regioes'
    },
    {
      title: `${region.name}`,
    }
  ]

  useEffect(() => {
    getRegionDetails(url);
  }, [url]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{region.name}</span>
          <div className="w-100">
            {permissions.includes(props?.theme?.permission) && <>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/regioes/editar/${region_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Desativar
              </Button>
            </>}
            <DeleteModal
              open={disableModal}
              close={() => setDisableModal(false)}
              url={`/regions/${region_id}`}
              title="Excluir região?"
              redirect={'/admin/regioes'}
              description="Você realmente deseja excluir esta região? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
            />
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações da região</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome da região
            </div>
            <div className="flex-1">
              {region.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Status
            </div>
            <div className="flex-1">
              {region.status
                ? <>
                  <span className="mr-2" style={{ color: '#4CAF50' }}>
                    Ativo
                  </span>
                </> : <>
                  <span className="mr-2" style={{ color: '#F44336' }}>
                    Inativo
                  </span>
                </>
              }
            </div>
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins flex justify-between">
            <span>Matriz de comissão</span>
            {permissions.includes(props?.theme?.permission) && <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => history.push(`/admin/regioes/detalhe/${region_id}/matriz-de-comissao`)}
              customcolor={props.theme.color}
            >
              <Icon className="mr-2">
                add
              </Icon>
              Novo
            </Button>}
          </div>
          <CommissionTable props={props} />
        </div>
      </div>
    }
  </>
}

export default Show;
