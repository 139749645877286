import Button from '@components/Button';
import TextField from '@components/TextField';
import { range } from '@utils/helpers';
import RadioButton from '@components/RadioButton';
import MenuItem from '@material-ui/core/MenuItem'
import PhoneMaskInput from '@components/PhoneMaskInput';

const operationalOptions = [
  {
    value: 'false',
    label: 'Sem desconto do custo operacional'
  },
  {
    value: 'true',
    label: 'Com desconto do custo operacional'
  }
];

const radioButtonData = [
  {
    value: 'true',
    label: 'Ativo'
  },
  {
    value: 'false',
    label: 'Inativo'
  }
];

const percentages = range(0, 100, 5).map((v) => ({ value: v, text: `${v}%`}));

const Form = (props) => {
  return <>
      <form onSubmit={props.submit}>
        <div className="p-4 pt-3 pb-6 mt-6 bg-white rounded-xl text-blue font-poppins border-1">
          <div className="flex flex-wrap mt-3 space-x-3 lg:flex-nowrap">
            <TextField
              label="Nome da agência"
              name="name"
              variant="outlined"
              error={props.errors?.name}
              value={props.agency?.name}
              type="text"
              onChange={(e) => props.changeState(e)}
              required />
            <TextField
              label="Razão Social"
              maxwidth="380px"
              name="social_reason"
              variant="outlined"
              error={props.errors?.social_reason}
              value={props.agency?.social_reason}
              onChange={(e) => props.changeState(e)}
              required />
            <TextField
              label="Pessoa de contato"
              maxwidth="370px"
              variant="outlined"
              name="contact_person"
              error={props.errors?.contact_person}
              value={props.agency?.contact_person}
              onChange={(e) => props.changeState(e)}
              required />
          </div>
          <div className="flex flex-wrap mt-5 space-x-3 lg:flex-nowrap">
            <PhoneMaskInput
              name="telephone"
              value={props.agency.telephone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({target: {name: 'telephone', value: target.target.value}})}
            />
            <TextField
              className="inputRounded"
              label="E-mail"
              name="email"
              maxwidth="375px"
              variant="outlined"
              error={props.errors?.email}
              value={props.agency?.email}
              onChange={(e) => props.changeState(e)}
              type="email"
              required />
          </div>
          <div className="mt-5">
            <TextField
              className="inputRounded"
              label="Observações"
              placeholder="Observações"
              variant="outlined"
              multiline
              rows={2}
              name="observations"
              error={props.errors?.observations}
              value={props.agency?.observations}
              onChange={(e) => props.changeState(e)}
            />
          </div>
          <div className="mt-10">
            <label className="text-dark">Comissão da agência</label>
            <div className="flex flex-wrap items-center mt-5 text-gray">
              <TextField
                className="mr-5"
                label="Comissão"
                maxwidth="100px"
                select
                variant="outlined"
                name="percentage"
                error={props.errors?.percentage}
                value={props.agency?.percentage}
                onChange={(e) => props.changeState(e)}
                required>
                  {percentages.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
              </TextField>
              <RadioButton
                data={operationalOptions}
                name="operational_cost"
                change={(ev) => props.changeState(ev)}
                value={props.agency?.operational_cost + '' || 'false'}
              />
            </div>
          </div>
          {props.isEditing && <div className="mt-10">
            <label className="text-dark">Status</label>
            <div className="flex mb-4 space-x-3">
              <RadioButton
                data={radioButtonData}
                name="status"
                change={(ev) => props.changeState(ev)}
                value={props.agency?.status + '' || 'false'}
              />
            </div>
          </div>}
        </div>

        <div className="text-right">
          <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
}

export default Form;
