import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { profileUpdate } from '@actions/profile';

const Update = (props) => {
  document.title = `Editar meu perfil - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [oldImage, setOldImage] = useState(null)
  const [profile, setProfile] = useState({
    name: null,
    email: null,
    lang: null,
    image: null,
  })
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Meu perfil',
      active: true,
      route: '/admin/perfil'
    },
    {
      title: 'Editar perfil',
    }
  ]
  const getProfile = ev => {
    setProfile({
      name: ev.name,
      email: ev.email,
      lang: ev.lang,
      image: ev.image
    })
    setIsEditing(true)
    setOldImage(ev.image)
  }

  const changeState = (ev) => {
    let value = ev.target.value;
    setProfile(
      {
        ...profile,
        [ev.target.name]: value
      }
    )
  }

  const changeFile = (data) => {
    setProfile(
      {
        ...profile,
        image: data
      }
    )
  }

  const submitProfileEdit = (ev) => {
    ev.preventDefault();
    const formData = new FormData()

    if (oldImage !== profile.image) {
      formData.append('image', profile.image)
    }
    formData.append('name', profile.name)
    formData.append('email', profile.email)
    formData.append('lang', profile.lang)

    apiClient
      .httpInstance.post('/user/profile', formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          let user = {
            data: response.data.user,
            token: props.user.token
          };
          user.data.permission = props.user.data.permission

          props.profile(user);
          history.push('/admin/perfil');
          window.location.reload()
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }
  useEffect(() => {
    getProfile(props.user.data);
  }, [props.user.data]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {isEditing &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Editar perfil</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push('/admin/perfil')}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitProfileEdit}
            profile={profile}
            changeState={changeState}
            changeFile={changeFile}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProp = dispatch => {
  return {
    profile(user) {
      const action = profileUpdate(user);
      dispatch(action);
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(Update);
