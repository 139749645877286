import Index from './Index';
import Create from './Create';
import Show from './Show';
import Update from './Update';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Tours = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <Switch>
    <Route exact path={`${path}`}>
      <Index theme={theme} setShowMenu={props.setShowMenu} />
    </Route>
    <Route exact path={`${path}/cadastro`}>
      <Create theme={theme} />
    </Route>
    <Route exact path={`${path}/detalhe/:user_id`}>
      <Show theme={theme} />
    </Route>
    <Route exact path={`${path}/editar/:user_id`}>
      <Update theme={theme} />
    </Route>
  </Switch>
}

export default Tours;
