import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import '@assets/css/Admin/components/phoneMaskInput.scss'

const PhoneMaskInput = (props) => {
  return <>
    <PhoneInput
      {...props}
      masks={{cl: '. .... ....'}}
      country={'br'}
    />
  </>
}
export default PhoneMaskInput;
