import Table from '@components/Table';
import { Link, useParams } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { useState } from 'react';
import DeleteModal from '@components/DeleteModal';
import NumberFormat from 'react-number-format';

const permissions = ['administrador'];

const CommissionTable = (props) => {
  const { region_id } = useParams();
  const [rows] = useState([]);
  const [idCommisison, setIdCommission] = useState(null)
  const [disableModal, setDisableModal] = useState(false);
  const path = 'region_commissions/team_variable_rate';

  const openDisableModal = (id) => {
    setDisableModal(true);
    setIdCommission(id)
  }

  const closeDisableModal = () => {
    setDisableModal(false);
    setIdCommission(null)
  }

  return <>
    <Table
      params={{
        region_id
      }}
      onRowClick={null}
      path={path}
      columns={[
        {
          headerName: 'Intervalo inicial de venda',
          headerClassName: 'super-app-theme--header',
          field: 'initial_value',
          renderCell: (params) => {
            return (<NumberFormat
              value={Number(params.row.initial_value)}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
            />);
          },
          flex: 1
        },
        {
          headerName: 'Intervalo final de venda',
          headerClassName: 'super-app-theme--header',
          field: 'final_value',
          renderCell: (params) => {
            return (<NumberFormat
              value={Number(params.row.final_value)}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
            />);
          },
          flex: 1
        },
        {
          headerName: 'Comissão',
          field: 'commission',
          headerClassName: 'super-app-theme--header',
          flex: 1
        },
        {
          headerName: 'Modalidade',
          headerClassName: 'super-app-theme--header',
          field: 'modality',
          flex: 1
        },
        {
          headerName: 'Ações',
          field: 'actions',
          headerAlign: 'right',
          align: 'right',
          headerClassName: 'super-app-theme--header',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            const editLink = `/admin/regioes/detalhe/${region_id}/matriz-de-comissao/${row.id}`;

            return (<div className="flex items-center pr-4 space-x-5">
              {permissions.includes(props?.props?.theme?.permission) && <>
                <Link to={editLink} className="flex items-center">
                  <Icon className="text-lightblue">
                    edit
                  </Icon>
                </Link>
                <Icon className="text-red-500" onClick={() => openDisableModal(row.id)}>
                  delete
                </Icon>
                <DeleteModal
                  open={disableModal}
                  close={() => closeDisableModal}
                  url={`/region_commissions/team_variable_rate/${idCommisison}`}
                  title="Excluir matriz de comissão?"
                  redirect={'/admin/regioes'}
                  description="Você realmente deseja excluir esta matriz de comissão? Essa ação é irreversível,
                    mas você poderá cadastrá-lo no futuro novamente."
                />
              </>}
            </div>);
          }
        },
      ]}
      rows={rows}
    />
  </>
}

export default CommissionTable;
