import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';

const permissions = ['administrador'];

const today = _ => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/vendedores'
  },
  {
    title: `${props.name || ''}`,
    active: true,
    route: `/admin/vendedores/detalhe/${props.id || ''}`
  },
  {
    title: 'Edição',
  }
]);

const Update = (props) => {
  document.title = `Editar vendedores - ${process.env.REACT_APP_NAME}`
  const { salespeople_id } = useParams();
  const [error] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorMessage] = useState("");
  const [salespeople, setSalespeople] = useState({
    id: '',
    name: '',
    email: '',
    birth_date: today(),
    freelancer: false,
    address: '',
    identification_document: '',
    passport_number: '',
    phone: '',
    role_id: '',
    observations: '',
    hab_venda_parceiros: 'true',
    access: 'true',
    status: 'false',
  });
  const url = `/admin/vendedores`;
  const resourcePath = `/sellers/${salespeople_id}`;
  const history = useHistory();

  const getSalespeopleData = resourcePath => {
    apiClient.httpInstance.get(resourcePath).then(response => {
      if (response.status === 200) {
        setSalespeople(response.data);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getSalespeopleData(resourcePath);
  }, [resourcePath]);

  const changeState = (e) => {
    setSalespeople(
      {
        ...salespeople,
        [e.target.name]: e.target.value
      }
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient.httpInstance.put(resourcePath, {
      ...salespeople
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            history.push(url);
          }, 2000);
        }
      })
      .catch(error => {
        if (error) {
          setErrors(error.response.data);
          setTimeout(() => { setErrors("") }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig(salespeople)} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading && <div className="px-7 pt-6">
      {permissions.includes(props?.theme?.permission) ?
        <>
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Link color="inherit" to={`/admin/vendedores/detalhe/${salespeople_id}`}>
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  arrow_back
                </Icon>
                Cancelar
              </Button>
            </Link>
          </div>
          {success && <Alert className="mt-5" severity="success" color="success">
            Vendedor atualizado com sucesso
          </Alert>}
          <Form
            {...props}
            submit={handleSubmit}
            errors={errors}
            salespeople={salespeople}
            isEditing={true}
            changeState={changeState} />
        </>
        :
        <>
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Link color="inherit" to={`/admin/vendedores/detalhe/${salespeople_id}`}>
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  arrow_back
                </Icon>
                Cancelar
              </Button>
            </Link>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </>
      }
    </div>
    }
  </>
}

export default Update;
