import TextField from '@components/TextField';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect } from 'react';
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import BreadCrumbs from '@components/Breadcrumbs';
import { apiClient } from '@services/api';
import Select from '@components/Select';

const Index = (props) => {
  document.title = `Lista de saídas - ${process.env.REACT_APP_NAME}`;
  const today = new Date();
  const [rows] = useState([]);
  const [q, setQ] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [date, setDate] = useState(format(today, "yyyy-MM-dd"));
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const { urlCompanies } = '/companies';

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const changeDate = (date) => {
    setDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const getCompanies = url => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })
        
        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  useEffect(() => {
    getCompanies(urlCompanies);
  }, []);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            departure_board
          </Icon>
          <span style={{fontSize: 'calc(10px + 2vmin)'}}>Lista de saídas</span>
        </div>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <DatePicker
              label="Filtre por data"
              value={date !== null ? `${date} 00:00:00` : null}
              onChange={date => changeDate(date)}
            />
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Filtrar por empresa"
              defaultValue="all"
              gender="f"
              width="230px"
              onChange={(e) => setCompany(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <TextField
              className="inputRounded"
              label="Busque por um guia"
              width="275px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(ev) => setQ(ev.target.value)}
            />
          </div>
        </div>
      </div>
      <Table
        params={{
          q, date, company, pageSize
        }}
        path="/exit/values"
        //path="/exits"
        columns={[
          {
            headerName: 'Saída',
            headerClassName: 'super-app-theme--header',
            field: 'tour_date',
            //flex: 1,
            renderCell: (params) => {
              const row = params.row;
              //if (row.travel_type == 'in' || row.travel_type == 'out')
              return (<div className="flex items-center pr-4 space-x-5">
                <Moment format="DD/MM/YYYY" date={row.tour_date} />
              </div>);
            }
          },
          {
            headerName: 'Produto',
            headerClassName: 'super-app-theme--header',
            field: 'travel_name',
            //flex: 1,
            width: 250,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.travel_name}
                {row.travel_type == 'in' ?
                  <span className="flex items-center justify-center rounded-full ml-1 h-6 w-12 text-white" style={{ background: '#009C86E5' }}>
                    In
                  </span>
                :
                  row.travel_type == 'out' ?
                    <span className="flex items-center justify-center rounded-full ml-1 h-6 w-12 text-white" style={{ background: '#4480A8E5' }}>
                      Out
                    </span>
                    :
                    ''
                }
              </div>);
            }
          },
          {
            headerName: 'Criado por',
            headerClassName: 'super-app-theme--header',
            field: 'user_name',
            flex: 1,
          },
          {
            headerName: 'Data de criação',
            headerClassName: 'super-app-theme--header',
            field: 'created_at',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                <Moment format="DD/MM/YYYY" date={row.created_at} />
              </div>);
            }
          },
          {
            headerName: 'Última alteração',
            headerClassName: 'super-app-theme--header',
            field: 'updated_at',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                <Moment format="DD/MM/YYYY" date={row.updated_at} />
              </div>);
            }
          },
          {
            headerName: 'Qtde. de veículos',
            headerClassName: 'super-app-theme--header',
            field: 'total_vehicle',
            flex: 1,
          },
          {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
              const row = params.row;
              let value = row.status
              const exit_status = {
                draft: {
                  classColor: "text-yellow-500",
                  text: "Rascunho"
                },
                pending: {
                  classColor: "text-gray",
                  text: "Pendente"
                },
                canceled: {
                  classColor: "text-red-500",
                  text: "Cancelado"
                },
                confirmed: {
                  classColor: "text-green-500",
                  text: "Confirmado"
                }
              }
              return <>
                <div className={`flex items-center pr-4 space-x-5 ${exit_status[value].classColor}`}>
                  {exit_status[value].text}
                </div>
              </>;
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default Index;
