import { useParams, useHistory } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import BreadCrumbs from '@components/Breadcrumbs';
import { dateLocale } from '@utils/helpers'
import Button from '@components/Button'
import DeleteModal from '@components/DeleteModal';
import TableDerivedReservation from '../partials/TableDerivedReservation';
import DerivedModal from '../partials/DerivedModal';

const permissions = ['administrador', 'logistica', 'operacao'];

const Show = (props) => {
  const { exit_id, derived_id } = useParams();
  const [exit, setExit] = useState({});
  const [derived, setDerived] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const [disableModal, setDisableModal] = useState(false)
  const exitUrl = `/exits/${exit_id}`;
  const derivedUrl = `/deriveds/${derived_id}`;
  const history = useHistory();
  const types = {
    single: 'Privado',
    group: 'Grupo'
  };

  const getExitsDetails = exitUrl => {
    apiClient.httpInstance.get(exitUrl).then(response => {
      if (response.status === 200) {
        setExit(response.data);
        setLoading(false);
      }
    });
  }

  const getDerivedDetails = derivedUrl => {
    apiClient.httpInstance.get(derivedUrl).then(response => {
      if (response.status === 200) {
        setDerived(response.data);
        setLoading(false);
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: `Início`,
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `${exit?.travel_name || ''} (${dateLocale(exit?.tour_date)})`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}`
    },
    {
      title: `Detalhes de derivado de ${derived?.agency_name}`,
    }
  ]

  useEffect(() => {
    getExitsDetails(exitUrl);
  }, [exitUrl]);

  useEffect(() => {
    getDerivedDetails(derivedUrl);
  }, [derivedUrl]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-end justify-between mt-2 text-2xl text-blue">
          <span>Detalhes de {derived.agency_name}</span>
          {permissions.includes(props?.theme?.permission) && <div className="w-100">
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => history.push(`/admin/saidas/detalhe/${exit_id}/derivados/editar/${derived_id}`)}
            >
              <Icon className="mr-2">
                edit
              </Icon>
              Editar
            </Button>
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              bgColor="#F44336"
              onClick={() => setDisableModal(true)}
            >
              <Icon className="mr-2">delete</Icon>
              Excluir
            </Button>
          </div>}
        </div>
        <div className="flex">
          <div className="flex-1 w-8/12 mt-4 mr-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl text-blue font-poppins">
              <span>Derivado de {derived?.agency_name}</span>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Nome do guia
              </div>
              <div className="flex-1 text-gray">
                {derived?.driver_name}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Placa do veículo
              </div>
              <div className="flex-1 text-gray">
                {derived?.license_plate}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Horário
              </div>
              <div className="flex-1 text-gray">
                {derived?.schedule}
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          open={disableModal}
          close={() => setDisableModal(false)}
          url={`/deriveds/${derived_id}`}
          title="Excluir derivado?"
          redirect={`/admin/saidas/detalhe/${exit_id}`}
          description="Você realmente deseja excluir este derivado? Essa ação é irreversível,
          mas você poderá cadastrá-lo no futuro novamente."
        />
        <TableDerivedReservation
          {...props}
          path={`derived/${derived_id}/reservations`}
          derived={derived}
          onClick={() => setOpen(true)}
        />
        <DerivedModal
          theme={props.theme}
          derived={derived}
          derivedDetail={() => getDerivedDetails(derivedUrl)}
          exit={exit}
          open={open}
          close={() => setOpen(false)}
        />
      </div>
    </>
  } else {
    return <>
    </>
  }
}

export default Show;
