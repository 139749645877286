import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CuposModal from './partials/CuposModal';
import { format, isValid } from 'date-fns';
import { connect } from 'react-redux';
import DeleteModal from '@components/DeleteModal';
import Alert from '@material-ui/lab/Alert';
import NumberFormat from 'react-number-format';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do passaio - ${process.env.REACT_APP_NAME}`
  const { tour_id } = useParams();
  let [tour, setTour] = useState({});
  let [regionName, setRegionName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [cupos, setCupos] = useState({
    availables_cupos: null,
    date_type: null,
    days: [],
    tour_id: null,
    company_id: null,
    cupo_date: null
  })
  const [period, setPeriod] = useState({
    initial: null,
    final: null
  })
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let url = `/tours/${tour_id}`;
  const history = useHistory();

  const changeState = (ev) => {
    let value = ev.target.value;

    setCupos(
      {
        ...cupos,
        [ev.target.name]: value
      }
    )
  }

  const changeDate = (date, name) => {

    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setPeriod({
      ...period,
      [name]: value
    })
  }

  const getRegionData = url => {
    apiClient.httpInstance.get(url).then(async response => {
      if (response.status === 200) {
        setTour(response.data);
        const regionName = await getRegionById(response.data.region)
        setRegionName(regionName)
        setLoading(false)
      }
    });
  }

  const handleCreate = (ev) => {
    ev.preventDefault();
    const initial = period.initial !== null ? format(new Date(`${period.initial} 00:00:00`), "dd/MM/yyyy") : null
    const final = period.final !== null ? format(new Date(`${period.final} 00:00:00`), "dd/MM/yyyy") : null
    let data = {
      availables_cupos: cupos.availables_cupos,
      date_type: period.final ? 'period' : 'single',
      days: cupos.days,
      tour_id: tour_id,
      company_id: props.user.data.company_id,
      cupo_date: final ? `${initial}-${final}` : initial
    }

    apiClient
      .httpInstance.post("/seats", data)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setOpenModal(false)

          setSuccess(true);
          setSuccessMessage("Salvo com sucesso!")
          setTimeout(() => { setSuccess(false) }, 4000);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  const getRegionById = id => {
    return apiClient
      .httpInstance.get(`/regions/${id}`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          return response.data.name
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getRegionData(url);
  }, [url]);

  return <>
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <Link color="inherit" to="/admin/passeios">
        <span className="text-blue font-poppins">Início</span>
      </Link>
      <span className="font-poppins">{tour.name}</span>
    </Breadcrumbs>
    {
      !loading &&
      <>
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>{tour.name}</span>
            {permissions.includes(props?.theme?.permission) && <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/passeios/editar/${tour_id}`)}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                bgColor="#F44336"
                onClick={() => setDisableModal(true)}
              >
                <Icon className="mr-2">delete</Icon>
                Excluir
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/tours/${tour_id}`}
                title="Excluir passeio?"
                redirect={'/admin/passeios'}
                description="Você realmente deseja excluir este passeio? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>}
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl text-blue font-poppins">
              <span>Informações</span>
            </div>
            <div className="flex p-4 py-5 border-t border-b border-solid border-lightgray">
              <div className="flex items-center flex-1">
                <span>Imagem do passeio</span>
              </div>
              <div className="flex-1">
                <img src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Passeio
              </div>
              <div className="flex-1">
                {tour.name}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Região da venda
              </div>
              <div className="flex-1">
                {regionName ? regionName : "Sem região"}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Período do passeio
              </div>
              <div className="flex-1">
                {tour.tour_period === "halfday" ? "Meio período" : "Integral"}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Período do dia
              </div>
              <div className="flex-1">
                {tour.tour_period === "fullday" ? "Integral" : tour.day_period}

              </div>
            </div>
            <div className="flex p-4 border-t">
              <div className="flex-1">
                Descrição
              </div>
              <div className="flex-1">
                {tour.description}
              </div>
            </div>
          </div>
          <div className="my-1">
            {error && (
              <Alert className="mb-5" variant="filled" severity="error">
                {errorMessage}
              </Alert>
            )}
            {success &&
              <Alert className="mt-5" severity="success" color="success">
                {successMessage}
              </Alert>
            }
            <div className="flex p-4">
              <div className="flex-1 space-x-3">
                <span
                  className="cursor-pointer text-blue font-poppins"
                  onClick={() => setOpenModal(true)}
                >
                  Adicionar vagas
                </span>
                <span
                  className="cursor-pointer text-blue font-poppins"
                  onClick={() => history.push(`/admin/passeios/detalhe/${tour_id}/cupos`)}
                >
                  Visualizar cupos
                </span>
              </div>
            </div>
          </div>
          <div className="mb-8 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl text-blue font-poppins">
              <span>Valor ({`${process.env.REACT_APP_CURRENCY}`})</span>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Valor para adultos
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Desconto para adultos
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.adult_discount}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Valor para crianças
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.kids_price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Desconto para crianças
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.kid_discount}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Custo operacional para adultos (Parceiros)
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.operational_cost}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Custo operacional para crianças (Parceiros)
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.kid_operational_cost}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Custo operacional para adultos (Venda)
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.operational_cost_sale}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Custo operacional para crianças (Venda)
              </div>
              <div className="flex-1">
                <NumberFormat
                  value={tour.kid_operational_cost_sale}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </div>
            </div>
          </div>
        </div>
        <CuposModal
          open={openModal}
          close={() => setOpenModal(false)}
          onCreate={handleCreate}
          cupos={cupos}
          period={period}
          changeState={changeState}
          changeDate={changeDate}
          error={error}
          errorMessage={errorMessage}
          {...props}
        />
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Show);
