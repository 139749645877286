import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Table from '@components/Table';
import Select from '@components/Select';
import Icon from '@material-ui/core/Icon';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const Index = (props) => {
  document.title = `Lista de combos - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  let [rows] = useState([]);
  let [tour, setTour] = useState(null);
  let [toursData, setToursData] = useState([]);
  const [from_date, setFromDate] = useState(null)
  const [to_date, setToDate] = useState(null)
  let url = '/tours';

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const setTourData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setToursData(response.data.data);
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    setTourData(url);
  }, [url]);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            card_travel
          </Icon>
          <span>Lista de combos</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('/admin/combos/cadastro')}
          customcolor={props.theme.color}><Icon className="mr-2">add</Icon> Novo</Button>}
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center space-x-3">
          <div>
            <Select
              className="inputRounded pr-2"
              label="Filtre por passeio"
              width="200px"
              defaultValue="all"
              onChange={(e) => setTour(e.target.value === "all" ? null : e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: toursData
              }}
            />
          </div>
          <div>
            <DatePicker
              label="Data inícial"
              value={from_date !== null ? `${from_date} 00:00:00` : null}
              onChange={date => changeFromDate(date)}
            />
          </div>
          <div>
            <DatePicker
              label="Data final"
              value={to_date !== null ? `${to_date} 00:00:00` : null}
              minDate={`${from_date} 00:00:00`}
              onChange={date => changeToDate(date)}
            />
          </div>
        </div>
      </div>
      <Table
        path="/combos"
        params={{
          tour, from_date, to_date
        }}
        columns={[
            {
              headerName: 'Nome do combo',
              headerClassName: 'super-app-theme--header',
              field: 'name',
              flex: 1,
            },
            {
              headerName: 'Desconto',
              headerClassName: 'super-app-theme--header',
              field: 'discount',
              flex: 1,
            },
            {
              headerName: 'De',
              headerClassName: 'super-app-theme--header',
              field: 'from_date',
              flex: 0.5,
              type: 'date',
              renderCell: (params) => {
                const row = params.row;
                return (<div className="flex items-center pr-4 space-x-5">
                  <Moment format="DD/MM/YYYY" date={row.from_date} />
                </div>);
              }
            },
            {
              headerName: 'Até',
              headerClassName: 'super-app-theme--header',
              field: 'to_date',
              flex: 0.5,
              renderCell: (params) => {
                const row = params.row;
                return (row.to_date && row.to_date !== null ? <div className="flex items-center pr-4 space-x-5">
                  <Moment format="DD/MM/YYYY" date={row.to_date} />
                </div> : '');
              }
            },
            {
              headerName: 'Status',
              headerClassName: 'super-app-theme--header',
              headerAlign: 'right',
              align: 'right',
              field: 'status',
              flex: 0.5,
            }
          ]}
        rows={rows}></Table>
    </div>
  </>
}

export default Index;
