import TextField from '@components/TextField';
import Button from '@components/Button';
import Select from '@components/Select';
import InputFile from '@components/InputFile'

const Form = (props) => {
  const langOptions = [
    {
      value: "es",
      name: "Espanhol"
    },
    {
      value: "en",
      name: "Inglês"
    },
    {
      value: "pt",
      name: "Português"
    },
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex itens-center justify-center py-3">
          <div className="flex-none w-1/3 mb-4">
            <InputFile changeFile={props.changeFile} banner={props.profile.image} />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome"
              variant="outlined"
              type="text"
              name="name"
              value={props.profile.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              value={props.profile.email}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none">
            <Select
              withoutAll
              width="170px"
              label="Idioma"
              name="lang"
              value={props.profile.lang}
              onChange={(ev) => props.changeState(ev)}
              required
              items={{
                field: {
                  value: 'value',
                  text: 'name'
                },
                data: langOptions
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form
