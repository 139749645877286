import { useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@assets/css/Admin/components/calendar.scss';
import DeleteModal from '@components/DeleteModal';
import { format } from 'date-fns';
import { apiClient } from '@services/api';

const renderEventContent = (props) => {
  return <div className="w-full h-full text-xs text-blue font-poppins">
    <p className="break-words">Vagas</p>
    <p className="break-words">Disponíveis: {Number(props.event._def.extendedProps.availables_cupos) > 0 && Number(props.event._def.extendedProps.availables_cupos) - Number(props.event._def.extendedProps.reserved_cupos) > 0 || Number(props.event._def.extendedProps.availables_cupos) - Number(props.event._def.extendedProps.reserved_cupos) === 0 ? Number(props.event._def.extendedProps.availables_cupos) - Number(props.event._def.extendedProps.reserved_cupos) : Number(props.event._def.extendedProps.availables_cupos)}</p>
    <p className="break-words">Reservados: {props.event._def.extendedProps.reserved_cupos}</p>
  </div>
}

const Calendar = (props) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const getEvents = () => {
    const cuposArray = Object.values(props.cupos)
    const events = cuposArray.map((obj) => ({
      id: obj.id,
      date: obj.cupo_date,
      extendedProps: {
        availables_cupos: obj.availables_cupos,
        reserved_cupos: obj.reserved_cupos
      },
    }))
    return events
  }

  const calendarRef = useRef(null);

  const handleSelect = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    arg.end.setDate(arg.end.getDate() - 1)
    const from_date = format(arg.start , "yyyy-MM-dd")
    const to_date = format(arg.end , "yyyy-MM-dd")
    const dateStart = format(arg.start , "dd/MM/yyyy")
    const dateEnd = format(arg.end , "dd/MM/yyyy")
    setDateStart(dateStart)
    setFromDate(from_date)
    setToDate(to_date)
    if(to_date!== from_date) {
      setDateEnd(dateEnd)
    }
    setDeleteModal(true)
  }

  const deleteCupos = (getSeats) => {
    apiClient.httpInstance.post('/seats/delete_from_period', {
      from_date: fromDate,
      to_date: toDate
    })
      .then(response => {
        if (response.status === 200 || response.status === 204) {
          setDeleteModal(false)
          getSeats()
        }
      });
  }

  return <>
    <FullCalendar
      ref={calendarRef}
      locale="pt-br"
      timeZone="local"
      events={getEvents()}
      eventColor="transparent"
      headerToolbar={{
        start: 'prev',
        center: 'title',
        end: 'next'
      }}
      plugins={[
        dayGridPlugin,
        interactionPlugin
      ]}
      initialView="dayGridMonth"
      select={handleSelect}
      selectable
      eventContent={renderEventContent}
    />
    <DeleteModal
      open={deleteModal}
      close={() => setDeleteModal(false)}
      delete={() => deleteCupos(props.getSeats)}
      title="Excluir cupos?"
      description={`Você realmente deseja excluir cupos do dia ${dateStart}${dateEnd !== null ? ` até ${dateEnd}` : ''}? Essa ação é irreversível, mas você poderá cadastrá-los no futuro novamente.`}
    />
  </>
}

export default Calendar;
