import { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import Icon from '@material-ui/core/Icon';
import Button from '@components/Button';
import DeleteModal from '@components/DeleteModal';
import ChangeDate from '../../budgets/partials/ChangeDate';
import ChangeDateTransfer from '../../budgets/partials/ChangeDateTransfer';
import { format } from 'date-fns';
import '@assets/css/Admin/components/table.scss';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

const TableCart = (props) => {
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [changeDate, setChangeDate] = useState(false)
  const [changeDateTransfer, setChangeDateTransfer] = useState(false)
  const [date, setDate] = useState(null)
  const [tourId, setTourId] = useState(null)
  const [index, setIndex] = useState(null)
  const [idxRow, setIdxRow] = useState('');
  const [viewDate, setViewDate] = useState(false)
  const [finished, setFinished] = useState(false)
  const [existBudgets, setExistBudgets] = useState(false)
  const [existReservations, setExistReservations] = useState(false)
  const history = useHistory();
  const url = '/user/cart';

  const handleDelete = (id) => {
    setSelectedId(id)
    setDeleteModal(true)
  }

  const checkBudgets = () => {
    props.rows.forEach(element => {
      if (element.is_budget) {
        setExistBudgets(true)
      }
      if (!element.is_budget) {
        setExistReservations(true)
      }
    })
  }

  useEffect(() => {
    checkBudgets()
  }, [url]);

  const openDateModal = (row, idx) => {
    setDate(format(new Date(), "yyyy-MM-dd"))
    setTourId(row.travel_id)
    setIndex(idx)
    row.type === 'tour' ? setChangeDate(true) : setChangeDateTransfer(true)
  }

  const verifyFinished = () => {
    if (props?.budget) {
      let el = 0
      props.rows.forEach(element => {
        if (element.tour_date != null) {
          el++
        }
      })
      if (props.rows.length == el) {
        setFinished(true)
      }
    }
  }

  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="font-bold text-blue">Meus passeios</TableCell>
              <TableCell className="font-bold text-blue">Adultos</TableCell>
              <TableCell className="font-bold text-blue">Crianças</TableCell>
              <TableCell className="font-bold text-blue">Data da reserva</TableCell>
              <TableCell className="font-bold text-blue">Valor</TableCell>
              <TableCell className="font-bold text-blue"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, idx) => (
              <TableRow key={row.id}>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  <div className="flex items-center">
                    {row.is_budget && <Icon>
                      receipt_long
                    </Icon>}
                    <div className="flex-none">
                      <img src={row.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${row.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
                    </div>
                    <div className="flex-auto space-y-1">
                      <div className="text-lg font-medium">
                        {row.travel_name} {row.transfer_type ? `(${row.transfer_type})` : null}
                      </div>
                      <div>
                        {row.desc}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  <NumberFormat
                    value={Number(row.type === 'tour' ? row.adults_total : row.cupos) || 0}
                    displayType={'text'}
                    decimalSeparator={null}
                    thousandSeparator={"."}
                    isNumericString
                    prefix=""
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  <NumberFormat
                    value={Number(row.kids_total) || 0}
                    displayType={'text'}
                    decimalSeparator={null}
                    thousandSeparator={"."}
                    isNumericString
                    prefix=""
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  {props?.budget ?
                    <>
                      {row.type === 'transfer' && <>
                        {row.tour_date && <><Moment format="DD/MM/YYYY" date={row.tour_date} /> ({row.flight_time})</>}
                        <p
                          className="text-red-500 cursor-pointer"
                          onClick={() => openDateModal(row, idx)}
                        >
                          Escolher data/hora
                        </p>
                      </>
                      }
                      {row.type === 'tour' && <>
                        {row.tour_date && <Moment format="DD/MM/YYYY" date={row.tour_date} />}
                        <p
                          className="text-red-500 cursor-pointer"
                          onClick={() => openDateModal(row, idx)}
                        >
                          Escolher data
                        </p>
                      </>
                      }</>
                    :
                    <Moment format="DD/MM/YYYY" date={row.tour_date} />
                  }
                </TableCell>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  <NumberFormat
                    value={Number(row.total_amount/*  - (row.total_amount * (row.discount_total_sale / 100)) */) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                  <Icon
                    className="mr-2 text-red-500 cursor-pointer"
                    onClick={() => handleDelete(row.id)}
                  >
                    delete
                  </Icon>
                </TableCell>

                {/* Modal para inclusão da data */}
                {row.type === 'tour' && <ChangeDate
                  type={row.type}
                  open={changeDate}
                  close={() => setChangeDate(false)}
                  setDate={props.changeDate}
                  setViewDate={setViewDate}
                  date={date}
                  idx={index}
                  tourId={tourId}
                  verifyFinished={verifyFinished}
                  {...props}
                />}
                {row.type === 'transfer' && <ChangeDateTransfer
                  type={row.type}
                  open={changeDateTransfer}
                  close={() => setChangeDateTransfer(false)}
                  setDate={props.changeDate}
                  setViewDate={setViewDate}
                  date={date}
                  idx={index}
                  tourId={tourId}
                  verifyFinished={verifyFinished}
                  {...props}
                />}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        url={`/user/cart/products/${selectedId}/delete`}
        title="Excluir item?"
        refresh={() => props.refresh(url)}
        description="Você realmente deseja excluir este item do carrinho? Essa ação é irreversível,
        mas você poderá adicionar no futuro novamente."
      />
    </div>
    <div className="flex justify-end space-x-3">
      {existReservations && !existBudgets && <><Button
        textcolor="#212121"
        padding="4px 15px"
        width="300"
        variant="outlined"
        bgColor="transparent"
        onClick={() => props.openModal(true)}
      >
        Adicionar outra reserva
      </Button>
      <Button
        textcolor="#FFF"
        padding="4px 15px"
        width="200"
        disabled={finished ? false : props?.budget ? true : false}
        customcolor={props.theme.color}
        onClick={() => props.finishBuy()}
      >
        Finalizar compra
      </Button></>}
      {!existReservations && existBudgets && <Button
        textcolor="#FFF"
        padding="4px 15px"
        width="200"
        disabled={finished ? false : props?.budget ? true : false}
        customcolor={props.theme.color}
        onClick={() => props.finishBuy()}
      >
        Finalizar compra
      </Button>}
    </div>
    {existBudgets && existReservations && <span><small>Remova os orçamentos para continuar</small></span>}
  </>
}

export default TableCart;
