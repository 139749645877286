import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DeleteModal from '@components/DeleteModal'
import CircularProgress from '@material-ui/core/CircularProgress';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do motorista - ${process.env.REACT_APP_NAME}`
  const host = window.location.host
  const { driver_id } = useParams();
  const [driver, setDriver] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/drivers/${driver_id}`;

  const getDriverDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setDriver(response.data);
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const userLogged = JSON.parse(localStorage.getItem('user'));

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/motoristas'
    },
    {
      title: `${driver.name}`,
    }
  ]

  useEffect(() => {
    getDriverDetails(url);
  }, [url]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{driver.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/motoristas/editar/${driver_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Desativar
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/drivers/${driver_id}`}
                title="Excluir motorista?"
                redirect={'/admin/motoristas'}
                description="Você realmente deseja excluir este motorista? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do motorista</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do motorista
            </div>
            <div className="flex-1">
              {driver.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {driver.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {driver.phone}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Endereço
            </div>
            <div className="flex-1">
              {driver.address}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Número da licença
            </div>
            <div className="flex-1">
              {driver.identification_document}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Tipo da licença
            </div>
            <div className="flex-1">
              {driver.license_type}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Observações
            </div>
            <div className="flex-1">
              {driver.observation}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Link de acesso de motorista
            </div>
            <div className="flex-1 underline text-primary truncate -mx-4">
              <Link to={`/saidas/motorista/${driver.url_link}/Motorista`} target="_blank" >
                {host}/saidas/motorista/{driver.url_link}/Motorista
              </Link>
            </div>
          </div>
          {(userLogged.data.permission === 'Administrador' || userLogged.data.permission === 'Gerente' || userLogged.data.permission === 'Logistica') && <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Link de acesso de administrador
            </div>
            <div className="flex-1 underline text-primary truncate -mx-4">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="200px"
                onClick={() => history.push(`/admin/motoristas/saidas/${driver.id}/${driver.url_link}`)}
                customcolor={props.theme.color}
              >
                Visualizar saídas
              </Button>
            </div>
          </div>}
        </div>
      </div>
    </>
  } else {
    return <div className="grid w-full h-full justify-items-center">
      <CircularProgress size={50} />
    </div>
  }

}

export default Show;
