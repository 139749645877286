import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DeleteModal from '@components/DeleteModal'
import Moment from 'react-moment';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do combo - ${process.env.REACT_APP_NAME}`
  const { combo_id } = useParams();
  const [combo, setCombo] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/combos/${combo_id}`

  const getComboDetails = (url) => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCombo({
          from_date: response.data.from_date,
          to_date: response.data.to_date,
          name: response.data.name,
          discount: response.data.discount,
          status: response.data.status,
          tours: response.data.tours.map(obj => { return obj.name })
        });
        setLoading(true)
      }
    })
      .catch((error) => console.log(error));
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/combos'
    },
    {
      title: `${combo.name}`,
    }
  ]

  useEffect(() => {
    getComboDetails(url);
  }, [url]);

  if (loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{combo.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/combos/editar/${combo_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Desativar
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/combos/${combo_id}`}
                title="Desativar combo?"
                redirect={'/admin/combos'}
                description="Você realmente deseja desativar este combo? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
                btnText="Desativar"
              />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do combo</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do combo
            </div>
            <div className="flex-1">
              {combo.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Passeios
            </div>
            <div className="flex-1">
              {combo?.tours?.join(", ")}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Período
            </div>
            <div className="flex-1">
              <Moment format="DD/MM/YYYY" date={combo.from_date} /> - <Moment format="DD/MM/YYYY" date={combo.to_date} />
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Porcentagem
            </div>
            <div className="flex-1">
              {combo.discount}%
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Status
            </div>
            <div className="flex-1">
              {combo.status}
            </div>
          </div>
        </div>
      </div>
    </>
  } else {
    return <>
    </>
  }
}

export default Show;
