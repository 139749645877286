import Index from './Index';
import Show from './Show';
import Create from './Create';
import Update from './Update';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Transfers = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <Switch>
    <Route exact path={`${path}`}>
      <Index theme={theme} setShowMenu={props.setShowMenu} ></Index>
    </Route>
    <Route exact path={`${path}/detalhe/:transfer_id`}>
      <Show theme={theme}></Show>
    </Route>
    <Route exact path={`${path}/:transfer_id/cadastro`}>
      <Create theme={theme}></Create>
    </Route>
    <Route exact path={`${path}/editar/:value_id`}>
      <Update theme={theme}></Update>
    </Route>
  </Switch>
}

export default Transfers;
