import { useState, useEffect } from 'react';
import Button from '@components/Button';
import ClientForm from '../../partials/form/ClientForm';
import ModalDerived from './partials/ModalDerived';

const ContentStep1 = (props) => {
  const [open, setOpen] = useState(false)
  const [emailValidate, setEmailValidate] = useState(false)

  useEffect(() => {
    if(props?.client?.email){
      setEmailValidate(true)
    }
  }, [props.client]);
  
  const idDisabled = () => {
    const client = props.client
    if (client.email && emailValidate && client.document && client.name && client.phone?.length > 14 && !client.derived) {
      if ((props.type === "cart" || props.type === "transfer")) {
        return false
      }
      if ((props.type === "tour" || props.type === "combo")) {
        return false
      }
    } else if (client.partnerEmail && client.email && client.phone?.length > 14) {
      return false
    }
    return true
  }

  const openModal = () => {
    const client = props.client
    if (client.partnerEmail === client.email) {
      setOpen(true)
    } else {
      props.changeStep(2)
    }
  }

  const isDerived = (confirm) => {
    if (confirm) {
      props.changeClient({
        target: {
          name: 'derived',
          value: confirm
        }
      })
      props.changeStep(2)
    } else {
      const data = {
        derived: confirm,
        email: ''
      }
      props.notDerived(data)
    }
  }
  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <ClientForm
        client={props.client}
        reserve={props.reserve}
        changeReserve={props.changeReserve}
        clientEmail={props.reservationData?.cart[0]?.customer_email}
        regionsTravel={props.regionsTravel}
        changeClientByEmail={props.changeClientByEmail}
        changeClientByPartner={props.changeClientByPartner}
        changeClient={props.changeClient}
        changeClientAddress={props.changeClientAddress}
        changeObservations={props.changeObservations}
        onlyLetterAndNumber={props.onlyLetterAndNumber}
        changeClientCheckbox={props.changeClientCheckbox}
        changeSetEmail={setEmailValidate}
      />
      <ModalDerived
        open={open}
        close={() => setOpen(false)}
        isDerived={isDerived}
      />
    </div>
    <div className="flex space-x-5 justify-end mb-4">
      <Button
        textcolor="#212121"
        padding="6px 15px"
        width="100px"
        variant="outlined"
        onClick={props.backPage}
        bgColor="transparent"
      >
        Voltar
      </Button>
      <Button
        disabled={idDisabled()}
        textcolor="#fff"
        padding="6px 15px"
        width="260px"
        onClick={() => props.create()}
        customcolor={props.theme.color}
      >
        Confirmar orçamento
      </Button>
    </div>
  </>
}

export default ContentStep1;