import TextField from '@components/TextField';
import { apiClient } from '@services/api';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Select from '@components/Select';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import BreadCrumbs from '@components/Breadcrumbs';

const ToursTable = (props) => {
  document.title = `Lista de passeios - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  let [rows] = useState([]);
  const [q, setQ] = useState(null)
  const [cupos, setCupos] = useState(0)
  const [from_date, setFromDate] = useState(null)
  const [to_date, setToDate] = useState(null)
  let [region, setRegion] = useState("all");
  let [regionsData, setRegionsData] = useState([]);
  let [filterStatus, setFilterStatus] = useState([]);
  let urlRegions = '/regions';

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: 'Passeios',
    }
  ];

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const getRegionData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })

        setRegionsData(regions);
      }
    });
  }

  const getRegionById = id => {
    return apiClient
      .httpInstance.get(`/regions/${id}`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          return response.data.name
        }
      })
      .catch(error => {
        if (error) {
          return 'Sem região'
        }
      });
  }

  const getArrayWithRegionName = async (array) => {
    const value = array.map(async (obj) => {
      return {
        ...obj,
        regionName: !Number(obj.region) ? obj.region : await getRegionById(obj.region)
      }
    })
    return Promise.all(value)
  }

  useEffect(() => {
    getRegionData(urlRegions);
  }, [urlRegions]);

  return <>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Passeios</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="grid items-center grid-cols-5 gap-4">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por um passeio"
              variant="outlined"
              type="text"
              name="q"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <Select
              label="Filtrar por Região"
              defaultValue="all"
              gender="f"
              onChange={(e) => setRegion(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: regionsData
              }}
            ></Select>
          </div>
          <div className="flex items-center justify-end col-start-5 col-end-6">
            <Select
              label="Status"
              defaultValue="all"
              onChange={(e) => console.log(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: filterStatus
              }}
            ></Select>
          </div>
        </div>
      </div>
      <Table
        path="/tours?reservations=true"
        params={{
          q, region, from_date, to_date, cupos
        }}
        onRowClick={null}
        columns={[
          {
            headerName: 'Passeio',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
          },
          {
            headerName: 'Pax',
            headerClassName: 'super-app-theme--header',
            field: 'price',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={Number(row.price)}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: 'Pax criança',
            headerClassName: 'super-app-theme--header',
            field: 'kids_price',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={row.kids_price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: ' ',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              const link = `passeios/${row.id}/comprar`
              return (<div className="flex items-center pr-4 mb-3">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(link, { cupos: 0 })}
                  bgColor="transparent"
                >
                  Orçar
                </Button>
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default ToursTable;