const CompanyProfile = (props) => {
  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{borderRadius: '10px'}}>
      <div className="p-4 text-xl text-blue font-poppins">
        <span>Informações</span>
      </div>
      {props.company &&
        <>
          <div className="flex p-4 border-t border-solid border-lightgray">
            <div className="flex-1">
              Logotipo
            </div>
            <div className="flex-1">
              <img src={props.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.company.logo}` : null} width="60" className="rounded-full" alt="logo" />
            </div>
          </div>
          <div className="flex p-4 border-t border-solid border-lightgray">
            <div className="flex-1">
              Nome da empresa
            </div>
            <div className="flex-1">
              {props.company.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-solid border-lightgray">
            <div className="flex-1">
              RUT
            </div>
            <div className="flex-1">
              {props.company.rut}
            </div>
          </div>
          <div className="flex p-4 border-t border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {props.company.phone}
            </div>
          </div>
          <div className="flex p-4 border-t border-solid border-lightgray">
            <div className="flex-1">
              Endereço
            </div>
            <div className="flex-1">
              {props.company.address}, {props.company.number}
            </div>
          </div>
        </>
      }
    </div>
  </>
}


export default CompanyProfile;
