import Button from '@components/Button';
import { dateLocale } from '@utils/helpers';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import DeleteModal from '@components/DeleteModal';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do vendedor - ${process.env.REACT_APP_NAME}`
  const { salespeople_id } = useParams();
  const [deleteRegionModal, setDeleteRegionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [salespeople, setSalespeople] = useState({});
  const url = `/sellers/${salespeople_id}`;
  const history = useHistory();

  const getSalespeopleData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setSalespeople(response.data);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getSalespeopleData(url);
  }, [url]);

  return <>
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <Link color="inherit" to="/admin/vendedores">
        <span className="text-blue font-poppins">Início</span>
      </Link>
      <span className="font-poppins">{salespeople.name}</span>
    </Breadcrumbs>
    {!loading &&
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{salespeople.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/vendedores/editar/${salespeople_id}`)}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => setDeleteRegionModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Excluir
              </Button>
              <DeleteModal
                open={deleteRegionModal}
                close={() => setDeleteRegionModal(false)}
                url={`sellers/${salespeople.id || ''}`}
                title="Excluir vendedor?"
                redirect={'/admin/vendedores'}
                description="Você realmente deseja excluir este vendedor? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente." />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do vendedor
            </div>
            <div className="flex-1">
              {salespeople.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {salespeople.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Data de nascimento
            </div>
            <div className="flex-1">
              {dateLocale(salespeople.birth_date)}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Descrição
            </div>
            <div className="flex-1">
              {salespeople.observations}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Endereço
            </div>
            <div className="flex-1">
              {salespeople.address}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray ">
            <div className="flex-1">
              Doc. de identificação
            </div>
            <div className="flex-1">
              {salespeople.identification_document}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray ">
            <div className="flex-1">
              Passaporte
            </div>
            <div className="flex-1">
              {salespeople.passport_number}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray ">
            <div className="flex-1">
              Com salário fixo
            </div>
            <div className="flex-1">
              {salespeople.freelancer ? 'Não' : 'Sim'}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray ">
            <div className="flex-1">
              Habilitação de venda para parceiros
            </div>
            <div className="flex-1">
              {salespeople.hab_venda_parceiros ? 'Sim' : 'Não'}
            </div>
          </div>
        </div>
      </div>
    }
  </>
}

export default Show;
