import React from 'react';
import '@css/Admin/index.scss';
import Header from './partials/Header';
import Content from './partials/Content';
import Exits from './pages/exits/main';
import AdminConfig from '@config/admin';
import { slugfy } from '@utils/helpers';
import { connect } from 'react-redux';
import {
    Switch,
    Route,
    useRouteMatch,
    // Redirect,
    useParams
} from "react-router-dom";

const Driver = props => {
    let { path } = useRouteMatch();
    let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.logistica;
    let permission = useParams().permission;
    return <Switch>
      <React.Fragment>
        <div className="flex flex-col admin">
          <Header theme={theme} />
          <div className="flex flex-row admin-body">
            <Content>
              <Route path={`${path}`}>
                <Exits theme={theme} permission={permission} />
              </Route>
            </Content>
          </div>
        </div>
      </React.Fragment>
    </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Driver);
