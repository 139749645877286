import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableBodyCart from './tableBody/TableBodyCart';

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const FinishTable = (props) => {
  const classes = useStyles();
  const tableBody = <TableBodyCart data={props.data} finish={props.finish} budget={props.budget}/>
  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <div className="p-4 text-lg text-blue font-poppins border-b border-solid border-lightgray">
        <span>Informações do orçamento</span>
      </div>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className="font-bold leading-5 text-blue border-0">
                Minhas reservas
              </TableCell>
              <TableCell className="font-bold leading-5 text-blue border-0">
                Passageiros
              </TableCell>
              <TableCell className="font-bold leading-5 text-blue border-0">
                Valor
              </TableCell>
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
    </div>
  </>
}

export default FinishTable;
