import { useState, useEffect } from 'react';
import DatePicker from '@components/DatePicker';
import Button from '@components/Button';
import Select from '@components/Select'
import { apiClient } from '@services/api'

const Form = (props) => {
  const [productOptions, setProductOptions] = useState([])

  const tourTypeOptions = [
    {
      name: "Privado",
      key: "private"
    },
    {
      name: "Em grupo",
      key: "group"
    }
  ]

  const getTours = () => {
    apiClient
    .httpInstance.get('/logistic/exits', {
      params: {
        reservations: true
      }
    })
    .then(response => {
      if (response.status === 200) {
        setProductOptions(response.data.data);
      }
    });
  }
  useEffect(() => {
    getTours()
  }, [])
  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-none">
            <DatePicker
              label="Data"
              name="tour_date"
              value={props.exit.tour_date !== null ? `${props.exit.tour_date} 00:00:00` : null}
              onChange={date => props.changeDate(date)}
            />
          </div>
          <div className="flex-1">
            <Select
              withoutAll
              label="Produto"
              value={props.exit.reservation_id}
              name="reservation_id"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'id',
                  text: 'travel_name'
                },
                data: productOptions
              }}
            />
          </div>
          <div className="flex-1">
            <Select
              withoutAll
              label="Tipo de passeio"
              value={props.exit.tour_type}
              name="type"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'key',
                  text: 'name'
                },
                data: tourTypeOptions
              }}
              />
          </div>
        </div>
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  </>
}

export default Form;
