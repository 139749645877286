import Index from './Index';
import ToursTable from './product/tour/ToursTable'
import CombosTable from './product/combo/CombosTable'
import BuyTour from './product/tour/BuyTour';
import BuyCombo from './product/combo/BuyCombo';
import Show from './Show';
import Update from './Update';
import TransferTable from './product/transfer/TransferTable'
import BuyTransfer from './product/transfer/BuyTransfer';
import Voucher from './product/finish/voucher/Show';

import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Budgets = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/passeios`}>
        <ToursTable theme={theme} />
      </Route>
      <Route exact path={`${path}/passeios/:tour_id/comprar`}>
        <BuyTour theme={theme} />
      </Route>
      <Route exact path={`${path}/traslados`}>
        <TransferTable theme={theme} />
      </Route>
      <Route exact path={`${path}/combos`}>
        <CombosTable theme={theme} />
      </Route>
      <Route exact path={`${path}/combos/:combo_id/comprar`}>
        <BuyCombo theme={theme} />
      </Route>
      <Route exact path={`${path}/detalhe/:presales_id`}>
        <Show theme={theme} />
      </Route>
      <Route exact path={`${path}/editar/:presales_id`}>
        <Update theme={theme} />
      </Route>
      <Route exact path={`${path}/traslados/:transfer_id/comprar`}>
        <BuyTransfer theme={theme} />
      </Route>
      <Route exact path={`${path}/traslados/:transfer_id/comprar`}>
        <BuyTransfer theme={theme} />
      </Route>
      <Route exact path={`${path}/traslados/:transfer_id/comprar`}>
        <BuyTransfer theme={theme} />
      </Route>
      <Route exact path={`${path}/:travel_type/:travel_id/documento/:presale_id`}>
        <Voucher theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Budgets;
