import HighFiveIcon from '@assets/images/icons/undraw_High_five.svg';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import FinishTable from './FinishTable';

const bradecrumbsConfig = [
  {
    title: 'Início',
    active: true,
    route: '/admin/orcamentos'
  },
  {
    title: 'Passeio',
    active: true,
    route: '/admin/orcamentos/passeios'
  },
  {
    title: `Comprar`,
    active: true,
  },
  {
    title: 'Orçamento'
  }
]

const ReviewPage = (props) => {
  const history = useHistory();
  const [proofs, setProofs] = useState([])
  const payment_method = {
    card: 'Cartão',
    money: 'Dinheiro'
  }

  const lang = {
    'pt': 'Português',
    'en': 'Inglês',
    'es': 'Espanhol'
  }

  const status = {
    accomplished: {
      text: 'Realizado'
    },
    pending: {
      text: 'Pendente'
    },
    partial: {
      text: 'Parcial'
    }
  }

  const showDerivedInfo = (info) => {
    if (props.client.derived && info) {
      return false
    } else if (props.client.derived && !info) {
      return true
    }
    return false
  }

  const getReserveById = () => {
    apiClient.httpInstance.get(`presales/${props.saleId}`)
      .then(response => {
        if (response.status === 200) {
          if (response.data?.payments && response.data?.payments.lenght) {
            setProofs(response.data?.payments[0]?.proofs)
          }
        }
      }
      );
  }

  useEffect(() => {
    getReserveById()
  }, [])
  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Orçamento</span>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="flex justify-center pt-3 text-xl font-medium">
          Orçamento realizado com sucesso!
        </div>
        <div className="flex justify-center pt-3">
          <img
            alt="Select file"
            src={HighFiveIcon}
            width="450"
          />
        </div>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="p-4 text-xl text-blue font-poppins">
          <span>Informações do cliente</span>
        </div>
        <div className='grid grid-cols-3 gap-4 p-4 border-b border-solid border-lightgray'>
          <div >
            <div className="grid grid-cols-1 gap-4 p-4 text-base font-normal">
              <div className={showDerivedInfo(props.client.name) ? 'hidden' : ''}>
                {props.client.name}
              </div>
              <div className={showDerivedInfo(props.client.email) ? 'col-span-2' : ''}>
                {props.client.email}
              </div>
              <div className={showDerivedInfo(props.client.phone) ? 'col-span-2' : ''}>
                {props.client.phone}
              </div>
              <div className={showDerivedInfo(props.client.document) ? 'hidden' : ''}>
                Documento: {props.client.document}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 text-xl text-blue font-poppins">
          <FinishTable data={props.reservationData} type={props.type} finish={true} />
        </div>
      </div>
      <div className="flex justify-end mb-4 space-x-5">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="250px"
          variant="outlined"
          onClick={() => history.push('/admin/orcamentos')}
          bgColor="transparent"
        >
          Voltar para orçamentos
        </Button>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          width="160px"
          onClick={() => history.push(`documento/${props.saleId}`)}
          customcolor={props.theme.color}
        >
          Ver documento
        </Button>
      </div>
    </div>
  </>
}

export default ReviewPage;
