import RadioButton from '@components/RadioButton';

const ContentStep1 = (props) => {
  return <>
    <div className="p-4 font-bold text-sm text-blue font-poppins flex">
      Selecione o tipo de passeio desejado
    </div>
    <div className="p-4 text-xl text-blue font-poppins flex">
      <div className="flex-1 space-y-1">
        <RadioButton
          {...props}
          data={[{
            value: 'Em grupo',
            label: "Em grupo"
          }]}
          name="typeTour"
          change={(ev) => props.setTypeTour(ev)}
          value={props.typeTour}
        />
        <span className="font-normal text-sm">Desejo que esse orçamento seja marcada como um grupo</span>
      </div>
      <div className="flex-1 space-y-1">
        <RadioButton
          {...props}
          data={[{
            value: 'Passeio privado',
            label: "Passeio privado"
          }]}
          name="typeTour"
          change={(ev) => props.setTypeTour(ev)}
          value={props.typeTour}
        />
        <span className="font-normal text-sm">Desejo que esse orçamento seja marcada como particular</span>
      </div>
    </div>
  </>
}

export default ContentStep1;