import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import Select from '@components/Select';

const RegionsCreateModal = (props) => {
  let ref = useRef(null);
  const [lang, setLang] = useState(null)

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Escolha um idioma</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex mt-4">
        <Select
          withoutAll
          label="Selecione"
          width="200px"
          onChange={ev => setLang(ev.target.value)}
          defaultValue={lang}
          items={{
            field: {
              value: 'lang',
              text: 'text'
            },
            data: props.langs.filter(lang => props.terms[lang.lang] === false)
          }}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => props.onCreate(lang, props.type)}
          customcolor={props.theme.color}
        >
            Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default RegionsCreateModal;
