import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import Icon from '@material-ui/core/Icon';
import Select from '@components/Select';

const ChangeStateModal = (props) => {
  let ref = useRef(null);

  const statusOptions = [
    {
      value: 'pending',
      title: 'Aguardando'
    },
    {
      value: 'sended',
      title: 'Realizado'
    },
    {
      value: 'canceled',
      title: 'Cancelado'
    }
  ]

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '450px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Confirmar o recebimento do valor?</span>
          <small className="text-gray">Você quer mesmo confirmar esse recebimento? Essa ação é irreversível e você não poderá alterá-la.</small>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="justify-end mt-4 md:flex w-100">
        <Button
          textcolor="#212121"
          variant="outlined"
          padding="10px 15px"
          margintop="0px"
          marginright="20px"
          width="120px"
          onClick={props.close}
          bgColor="transparent"
        >
            Cancelar
        </Button>
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="120px"
          onClick={(ev) => props.onCreate(ev)}
        >
            Confirmar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeStateModal;
