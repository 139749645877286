import Button from '@components/Button';
import { apiClient } from '@services/api';
import BreadCrumbs from '@components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print'

class Content extends React.Component {
  render() {
    const props = this.props
    return <>
      <div className="p-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        {props.voucher.map((v) => (
          <>
            <div className="flex">
              <div className="flex flex-col flex-none text-center">
                <img src="https://via.placeholder.com/60x60" width="60" className="self-center rounded-full" alt="" />
                <span>{process.env.REACT_APP_NAME}</span>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-right">
                <p>{props.voucher.company_address}</p>
                <p>RUT: 76.875.529-9</p>
                <p>Telefone empresa: 56967367782</p>
              </div>
            </div>
            <div className="flex flex-col mb-4 space-y-3 text-center">
              <p>Voucher {process.env.REACT_APP_NAME}</p>
              <p className="text-xl font-medium">{props.voucher.customerData_name}</p>
            </div>
            <div className="flex">
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>Data</p>
                <p>{props.voucher.tour_date}</p>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>Passeios</p>
                <p>{props.voucher.travel_name}</p>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>Adulto</p>
                <p>{props.voucher.adults_total}</p>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>Crianças</p>
                <p>{props.voucher.kids_total}</p>
              </div>
            </div>
            <div className="flex justify-center my-5">
              Informações
            </div>
            <div className="flex">
              <div className="flex flex-col flex-1 space-y-1">
                <p>Referência: {props.voucher.type}</p>
                <p>Motorista: {props.voucher.driver_name}</p>
                <p>Guia: {props.voucher.guide_name}</p>
                <p>Hora: {props.voucher.time}</p>
                {props.voucher?.reservationData?.combo_id && <p>Endereço: {props.voucher.customerData_hotel}</p>}
                {props.voucher.customerData?.customer_addresses.map((address, index) => {
                  return (<p>Endereço: ({address.name}): {address.hotel_address}</p>)
                })
                }
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>Nome do cliente: {props.voucher.customerData_name}</p>
                <p>ID: {props.voucher.customerData_id}</p>
                <p>Data de compra: {props.voucher.emission_date}</p>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  }
}
const Voucher = (props) => {
  const componentRef = useRef()
  const { exit_id, vehicle_id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [voucher, setVoucher] = React.useState({});
  const url = `reservations/${exit_id}/exit_voucher/vehicle/${vehicle_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Saídas',
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `Detalhes`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}`
    },
    {
      title: 'Voucher'
    }
  ]

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false)
      }
    });
  }

  React.useEffect(() => {
    getVoucher(url)
  }, [url])

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading && <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Voucher</span>
        <div className="space-x-3 w-100">
          <Button
            textcolor="#212121"
            padding="6px 15px"
            width="200px"
            variant="outlined"
            onClick={null}
            bgColor="transparent"
          >
            Enviar por e-mail
          </Button>
          <ReactToPrint
            trigger={() => <Button
              textcolor="#FFF"
              padding="6px 15px"
              width="200"
              customcolor={props.theme.color}
            >
              Visualizar PDF
            </Button>
            }
            content={() => componentRef.current}
          />

        </div>
      </div>
      {/* <Content
        {...props}
        voucher={voucher}
        ref={componentRef}
      /> */}
    </div>}
  </>
}

export default Voucher;