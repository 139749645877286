import Icon from '@material-ui/core/Icon';
import DatePicker from '@components/DatePicker';
import RadioButton from '@components/RadioButton';

const ContentStep1 = (props) => {
  const isDateDisabled = (date, id) => {
    const dateAvailable = props.disableDates(date, id)
    return dateAvailable
  }

  return <>
  {props.tours.map((tour, idx) => (
    <div className="mt-4 border-t border-solid border-lightgray" key={tour.id}>
      <div className="p-4 text-xl text-blue font-poppins flex">
        <div className="flex-none">
          <img src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
        </div>
        <div className="flex-auto space-y-1">
          <div className="font-medium text-base">
            {tour.name}
          </div>
          <div className="font-medium text-xs">
            {tour.region}
          </div>
          <div className="font-medium text-sm text-gray flex items-center">
            <Icon className="mr-2">{tour.typeTour === 'Em grupo' ? 'people' : 'person'}</Icon>{tour.typeTour === 'Em grupo' ? 'Passeio em grupo ' : 'Passeio em particular'}
          </div>
        </div>
        <div className="flex-auto text-right">
          <DatePicker
            label="Data"
            value={tour.date !== null ? `${tour.date} 00:00:00` : null}
            shouldDisableDate={(date) => !isDateDisabled(date, tour.id)}
            onChange={date => props.changeDate(idx, date)}
          />
        </div>
      </div>
      <div className="p-4 font-bold text-sm text-blue font-poppins flex">
        Selecione o tipo de passeio desejado
      </div>
      <div className="p-4 text-xl text-blue font-poppins flex">
        <div className="flex-1 space-y-1">
        <RadioButton
            {...props}
            data={[{
              value: 'Em grupo',
              label: "Em grupo"
            }]}
            name="typeTour"
            change={(ev) => props.changeValue(idx, ev)}
            value={tour.typeTour}
          />
          <span className="font-normal text-sm">Desejo que esse orçamento seja marcado como um grupo</span>
        </div>
        <div className="flex-1 space-y-1">
          <RadioButton
            {...props}
            data={[{
              value: 'Passeio privado',
              label: "Passeio privado"
            }]}
            name="typeTour"
            change={(ev) => props.changeValue(idx, ev)}
            value={tour.typeTour}
          />
          <span className="font-normal text-sm">Desejo que esse orçamento seja marcado como particular</span>
        </div>
      </div>
    </div>
  ))}
  </>
}

export default ContentStep1;
