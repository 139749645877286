import logo from '@assets/images/logo/small/white.png';
import { connect } from 'react-redux';

let Header = (props) => {
  return <div className={`flex items-center justify-between p-4 header`}>
    <img className="logo" src={`${process.env.REACT_APP_LOGO_SMALL_WHITE}`} alt={process.env.REACT_APP_NAME}></img>
  </div>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Header);
