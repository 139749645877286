let Admin = {
  themes: {
    default: { class: "bg-admin-default", color: `${process.env.REACT_APP_ADMIN_COLOR}`, permission: 'administrador' },
    gerente: { class: "bg-admin-gerente", color: `${process.env.REACT_APP_MANAGER_COLOR}`, permission: 'gerente' },
    logistica: { class: "bg-admin-logistica", color: `${process.env.REACT_APP_LOGISTIC_COLOR}`, permission: 'logistica' },
    supervisor: { class: "bg-admin-vendedor", color: `${process.env.REACT_APP_SELLER_COLOR}`, permission: 'supervisor' },
    vendedor: { class: "bg-admin-vendedor", color: `${process.env.REACT_APP_SELLER_COLOR}`, permission: 'operacao' },
    operacao: { class: "bg-admin-default", color: `${process.env.REACT_APP_SELLER_COLOR}`, permission: 'operacao' },
    parceiro: { class: "bg-admin-vendedor", color: `${process.env.REACT_APP_SELLER_COLOR}`, permission: 'parceiro' },
  },

  items: [
    {
      section: "Geral",
      options: [
        {
          icon: "dashboard",
          text: "Dashboard",
          link: "/admin",
          permission: ['administrador', 'gerente', 'logistica', 'operacao', 'parceiro', 'supervisor'],
        },
        {
          icon: "people",
          text: "Clientes",
          permission: ['administrador', 'gerente', 'logistica', 'operacao', 'supervisor'],
        },
        {
          icon: "shopping_cart",
          text: "Reservas",
          permission: ['administrador', 'gerente', 'logistica', 'operacao', 'parceiro', 'supervisor'],
        },
        {
          icon: "receipt_long",
          text: "Orçamentos",
          permission: ['administrador', 'gerente', 'operacao', 'supervisor'],
        },
        {
          icon: "calendar_today",
          text: "Cupos",
          permission: ['administrador', 'gerente', 'logistica', 'operacao', 'supervisor'],
          
        },
        {
          icon: "departure_board",
          text: "Saídas",
          permission: ['administrador', 'gerente', 'logistica'],
        },
      ]
    },
    {
      section: "Administrar",
      permission: ['administrador', 'gerente', 'logistica'],
      options: [
        {
          icon: "monetization_on_icon",
          text: "Fluxo de Caixa",
          permission: ['administrador', 'gerente'],
        },
        {
          icon: "pending_actions",
          text: "Aprovações",
          permission: ['administrador'],
        },
        {
          id: "central",
          icon: "chat",
          text: "Central",
          dropdown: true,
          children: [
            {
              name: 'Mensagens',
            },
            {
              name: 'Templates',
            },
            {
              name: 'Tipos',
            }
          ],
          permission: ['administrador', 'gerente', 'logistica'],
        },
        {
          icon: "map",
          text: "Regiões",
          permission: ['administrador', 'gerente'],
        },
        {
          icon: "photo_camera",
          text: "Passeios",
          permission: ['administrador', 'gerente', 'logistica'],
        },
        {
          icon: "flight",
          text: "Traslados",
          permission: ['administrador', 'gerente'],
        },
        {
          icon: "card_travel",
          text: "Combos",
          permission: ['administrador', 'gerente'],
        },
        {
          icon: 'directions_bus_filled',
          text: 'Veículos',
          permission: ['administrador', 'gerente'],
        },
        {
          icon: "storefront",
          text: "Vendedores",
          permission: ['administrador', 'gerente'],
        },
        {
          icon: "accessibility",
          text: "Motoristas",
          permission: ['administrador', 'gerente', 'logistica'],
        },
        {
          icon: "person_search",
          text: "Guias",
          permission: ['administrador', 'gerente', 'logistica'],
        },
        {
          id: "partners",
          icon: "assignment_ind",
          text: "Parceiros",
          dropdown: true,
          children: [
            {
              name: 'Agências',
            },
            {
              name: 'Vendedores',
            }
          ],
          permission: ['administrador', 'gerente'],
        },
      ]
    },
    {
      section: "Relatórios",
      permission: ['administrador', 'gerente'],
      options: [
        {
          icon: "percent",
          text: "Comissões",
        },
      ]
    }
  ]
};

export default Admin;