import Modal from '@components/Modal';
import Button from '@components/Button';
import TextField from '@components/TextField';
import { apiClient } from '@services/api';
import { useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

const CreateRegionModal = (props) => {
  const [region, setRegion] = useState("");
  const history = useHistory();
  let ref = useRef(null);

  const handleSubmit = (e, goToDetail = false) => {
    e.preventDefault();
    apiClient.httpInstance.post("/translados", {
      region_name: region,
      status: true
    })
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        ref.current.click();

        if (goToDetail) {
          history.push(`/admin/traslados/detalhe/${response.data.id}`);
        }
      }
    })
  }

  return <Modal open={props.open} close={props.close}>
    <div className="flex justify-between">
      <span>Nova zona</span>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <div className="mt-3">
      <TextField
        className="inputRounded"
        placeholder="Digite o nome da zona"
        variant="outlined"
        type="text"
        name="region"
        value={region}
        onChange={(e) => setRegion(e.target.value)}
        required></TextField>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="15px"
          customcolor={props.theme.color}
          onClick={(e) => handleSubmit(e)}>
            Salvar e continuar
        </Button>
        <Button
          textcolor={process.env.REACT_APP_SECONDARY_COLOR}
          padding="6px 15px"
          margintop="8px"
          variant="outlined"
          bgColor="transparent"
          onClick={(e) => handleSubmit(e, true)}>
            Salvar e ver detalhes
        </Button>
    </div>
  </Modal>
}

export default CreateRegionModal;
