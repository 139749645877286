import * as React from 'react'
import { Tab, Tabs as TabsMui, withStyles } from '@material-ui/core'

const Tabs = ({
    data,
    className
}) => {

    const [tabIndex, setTabIndex] = React.useState(0);
    const [tabs, setTabs ] = React.useState([])
    const [tabsContent, setTabsContent ] = React.useState([])
    
    const handleChange = (event, newValue) => {
      setTabIndex(newValue);
    };  

    const TabPanel = (props) => {

        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    }

    const TabsStyles = withStyles({
        indicator: {
          backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
        },
    })(TabsMui);
    
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const start = React.useCallback((data) => {
        const titles = data.map((item, index) => {
            return item.title
        })
        const contents = data.map((item, index) => {
            return item.content
        })
        setTabs(titles)
        setTabsContent(contents)
    }, [])

    React.useEffect(() => {
        start(data)
    }, [data])

    return (
        <>
            <TabsStyles 
                className={className}
                value={tabIndex} 
                onChange={handleChange} 
                aria-label="simple tabs example"
            >
                {tabs.map((title, index) => {
                    return (
                        <Tab key={index} label={title} {...a11yProps(index)} />
                    )
                })}
            </TabsStyles>
            {tabsContent.map((content, index) => {
                return (
                    <TabPanel value={tabIndex} index={index}>
                        {content}
                    </TabPanel>
                )
            })}    
        </>
    )
}

export default Tabs