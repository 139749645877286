import Index from './Index';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Regions = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
    </Switch>
  </>
}

export default Regions;
