import Table from '@components/Table';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useState } from 'react';

const permissions = ['administrador', 'logistica'];

const TableDeriveds = (props) => {
  const history = useHistory();
  const exit = props.exit;
  const [rows] = useState([]);

  return <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
    <div className="flex items-center justify-between p-4 text-xl text-blue font-poppins">
      <span>Derivados</span>
      {permissions.includes(props?.theme?.permission) && <Button
        textcolor="#FFF"
        padding="6px 15px"
        width="200"
        customcolor={props.theme.color}
        type="submit"
        onClick={props.onClick}
      >
        <Icon className="mr-2">
          add
        </Icon>
        Novo derivado
      </Button>}
    </div>
    <div className="pb-3 mb-10">
      <div className={rows.length > 0 ? 'hidden' : ''}>
        <Table
          path={props.path}
          onRowClick={(props) => {
            const derived = props.row;

            history.push(`/admin/saidas/detalhe/${exit.id}/derivados/detalhe/${derived.id}`);
          }}
          columns={[
            {
              headerName: 'Agência parceira',
              headerClassName: 'super-app-theme--header',
              field: 'agency_name',
              width: 200
            },
            {
              headerName: 'Reservas',
              headerClassName: 'super-app-theme--header',
              field: 'reservations',
              width: 100,
            },
            {
              headerName: 'Nome do guia',
              headerClassName: 'super-app-theme--header',
              field: 'guide_name',
              width: 150,
            },
            {
              headerName: 'Horário',
              headerClassName: 'super-app-theme--header',
              field: 'schedule',
              width: 250,
            },
          ]}
          rows={rows}
        />
      </div>
      <div className={`ml-4 font-bold ${rows.length === 0 ? 'hidden' : ''}`}>
        Você ainda não tem nenhum derivado cadastrado.
      </div>
    </div>
  </div>


}

export default TableDeriveds;
