import { useState, useEffect } from 'react';
import { apiClient } from '@services/api';
import TextField from '@components/TextField';
import Button from '@components/Button';
import Select from '@components/Select';
import RadioButton from '@components/RadioButton';
import Checkbox from '@components/Checkbox';

const Form = (props) => {
  const [templates, setTemplates] = useState([])
  const [templateTypes, setTemplateTypes] = useState([])
  const [permissionsValues, setPermissionsValues] = useState([
    {
      id: "gerente",
      name: "Gerente",
      checked: props.permissions.includes('gerente')
    },
    {
      id: "supervisor",
      name: "Supervisor",
      checked: props.permissions.includes('supervisor')
    },
    {
      id: "logistica",
      name: "Logística",
      checked: props.permissions.includes('logistica')
    },
    {
      id: "operacao",
      name: "Operação",
      checked: props.permissions.includes('operacao')
    },
  ])

  const getTemplatesTypes = () => {
    apiClient.httpInstance.get('template_types').then(response => {
      if (response.status === 200) {
        setTemplateTypes(response.data.data);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar tipos')
    });
  }

  const getTemplates = () => {
    apiClient.httpInstance.get('central/templates').then(response => {
      if (response.status === 200) {
        setTemplates(response.data.items);
      }
    }).catch(function (response) {
      console.log('Erro ao buscar templates')
    });
  }

  useEffect(() => {
    getTemplates();
    getTemplatesTypes();
  }, []);
  
  useEffect(() => {
    
  }, [props.permissions]);

  const changeCheckbox = (ev) => {
    if (!props.permissions.includes(ev.target.name) && ev.target.name !== 'permissions') {
      props.permissions.push(ev.target.name)
    } else if (ev.target.name !== 'permissions') {
      let i = props.permissions.indexOf(ev.target.name)
      props.permissions.splice(i, 1);
    }
    props.changeState({target:{name: 'update', value: ''}})
  }

  const removeOption = (value) => {
    let newPermissions = props.permissions
    let i = newPermissions.indexOf(value)
    newPermissions.splice(i, 1);

    props.setPermissions(newPermissions)
    props.changeState({target:{name: 'update', value: ''}})
  }

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <Select
              withoutAll
              label="Template Utalk"
              value={props.template.utalk_id}
              onChange={(ev) => props.changeState(ev)}
              name="utalk_id"
              items={{
                field: {
                  value: 'id',
                  text: 'label'
                },
                data: templates
              }}
              required
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Nome do template"
              variant="outlined"
              type="text"
              name="name"
              value={props.template.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <Select
              withoutAll
              label="Tipo"
              value={props.template.template_type_id}
              onChange={(ev) => props.changeState(ev)}
              name="template_type_id"
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: templateTypes
              }}
              required
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <p className="pb-2">Permissões</p>
            {permissionsValues.map((value, idx) => {
              return (<>{props.permissions.includes(value.id) && <span className="mx-3 cursor-pointer" onClick={() => removeOption(value.id)}>{value.name} x</span>}</>)
            })}
            <p></p>
            {permissionsValues.map((value, idx) => {
              return (<>{!props.permissions.includes(value.id) && <Checkbox
                className="text-primary"
                label={value.name}
                name={value.id}
                index={idx}
                onChange={(ev) => changeCheckbox(ev)}
              />}</>)
            })}
          </div>
        </div>

      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form
