import Modal from '@components/Modal';
import { useRef, useState, useEffect } from 'react';
import { apiClient } from '@services/api';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { useParams, useHistory } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@components/Button';
import TextField from '@components/TextField';
import '@assets/css/Admin/components/table.scss';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

const DerivedModal = (props) => {
  let ref = useRef(null);
  const classes = useStyles();
  const { exit_id, derived_id } = useParams();
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const exit = props.exit;
  const derived = props.derived;
  const [availableSeats, setAvailableSeats] = useState(0);
  const url = `exits/${exit_id}/deriveds/${derived_id}/available_reservations`;

  const getAvailableReservations = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setRows(response.data);
      }
    });
  }

  const changeRowsState = (index, cupos, max_cupos) => {
    let newArray = [...rows];
    if (cupos <= max_cupos && cupos >= 0) {
      newArray[index].cupos = cupos;
    }
    setRows(newArray);
  }

  const allocateReservation = reservation => {
    apiClient.httpInstance.post(`/derived/${derived.id}/allocate`, {
      reservation_id: reservation.id,
      cupos: reservation.cupos || reservation.max_cupos
    }).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage('Reserva derivada com sucesso');
        getAvailableReservations(url);
        props.derivedDetail();
        setTimeout(() => {setSuccess(false)}, 4000);
      }
    }).catch(error => {
      if(error) {
        setError(true);
        setErrorMessage(error.response.data || "Erro inesperado, tente novamente mais tarde")
        setTimeout(() => {setError(false)}, 4000);
      }
    });
  }

  const deallocateReservation = reservation => {
    apiClient.httpInstance.post(`/derived/${derived.id}/deallocate`, {
      reservation_id: reservation.id,
      cupos: reservation.cupos || reservation.max_cupos
    }).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage('Derivado removido com sucesso');
        props.derivedDetail();
        getAvailableReservations(url);
        setTimeout(() => {setSuccess(false)}, 4000);
      }
    });
  }

  useEffect(() => {
    setAvailableSeats(derived.seats - derived.seats_used);
  }, [derived]);

  useEffect(() => {
    getAvailableReservations(url);
  }, [url]);

  return <Modal open={props.open} close={props.close} style={{ width: '90%', maxWidth: '820px', padding: '24px 17px 24px'}}>
    <div className="flex justify-between">
      <span>Aguardando alocação</span>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <div className="mt-3">
      {success && (
        <Alert className="mb-5" variant="filled" severity="success">
          {successMessage}
        </Alert>
      )}
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="font-bold text-blue">Cliente</TableCell>
              <TableCell className="font-bold text-blue">Cupos</TableCell>
              <TableCell className="font-bold text-blue">Endereço</TableCell>
              <TableCell className="font-bold text-blue" align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell className="text-blue" component="th" scope="row">{row.customer}</TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  {row.total_derived > 0
                    ?
                      <>{row.cupos}</>
                    : <TextField
                        className="inputRounded"
                        variant="outlined"
                        width="100px"
                        type="number"
                        name="cupos"
                        value={row.cupos ? row.cupos : row.to_allocate}
                        onChange={(ev) => changeRowsState(index, ev.target.value, row.to_allocate)}
                      />
                  }
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">{row.address}</TableCell>
                <TableCell className="text-blue" align="center">
                  {row.total_derived > 0 ?
                    <div className="mb-4">
                      <Button
                        textcolor="#212121"
                        padding="4px 15px"
                        width="200"
                        variant={"outlined"}
                        onClick={() => deallocateReservation(row)}
                        bgColor="transparent"
                      >
                        Retirar derivado
                      </Button>
                    </div>
                  :
                  <Button
                    textcolor="#FFF"
                    padding="4px 15px"
                    margintop="0px"
                    width="200"
                    onClick={() => allocateReservation(row)}
                    type="submit"
                  >
                    Alocar
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </Modal>
}

export default DerivedModal;
