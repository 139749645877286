import { SHOW_CALENDAR_MODAL } from '../actions/actionsTypes'

let modalStateInit = JSON.parse(sessionStorage.getItem('showCalendarModal'));

export default function calendarModalState (state = modalStateInit, action) {
  switch(action.type) {
    case SHOW_CALENDAR_MODAL:
      let ModalState = action.payload
      sessionStorage.setItem('showCalendarModal', JSON.stringify(ModalState))
      return ModalState;
    default:
      return state;
  }
}
