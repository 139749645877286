import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Form from './Form';

const Create = (props) => {
  document.title = `Novo passeio - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [tour, setTour] = useState({
    banner: null,
    name: "",
    region: "",
    tour_period: "",
    day_period: "",
    description: "",
    price: "",
    adult_discount: "",
    adult_discount_percentage: "",
    operational_cost: "",
    operational_cost_sale: "",
    kids_price: "",
    kid_discount: "",
    kid_discount_percentage: "",
    kid_operational_cost: "",
    kid_operational_cost_sale: "",
    private_values: [
      {
        value: "",
        cost: "",
        cost_sale: "",
        min_people: 1,
        max_people: 2,
        discount: ""
      }
    ]
  })

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/passeios'
    },
    {
      title: 'Novo passeio',
    }
  ]

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changeState = (ev) => {
    setTour(
      {
        ...tour,
        [ev.target.name]: ev.target.value

      }
    )
  }

  const changeFile = (data) => {
    setTour(
      {
        ...tour,
        banner: data
      }
    )
  }

  const changeStatePrivate = (idx, ev) => {
    const value = ev.target.value
    const newArr = [...tour.private_values]
    newArr[idx][ev.target.name] = value
    setTour({
      ...tour,
      private_values: newArr
    })
  }

  const addPrivate = () => {
    const privateTour = tour.private_values
    const nextMinPeople = Number(privateTour[tour.private_values.length - 1].max_people) + 1
    const newPrivate = {
      value: "",
      cost: "",
      cost_sale: "",
      min_people: nextMinPeople,
      max_people: nextMinPeople + 1,
      discount: ""
    }
    privateTour.push(newPrivate)
    setTour({
      ...tour,
      private_values: privateTour
    })
  }

  const removePrivate = (idx) => {
    const privateTour = tour.private_values
    privateTour.splice(idx, 1);
    setTour({
      ...tour,
      private_values: privateTour
    })
  }

  const submitTour = (ev) => {
    ev.preventDefault();
    const formData = new FormData()
    formData.append('banner', tour.banner)
    formData.append('name', tour.name)
    formData.append('region', tour.region)
    formData.append('tour_period', tour.tour_period)
    formData.append('day_period', tour.tour_period === 'fullday' ? null : tour.day_period)
    formData.append('description', tour.description)
    formData.append('price', tour.price)
    formData.append('adult_discount', (Number(tour.price) * (tour.adult_discount_percentage / 100)))
    formData.append('adult_discount_percentage', tour.adult_discount_percentage)
    formData.append('operational_cost', tour.operational_cost)
    formData.append('operational_cost_sale', tour.operational_cost)
    formData.append('kids_price', tour.kids_price)
    formData.append('kid_discount', (Number(tour.kids_price) * (tour.kid_discount_percentage / 100)))
    formData.append('kid_discount_percentage', tour.kid_discount_percentage)
    formData.append('kid_discount_pesos', (Number(tour.kids_price_pesos) * (tour.kid_discount / 100)))
    formData.append('kid_operational_cost', tour.kid_operational_cost)
    formData.append('kid_operational_cost_sale', tour.kid_operational_cost)
    formData.append('company_id', props.user.data.company_id)
    apiClient
      .httpInstance.post("/tours",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          if (tour.private_values.length > 0) {
            submitPrivate(response.data.id, tour.private_values)
          } else {
            history.push('/admin/passeios');
          }
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  const submitPrivate = (id, privateValues) => {
    apiClient
      .httpInstance.post(`/tours/${id}/sync_private_values`, {
        private_values: privateValues
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/passeios');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo passeio</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form {...props} submit={submitTour} tour={tour} isEditing={false} changeState={changeState} changeStatePrivate={changeStatePrivate} changeFile={changeFile} addPrivate={addPrivate} removePrivate={removePrivate} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
