import { useState, useEffect } from 'react';
import TextField from '@components/TextField';
import DatePicker from '@components/DatePicker';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import Select from '@components/Select';
import { apiClient } from '@services/api';
import DiscountInput from '@components/DiscountInput';

const Form = (props) => {
  const [tourOptions, setTourOptions] = useState([])

  const radioButtonData = [
    {
      value: 'Ativo',
      label: 'Ativo'
    },
    {
      value: 'Inativo',
      label: "Inativo"
    }
  ]

  const getTours = () => {
    apiClient
    .httpInstance.get('/tours?array=true').then(response => {
      if (response.status === 200) {
        setTourOptions(response.data);
      }
    })
    .catch((error) => console.log(error));
  }
  useEffect(() => {
    getTours()
  }, [])
  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome do combo"
              variant="outlined"
              type="text"
              name="name"
              defaultValue={props.combo.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <Select
              withoutAll
              label="Passeios"
              value={props.combo.tours}
              multiple
              name="tours"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: tourOptions
              }}
              />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <DatePicker
            label="Data inicial"
            value={props.combo.from_date !== null ? `${props.combo.from_date} 00:00:00` : null}
            name="from_date"
            onChange={date => props.changeDate(date, "from_date")}
          />
          <DatePicker
            label="Data final"
            value={props.combo.to_date !== null ? `${props.combo.to_date} 00:00:00` : null}
            name="to_date"
            minDate={`${props.combo.from_date} 00:00:00`}
            onChange={date => props.changeDate(date, "to_date")}
          />
          <div className="flex-none">
            <DiscountInput
              label="Porcentagem"
              name="discount"
              value={props.combo.discount}
              onChange={(ev) => props.changeState(ev)}
              width="150px"
              required
            />
          </div>
        </div>
        {props.isEditing && <>
          <span className="text-xl text-black">Status</span>
          <div className="flex mb-4 space-x-3">
            <RadioButton
              {...props}
              data={radioButtonData}
              name="status"
              change={(ev) => props.changeState(ev)}
              value={props.combo.status}
            />
          </div>
        </>}
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form;
