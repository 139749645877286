import Button from '@components/Button';
import TextField from '@components/TextField';
import { withStyles } from '@material-ui/core/styles';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { apiClient } from '@services/api';
import Select from '@components/Select';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/drivers/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
      .catch((error) => console.log(error));
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Lista de motoristas - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [q, setQ] = useState("");
  const [license_type, setLicenseType] = useState("all")

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const changePageDetails = (e, id) => {
    e.preventDefault();
    history.push(`motoristas/detalhe/${id}`)
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const licenceTypeOptions = [
    {
      name: "Classe A1"
    },
    {
      name: "Classe A2"
    },
    {
      name: "Classe A3"
    },
    {
      name: "Classe A4"
    },
    {
      name: "Classe A5"
    },
    {
      name: "Classe B"
    },
    {
      name: "Classe C"
    },
  ]

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            accessibility
          </Icon>
          <span>Lista de motoristas</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('/admin/motoristas/cadastro')}
          customcolor={props.theme.color}
        >
          <Icon className="mr-2">add</Icon>
          Novo
        </Button>}
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por um motorista"
              width="280px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(ev) => setQ(ev.target.value)}
            />
          </div>
          <div className="flex-none">
            <Select
              label="Filtre por tipo de licença"
              width="250px"
              defaultValue="all"
              onChange={(ev) => setLicenseType(ev.target.value)}
              items={{
                field: {
                  value: 'name',
                  text: 'name'
                },
                data: licenceTypeOptions
              }}
            />
          </div>
        </div>
      </div>
      <Table
        params={{
          q, license_type
        }}
        path="/drivers"
        onRowClick={null}
        columns={[
          {
            headerName: 'Nome do motorista',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => changePageDetails(e, row.id)} >
                {row.name}
              </div>);
            }
          },
          {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => changePageDetails(e, row.id)} >
                {row.email}
              </div>);
            }
          },
          {
            headerName: 'Telefone',
            headerClassName: 'super-app-theme--header',
            field: 'phone',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => changePageDetails(e, row.id)} >
                {row.phone}
              </div>);
            }
          },
          {
            headerName: 'Tipo de licença',
            headerClassName: 'super-app-theme--header',
            field: 'identification_document',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => changePageDetails(e, row.id)} >
                {row.identification_document}
              </div>);
            }
          },
          {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;

              return (<div className="flex items-center pr-4 space-x-5">
                {permissions.includes(props?.theme?.permission) ?
                  <RowSwitch
                    status={row.status}
                    id={row.id}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  :
                  <>{row.status ? 'Ativo' : 'Inativo'}</>
                }
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default Index;
