import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '@components/Breadcrumbs';
import DashboardCard from './partials/DashboardCard';
import DatePicker from '@components/DatePicker';
import NumberFormat from 'react-number-format';
import TablePartner from './partials/TablePartner';
import { apiClient } from '@services/api';
import { format, endOfMonth, startOfMonth, isValid } from 'date-fns';
import Icon from '@material-ui/core/Icon';

const Index = (props) => {
  const { access_token } = useParams();
  const [fromDate, setFromDate] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd"))
  const [toDate, setToDate] = useState(format(endOfMonth(new Date()), "yyyy-MM-dd"))
  const [totalSales, setTotalSales] = useState({
    total: null,
    series: []
  })
  const [totalSalesAmount, setTotalSalesAmount] = useState({
    total: null,
    series: []
  })
  const [averageSalesAmount, setAverageSalesAmount] = useState({
    total: null,
    series: []
  })
  const url = '/partner_seller/statistics';

  const changeDate = (date, name) => {
    name === 'fromDate' ?
      setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
    :
      setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const getDashboard = url => {
    apiClient
    .httpInstance.get(url, {
      params: {
        access_token: access_token
      }
    })
      .then(response => {
        if (response.status === 200) {
          setTotalSales({
            total: response.data.statistics.totalSales.total,
            last_period: response.data.statistics.totalSales.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.totalSales.tours,
                label: 'Passeios',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.totalSales.transfer,
                label: 'Translados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.totalSales.combos,
                label: 'Combos'
              }
            ],
          })
          setTotalSalesAmount({
            total: response.data.statistics.totalSalesAmount.total,
            last_period: response.data.statistics.totalSalesAmount.last_period,
            categories: Object.keys(response.data.statistics.totalSalesAmount.total_by_month),
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: Object.values(response.data.statistics.totalSalesAmount.total_by_month),
                label: null
              },
            ]
          })
          setAverageSalesAmount({
            total: response.data.statistics.averageSalesAmount.total,
            last_period: response.data.statistics.averageSalesAmount.last_period,
            categories: [],
            series: [
              {
                color: `${process.env.REACT_APP_SELLER_COLOR}`,
                data: response.data.statistics.averageSalesAmount.tours,
                label: 'Passeios teste teste',
              },
              {
                color: `${process.env.REACT_APP_LOGISTIC_COLOR}`,
                data: response.data.statistics.averageSalesAmount.transfer,
                label: 'Translados'
              },
              {
                color: `${process.env.REACT_APP_MANAGER_COLOR}`,
                data: response.data.statistics.averageSalesAmount.combos,
                label: 'Combos'
              }
            ],
          })
        }
      }
    );
  }

  useEffect(() => {
    getDashboard(url);
  }, [url]);



  return <>
    <div className="px-24">
      <div className="flex items-center mt-2 text-2xl text-blue">
        <Icon className="mr-2">dashboard</Icon> <span>Dashboard</span>
      </div>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="flex flex-row-reverse space-x-3 space-x-reverse py-4">
        <div className="flex-none">
          <DatePicker
            label="Data final"
            value={toDate !== null ? `${toDate} 00:00:00` : null}
            onChange={date => changeDate(date, 'toDate')}
          />
        </div>
        <div className="flex-none">
          <DatePicker
            label="Data inícial"
            value={fromDate !== null ? `${fromDate} 00:00:00` : null}
            onChange={date => changeDate(date, 'fromDate')}
          />
        </div>
      </div>
      <div className="py-4">
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-1">
            {totalSales.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalSales}
                typeChart="pie"
                title="Total de vendas"
                value={totalSales.total}
                textFooter={`${totalSales.last_period}% em comparação ao período anterior`}
                icon={totalSales.last_period < 0 ? "arrow_downward" : "arrow_upward"}
                color={totalSales.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            }
          </div>
          <div className="flex-1">
            {totalSalesAmount.series.length > 0 &&
              <DashboardCard
                {...props}
                data={totalSalesAmount}
                title="Total em vendas"
                value={
                  <NumberFormat
                    value={totalSalesAmount.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                typeChart="bar"
                textFooter={`${totalSalesAmount.last_period}% em comparação ao período anterior`}
                icon={totalSalesAmount.last_period < 0 ? "arrow_downward" : "arrow_upward"}
                color={totalSalesAmount.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="165"
              />
            }
          </div>
          <div className="flex-1">
            {averageSalesAmount.series.length > 0 &&
              <DashboardCard
                {...props}
                data={averageSalesAmount}
                typeChart="pie"
                title="Ticket médio"
                value={
                  <NumberFormat
                    value={averageSalesAmount.total}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                  />
                }
                textFooter={`${averageSalesAmount.last_period}% em comparação ao período anterior`}
                icon={averageSalesAmount.last_period < 0 ? "arrow_downward" : "arrow_upward"}
                color={averageSalesAmount.last_period < 0 ? "#F44336" : "#4CAF50"}
                height="200"
              />
            }
          </div>
        </div>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{borderRadius: '10px'}}>
        <div className="p-4 text-xl flex font-medium items-center text-blue font-poppins">
          <span>Vendas do período</span>
        </div>
        <TablePartner fromDate={fromDate} toDate={toDate} accessToken={access_token}/>
      </div>
    </div>
  </>
}

export default Index;
