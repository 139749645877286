import { useState } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Create = (props) => {
  document.title = `Cadastrar guia - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [guide, setGuide] = useState({
    name: null,
    email: null,
    phone: null,
    address: null,
    identification_document: null,
    observation: null,
    access_days: null,
    company_id: null,
    status: true
  })
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/guias'
    },
    {
      title: 'Novo guia',
    }
  ]

  const changeCheckbox = (ev) => {
    setGuide(
      {
        ...guide,
        access_days: ev.target.checked
      }
    )
  }

  const changeState = (ev) => {
    setGuide(
      {
        ...guide,
        [ev.target.name]: ev.target.value

      }
    )
  }

  const submitGuide = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/guides", {
        ...guide,
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/guias');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo guia</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/guias')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        submit={submitGuide}
        guide={guide}
        isEditing={false}
        changeState={changeState}
        changeCheckbox={changeCheckbox}
      />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
