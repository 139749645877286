import { TextField as MaterialTextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomTextField = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: "40px",
    width: props?.width || "100%",
    minWidth: props?.minWidth || ""
  }),
}))(MaterialTextField);

let Textfield = props => {
  const {style, className, ...TextfieldProps} = props;

  return <div className={className} style={style || { width: props.width || '100%', maxWidth: props.maxwidth || 'initial' }}>
      <CustomTextField
      {...TextfieldProps}
      ></CustomTextField>
      <div className="block">
        {props.error && <small className="ml-3 text-red-500">{props.error}</small>}
      </div>
    </div>;
}

export default Textfield;
