import Button from '@components/Button';
import Modal from '@components/Modal';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none',
  }
});

const AllocationModal = (props) => {
  let ref = useRef(null);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const exit = props.exit;
  const vehicle = props.vehicle;
  const [availableSeats, setAvailableSeats] = useState(0);

  const language = {
    pt: 'Português',
    en: 'Inglês',
    es: 'Espanhol'
  };

  const getAvailableReservations = exit => {
    apiClient.httpInstance.get(`exits/${exit.id}/available_reservations`).then(response => {
      if (response.status === 200) {
        setRows(response.data);
      }
    });
  }

  const allocateReservation = reservation_id => {
    apiClient.httpInstance.post(`/logistic/vehicle/${vehicle.id}/allocate_reservation`, {
      reservation_id
    }).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage(response.data.response);
        getAvailableReservations(exit);
        props.vehicleDetail();
        setTimeout(() => { setSuccess(false) }, 4000);
      }
    }).catch(error => {
      if (error) {
        setError(true);
        setErrorMessage(error.response.data || "Erro inesperado, tente novamente mais tarde")
        setTimeout(() => { setError(false) }, 4000);
      }
    });
  }

  const deallocateReservation = reservation_id => {
    apiClient.httpInstance.post(`/logistic/vehicle/${vehicle.id}/deallocate_reservation`, {
      reservation_id
    }).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage(response.data.response);
        props.vehicleDetail();
        getAvailableReservations(exit);
        setTimeout(() => { setSuccess(false) }, 4000);
      }
    });
  }

  useEffect(() => {
    setAvailableSeats(vehicle.seats - vehicle.seats_used);
  }, [vehicle]);

  useEffect(() => {
    if (Object.keys(exit).length > 0) {
      getAvailableReservations(exit);
    }
  }, [exit]);

  return <Modal open={props.open} close={props.close} style={{ width: '90%', maxWidth: '820px', padding: '24px 17px 24px' }}>
    <div className="flex justify-between">
      <span>Aguardando alocação</span>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <hr className="mt-2 mb-4" style={{ color: "#E0E0E0" }} />
    <div className="mt-3">
      {success && (
        <Alert className="mb-5" variant="filled" severity="success">
          {successMessage}
        </Alert>
      )}
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      <div style={{ maxHeight: '440px', overflow: 'auto' }}>

        <div className="block md:hidden">
          <div className="flex flex-row justify-between">
            <span className="font-bold text-blue">Cliente</span>
            <span className="font-bold text-blue">Cupos</span>
          </div>
          {rows.length && rows.map((row) => (
            <>
              <div className="flex flex-row justify-between mr-5">
                <div className="flex-column w-100" key={row.id}>
                  <p className="text-blue">{row.customer}</p>
                  <p className="text-blue">{row.address}</p>
                  <p className="text-blue">{language[row.language]}</p>
                  <p className="text-blue">{row.company?.split(' ')[0] + ' ' + row.company?.split(' ')[1]}</p>
                </div>
                <span className="text-blue">{row.cupos}</span>
              </div>
              <div className="flex flex-row justify-end text-blue w-100" align="center">
                {Number(row.cupos) === Number(row.cupos_allocated) || (Number(row.cupos_allocated) > 0 && Number(row.to_allocate) > availableSeats) ?
                  <Button
                    textcolor="#212121"
                    padding="4px 15px"
                    width="200"
                    margintop="1px"
                    variant={"outlined"}
                    onClick={() => deallocateReservation(row.id)}
                    bgColor="transparent"
                  >
                    Desalocar
                  </Button>
                  :
                  <Button
                    textcolor="#FFF"
                    padding="4px 15px"
                    margintop="0px"
                    width="200"
                    margin="0"
                    onClick={() => allocateReservation(row.id)}
                    type="submit"
                  >
                    Alocar
                  </Button>
                }
              </div>
              <hr className="mt-6 mb-4" style={{ color: "#E0E0E0" }} />
            </>
          ))}
          {rows.length == 0 ? ' Clientes para alocar' : ''}
        </div>

        <div className="hidden md:block">
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="font-bold text-blue">Cliente</TableCell>
                  <TableCell className="font-bold text-blue">Idioma</TableCell>
                  <TableCell className="font-bold text-blue">Cupos</TableCell>
                  <TableCell className="font-bold text-blue">Empresa</TableCell>
                  <TableCell className="font-bold text-blue">Endereço</TableCell>
                  <TableCell className="font-bold text-blue" align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length && rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="text-blue" component="th" scope="row">{row.customer}</TableCell>
                    <TableCell className="text-blue" component="th" scope="row">{language[row.language]}</TableCell>
                    <TableCell className="text-blue" component="th" scope="row">{row.cupos}</TableCell>
                    <TableCell className="text-blue" component="th" scope="row">{row.company?.split(' ')[0] + ' ' + row.company?.split(' ')[1]}</TableCell>
                    <TableCell className="text-blue" component="th" scope="row">{row.address}</TableCell>
                    <TableCell className="text-blue" align="center">
                      {Number(row.cupos) === Number(row.cupos_allocated) || (Number(row.cupos_allocated) > 0 && Number(row.to_allocate) > availableSeats) ?
                        <Button
                          textcolor="#212121"
                          padding="4px 15px"
                          width="200"
                          variant={"outlined"}
                          onClick={() => deallocateReservation(row.id)}
                          bgColor="transparent"
                        >
                          Desalocar
                        </Button>
                        :
                        <Button
                          textcolor="#FFF"
                          padding="4px 15px"
                          margintop="0px"
                          width="200"
                          onClick={() => allocateReservation(row.id)}
                          type="submit"
                        >
                          Alocar
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
                {rows.length == 0 ? ' Clientes para alocar' : ''}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  </Modal>
}

export default AllocationModal;
