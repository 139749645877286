import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: `${process.env.REACT_APP_SELLER_COLOR}`,
    },
  },
});

let RadioButton = (props) => {
  return <RadioGroup row name={props.name} value={props.value} onChange={props.change}>
    <ThemeProvider theme={theme}>
    {props.data.map((obj, index) => {
      return <FormControlLabel
        key={index}
        value={obj.value}
        control={<Radio color="primary"/>}
        label={obj.label}
        labelPlacement="end"
        disabled={props.disabled}
      />
    })}
    </ThemeProvider>
  </RadioGroup>
}

export default RadioButton
