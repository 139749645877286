import Table from '@components/Table';
import { useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from "react-router-dom";

const TableWeek = (props) => {
  const [rows] = useState([]);
  const history = useHistory();
  const typeUser = props.typeUser
  const access_token = props.accessToken
  const to_date = props.toDate
  const from_date = props.fromDate

  return <>
    <div className="mt-2 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
      <div className="flex flex-wrap items-center p-5 space-x-3">
        <span className="text-xl font-normal text-blue">
          Saídas
        </span>
      </div>
      <div className="flex flex-wrap items-center space-x-3">
        <Table
          path={props.path}
          params={{
            access_token, from_date, to_date
          }}
          onRowClick={(props) => {
            const row = props.row;
            history.push(`/admin/motoristas/saidas/${typeUser}/${access_token}/${row.exit_vehicle_id}`);
          }}
          columns={[
            {
              headerName: 'Produto',
              headerClassName: 'super-app-theme--header',
              field: 'type',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                return <div className="flex items-center pr-4 space-x-5">
                  {row.travel_name} {row.type === 'transfer' && row.transfer_type !== null ? `(${row.transfer_type})` : ''}
                </div>;
              }
            },
            {
              headerName: 'Data',
              headerClassName: 'super-app-theme--header',
              field: 'tour_date',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                return <div className="flex items-center pr-4 space-x-5">
                  <Moment format="DD/MM/YYYY" date={row.tour_date} />
                </div>;
              }
            },
            {
              headerName: 'Horário',
              headerClassName: 'super-app-theme--header',
              field: 'time',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                const time = row.flight_time !== undefined && row.flight_time !== null ? row.flight_time.slice(0, -3) : row.time !== null ? row.time.slice(0, -3) : null
                return <div className="flex items-center pr-4 space-x-5">
                  {time}
                </div>;
              }
            },
            {
              headerName: 'Voo',
              headerClassName: 'super-app-theme--header',
              field: 'flight_code',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                const code = row.flight_code !== undefined && row.flight_code !== null ? row.flight_code : ''
                return <div className="flex items-center pr-4 space-x-5">
                  {code}
                </div>;
              }
            },
          ]}
          rows={rows}
        />
      </div>
    </div>
  </>
}

export default TableWeek;
