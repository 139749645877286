import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import Icon from '@material-ui/core/Icon';

const ChooseProductModal = (props) => {
  let ref = useRef(null);

  return <>
    <Modal open={props.open} close={props.close} style={{ width: '80%', maxWidth: '550px'}}>
      <div className="flex font-poppins">
        <div className="flex-auto text-center">
            <span className="mb-3 text-xl text-blue">Nova reserva</span>
        </div>
        <div className="flex-none">
          <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 text-center">
            <span className="mb-3 text-xs text-gray">Escolha o tipo de produto para a sua reserva</span>
        </div>
      </div>
      <div className="justify-center my-4 space-x-4 flex w-100">
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="100px"
          onClick={() => props.changeTourPage()}
          customcolor={props.theme.color}
        >
            Passeios
        </Button>
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="110px"
          onClick={(ev) => props.changeTransferPage()}
          customcolor={props.theme.color}
        >
            Traslados
        </Button>
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="100px"
          onClick={(ev) =>  props.changeCombosPage()}
          customcolor={props.theme.color}
        >
            Combos
        </Button>
      </div>
    </Modal>
  </>
}

export default ChooseProductModal;
