import { useState, useEffect } from 'react'
import BreadCrumbs from '@components/Breadcrumbs'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Calendar from './partials/Calendar';
import StartCalendarModal from './partials/StartCalendarModal';
import { changeModalCalendarState } from '@actions/showCalendarModal';
import Tooltip from '@components/Tooltip';

const ViewCupos = (props) => {
  document.title = `Visualizar vagas - ${process.env.REACT_APP_NAME}`
  const { tour_id } = useParams();
  const [tour, setTour] = useState({})
  const [seats, setSeats] = useState([])
  const [loading, setLoading] = useState(true)
  const [startModal, setStartModal] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const urlTour = `/tours/${tour_id}`;
  const urlSeats = '/seats'
  const infoText = 'Para selecionar vários dias, clique na data inicial e arraste até a data final.\n\n Se deseja excluir cupos de um dia específico, basta clicar no dia desejado.'
  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/passeios'
    },
    {
      title: `${tour.name === undefined ? '' : tour.name}`,
      active: true,
      route: '/admin/passeios'
    },
    {
      title: 'Vagas disponíveis',
    }
  ]

  const getTourById = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTour(response.data);
        setStartModal(props.calendarIsVisible)
        setLoading(false)
      }
    });
  }

  const getSeats = url => {
    apiClient.httpInstance.get(url, {
      params: {
        tour_id: tour_id
      }
    }).then(response => {
      if (response.status === 200) {
        setSeats(response.data);
      }
    });
  }

  const handleSave = (changeModalCalendarState) => {
    changeModalCalendarState(!showModal)
    setStartModal(false)
  }

  useEffect(() => {
    getTourById(urlTour);
    getSeats(urlSeats)
  }, [urlTour, urlSeats]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Vagas disponíveis</span>
        </div>
        <div className="mt-4 bg-white" style={{ borderRadius: '10px' }}>
          <div className="p-3 text-right text-blue">
            <Tooltip title={infoText} placement="top-end" icon="info" />
          </div>
          <div className="p-3">
            <Calendar {...props} cupos={seats} totalCupos={tour.cupos} getSeats={() => getSeats(urlSeats)} />
          </div>
        </div>
        <StartCalendarModal
          open={startModal}
          close={() => setStartModal(false)}
          showModal={showModal}
          setShowModal={setShowModal}
          onSave={() => handleSave(props.changeModalCalendarState)}
          {...props}
        />
      </div>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user,
    calendarIsVisible: state.showCalendarModal
  }
}
const mapDispatchToProp = dispatch => {
  return {
    changeModalCalendarState(state) {
      const action = changeModalCalendarState(state);
      dispatch(action);
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProp
)(ViewCupos);
