import { useState } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Create = (props) => {
  document.title = `Cadastrar motorista - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [driver, setDriver] = useState({})
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/motoristas'
    },
    {
      title: 'Novo motorista',
    }
  ]

  const changeState = (ev) => {
    setDriver(
      {
        ...driver,
        [ev.target.name]: ev.target.value

      }
    )
  }

  const submitDriver = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/drivers", {
        name: driver.name,
        email: driver.email,
        phone: driver.phone,
        address: driver.address,
        identification_document: driver.identification_document,
        license_type: driver.license_type,
        observation: driver.observation,
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/motoristas');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo motorista</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/motoristas')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        submit={submitDriver}
        driver={driver}
        isEditing={false}
        changeState={changeState}
      />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
