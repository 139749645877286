import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import TableWeek from './partials/TableWeek';
import TableDay from './partials/TableDay';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/lab/TabPanel';
import { format } from 'date-fns';
import Moment from 'react-moment';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  indicator: {
    background: "none"
  },
  appBar: {
    boxShadow: 'none',
    border: 'none',
    textTransform: 'none',
    "& button[aria-selected='true']": {
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        border: "1.5px solid #212121",
        borderRadius: 30,
      },

      "& > *": { zIndex: 0 },
      "& > .MuiTab-wrapper": {
        background: "transparent",
        height: "100%"
      }
    }
  }
});

const Index = (props) => {
  const { type_user } = useParams();
  const { access_token } = useParams();
  const [value, setValue] = useState('1');
  const [path, setPath] = useState(null);
  let today = new Date()
  let week = new Date()
  week.setDate(today.getDate() + 6)
  today = format(today, "yyyy-MM-dd")
  week = format(week, "yyyy-MM-dd")
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getExit = (type_user) => {
    const typeUser = type_user === 'motorista' || type_user === 'undefined' ? 'driver' : 'guide'
    setPath(`/${typeUser}/get_exits`)
  }

  useEffect(() => {
    getExit(type_user);
  }, [type_user]);

  //Oculta menu lateral
  useEffect(() => {
    //props.setShowMenu(false)
  }, []);
  
  return <>
    {path !== null &&
      <div className="px-24">
        <p className="mb-4 text-2xl text-blue font-poppins font-medium">
          {value === "1" ?
          <>
            Saídas para a semana <Moment format="DD/MM/YYYY" date={today} /> - <Moment format="DD/MM/YYYY" date={week} />
          </>
          :
          <>
            Saídas para <Moment format="DD/MM/YYYY" date={today} />
          </>
          }
        </p>
        <div className={classes.root}>
            <TabContext value={value}>
              <AppBar position="static" color="default" className={classes.appBar}>
                <Tabs
                  value={value}
                  classes={{
                    indicator: classes.indicator
                  }}
                  textcolor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab
                    className="normal-case font-normal text-lg text-blue font-poppins"
                    label="Semana"
                    value="1"
                    indicatorColor="primary"
                  />
                  <Tab
                    className="normal-case font-normal text-lg text-blue font-poppins"
                    label="Dia"
                    value="2"
                  />
                </Tabs>
              </AppBar>
                  <TabPanel value="1" className="px-0 pb-0">
                    <TableWeek today={today} week={week} accessToken={access_token} typeUser={type_user} toDate={week} permission={props.permission} fromDate={today} path={path}/>
                  </TabPanel>
                  <TabPanel value="2" className="px-0 pb-0">
                    <TableDay today={today} accessToken={access_token} typeUser={type_user} path={path} toDate={today} permission={props.permission} fromDate={today}/>
                  </TabPanel>
            </TabContext>
        </div>
      </div>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);

