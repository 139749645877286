import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableBodyBudget from './partials/TableBodyBudget'

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const Update = (props) => {
  document.title = `Editar detalhes do orçamento - ${process.env.REACT_APP_NAME}`

  const { presales_id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [changeReservations, setChangeReservations] = useState(false);
  const [regionsTravel, setRegionsTravel] = useState([]);
  const [reserve, setReserve] = useState({
    payment_status: null,
    payment_type: null,
    payment_method: null,
    customer_paid_amount: null,
    payment_proof: null,
    user_id: null,
    seller_name: null,
    seller_email: null,
  })
  const [isEditing, setIsEditing] = useState(false)
  const [client, setClient] = useState({
    addresses: [{
      id: '',
      region_id: '',
      region_name: '',
      customer_id: '',
      hotel: '',
      hotel_address: '',
      room_number: ''
    },
    {
      id: '',
      region_id: '',
      region_name: '',
      customer_id: '',
      hotel: '',
      hotel_address: '',
      room_number: ''
    }],
    customer_id: null,
    email: null,
    document: null,
    name: null,
    phone: null,
    hotel_name: null,
    hotel_address: null,
    room_number: null,
    customer_language: null,
    observations: null,
    havePartner: false,
  })
  const history = useHistory()
  const url = `presales/${presales_id}`

  const changeClient = (ev) => {
    let value = ev.target.value

    setClient({
      ...client,
      [ev.target.name]: value
    })
  }

  const changeReserve = (ev) => {
    let value = ev.target.value
    if (ev.target.name === "havePartner") {
      value = ev.target.value === "true" ? true : false;
    }
    setReserve({
      ...reserve,
      [ev.target.name]: value
    })
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: `Orçamento ${client.name !== null ? client.name : ''}`,
      active: true,
      route: `/admin/orcamentos/detalhe/${presales_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getReserveById = url => {
    apiClient.httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setClient({
            customer_id: response.data.customer_id,
            addresses: response.data.customer_addresses,
            name: response.data.name,
            email: response.data.email,
            phone: response.data.phone,
            document: response.data.document,
            hotel_name: response.data.hotel,
            hotel_address: response.data.hotel_address,
            room_number: response.data.room_number,
            customer_language: response.data.language,
            observations: response.data.observations,
          })
          setReserve({
            id: response.data.id,
            total: response.data.total,
            user_id: response.data.user_id,
            seller_name: response.data.seller_name,
            seller_email: response.data.seller_email,
            proofs: [],
            discount_total_sale: response.data.discount_total_sale,
          })
          setIsEditing(true)
        }
      }
      );
  }

  const getReservations = url => {
    apiClient.httpInstance.get(`${url}/budgets`).then(response => {
      if (response.status === 200) {
        setReservations(response.data);

        response.data.map((element) => {
          //Recolhe ids das regiões para lista de endereços do cliente
          if (element && element.region_id !== undefined) {
            if (!regionsTravel.includes(element.region_id)) {
              regionsTravel.push(element.region_id)
            }
          }
        })
      }
    });
  }

  const editSale = () => {
    const data = {
      customer_id: client.customer_id,
      customer_email: client.email,
      customer_name: client.name,
      customer_document: client.document,
      phone: client.phone,
      customer_language: client.customer_language,
      customer_observations: client.observations,
      pre_sale_id: reserve.id,
      seller_name: reserve.seller_name,
      seller_email: reserve.seller_email,
      user_id: reserve.user_id,
      reservations: reservations,
    }

    apiClient
      .httpInstance.put(`/presales/${presales_id}/update-values`, data)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/orcamentos/detalhe/${presales_id}`)
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      })
  }

  useEffect(() => {
    getReserveById(url);
    getReservations(url)
  }, [url]);
  
  useEffect(() => {
    console.log('changed')
  }, [changeReservations]);

  const setQuantities = (index, value, action, tour) => {
    setChangeReservations(!changeReservations)
    let valueQuantity = action === 'add' ? (Number(value) + 1) : (Number(value) - 1)
    //getReservations(url)
    let lastReservations = reservations

    lastReservations[index].adults_total = valueQuantity

    //Atualiza a reserva para o update
    setReservations(lastReservations)
  }

  const setQuantitiesTour = (index, value, type, action, tour) => {
    setChangeReservations(!changeReservations)
    let valueQuantity = action === 'add' ? (Number(value) + 1) : (Number(value) - 1)
    //getReservations(url)
    let lastReservations = reservations

    if (type == 'adults') {
      lastReservations[index].adults_total = valueQuantity
    }
    if (type == 'kids') {
      lastReservations[index].kids_total = valueQuantity
    }

    //Atualiza a reserva para o update
    setReservations(lastReservations)

    //Se isso for atualizado, pego os valores atuais do passeio que está sendo atualizado e jogo na interface para ser visto
    /* apiClient.httpInstance.get(`/tours/${tour}`).then(response => {
      if (response.status === 200) {
        console.log(response.data)
        //console.log(index, reservations[index])
      }
    })
    .catch((error) => console.log(error)); */

  }

  const classes = useStyles();
  const tableBody = <TableBodyBudget data={reservations} setQuantities={setQuantities} setQuantitiesTour={setQuantitiesTour} />

  return <>
  <BreadCrumbs items={bradecrumbsConfig} />
    <div className="px-7 pt-6">
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Edição</span>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="font-bold leading-5 text-blue border-0">
                  Minhas reservas
                </TableCell>
                <TableCell className="font-bold leading-5 text-blue border-0">
                  Passageiros
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map((cartValue, index) => (
                <TableRow key={cartValue.id}>
                  <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                    <div className="flex items-center">
                      <div className="flex-none">
                        <img src={cartValue.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${cartValue.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
                      </div>
                      <div className="text-lg font-medium">
                        {cartValue.travel_name} {cartValue.transfer_type ? `(${cartValue.transfer_type})` : null}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
                    {cartValue.transfer_type ?
                      <>
                        {cartValue.adults_total > 1 &&
                          <Icon
                            className="mr-2 text-sm cursor-pointer"
                            onClick={() => setQuantities(index, cartValue.adults_total, 'remove', cartValue.travel_id)}
                          >
                            remove
                          </Icon>
                        }
                        {parseInt(cartValue.adults_total)}
                        {cartValue.adults_total < 10 &&
                          <Icon
                            className="ml-2 text-sm cursor-pointer"
                            onClick={() => setQuantities(index, cartValue.adults_total, 'add', cartValue.travel_id)}
                          >
                            add
                          </Icon>
                        }
                      </>
                      :
                      <>
                        <Icon
                          className="mr-2 text-sm cursor-pointer"
                          onClick={() => setQuantitiesTour(index, cartValue.adults_total, 'adults', 'remove', cartValue.travel_id)}
                        >
                          remove
                        </Icon>
                        <span>{cartValue.adults_total} adulto{cartValue.adults_total > 1 ? 's' : ''}</span>
                        <Icon
                          className="ml-2 text-sm cursor-pointer"
                          onClick={() => setQuantitiesTour(index, cartValue.adults_total, 'adults', 'add', cartValue.travel_id)}
                        >
                          add
                        </Icon><br />
                        <Icon
                          className="mr-2 text-sm cursor-pointer"
                          onClick={() => setQuantitiesTour(index, cartValue.kids_total, 'kids', 'remove', cartValue.travel_id)}
                        >
                          remove
                        </Icon>
                        <span>{cartValue.kids_total} criança{cartValue.kids_total > 1 ? 's' : ''}</span>
                        <Icon
                          className="ml-2 text-sm cursor-pointer"
                          onClick={() => setQuantitiesTour(index, cartValue.kids_total, 'kids', 'add', cartValue.travel_id)}
                        >
                          add
                        </Icon>
                      </>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="p-4 text-xl">
          <span>Informações do cliente</span>
        </div>
        <div className='grid grid-cols-3 gap-4 p-4'>
          <div >
            <div className="grid grid-cols-1 gap-4 text-base font-normal">
              <div>
                {client.name}
              </div>
              <div>
                {client.email}
              </div>
              <div>
                {client.phone}
              </div>
              <div>
                Documento: {client.document}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-4 space-x-5">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push(`/admin/orcamentos/detalhe/${presales_id}`)}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          width="260px"
          onClick={() => editSale()}
          customcolor={props.theme.color}
        >
          Salvar alterações
        </Button>
      </div>
    </div>
  </>
}

export default Update;
