import { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Update = (props) => {
  document.title = `Editar veículo - ${process.env.REACT_APP_NAME}`
  const { exit_id, vehicle_id } = useParams();
  const history = useHistory();
  const [exit, setExit] = useState({})
  const [vehicle, setVehicle] = useState({
    nickname: null,
    driver_id: null,
    guide_id: null,
    license_plate: null,
    reservation_id: null,
    type_time: 'fixed'
  })
  const [idVehicle, setIdVehicle] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const url = `/exits/${exit_id}`;
  const vehicleUrl = `/exits/${exit_id}/vehicles/${vehicle_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `${exit?.travel_name || ''}`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}`
    },
    {
      title: 'Editar veículo',
    }
  ]

  const getExitsById = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setExit(response.data);
      }
    });
  }

  const getVehicleById = vehicleUrl => {
    apiClient.httpInstance.get(vehicleUrl).then(response => {
      if (response.status === 200) {
        setIdVehicle(response.data.vehicle_id)
        setVehicle({
          nickname: response.data.nickname,
          driver_id: response.data.driver_id,
          seats: response.data.seats,
          guide_id: response.data.guide_id,
          license_plate: response.data.license_plate,
          type_time: response.data.type_time,
        });
        setIsEditing(true);
      }
    });
  }
  const changeState = (ev) => {
    let value = ev.target.value
    setVehicle(
      {
        ...vehicle,
        [ev.target.name]: value

      }
    )
  }

  const submitUpdateExitVehicle = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/exits/${exit_id}/vehicles`, {
        ...vehicle,
        vehicle_id: idVehicle,
        exit_vehicle_id: parseInt(vehicle_id)

      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/saidas/detalhe/${exit_id}`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getExitsById(url);
    getVehicleById(vehicleUrl)
  }, [url, vehicleUrl]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {isEditing &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Editar veículo</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/saidas/detalhe/${exit_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitUpdateExitVehicle}
            vehicle={vehicle}
            idVehicle={idVehicle}
            isEditing={isEditing}
            changeState={changeState}
            changeIdVehicle={setIdVehicle}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
