import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { apiClient } from '@services/api';

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/traslados'
  },
  {
    title: `${props.region_name || ''}`,
    active: true,
    route: `/admin/traslados/detalhe/${props.id}`
  },
  {
    title: 'Novo Traslado',
  }
]);

const Create = (props) => {
  document.title = `Cadastrar transfer - ${process.env.REACT_APP_NAME}`
  const [error] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [errorMessage] = useState("");
  const { transfer_id } = useParams();
  const [region, setRegion] = useState({});
  const history = useHistory();
  const [transfer, setTransfer] = useState({
    commission: null,
    transferType: 'in',
    minPeople: 1,
    maxPeople: 1,
    maxReadOnly: false,
    price: null,
    discountMargin: null,
    cost: null,
  });
  const url = `/admin/traslados/detalhe/${transfer_id}`;
  const getTrasferPath = `/translados/${transfer_id}`;

  const changeState = (e) => {
    const value = e.target.value
    setTransfer(
      {
        ...transfer,
        [e.target.name]: value
      }
    )
  }

  const changeTypeTransfer = (ev) => {
    const value = ev.target.value
    let data = {}
    if (value === 'out') {
      data = {
        ...transfer,
        transferType: value,
        minPeople: region.min_value_to_out !== null ? region.min_value_to_out : region.max_value_to_out + 1,
        maxPeople: region.min_value_to_out !== null ? region.max_value_to_out : region.max_value_to_out + 2,
        maxReadOnly: region.min_value_to_out !== null ? true : false,
      }
    } else {
      data = {
        ...transfer,
        transferType: value,
        minPeople: region.min_value_to_in !== null ? region.min_value_to_in : region.max_value_to_in + 1,
        maxPeople: region.min_value_to_in !== null ? region.max_value_to_in : region.max_value_to_in + 2,
        maxReadOnly: region.min_value_to_in !== null ? true : false,
      }
    }
    setTransfer(data)
  }

  const getTransferData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setRegion(response.data);
        setTransfer({
          ...transfer,
          minPeople: response.data.min_value_to_in !== null ? response.data.min_value_to_in : response.data.max_value_to_in + 1,
          maxPeople: response.data.min_value_to_in !== null ? response.data.max_value_to_in : response.data.max_value_to_in + 2,
          maxReadOnly: response.data.min_value_to_in !== null ? true : false,
        })
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getTransferData(getTrasferPath);
  }, [getTrasferPath]);

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient.httpInstance.post("/translados/values", {
      commission: transfer.commission,
      mode_transfer: transfer.transferType,
      value_of: transfer.minPeople,
      value_to: transfer.maxPeople,
      value_dolars: transfer.price,
      value: transfer.price,
      margin_discount: transfer.discountMargin,
      value_dolars_cost: transfer.cost,
      value_cost: transfer.cost,
      translate_id: transfer_id,
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            history.push(url);
          }, 2000);
        }
      })
      .catch(error => {
        if (error) {
          setErrors(error.response.data);
          setTimeout(() => { setErrors("") }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig(region)} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading && <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo traslado</span>
        <Link color="inherit" to={url}>
          <Button
            textcolor="#212121"
            padding="6px 15px"
            width="200"
            variant="outlined"
            bgColor="transparent"
          >
            <Icon className="mr-2">
              arrow_back
            </Icon>
            Cancelar
          </Button>
        </Link>
      </div>
      {success && <Alert className="mt-5" severity="success" color="success">
        Traslado cadastrado com sucesso
      </Alert>}
      <Form
        {...props}
        submit={handleSubmit}
        errors={errors}
        transfer={transfer}
        region={region}
        isEditing={false}
        changeState={changeState}
        changeTypeTransfer={changeTypeTransfer}
      />
    </div>}
  </>
}

export default Create;
