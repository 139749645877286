import { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { Collapse, List, ListItem } from '@material-ui/core';
import { slugfy } from '@utils/helpers';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import PercentIcon from '@assets/images/icons/PercentIcon'

const SidebarSection = (props) => {
  const [options, setOptions] = useState({
    'partners' : false,
    'central' : false
  })
  const [open, setOpen] = useState(false)
  const [chevronIcon, setChevronIcon] = useState('chevron_right')

  const handleClick = (id) => {
    let op = options
    op[id] = !open
    setOptions(op)
    setOpen(!open);
    setChevronIcon((chevronIcon && open) ? 'chevron_right' : 'expand_more')
  };

  let items = props?.item?.options.filter((item) => {
    if (!item?.permission || (item?.permission && item?.permission.includes(slugfy(props?.user?.data?.permission)))) {
        return item;
    }

    return false;
  });

  return <div className="mb-5">
      <h3 className="text-sm font-medium tracking-wide uppercase text-blue">{props?.item?.section}</h3>
      <div className="mt-2">
        <ul className="items text-gray">
          {items.map((option, index) => option.dropdown ? (
            <div
              to={null}
              key={index}
            >
              <li className="p-4" onClick={() => handleClick(option?.id)}>
                <Icon >{option.icon}</Icon>
                <span className="flex ml-3 space-x-24">
                  <span>{option.text}</span>
                  <Icon >{chevronIcon}</Icon>
                </span>
              </li>
              <Collapse in={options[option?.id]} timeout="auto" unmountOnExit>
                <List component="div">
                  {option.id === 'central' && option.children.map((items, idx) => (
                    <NavLink
                      to={`/admin/central/${slugfy(items.name)}`}
                      activeClassName="text-dark"
                      key={idx}
                    >
                      <ListItem className="justify-center">
                        {items.name}
                      </ListItem>
                    </NavLink>
                  ))}
                  {option.id === 'partners' && option.children.map((items, idx) => (
                    <NavLink
                      to={`/admin/parceiros/${slugfy(items.name)}`}
                      activeClassName="text-dark"
                      key={idx}
                    >
                      <ListItem className="justify-center">
                        {items.name}
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            </div>
          ) : (
            <NavLink
              to={option?.link || `/admin/${slugfy(option.text)}`}
              isActive={(match) => {
                if(option.text === 'Dashboard') {
                  return match?.isExact ? true : false
                } else if (match?.url === `/admin/${slugfy(option.text)}`) {
                  return true
                }
              }}
              activeClassName="text-dark"
              key={index}
            >
              <li className="p-4">
                {option.icon === 'percent' ?
                  <PercentIcon />
                :
                  <Icon>{option.icon}</Icon>
                }
                <span className="ml-3">{option.text}</span>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>;
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(SidebarSection);
