import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@components/Table';
import { useHistory } from "react-router-dom";
import TextField from '@components/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const statusOptions = [
  { value: 'all', text: 'Todos' },
  { value: true, text: 'Ativo' },
  { value: false, text: 'Inativo' },
]

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    event.stopPropagation();
    setLoading(true);

    apiClient.httpInstance.put(`/agencies/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
      .catch((error) => console.log(error));
  };

  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Lista de agências parceiras - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState('all');

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            assignment_ind
          </Icon>
          <span>Lista de agências parceiras</span>
        </div>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('agencias/cadastro')}
          customcolor={props.theme.color}><Icon className="mr-2">add</Icon> Novo</Button>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center">
          <TextField
            className="inputRounded"
            maxwidth="300px"
            placeholder="Busque por uma agência"
            variant="outlined"
            type="text"
            name="q"
            onChange={(e) => setQ(e.target.value)} />
          <TextField
            className="ml-4 inputRounded"
            label="Filtre por status"
            maxwidth="300px"
            select
            variant="outlined"
            name="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required>
            {statusOptions.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <Table
        path="/agencies"
        params={{
          q, status
        }}
        columns={[
          {
            headerName: 'Nome',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1
          },
          {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            flex: 1,
          },
          {
            headerName: 'Comissão',
            field: 'percentage',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            valueFormatter: (params) => {
              const row = params.row;
              return `${row.percentage}%`;
            },
          },
          {
            headerName: 'Status',
            field: 'status',
            headerAlign: 'right',
            align: 'right',
            preventRedirect: true,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
              return (<>
                {permissions.includes(props?.theme?.permission) ?
                  <RowSwitch
                    status={params.row.status}
                    id={params.row.id}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  :
                  <>{params.row.status ? 'Ativo' : 'Inativo'}</>
                }
              </>);
            },
          }
        ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);
