import Button from '@components/Button';
import TextField from '@components/TextField';
import PhoneMaskInput from '@components/PhoneMaskInput';

const Form = (props) => {

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex flex-wrap mb-4">
          <div className="flex-none sm:p-2 mt-2 md:mt-0 sm:w-40 min-w-max md:w-2/3">
            <TextField
              className="inputRounded"
              label="Nome do cliente"
              variant="outlined"
              type="text"
              name="name"
              value={props.client.name}
              onChange={(ev) => props.changeState(ev)}
              minWidth='300px'
              required
            />
          </div>
          <div className="flex flex-wrap sm:p-2 mt-5 md:mt-0 sm:w-40 min-w-max md:w-1/3">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              value={props.client.email}
              onChange={(ev) => props.changeState(ev)}
              minWidth='300px'
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4">
          <div className="flex-none sm:p-2 mt-2 md:mt-0 sm:w-40 min-w-max">
            <PhoneMaskInput
              name="phone"
              value={props.client.phone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({ target: { name: 'phone', value: target.target.value } })}
              width='300px'
            />
          </div>
          <div className="flex flex-wrap sm:p-2 mt-5 md:mt-0 sm:w-40 min-w-max">
            <TextField
              width="300px"
              className="inputRounded"
              label="Documento de identificação"
              variant="outlined"
              type="text"
              name="document"
              value={props.client.document}
              onChange={(ev) => props.changeState(ev)}
              minWidth='300px'
              required
            />
          </div>
        </div>
      </div>
      <div className="text-left sm:text-right space-x-3">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="100px"
          variant="outlined"
          onClick={props.backLink}
          type="button"
          bgColor="transparent"
        >
          Cancelar
        </Button>
        <Button
          textcolor="#FFF"
          padding="6px 10px"
          width="180px"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar alterações
        </Button>
      </div>
    </form>
  </>
}

export default Form;
