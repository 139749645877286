import './app.css';
import Login from './login';
import ForgetPassword from './forget_password';
import NewPassword from './new_password';
import logo from '@assets/images/logo/large/tour_white.png';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    Switch,
    Route,
    // Redirect,
} from "react-router-dom";

const CustomCard = withStyles((theme) => ({
  root: {
    borderRadius: "20px",
  },
}))(Card);

const Auth = props => {
    return <Switch>
      <div className="wrapper">
        <div className="auth-content">
          <img className="mx-auto mb-10 logo" src={`${process.env.REACT_APP_LOGO_BLACK}`} alt={process.env.REACT_APP_NAME}></img>
          <Route path="/login">
            <div className="my-5 text-blue description">
              <h2 className="mb-3 text-4xl font-medium">Seja bem-vindo</h2>
              <span>Use o formulário abaixo para se autenticar na nossa plataforma exclusiva</span>
            </div>
          </Route>
          <CustomCard className="p-6 text-left rounded content">
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/recuperar-senha">
                <ForgetPassword />
            </Route>
            <Route exact path="/nova-senha/token/:token">
                <NewPassword />
            </Route>
          </CustomCard>
        </div>
      </div>
    </Switch>
}

export default Auth;
