import InputCurrency from '@components/InputCurrency';
import RadioButton from '@components/RadioButton';
import TextField from '@components/TextField';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useRef, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

const PaymentForm = (props) => {
  const [idProof, setIdProof] = useState('')
  const [nameProof, setNameProof] = useState('')
  const fileInput = useRef(null)
  
  const disabledProof = () => {
    if (props.reserve.payment_status !== 'accomplished') {
      return true
    } else if (props.reserve.payment_method === null) {
      return true
    }
    return false
  }

  const getFile = (ev) => {
    if (props.hasOwnProperty('addProof')) {
      props.addProof(ev.target.files[0], ev.target.files[0].name)
      if (idProof) {
        document.getElementById(idProof).innerHTML = 'Comprovante anexado'
        document.getElementById(idProof).classList.remove('MuiButton-outlined')
        document.getElementById(nameProof).innerHTML += '<br />' + ev.target.files[0].name
      }
    } else {
      if (ev.target.files && ev.target.files.length > 0) {
        if (idProof) {
          let id = idProof.split('_')
          props.changeProof(ev.target.files[0], ev.target.files[0].name, id[1])
          document.getElementById(idProof).innerHTML = 'Comprovante anexado'
          document.getElementById(idProof).classList.remove('MuiButton-outlined')
          document.getElementById(nameProof).innerHTML += '<br />' + ev.target.files[0].name
        } else {
          props.changeProof(ev.target.files[0], ev.target.files[0].name)
        }
      }
    }
  }

  return <>
    <div className="p-4 text-lg border-b border-solid text-blue font-poppins border-lightgray">
      <span>Informações de pagamento</span>
    </div>
    {Number(props.reserve.totalPayment) !== Number(props.reserve.total) &&
      <>
        <div className="px-4 py-2 font-poppins">
          <p className="pb-4 font-bold">Detalhes do pagamento</p>
        </div>
        <div className="flex p-4">
          <div className="flex-1">
            Status do pagamento
          </div>
          <div className="flex justify-end flex-1">
            <RadioButton
              data={[
                {
                  value: 'pending',
                  label: 'Pendente'
                },
                {
                  value: 'accomplished',
                  label: 'Realizado'
                }
              ]}
              name="payment_status"
              value={props.reserve.payment_status}
              change={(ev) => props.changeReserve(ev)}
            />
          </div>
        </div>
        <div className="flex p-4 border-t border-solid border-lightgray">
          <div className="flex-1">
            Total do pagamento
          </div>
          <div className="flex justify-end flex-1">
            <RadioButton
              disabled={props.reserve.payment_status !== 'accomplished' ? true : false}
              data={[
                {
                  value: 'partial',
                  label: 'Parcial'
                },
                {
                  value: 'total',
                  label: 'Total'
                }
              ]}
              name="payment_type"
              value={props.reserve.payment_status !== 'accomplished' ? null : props.reserve.payment_type}
              change={(ev) => props.changeReserve(ev)}
            />
          </div>
        </div>
        <div className={`flex px-4 ${props.reserve.payment_type !== 'partial' ? 'hidden' : ''}`}>
          <div className="flex-1">
            <InputCurrency
              disabled={false}
              className="inputRounded"
              label="Valor pago pelo cliente"
              variant="outlined"
              type="text"
              name="customer_paid_amount"
              value={props.reserve.payment_status !== 'accomplished' ? 0 : props.reserve.customer_paid_amount}
              onChange={(ev) => props.changeReserve(ev)}
            />
          </div>
          <div className="flex-1" />
        </div>
        <div className="flex p-4 border-b border-solid border-lightgray">
          <div className="flex-1">
            Forma de pagamento
          </div>
          <div className="flex justify-end flex-1">
            <RadioButton
              disabled={props.reserve.payment_status !== 'accomplished' ? true : false}
              data={[
                {
                  value: 'money',
                  label: 'Dinheiro'
                },
                {
                  value: 'card',
                  label: 'Cartão'
                }
              ]}
              name="payment_method"
              value={props.reserve.payment_status !== 'accomplished' ? null : props.reserve.payment_method}
              change={(ev) => props.changeReserve(ev)}
            />
          </div>
        </div>
        {props.reserve.payment_status === 'accomplished' &&
          <>
            <div className="px-4 py-2 font-poppins">
              <p className="pb-2 font-bold">Comprovantes</p>
            </div>
            <div className="flex p-4">
              <div className="flex-1">
                <TextField
                  disabled={disabledProof()}
                  className="inputRounded"
                  label={props.reserve.payment_method === "money" ? "Número da boleta" : "Número de autorização do cartão"}
                  variant="outlined"
                  type="text"
                  name="payment_proof"
                  value={props.reserve.payment_status !== 'accomplished' ? null : props.reserve.payment_proof}
                  onChange={(ev) => props.changeReserve(ev)}
                />
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex items-start p-4">
              <div className="flex-1">
                <input
                  className="hidden"
                  type='file'
                  name='file'
                  ref={fileInput}
                  onChange={getFile}
                  accept="image/*,application/pdf"
                />
                <span className="cursor-pointer text-blue" onClick={() => fileInput.current.click()}>
                  Anexar comprovante</span>
              </div>
              <div className="flex-col flex-1 space-y-3">
                <div className="flex-1 font-medium">
                  Comprovantes
                </div>
                {props?.reserve?.proofs && props?.reserve?.proofs.map((proof, idx) => {
                  return <div className="flex items-center flex-1">
                    <span className="cursor-pointer">{proof.proof_name ? proof.proof_name : proof?.name}</span>
                    <Icon className="ml-2 text-red-500 cursor-pointer" onClick={() => props.removeProofPayment(idx)}>delete</Icon>
                  </div>
                })}
              </div>
            </div>
          </>
        }
      </>
    }
    {props.reserve.payments &&
      <>
        <div className="p-4 pb-0 text-lg text-blue font-poppins border-lightgray">
          <span>Histórico de pagamento</span>
        </div>
        {props.reserve.payments.map((oldPayment, index) => {
          return <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2 md:col-span-1 px-4 space-y-3">
              <div className="flex-1 font-medium">
                Data
              </div>
              <div className="flex flex-1">
                <Moment format="DD/MM/YYYY" date={oldPayment.created_at} />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 px-4 space-y-3">
              <div className="flex-1 font-medium">
                Valor pago
              </div>
              <div className="flex flex-1">
                <NumberFormat
                  value={Number(oldPayment.total) || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />
              </div>
            </div>
            <div className="col-span-4 md:col-span-1 px-4 space-y-3">
              <div className="flex-1 font-medium">
                Comprovantes
              </div>
              {!oldPayment.proof_name && !oldPayment.proofs.length &&
                <div className="flex flex-1">
                  <span id={'comp_name_' + oldPayment.id} className="h-6">
                  </span>
                </div>
              }
              {oldPayment.proof_name &&
                <div className="flex flex-1">
                  <a id={'comp_name_' + oldPayment.id} className="cursor-pointer h-6" href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${oldPayment.proof}`} target="_blank">
                    {oldPayment.proof_name}
                  </a>
                </div>
              }
              {oldPayment.proofs.map((proof, idx) => {
                return <div className="flex flex-1">
                  <a id={'comp_name_' + oldPayment.id} className="cursor-pointer h-6" href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${proof.path}`} target="_blank">
                    {proof.name}
                  </a>
                </div>
              })}
            </div>
            <div className="col-span-4 md:col-span-1 px-4 pb-3 mb-4 md:pb-0 md:mb-0 space-y-3 border-b border-solid md:border-none border-lightgray">
              <div className="flex-1 font-medium">
                Anexar
              </div>
              <div className="flex-1">
                <input
                  className="hidden"
                  type='file'
                  name='file'
                  ref={fileInput}
                  onChange={getFile}
                  accept="image/*,application/pdf"
                />
                {!oldPayment?.proofs?.length ?
                  <Button
                    id={'comp_' + oldPayment.id}
                    textcolor="#212121"
                    padding="6px 15px"
                    margintop="0px"
                    width="200"
                    variant="outlined"
                    onClick={() => {
                      fileInput.current.click()
                      setIdProof('comp_' + oldPayment.id)
                      setNameProof('comp_name_' + oldPayment.id)
                    }}
                    bgColor="transparent"
                  >
                    Anexar comprovante
                  </Button>
                  :
                  <div className="flex flex-1">
                    <a id={'comp_name_' + oldPayment?.proofs[0].id} className="cursor-pointer h-6" href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${oldPayment.proofs[0].path}`} target="_blank">
                      Visualizar comprovante
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>
        })}
      </>
    }
  </>
}

export default PaymentForm;