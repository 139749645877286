import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, useHistory, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import DeleteModal from '@components/DeleteModal';
import CircularProgress from '@material-ui/core/CircularProgress';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do guia - ${process.env.REACT_APP_NAME}`
  const host = window.location.host
  const { guide_id } = useParams();
  const [guide, setGuide] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/guides/${guide_id}`;

  const getGuideDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setGuide(response.data);
        setLoading(false)
      }
    });
  }

  const userLogged = JSON.parse(localStorage.getItem('user'));

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/guias'
    },
    {
      title: `${guide.name}`,
    }
  ]

  useEffect(() => {
    getGuideDetails(url);
  }, [url]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{guide.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/guias/editar/${guide_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Desativar
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/guides/${guide_id}`}
                title="Excluir guia?"
                redirect={'/admin/guias'}
                description="Você realmente deseja excluir este guia? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do guia</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do guia
            </div>
            <div className="flex-1">
              {guide.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {guide.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {guide.phone}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Endereço
            </div>
            <div className="flex-1">
              {guide.address}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Doc. de identificação
            </div>
            <div className="flex-1">
              {guide.identification_document}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Observações
            </div>
            <div className="flex-1">
              {guide.observation}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Permitir apenas o acesso nos dias de saída
            </div>
            <div className="flex-1">
              {guide.access_days
                ? <>
                  <Icon className="mr-2" style={{ color: '#4CAF50' }}>
                    check
                  </Icon>
                </> : <>
                  <Icon className="mr-2" style={{ color: '#F44336' }}>
                    close
                  </Icon>
                </>
              }
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Link de acesso de guia
            </div>
            <div className="flex-1 underline text-primary truncate -mx-4">
              <Link to={`/saidas/guia/${guide.url_link}/Guia`} target="_blank" >
                {host}/saidas/guia/{guide.url_link}/Guia
              </Link>
            </div>
          </div>
          {(userLogged.data.permission === 'Administrador' || userLogged.data.permission === 'Gerente' || userLogged.data.permission === 'Logistica') && <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Link de acesso de administrador
            </div>
            <div className="flex-1 underline text-primary truncate -mx-4">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="200px"
                onClick={() => history.push(`/admin/guias/saidas/${guide.id}/${guide.url_link}`)}
                customcolor={props.theme.color}
              >
                Visualizar saídas
              </Button>
            </div>
          </div>}
        </div>
      </div>
    </>
  } else {
    return <div className="grid w-full h-full justify-items-center">
      <CircularProgress size={50} />
    </div>
  }

}

export default Show;
