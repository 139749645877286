import { useRef, useState } from 'react'
import Icon from '@material-ui/core/Icon';
import { Popover, Box, MenuItem, ListItemText, Typography } from '@material-ui/core';
import Button from '@components/Button'
import { useHistory } from "react-router-dom";

const HeaderDropdownMin = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const lettersName = (name) => {
    if (name) {
      var arr = name?.split(' ');
      if (arr[1] && (arr[1]?.toLowerCase() === 'de' || arr[1]?.toLowerCase() === 'da' || arr[1]?.toLowerCase() === 'do')) {
        return arr[0][0].toUpperCase() + arr[2][0].toUpperCase()
      } else if (arr[0] && arr[1]) {
        return arr[0][0].toUpperCase() + arr[1][0].toUpperCase()
      } else {
        return arr[0] ? arr[0][0].toUpperCase() : ''
      }
    }
  }

  return <>
    <Box className="flex menu">
      <Box
        className="flex justify-space-around cursor-pointer md:hidden lg:hidden xl:hidden"
        onClick={props.dropdownInfo !== null ? handleOpen : null}
        ref={anchorRef}
      >
        <div className="flex user mr-5">
          {props.image ?
            <img className="mr-2 bg-gray avatar" src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${props.image}`} alt={lettersName(props.name)}></img>
            :
            <div className="mr-2 pt-3 bg-gray avatar items-center">{lettersName(props.name)}</div>
          }
          <div className="flex flex-col text-left">
            <span className={props.color === 'black' ? 'text-blue' : ''}>{props.name}</span>
            <small className={props.color === 'black' ? 'text-blue' : ''}>{props.permission}</small>
          </div>
        </div>
        {props.dropdownInfo !== null &&
          <span className="ml-5">
            {open ?
              <Icon>arrow_drop_up</Icon>
              :
              <Icon>arrow_right</Icon>
            }
          </span>
        }
      </Box>
    </Box>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 20,
        vertical: 55
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          'borderRadius': '16px'
        }
      }}
    >
      {props.dropdownInfo !== null &&
        <Box sx={{ mt: 2 }}>
          {props.dropdownInfo.map((opt, index) => {
            if (opt.type === 'list') {
              return (
                <MenuItem key={index} onClick={opt.onClick}>
                  <Icon>{opt.icon}</Icon>
                  <Box sx={{ ml: 2, mr: 5 }}>
                    <ListItemText
                      primary={(
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"

                        >
                          {opt.title}
                        </Typography>
                      )}
                    />
                  </Box>
                </MenuItem>
              )
            } else if (opt.type === 'btn') {
              return (
                <Box key={index} sx={{ p: 2, pt: 0 }}>
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    variant="outlined"
                    onClick={opt.onClick}
                    bgColor="transparent"
                  >
                    {opt.title}
                  </Button>
                </Box>
              )
            } else {
              return (<></>)
            }
          })}
        </Box>
      }
    </Popover>
  </>
}


export default HeaderDropdownMin
