import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar detalhes do tipo - ${process.env.REACT_APP_NAME}`
  const { template_type_id } = useParams();
  const history = useHistory();
  const [templateType, setTemplateType] = useState({});
  const [error, setError] = useState(false);
  const [isEditing, setIsEditing] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  let url = `/template_types/${template_type_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/central/tipos'
    },
    {
      title: `${templateType.name !== undefined ? templateType.name : ''}`,
      active: true,
      route: `/admin/central/tipos/detalhe/${template_type_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getTypeById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setTemplateType(response.data);
          setIsEditing(true)
        }
      }
      )
      .catch((error) => console.log(error));
  }

  const changeState = (ev) => {
    if (ev.target.name === "status" && ev.target.value === "true") {
      setTemplateType(
        {
          ...templateType,
          status: true
        }
      )
    } else if (ev.target.name === "status" && ev.target.value === "false") {
      setTemplateType(
        {
          ...templateType,
          status: false
        }
      )
    } else {
      setTemplateType(
        {
          ...templateType,
          [ev.target.name]: ev.target.value
        }
      )
    }
  }

  const submitTypeEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/template_types/${template_type_id}`, {
        name: templateType.name,
        status: templateType.status
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/central/tipos');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }
  useEffect(() => {
    getTypeById(url);
  }, [url]);

  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      {permissions.includes(props?.theme?.permission) ?
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              extcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/central/tipos/detalhe/${template_type_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitTypeEdit}
            templateType={templateType}
            isEditing={isEditing}
            changeState={changeState}
          />
        </div>
        :
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              extcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/central/tipos/detalhe/${template_type_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      }
    </>
  } else {
    return <>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
