import { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@components/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { apiClient } from '@services/api';
import Moment from 'react-moment';
import Button from '@components/Button';
import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import '@assets/css/Admin/components/table.scss';

const ServiceIntegrationsTable = (props) => {
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  let [rows] = useState([]);
  let params = props.params
  const history = useHistory();
  
  useEffect(() => {
  }, []);

  return <>
    <Table
      path="/service_integrations"
      onRowClick={(props) => {
        const row = props.row;
        history.push({pathname:`configuracoes/integracoes/${row.id}`});
      }}
      columns={[
        {
          headerName: 'Serviço',
          headerClassName: 'super-app-theme--header',
          field: 'name',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            return (
              <div className="flex items-center">
                <div className="flex-auto space-y-1">
                  <div className="">
                    {row.name}
                  </div>
                </div>
              </div>
            )
          },
        }
      ]}
      rows={rows}
    />
  </>
}

export default ServiceIntegrationsTable;
