import Index from './Index';
import Create from './Create'
import Show from './Show';
import Update from './Update'

import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Drivers = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} ></Index>
      </Route>
      <Route exact path={`${path}/cadastro`}>
        <Create theme={theme}></Create>
      </Route>
      <Route exact path={`${path}/detalhe/:template_id`}>
        <Show theme={theme}></Show>
      </Route>
      <Route exact path={`${path}/detalhe/:template_id`}>
        <Show theme={theme}></Show>
      </Route>
      <Route exact path={`${path}/editar/:template_id`}>
        <Update theme={theme}></Update>
      </Route>
    </Switch>
  </>
}

export default Drivers;