import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={","}
      decimalScale={2}
      thousandSeparator={"."}
      fixedDecimalScale={true}
      decimalPrecision={2}
      isNumericString
      prefix={`${process.env.REACT_APP_CURRENCY} `}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomTextField = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: "40px",
    width: props?.width || "100%"
  }),
}))(TextField);

const InputCurrency = props => {
  const {style, className, ...TextfieldProps} = props;

  return <div className={className} style={style || { width: props.width || '100%', maxWidth: props.maxwidth || 'initial' }}>
      <CustomTextField
        {...TextfieldProps}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
      <div className="block">
        {props.error && <small className="ml-3 text-red-500">{props.error}</small>}
      </div>
    </div>;
}

export default InputCurrency;