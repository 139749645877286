
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Tabs from '@components/Tabs';
import {apiClient} from '@services/api';
import ReactToPrint from 'react-to-print'
import Tours from './partials/tours'
import Transfers from './partials/transfers'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: 15
  }
}))

const SalesReserve = (props) => {
  const componentRef = useRef()
  const { type, id } = useParams();
  const [seller, setSeller] = useState(null)
  const [sellerPrint, setSellerPrint] = useState(null)
  const [loading, setLoading] = useState(false);
  const url = `sellers/get/all_sellers/${type}/${id}`;
  const classes = useStyles()

  const sellerType = {
    seller: {
      text: 'Vendedor',
    },
    partner: {
      text: 'Vendedor parceiro',
    },
    agency: {
      text: 'Agência parceira',
    }
  }

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/comissoes'
    },
    {
      title: `${seller?.name} (${sellerType[type].text || null})`,
    }
  ];

  const getSellerDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setSeller(response.data);
        setSellerPrint(`${response.data.name} (${sellerType[type].text || null})`)
        setLoading(false)
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    getSellerDetails(url);
  }, [url]);


  return <>
  <style type="text/css" media="print">{"\
   @page {\ size: landscape;\ }\
  "}</style>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="py-7 px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue font-poppins">
        <span>{seller?.name} ({sellerType[type].text || null})</span>
        <ReactToPrint
          trigger={() => <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            width="150px"
          >
            Baixar em PDF
          </Button>
          }
          content={() => componentRef.current}
        />
      </div>

      <Tabs
        ref={componentRef}
        className={classes.tabs}
        data={[
          {
            title: 'Passeios',
            content: (
              <Tours 
                {...{
                  ...props,
                  type, 
                  id,
                  sellerPrint,
                  componentRef
                }}
              />
            )
          },
          type == 'seller' ?
          {
            title: 'Translados',
            content: (
              <Transfers 
                {...{
                  ...props,
                  type, 
                  id,
                  sellerPrint,
                  componentRef
                }}
              />
            )
          } : ''
        ]}
      />
    </div>
  </>
}

export default SalesReserve;
