import Index from './Index';
import CreateTerms from './CreateTerms';
import EditCompanyProfile from './EditCompanyProfile';
import EditCompanyIntegration from './EditCompanyIntegration';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Config = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/termos-de-condicao/:lang/:type`}>
        <CreateTerms theme={theme} />
      </Route>
      <Route exact path={`${path}/perfil-da-empresa`}>
        <EditCompanyProfile theme={theme} />
      </Route>
      <Route exact path={`${path}/integracoes/:service_id`}>
        <EditCompanyIntegration theme={theme} />
      </Route>
      <Route exact path={`${path}/integracoes/`}>
        <EditCompanyIntegration theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Config;
