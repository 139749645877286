import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const PaymentsDetail = (props) => {
  const [changeDate, setChangeDate] = useState(false)
  const [date, setDate] = useState(null)
  const [tourId, setTourId] = useState(null)
  const [index, setIndex] = useState(null)
  const classes = useStyles();

  const openDateModal = (row, idx) => {
    setDate(row.tour_date)
    setTourId(row.tour_id)
    setIndex(idx)
    setChangeDate(true)
  }
  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Data
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Valor pago
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Comprovante
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataReservations.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <div className="flex items-center">
                  <div className="flex-none">
                    <img src={row.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${row.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
                  </div>
                  <div className="flex-auto space-y-3">
                    <div className="font-medium text-lg">
                      {row.tour_name}
                    </div>
                    <div>
                      {row.travel_name} {row.transfer_type ? `(${row.transfer_type})` : null}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.type === 'transfer' ?
                  row.adults_total
                :
                  row.total
                }
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <Moment format="DD/MM/YYYY" date={row.tour_date} />
                {props.isEditing && <>
                    <p
                      className="text-red-500 cursor-pointer"
                      onClick={() => openDateModal(row, idx)}
                    >
                      Alterar a data
                    </p>
                  </>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default PaymentsDetail;
