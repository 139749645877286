import React from 'react';
import Button from '@components/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '@components/TextField';
import { useLocation, useHistory } from "react-router-dom";
import {apiClient} from '@services/api';
import { login } from '@actions/auth';
import { connect } from 'react-redux';
import {
  Link,
} from "react-router-dom";

const Login = props => {
  document.title = `Faça login na sua conta - ${process.env.REACT_APP_NAME}`
  const location = useLocation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const history = useHistory();

  if (props?.user?.data) {
    history.push('/admin');
  }

  React.useEffect(() => {
    setSuccess(location?.state?.success);
    setTimeout(() => {setSuccess("")}, 4000);
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient
      .httpInstance.post("/login", {
        email: email,
        password: password,
        device_name: "React v0.1",
      })
      .then(response => {
        if (response.status === 200) {
          let user = {
            data: response.data.user,
            token: response.data.access_token,
          };
          apiClient.setToken(user.token)
          props.auth(user);

          history.push('/admin');
        }
      })
      .catch(error => {
        if(error?.response?.status === 401) {
          setError(true);
          setTimeout(() => {setError(false)}, 4000);
        }
      });
  };

  return <>
      <div className="flex text-3xl justify-content-start mb-7">
        <span style={{color: process.env.REACT_APP_SECONDARY_COLOR}}>Entre na sua conta</span>
      </div>
      {success && (<Alert className="mb-5" variant="filled" severity="success">
        {success}
      </Alert>)}
      {error && (<Alert className="mb-5" variant="filled" severity="error">
        Email ou senha incorreto!
      </Alert>)}
      <form onSubmit={handleSubmit}>
        <TextField
            className="inputRounded"
            placeholder="E-mail"
            variant="outlined"
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required></TextField>
        <div className="mt-5">
          <TextField
              className="inputRounded"
              type="password"
              placeholder="Senha"
              variant="outlined"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required></TextField>
        </div>
        <Button textcolor="#545454" type="submit" bgColor={process.env.REACT_APP_PRIMARY_COLOR}>Entrar</Button>
      </form>
      <hr className="mt-6 mb-4" style={{color: "#E0E0E0"}}/>
      <Link to="/recuperar-senha">
        <span className="text-sm text-gray">Esqueci minha senha</span>
      </Link>
    </>;
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProp = dispatch => {
  return {
    auth (user) {
      const action = login(user);
      dispatch(action);
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(Login);
