import React from 'react';
import '@css/Admin/index.scss';
import Header from './partials/Header';
import Content from './partials/Content';
import Dashboard from './pages/dashboard/main'
import Sidebar from './partials/sidebar/Sidebar';
import Agencies from './pages/agencies/main';
import Tours from './pages/tours/main';
import Transfers from './pages/transfers/main';
import Combos from './pages/combos/main';
import Vehicles from './pages/vehicles/main';
import Drivers from './pages/drivers/main';
import TemplateTypes from './pages/central/templateTypes/main';
import Templates from './pages/central/templates/main';
import Messages from './pages/central/messages/main';
import Partners from './pages/partners/main';
import Guides from './pages/guides/main';
import Cashflow from './pages/cashflow/main';
import Cupos from './pages/cupos/main';
import Budgets from './pages/budgets/main';
import Approves from './pages/budgets/Approves';
import Regions from './pages/regions/main';
import Profile from './pages/profile/main'
import Exits from './pages/exits/main';
import Users from './pages/users/main';
import Clients from './pages/clients/main';
import Salespeople from './pages/salespeople/main';
import Reservation from './pages/reservation/main';
import CartProduct from './pages/cartProduct/main';
import Config from './pages/config/main';
import Commissions from './pages/commissions/main';
import AdminConfig from '@config/admin';
import { slugfy } from '@utils/helpers';
import { connect } from 'react-redux';

import {
  Switch,
  Route,
  useRouteMatch,
  useHistory
  // Redirect,
} from "react-router-dom";

const Admin = props => {
  let { path } = useRouteMatch();
  const history = useHistory();
  let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.default;
  const [showMenu, setShowMenu] = React.useState(false);

  if (!props?.user?.data) {
    history.push('/login');
  }

  const handleAnchorClick = event => {
    console.log('Clicou')
  }

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} setShowMenu={setShowMenu} showMenu={showMenu} />
        <div className="flex flex-row admin-body">
          <Sidebar theme={theme} showMenu={showMenu} />
          <Content>
            <Route path={`${path}/`}>
              <Dashboard theme={theme} setShowMenu={setShowMenu}/>
            </Route>
            <Route path={`${path}/parceiros/agencias`}>
              <Agencies theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/passeios`}>
              <Tours theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/traslados`}>
              <Transfers theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/combos`}>
              <Combos theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/veiculos`}>
              <Vehicles theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/vendedores`}>
              <Salespeople theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/motoristas`}>
              <Drivers theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/parceiros/vendedores`}>
              <Partners theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/guias`}>
              <Guides theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/fluxo-de-caixa`}>
              <Cashflow theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/central/mensagens`}>
              <Messages theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/central/templates`}>
              <Templates theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/central/tipos`}>
              <TemplateTypes theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/cupos`}>
              <Cupos theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/regioes`}>
              <Regions theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/perfil`}>
              <Profile theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/saidas`}>
              <Exits theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/usuarios`}>
              <Users theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/reservas`}>
              <Reservation theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/orcamentos`}>
              <Budgets theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/aprovacoes`}>
              <Approves theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/clientes`}>
              <Clients theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/carrinho`}>
              <CartProduct theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/configuracoes`}>
              <Config theme={theme} setShowMenu={setShowMenu} />
            </Route>
            <Route path={`${path}/comissoes`}>
              <Commissions theme={theme} setShowMenu={setShowMenu} />
            </Route>
          </Content>
        </div>
      </div>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Admin);