
import Admin from '@config/admin';
import { slugfy } from '@utils/helpers';
import SidebarSection from './SidebarSection';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { apiClient } from '@services/api';
import { logout } from '@actions/auth';
import HeaderDropdownMin from '@components/HeaderDropdownMin';

const permissions = ['administrador'];

let Sidebar = (props) => {
  let show = props.showMenu ? 'block' : 'none'
  let sections = Admin?.items.filter((item) => {
    if (!item?.permission || (item?.permission && item?.permission.includes(slugfy(props?.user?.data?.permission)))) {
      return item;
    }

    return false;
  });

  const history = useHistory();
  const logout = () => {
    apiClient.setToken(null)
    props.auth();
    history.push('/login');
  }

  const dropdownInfo = [
    {
      type: 'list',
      icon: 'person',
      title: 'Perfil',
      onClick: () => history.push('/admin/perfil')
    },
    {},
    {
      type: 'btn',
      title: 'Sair',
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
      onClick: () => { logout() }
    }
  ]

  if (permissions.includes(props?.theme?.permission)) {
    dropdownInfo[1] = {
      type: 'list',
      icon: 'settings',
      title: 'Configurações',
      onClick: () => history.push('/admin/configuracoes')
    }
  }

  return <div className="px-4 pt-5 sidebar md:block lg:block xl:block" style={{ display: show, zIndex: 50000 }}>
    <HeaderDropdownMin
      name={props?.user?.data?.name}
      permission={props?.user?.data?.permission}
      image={props?.user?.data?.image}
      dropdownInfo={dropdownInfo}
    />
    <hr className="mt-2 mb-4" style={{ color: "#E0E0E0" }} />
    {sections.map((item, index) => (
      <>
        <SidebarSection item={item} key={index}></SidebarSection>
      </>
    ))}
  </div>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProp = dispatch => {
  return {
    auth() {
      const action = logout();
      dispatch(action);
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(Sidebar);
