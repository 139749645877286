import Button from '@components/Button';
import TextField from '@components/TextField';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import Select from '@components/Select';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';

const Index = (props) => {
  const history = useHistory();
  const [role, setRole] = useState('all')
  let [rows] = useState([]);
  let [q, setQ] = useState("");

  const roleOptions = [
    {
      name: 'Administrador'
    }
  ]

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Usuários</span>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('usuarios/cadastro')}
          customcolor={props.theme.color}
        >
          <Icon className="mr-2">add</Icon> Novo
        </Button>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center space-x-3">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por usuários"
              width="200px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <Select
              label="Nível"
              width="100px"
              defaultValue="all"
              onChange={(e) => setRole(e.target.value)}
              items={{
                field: {
                  value: 'name',
                  text: 'name'
                },
                data: roleOptions
              }}
            />
          </div>
        </div>
      </div>
      <Table
        path="/users"
        params={{
          q, role
        }}
        columns={[
            {
              headerName: 'Nome',
              headerClassName: 'super-app-theme--header',
              field: 'name',
              flex: 1,
            },
            {
              headerName: 'Nível',
              headerClassName: 'super-app-theme--header',
              field: 'livel',
              flex: 1,
            },
            {
              headerName: 'E-mail',
              headerClassName: 'super-app-theme--header',
              field: 'email',
              flex: 1,
            },
            {
              headerName: 'Telefone',
              headerClassName: 'super-app-theme--header',
              field: 'phone',
              flex: 1,
            },
            {
              headerName: 'Comissão',
              headerClassName: 'super-app-theme--header',
              headerAlign: 'right',
              align: 'right',
              field: 'commission',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                return (<div className="w-full h-full">
                  {row.commission? 'Sim' : 'Não'}
                </div>);
              }
            }
          ]}
        rows={rows}
        getRowId={(r) => r.user_id}
      />
    </div>
  </>
}

export default Index;
