import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar detalhes do guia - ${process.env.REACT_APP_NAME}`
  const { guide_id } = useParams();
  const history = useHistory();
  const [guide, setGuide] = useState({
    name: null,
    email: null,
    phone: null,
    address: null,
    identification_document: null,
    observation: null,
    access_days: null,
    company_id: null,
    status: true
  })
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let url = `/guides/${guide_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/guias'
    },
    {
      title: `${guide.name !== undefined ? guide.name : ''}`,
      active: true,
      route: `/admin/guias/detalhe/${guide_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getGuideById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setGuide(response.data);
          setIsEditing(true)
        }
      }
      );
  }

  const changeCheckbox = (ev) => {
    setGuide(
      {
        ...guide,
        access_days: ev.target.checked
      }
    )
  }

  const changeState = (ev) => {
    if (ev.target.name === "status") {
      setGuide(
        {
          ...guide,
          status: ev.target.value === "true" ? true : false,

        }
      )
    } else {
      setGuide(
        {
          ...guide,
          [ev.target.name]: ev.target.value

        }
      )
    }
  }

  const submitGuideEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/guides/${guide_id}`, {
        ...guide
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/guias');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getGuideById(url);
  }, [url]);

  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      {permissions.includes(props?.theme?.permission) ?
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/guias/detalhe/${guide_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitGuideEdit}
            guide={guide}
            isEditing={isEditing}
            changeState={changeState}
            changeCheckbox={changeCheckbox}
          />
        </div>
        :
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/guias/detalhe/${guide_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      }
    </>
  } else {
    return <></>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
