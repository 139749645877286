import { useState } from 'react'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import ModalLangTerms from './ModalLangTerms';

const ChooseButton = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const langs = [
    {
      lang: 'pt',
      text: 'Português'
    },
    {
      lang: 'en',
      text: 'Inglês'
    },
    {
      lang: 'es',
      text: 'Espanhol'
    },
  ]

  const chooseLang = (lang, type) => {
    history.push({
      pathname: `/admin/configuracoes/termos-de-condicao/${lang}/${type}`,
      state: {
        terms: props.terms
      }
    })
  }
  return <>
    {props.screen === '1'
    ?
      <Button
        textcolor="#FFF"
        padding="6px 15px"
        width="200"
        customcolor={props.theme.color}
        onClick={() => history.push('/admin/configuracoes/perfil-da-empresa')}
      >
        <Icon className="mr-2">
          edit
        </Icon>
          Editar
      </Button>
    :
      <div className="flex justify-end space-x-3">
        {props.terms && !props.terms.pt && !props.terms.en && !props.terms.es
          ?
            <></>

          :
            <Button
              disabled={!props.lang ? true : false}
              textcolor="#FFF"
              padding="6px 15px"
              width="200"
              customcolor={props.theme.color}
              onClick={() => history.push({
                pathname: `/admin/configuracoes/termos-de-condicao/${props.lang}/${props.typeTerm}`,
                state: {
                  terms: props.terms
                }
              })}
            >
              <Icon className="mr-2">
                edit
              </Icon>
                Editar
            </Button>
        }

        {props.terms && props.terms.pt && props.terms.en && props.terms.es
        ?
          <></>
        :
          <>{props.screen === '2' && <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            onClick={() => setOpen(true)}
          >
            <Icon className="mr-2">
              add
            </Icon>
              Novo
          </Button>}</>
        }
        
        {props.screen === '2'
        ?
          <></>
        :
          <>
          {/* <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            onClick={() => history.push('/admin/configuracoes/integracoes')}
          >
            <Icon className="mr-2">
              add
            </Icon>
              Nova integração
          </Button> */}
          </>
        }
      </div>
    }
    <ModalLangTerms
      open={open}
      close={() => setOpen(false)}
      onCreate={chooseLang}
      terms={props.terms}
      langs={langs}
      type={props.typeTerm}
      {...props}
    />
  </>
}

export default ChooseButton;
