import { useState, useEffect, useRef } from 'react'
import Modal from '@components/Modal';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Select from '@components/Select';
import {apiClient} from '@services/api';

const ChangeSeller = (props) => {
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState(null)
  let ref = useRef(null);
  const url = '/users?seller=true&status=true&pageSize=500';
  const getUsers = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setUsers(response.data.data);
      }
    });
  }
  const changeSeller = async () => {
    apiClient.httpInstance.get(`/users/${userId}`).then(response => {
      if (response.status === 200) {
        props.setReserve({
          ...props.reserve,
          user_id: response.data.id,
          seller_name: response.data.name,
          seller_email: response.data.email
        })
        setUserId(null)
        props.close()

      }
    });
  }
  useEffect(() => {
    getUsers(url);
  }, [url]);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Alterar o vendedor</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex mt-4 justify-center">
        <Select
          withoutAll
          label="Vendedor"
          width="200px"
          defaultValue="all"
          name="user_id"
          value={userId}
          onChange={(ev) => setUserId(ev.target.value)}
          items={{
            field: {
              value: 'user_id',
              text: 'name'
            },
            data: users
          }}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => changeSeller()}
          customcolor={props.theme.color}
        >
            Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeSeller;