import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import DeleteModal from '@components/DeleteModal';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes da agência parceira - ${process.env.REACT_APP_NAME}`
  const { agency_id } = useParams();
  const [deleteRegionModal, setDeleteRegionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [agency, setAgency] = useState({});
  const url = `/agencies/${agency_id}`;
  const history = useHistory();

  const getAgencyData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setAgency(response.data);
        setLoading(false);
      }
    })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    getAgencyData(url);
  }, [url]);

  return <>
    {!loading &&
      <>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to="/admin/parceiros/agencias/">
            <span className="text-blue font-poppins">Início</span>
          </Link>
          <span className="font-poppins">{agency.name}</span>
        </Breadcrumbs>
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>{agency.name}</span>
            {permissions.includes(props?.theme?.permission) &&
              <div className="w-100">
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="100px"
                  onClick={() => history.push(`/admin/parceiros/agencias/editar/${agency_id}`)}
                >
                  <Icon className="mr-2">
                    edit
                  </Icon>
                  Editar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="100px"
                  onClick={() => setDeleteRegionModal(true)}
                  bgColor="#F44336"
                >
                  <Icon className="mr-2">
                    delete
                  </Icon>
                  Excluir
                </Button>
                <DeleteModal
                  open={deleteRegionModal}
                  close={() => setDeleteRegionModal(false)}
                  url={`agencies/${agency.id || ''}`}
                  title="Excluir agência?"
                  redirect={'/admin/parceiros/agencias/'}
                  description="Você realmente deseja excluir esta agência? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente." />
              </div>
            }
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl text-blue font-poppins">
              <span>Informações</span>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Nome da agência
              </div>
              <div className="flex-1 text-gray">
                {agency.name}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Razão Social
              </div>
              <div className="flex-1 text-gray">
                {agency.social_reason}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Pessoa de contato
              </div>
              <div className="flex-1 text-gray">
                {agency.contact_person}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                E-mail
              </div>
              <div className="flex-1 text-gray">
                {agency.email}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Telefone
              </div>
              <div className="flex-1 text-gray">
                {agency.telephone}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Observações
              </div>
              <div className="flex-1 text-gray">
                {agency.observations}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray ">
              <div className="flex-1">
                Commissão (%)
              </div>
              <div className="flex-1 text-gray">
                {`${agency.percentage}%`} - {agency.operational_cost
                  ? 'Com desconto operacional'
                  : 'Sem desconto operacional'
                }
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray ">
              <div className="flex-1">
                Link de acesso
              </div>
              <div className="flex-1 text-gray">
                {agency?.url_link}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray ">
              <div className="flex-1">
                Status
              </div>
              <div className="flex-1 text-gray">
                <span className={agency.status ? 'text-green-500' : 'text-danger-500'}>
                  {agency.status ? 'Ativo' : 'Inativo'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    }
  </>
}

export default Show;
