import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import cuposExampleIcon from '@assets/images/icons/cuposExample.svg';
import Checkbox from '@components/Checkbox';

const StartCalendarModal = (props) => {
  let ref = useRef(null);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '560px'}}>
    <div className="flex justify-center font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-2xl text-blue">Bem vindo a visualização de cupos</span>
        </div>
      </div>
    </div>
    <form>
      <div className="flex mt-4">
        <p className="text-blue font-poppins">
          Para selecionar vários dias, clique na data inicial e arraste até a data final como mostrado abaixo.
        <br />
          Se deseja excluir cupos de um dia específico, basta clicar no dia desejado.
        </p>
      </div>
      <div className="flex">
        <img
          alt="Select file"
          src={cuposExampleIcon}
          width="504"
        />
      </div>
      <div className="flex">
        <Checkbox
            {...props}
            label="Não mostrar novamente"
            value={props.showModal}
            name="showModal"
            onChange={(ev) => props.setShowModal(ev.target.checked)}
          />
      </div>
      <div className="justify-end mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="7px 15px"
          margintop="0px"
          width="100px"
          onClick={props.onSave}
          customcolor={props.theme.color}
        >
          Continuar
        </Button>
      </div>
    </form>
  </Modal>
}

export default StartCalendarModal;
