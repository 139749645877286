import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import PersonCarIcon from '@assets/images/icons/PersonCar.svg';

const permissions = ['administrador', 'logistica'];

const CardNoVehicles = (props) => {
  return <div className="mt-4 bg-white font-poppins" style={{ borderRadius: '10px' }}>
    <div className="flex justify-center pt-3">
      <img
        alt="Select file"
        src={PersonCarIcon}
        width="450"
      />
    </div>
    <div className="flex justify-center text-center text-blue font-medium text-2xl pt-3">
      Sem veículos cadastrados nesta saída
    </div>
    <div className="flex justify-center text-center text-gray pt-3">
      Adicione os veículos necessários para essa saída antes de concluí-la
    </div>
    <div className="flex justify-center text-center py-3">
      {permissions.includes(props?.theme?.permission) &&
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200px"
          onClick={props.onClick}
          customcolor={props.theme.color}
        >
          <Icon className="mr-2">
            add
          </Icon>
          Novo veículo
        </Button>
      }
    </div>
  </div>
}

export default CardNoVehicles;
