import { useState } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Alert from '@material-ui/lab/Alert';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import { apiClient } from '@services/api';
import { format, isValid } from 'date-fns';
import { useHistory } from "react-router-dom";

const Create = (props) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [exit, setExit] = useState({
    tour_date: null,
    reservation_id: null,
    type: null
  })

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/saidas'
    },
    {
      title: 'Nova saída',
    }
  ]

  const changeDate = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setExit({
      ...exit,
      tour_date: value
    })
  }

  const changeState = (ev) => {
    setExit({
      ...exit,
      [ev.target.name]: ev.target.value
    })
  }

  const submitCombos = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/exits", exit)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/saidas/detalhe/${response.data.id}`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Nova saída</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          bgColor="transparent"
          onClick={() => history.push('/admin/saidas')}
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        isEditing={false}
        exit={exit}
        changeState={changeState}
        changeDate={changeDate}
        submit={submitCombos}
      />
    </div>
  </>
}

export default Create
