import Moment from 'react-moment';
import Table from '@components/Table';
import NumberFormat from 'react-number-format';
import Icon from '@material-ui/core/Icon';

const TablePartner = (props) => {
  const access_token = props.accessToken
  const from_date = props.fromDate
  const to_date = props.toDate
  return <div className="flex justify-center pb-3 text-center">
    <Table
      path={'/partner_seller/sales'}
      params={{access_token, from_date, to_date}}
      dataResult="sales"
      columns={[
        {
          headerName: 'Referencia',
          headerClassName: 'super-app-theme--header',
          field: 'references',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            return (<div className="flex items-center pr-4 space-x-5">
              {row.status === 'canceled' &&
                <Icon className="mr-2 text-red-500">block</Icon>
              }
              {row.references}
            </div>);
          }
        },
        {
          headerName: 'Cliente',
          headerClassName: 'super-app-theme--header',
          field: 'name',
          flex: 1,
        },
        {
          headerName: 'Tipo de produto',
          headerClassName: 'super-app-theme--header',
          field: 'type',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            const selectType = {
              tour: {
                bg: '#D8212FCC',
                text: 'Passeio'
              },
              transfer: {
                bg: '#4480A8E5',
                text: 'Traslado'
              },
              translate: {
                bg: '#4480A8E5',
                text: 'Traslado'
              },
              out: {
                bg: '#4480A8E5',
                text: 'Out'
              },
              in: {
                bg: '#009C86E5',
                text: 'In'
              }
            }
            return (
              <div className="flex items-center pr-4 space-x-5">
                {row.type &&
                  <div className="flex items-center justify-center rounded-full h-8 w-24 text-white" style={{ background: row.transfer_type ? selectType[row.transfer_type].bg : selectType[row.type].bg}}>
                    {row.transfer_type ? selectType[row.transfer_type].text : selectType[row.type].text}
                  </div>
                }
              </div>
            );
          }
        },
        {
          headerName: 'Quantidade',
          headerClassName: 'super-app-theme--header',
          field: 'quantity',
          flex: 1,
        },
        {
          headerName: 'Valor',
          headerClassName: 'super-app-theme--header',
          field: 'price',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            return (
              <NumberFormat
                value={row.price || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
              />);
          }
        },
        {
          headerName: 'Comissão',
          headerClassName: 'super-app-theme--header',
          field: 'commission',
          flex: 1,
          renderCell: (params) => {
            const row = params.row;
            return (
              <NumberFormat
                value={row.commission || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
              />);
          }
        },
      ]}
      rows={[]}
    />
  </div>
}

export default TablePartner;
