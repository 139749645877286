import Index from './Index';
import Create from './Create';
import Show from './Show';
import Voucher from './Voucher'
import VehicleCreate from './vehicles/Create'
import VehicleShow from './vehicles/Show'
import VehicleUpdate from './vehicles/Update'
import DerivedCreate from './deriveds/Create'
import DerivedUpdate from './deriveds/Update'
import DerivedShow from './deriveds/Show'
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Exits = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/cadastro`}>
        <Create theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id`}>
        <Show theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/veiculos/cadastro`}>
        <VehicleCreate theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/veiculos/detalhe/:vehicle_id`}>
        <VehicleShow theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/veiculos/editar/:vehicle_id`}>
        <VehicleUpdate theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/derivados/cadastro`}>
        <DerivedCreate theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/derivados/editar/:derived_id`}>
        <DerivedUpdate theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/derivados/detalhe/:derived_id`}>
        <DerivedShow theme={theme}/>
      </Route>
      <Route exact path={`${path}/detalhe/:exit_id/veiculos/detalhe/:vehicle_id/voucher`}>
        <Voucher theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Exits;
