import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Table from '@components/Table';
import NumberFormat from 'react-number-format';
import { format, isValid } from 'date-fns';
import ReactToPrint from 'react-to-print'
import { makeStyles } from '@material-ui/core'
import DatePicker from '@components/DatePicker';
import SelectSearch from 'react-select'
import TextField from '@components/TextField';
import Moment from 'react-moment';
import ChangeStateModal from './partials/ChangeStateModal';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@components/Select';
import Alert from '@material-ui/lab/Alert';

const permissions = ['administrador'];

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: 15
  }
}))

const Index = (props) => {
  const componentRef = useRef()
  const [rows] = useState([]);

  const [dateProof, setDateProof] = useState(null);
  const [search, setSearch] = useState(null);
  const [saleId, setSaleId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [idSending, setIdSending] = useState('');
  const [status, setStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [selectedIdPayment, setSelectedIdPayment] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [companyNF, setCompanyNF] = useState(null);
  const [success, setSuccess] = useState(false);

  const url = `cash_flow`;
  const classes = useStyles();

  useEffect(() => {
    //Oculta menu lateral
    props.setShowMenu(false)
    getCompanies();
    getCompanyNF()
  }, []);

  const getCompanies = () => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })
        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const sellerType = {
    seller: {
      text: 'Vendedor',
    },
    partner: {
      text: 'Vendedor parceiro',
    },
    agency: {
      text: 'Agência parceira',
    }
  }

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/fluxo-de-caixa'
    },
  ];

  const getCompanyNF = () => {
    apiClient.httpInstance.get(`/get_company_nf/${company}`, {})
      .then(response => {
        if (response.status === 200) {
          setCompanyNF(response.data)
          setCompany(response.data)
        }
      })
      .catch((error) => console.log(error));
  }

  const updateCompanyNF = (id) => {
    setCompany(id)
    apiClient.httpInstance.post(`/change_company_nf/${id}`, {
      company_id: id
    }).then(response => {
      if (response.status === 200) {
        setCompanyNF(response.data)
        setSuccess(true)
        setTimeout(() => { setSuccess(false) }, 4000);
      }
    })
      .catch((error) => console.log(error));
  }

  const confirmPayment = () => {
    apiClient.httpInstance.put(`/confirm_sale_payment/${selectedIdPayment}`, {
      payment_id: selectedIdPayment
    }).then(response => {
      if (response.status === 200) {
        setOpenModal(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const invoiceGenerate = (saleId) => {
    setIdSending(saleId)
    setLoading(true)
    apiClient.httpInstance.post(`/generate_invoice/${saleId}`, {
      company_id: company
    })
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      });
  }

  const changeDateProof = (date) => {
    setDateProof(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changePayment = (idPayment) => {
    setSelectedIdPayment(idPayment)
    setOpenModal(true)
  }

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            monetization_on_icon
          </Icon>
          <span>Fluxo de Caixa</span>
        </div>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center space-x-3">
          {((saleId !== '' && saleId !== null) || (customerId !== '' && customerId !== null)) && <div className="flex-none cursor-pointer">
            <Icon className="mr-2" onClick={() => {
              setSaleId('')
              setCustomerId('')
            }}>
              cached
            </Icon>
          </div>}
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por cliente, vendedor, referência, documento"
              width="450px"
              variant="outlined"
              type="text"
              name="search"
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </div>
          <div className="flex-none">
            <DatePicker
              label="Data"
              value={dateProof !== null ? `${dateProof} 00:00:00` : null}
              onChange={date => changeDateProof(date)}
            />
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Emissora da NF"
              value={companyNF ? companyNF : ''}
              width="160px"
              withoutAll
              onChange={(e) => updateCompanyNF(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div>
          {success && <Alert className="m-0 p-0 text-sm bg-white" severity="success" color="success">
            Empresa emissora atualizada com sucesso
          </Alert>}
        </div>
      </div>

      <Table
        path="/cash_flow"
        onRowClick={null}
        params={{
          'search': search,
          'date_proof': dateProof,
          'sale_id': saleId,
          'customer_id': customerId,
        }}
        columns={[
          {
            headerName: 'Referência',
            headerClassName: 'super-app-theme--header',
            field: 'references',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center" onClick={() => setSaleId(row.sale_id)}>
                {row.status === 'canceled' &&
                  <Icon className="mr-1 text-red-500">block</Icon>
                }
                {row?.total_payments > 1 &&
                  <Icon className="mr-1 items-center" style={{ fontSize: '12px', color: process.env.REACT_SECONDARY_PRIMARY_COLOR }} onClick={() => setSaleId(row.sale_id)}>circle</Icon>
                }
                {row.references}
              </div>);
            }
          },
          {
            headerName: 'Vendedor',
            headerClassName: 'super-app-theme--header',
            field: 'seller_name',
            flex: 1,
          },
          {
            headerName: 'Empresa',
            headerClassName: 'super-app-theme--header',
            field: 'company_name',
            width: 120
          },
          {
            headerName: 'Cliente',
            headerClassName: 'super-app-theme--header',
            field: 'customer_name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="items-center" style={{ lineHeight: '25px' }} onClick={() => setCustomerId(row.customer_id)}>
                <span>{row.customer_name}</span>
                <small className="flex m-0" style={itemStyles}>{row.customer_sales} {row?.customer_sales > 1 ? 'vendas' : 'venda'}</small>
              </div>);
            }
          },
          {
            headerName: 'Documento',
            headerClassName: 'super-app-theme--header',
            field: 'document',
            flex: 1,
          },
          {
            headerName: 'Valor Pago',
            headerClassName: 'super-app-theme--header',
            field: 'customer_paid_value',
            width: 140,
            renderCell: (params) => {
              const row = params.row;
              return <NumberFormat
                value={Number(row.customer_paid_value) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />;
            }
          },
          {
            headerName: 'Data do pagamento',
            headerClassName: 'super-app-theme--header',
            field: 'created_at',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return row.created_at ? <Moment format="DD/MM/YYYY" date={row.created_at} /> : ''
            }
          },
          {
            headerName: 'Comprovante',
            headerClassName: 'super-app-theme--header',
            field: 'path',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return <>
                {row.path ?
                  <a id={'comp_name_' + row.id} className="cursor-pointer" href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${row.path}`} target="_blank" style={{ textDecoration: 'underline' }}>
                    Visualizar
                  </a>
                  :
                  'Não adicionado'
                }
              </>
            }
          },
          {
            headerName: 'Recebimento',
            headerClassName: 'super-app-theme--header',
            field: 'confirmed',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
              const row = params.row
              return <>
                {row.confirmed === false ?
                  permissions.includes(props?.theme?.permission) && <Button
                    textcolor="#fff"
                    margintop="0px"
                    color="primary"
                    size="small"
                    type="button"
                    variant="text"
                    className="cursor-pointer"
                    onClick={() => permissions.includes(props?.theme?.permission) ? changePayment(row.id) : null}
                  >
                    Confirmar
                  </Button>
                  :
                  <div className="flex items-center justify-center">
                    <Icon className="mr-2">check_circle</Icon>
                    {row.invoice_id && row.invoice_id !== null ?
                      <a className="cursor-pointer" style={{ textDecoration: 'underline' }}>
                        Nota Gerada
                      </a>
                      :
                      !loading ?
                        permissions.includes(props?.theme?.permission) ? <a className="cursor-pointer" style={{ textDecoration: 'underline' }} onClick={() => permissions.includes(props?.theme?.permission) ? invoiceGenerate(row.sale_id) : null}>
                          Emitir nota
                        </a> :
                          'Pago'
                        :
                        row.sale_id === idSending ?
                          <div className="flex-1 mt-4">
                            <CircularProgress size={30} />
                          </div>
                          :
                          permissions.includes(props?.theme?.permission) && <a className="cursor-pointer" style={{ textDecoration: 'underline' }} onClick={() => permissions.includes(props?.theme?.permission) ? invoiceGenerate(row.sale_id) : null}>
                            Emitir nota
                          </a>
                    }
                  </div>
                }
              </>
            }
          },
        ]}
        rows={rows} />
    </div>
    <ChangeStateModal
      open={openModal}
      close={() => setOpenModal(false)}
      onCreate={confirmPayment}
      status={status}
      selectedIdPayment={selectedIdPayment}
      setStatus={setStatus}
    />
  </>
}

export default Index;

const itemStyles = {
  justifyContent: 'space-between',
  borderRadius: '8px',
  padding: '2px 5px',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  backgroundColor: '#D6FFF6',
  height: '15px',
  width: '75px',
  alignItems: 'center',
}