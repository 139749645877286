import Select from '@components/Select';
import { range } from '@utils/helpers';

const DiscountInput = (props) => {
  const value = Number(props.discountLimit) > 100 ? 100 : Number(props.discountLimit)
  const limit = props.discountLimit ? value : 100
  const percentages = range(0, limit , 5).map((v) => ({ value: v, text: `${v}%`}));
  percentages.splice(3, 0, { value: 12, text: '12%'})
  return <Select
    {...props}
    withoutAll
    items={{
      field: {
        value: 'value',
        text: 'text'
      },
      data: percentages
    }}
  />
}

export default DiscountInput;
