import { useState, useEffect } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import { apiClient } from '@services/api';
import Alert from '@material-ui/lab/Alert';
import Icon from '@material-ui/core/Icon';
import { format, isValid } from 'date-fns';
import DatePicker from '@components/DatePicker';

const ChangeDate = (props) => {
  const [date, setDate] = useState(props.date)
  const [dateAvaliable, setDateAvaliable] = useState([])
  const today = format(new Date(), "yyyy-MM-dd");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let ref = useRef(null);

  const changeDate = (idx) => {
    props.changeDate(idx, new Date(`${date} 00:00:00`))
    props.close()
  }

  const changeDateValue = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setDate(value)
  }

  const handleChangeDateValue = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    if (!dateAvaliable.find(item => item.cupo_date === value)) {
      setError(true);
      setErrorMessage("Não existe vaga disponível para essa data");
      return
    }
    setError(false)
    setErrorMessage(null)
    setDate(value)
  }

  const getDateAvaliable = (id) => {
    apiClient.httpInstance.get('reservations_dates/available', {
      params: {
        tour_id: id
      }
    }).then(response => {
      if (response.status === 200) {
        setDateAvaliable(response.data);
      }
    }).catch(error => { });
  }

  const disableDates = (date) => {
    let dates = null

    if (dateAvaliable.length > 0) {
      dateAvaliable.map((dateT, index) => {
        if (index === 0) {
          dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        } else {
          dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        }
      })
    } else {
      dates = false;
    }
    return dates
  }

  useEffect(() => {
    changeDateValue(new Date(`${props.date} 00:00:00`))
    getDateAvaliable(props.tourId);
  }, [props.date, props.tourId]);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Alterar data da reserva</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex justify-center mt-4">
        {date &&
          <DatePicker
            label="Data"
            value={date !== null ? `${date} 00:00:00` : null}
            shouldDisableDate={(date) => props.type === 'transfer' ? null : !disableDates(date)}
            minDate={`${today} 00:00:00`}
            onChange={date => handleChangeDateValue(date)}
          />
        }
      </div>
      {error && (
        <Alert className="mb-5 mt-2" severity="error">
          {errorMessage}
        </Alert>
      )}
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => changeDate(props.idx)}
          customcolor={props.theme.color}
          disabled={error ? true : false}
        >
          Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeDate;
