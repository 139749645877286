import TextField from '@components/TextField';
import Button from '@components/Button';
import Select from '@components/Select';
import RadioButton from '@components/RadioButton';
import PhoneMaskInput from '@components/PhoneMaskInput';

const Form = (props) => {
  const licenceTypeOptions = [
    {
      key: "all",
      name: "Classe A1"
    },
    {
      name: "Classe A2"
    },
    {
      name: "Classe A3"
    },
    {
      name: "Classe A4"
    },
    {
      name: "Classe A5"
    },
    {
      name: "Classe B"
    },
    {
      name: "Classe C"
    },

  ]

  const radioButtonData = [
    {
      value: true,
      label: 'Ativo'
    },
    {
      value: false,
      label: "Inativo"
    }
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Nome do motorista"
              variant="outlined"
              type="text"
              name="name"
              value={props.driver.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              value={props.driver.email}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <PhoneMaskInput
              name="phone"
              value={props.driver.phone}
              onChange={(value) => props.changeState({target: {name: 'phone', value}})}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Endereço"
              variant="outlined"
              type="text"
              name="address"
              value={props.driver.address}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Número da licença"
              variant="outlined"
              type="text"
              name="identification_document"
              value={props.driver.identification_document}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-1">
            <Select
              label="Filtre por tipo de licença"
              name="license_type"
              value={props.driver.license_type}
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'name',
                  text: 'name'
                },
                data: licenceTypeOptions
              }}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <TextField
            className="inputRounded"
            label="Observação"
            variant="outlined"
            type="text"
            name="observation"
            multiline
            rows={2}
            value={props.driver.observation}
            onChange={(ev) => props.changeState(ev)}
          />
        </div>
        {props.isEditing ? <>
            <span className="text-xl text-black">Status</span>
            <div className="flex mb-4 space-x-3">
              <RadioButton
                {...props}
                name="status"
                data={radioButtonData}
                change={(ev) => props.changeState(ev)}
                value={props.driver.status}
              />
            </div>
          </> : <></>
        }
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
          </Button>
      </div>
    </form>
  </>
}

export default Form
