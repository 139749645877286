import logo from '@assets/images/logo/small/white.png';
import { connect } from 'react-redux';
import HeaderDropdown from '@components/HeaderDropdown'

let Header = (props) => {
  return <div className={`flex items-center justify-between p-4 header ${props.theme.class}`}>
    <img className="logo" src={`${process.env.REACT_APP_LOGO_SMALL_WHITE}`} alt={process.env.REACT_APP_NAME}></img>
    <HeaderDropdown
      name={props?.user?.data?.name}
      permission={props?.user?.data?.permission}
      dropdownInfo={null}
    />
  </div>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Header);
