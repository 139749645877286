import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';

const permissions = ['administrador'];

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/traslados'
  },
  {
    title: `${props.region_name || ''}`,
    active: true,
    route: `/admin/traslados/detalhe/${props.id || ''}`
  },
  {
    title: 'Edição',
  }
]);

const Update = (props) => {
  document.title = `Editar transfer - ${process.env.REACT_APP_NAME}`
  const [error] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const { value_id } = useParams();
  const [region, setRegion] = useState({});
  const history = useHistory();
  const [transfer, setTransfer] = useState({
    commission: 1,
    transferType: 'in',
    minPeople: 1,
    maxPeople: 1,
    price: 1,
    discountMargin: 1,
    cost: 1,
  });
  const getTransferPath = `/translados-by-value/${value_id}`;
  const getTransferValuePath = `/translados/values/${value_id}`;
  const url = `/admin/traslados/detalhe/${region?.id || ''}`;
  const changeState = (e) => {
    setTransfer(
      {
        ...transfer,
        [e.target.name]: e.target.value
      }
    )
  }

  const getTransferData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setRegion(response.data);
      }
    });
  }

  const getTransferValueData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTransfer({
          commission: response.data.commission,
          transferType: response.data.mode_transfer,
          minPeople: response.data.value_of,
          maxPeople: response.data.value_to,
          price: response.data.value,
          discountMargin: response.data.margin_discount || 0,
          cost: response.data.value_cost,
        });

        setLoading(false);
      }
    });
  }

  const changeTypeTransfer = (ev) => {
    const value = ev.target.value
    let data = {}
    if (value === 'out') {
      data = {
        ...transfer,
        transferType: value,
        minPeople: region.min_value_to_out !== null ? region.min_value_to_out : region.max_value_to_out + 1,
        maxPeople: region.min_value_to_out !== null ? region.max_value_to_out : region.max_value_to_out + 2,
        maxReadOnly: region.min_value_to_out !== null ? true : false,
      }
    } else {
      data = {
        ...transfer,
        transferType: value,
        minPeople: region.min_value_to_in !== null ? region.min_value_to_in : region.max_value_to_in + 1,
        maxPeople: region.min_value_to_in !== null ? region.max_value_to_in : region.max_value_to_in + 2,
        maxReadOnly: region.min_value_to_in !== null ? true : false,
      }
    }
    setTransfer(data)
  }

  useEffect(() => {
    getTransferData(getTransferPath);
    getTransferValueData(getTransferValuePath);
  }, [getTransferPath, getTransferValuePath]);

  const handleSubmit = (e) => {
    e.preventDefault();
    apiClient.httpInstance.put(`/translados/values/${value_id}`, {
      commission: transfer.commission,
      mode_transfer: transfer.transferType,
      value_of: transfer.minPeople,
      value_to: transfer.maxPeople,
      value_dolars: transfer.price,
      value: transfer.price,
      margin_discount: transfer.discountMargin,
      value_dolars_cost: transfer.cost,
      value_cost: transfer.cost,
      translate_id: region.id,
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false)
            history.push(url)
          }, 4000);
        }
      })
      .catch(error => {
        if (error) {
          setErrors(error.response.data);
          setTimeout(() => { setErrors("") }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig(region)} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading &&
      permissions.includes(props?.theme?.permission) ?
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Edição</span>
          <Link color="inherit" to={url}>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </Link>
        </div>
        {success && <Alert className="mt-5" severity="success" color="success">
          Traslado atualizado com sucesso
        </Alert>}

        <Form
          {...props}
          submit={handleSubmit}
          errors={errors}
          transfer={transfer}
          isEditing={true}
          changeState={changeState}
          changeTypeTransfer={changeTypeTransfer}
        />
      </div>
      :
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Edição</span>
          <Link color="inherit" to={url}>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </Link>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          Você não possui permissão para acessar este recurso.
        </div>
      </div>
    }
  </>
}

export default Update;
