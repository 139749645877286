import React from 'react';
import { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@assets/css/Admin/components/calendar.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import AdminConfig from '@config/admin';
import { slugfy } from '@utils/helpers';

const renderEventContent = (props) => {
  // Altera estilo dos números dos dias
  document.querySelectorAll(".fc-col-header-cell-cushion").forEach(el => { el.style.fontSize = '14px'; el.style.fontWeight = '100'; })

  const lettersName = (name) => {
    var arr = name?.split(' ');
    if (arr[1] && arr[1]?.toLowerCase() == 'de' || arr[1]?.toLowerCase() == 'da' || arr[1]?.toLowerCase() == 'do') {
      return arr[0][0].toUpperCase() + arr[2][0].toUpperCase()
    } else if (arr[0] && arr[1]) {
      return arr[0][0].toUpperCase() + arr[1][0].toUpperCase()
    } else {
      return arr[0] ? arr[0][0].toUpperCase() : ''
    }
  }

  const languages = {
    'pt': 'Português',
    'en': 'Inglês',
    'es': 'Espanhol',
  }

  let contagemCupos = 0;
  let typeTravel = [];
  let loggedUserId = JSON.parse(localStorage.getItem('user'))?.data?.id

  if (props.event._def.extendedProps?.tour_type !== 'single') {
    return (<div style={cardStyles}>
      <div className="text-blue font-poppins">
        {Number(props.event._def.extendedProps?.availables_cupos) > 0 ?
          (<>
            {props.event._def.extendedProps.cupos.map((element) => {
              contagemCupos += parseInt(element.cupos_customer)
              if (!typeTravel.includes(element.type)) {
                typeTravel.push(element.type)
              }
            })}
            {typeTravel.includes(props.event._def.extendedProps?.tour_type) && props.event._def.extendedProps?.tour_type !== 'single' ?
              <>
                {Number(props.event._def.extendedProps.availables_cupos) - Number(contagemCupos) === 0 ?
                  <p style={titleStyles2}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span> <span className='text-gray'>{Number(props.event._def.extendedProps.availables_cupos)}</span></p>
                  :
                  <p className="bg-primary" style={titleStyles1}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span> <span>{Number(props.event._def.extendedProps.availables_cupos) > 0 && Number(props.event._def.extendedProps.availables_cupos) - Number(contagemCupos) > 0 ? Number(props.event._def.extendedProps.availables_cupos) - Number(contagemCupos) : Number(props.event._def.extendedProps.availables_cupos)}</span></p>
                }
              </>
              :
              <>
                {Number(props.event._def.extendedProps.availables_cupos) - Number(contagemCupos) === 0 ?
                  <p style={titleStyles2}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span> <span className='text-gray'>{Number(props.event._def.extendedProps.availables_cupos)}</span></p>
                  :
                  <p style={titleStyles3}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span></p>
                }
              </>
            }
          </>)
          :
          (<>
            {props.event._def.extendedProps.cupos.map((element) => {
              contagemCupos += parseInt(element.cupos_customer)
            })}
            <p style={titleStyles2}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span> <span className='text-gray'>{props.event._def.extendedProps.availables_cupos + contagemCupos}</span></p>
          </>)
        }
        {props.event._def.extendedProps.cupos.map((element) => {
          if (element.type === props.event._def.extendedProps.tour_type)
            return (<div style={itensStyles}>
              <div className='flex'><span>{element.cupos_customer_adults}{element.cupos_customer_kids > 0 ? '+' + element.cupos_customer_kids : ''}-
                {props.event._def.extendedProps?.permission === 'operacao' ?
                  loggedUserId === element.user_id ?
                    <Tooltip title="Ver reserva">
                      <Link color="inherit" to={`/admin/reservas/detalhe/${element.sale_id}`}>{element.customer_name}</Link>
                    </Tooltip>
                    :
                    <span>{element.customer_name}</span>
                  :
                  <Tooltip title="Ver reserva">
                    <Link color="inherit" to={`/admin/reservas/detalhe/${element.sale_id}`}>{element.customer_name}</Link>
                  </Tooltip>
                }
              </span> <Tooltip title={languages[element.customer_language]} className='cursor-pointer'><span style={language}>{element.customer_language}</span></Tooltip></div>
              <div className='flex cursor-pointer'><Tooltip title={element.seller_name}><span style={element.company_id == 3 ? company_1 : company_2}>{lettersName(element.seller_name)}</span></Tooltip></div>
            </div>)
        })}
      </div>
    </div>)
  }

  if (props.event._def.extendedProps?.tour_type === 'single') {
    return (<>
      {
        props.event._def.extendedProps.cupos.map((element) => {
          return (element.type === props.event._def.extendedProps.tour_type && <div style={cardStyles}>
            <div className="text-blue font-poppins">
              {Number(props.event._def.extendedProps.availables_cupos) - Number(contagemCupos) === 0 ?
                <p style={titleStyles2}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span> <span className='text-gray'>{Number(props.event._def.extendedProps.availables_cupos)}</span></p>
                :
                <p style={titleStyles3}><span>{props.event._def.extendedProps?.tour_name.toLowerCase()}</span></p>
              }
              <div style={itensStyles}>
                <div className='flex'><span>{element.cupos_customer_adults}{element.cupos_customer_kids > 0 ? '+' + element.cupos_customer_kids : ''}-
                  {props.event._def.extendedProps?.permission === 'operacao' ?
                    loggedUserId === element.user_id ?
                      <Tooltip title="Ver reserva">
                        <Link color="inherit" to={`/admin/reservas/detalhe/${element.sale_id}`}>{element.customer_name}</Link>
                      </Tooltip>
                      :
                      <span>{element.customer_name}</span>
                    :
                    <Tooltip title="Ver reserva">
                      <Link color="inherit" to={`/admin/reservas/detalhe/${element.sale_id}`}>{element.customer_name}</Link>
                    </Tooltip>
                  }
                </span> <Tooltip title={languages[element.customer_language]} className='cursor-pointer'><span style={language}>{element.customer_language}</span></Tooltip></div>
                <div className='flex cursor-pointer'><Tooltip title={element.seller_name}><span style={element.company_id == 3 ? company_1 : company_2}>{lettersName(element.seller_name)}</span></Tooltip></div>
              </div>
            </div>
          </div>)
        })
      }
    </>)
  }
}

const Calendar = (props) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)

  const getEvents = () => {
    const toursArray = Object.values(props.reservations)
    const cuposArray = Object.values(props.cupos)

    const events = toursArray.map((obj) => {
      let tours = []
      obj.reservations.forEach(t => {
        if (t.tour_date === obj.tour_date) {
          tours.push(t)
        }
      });

      return {
        date: obj.tour_date,
        tour_name: obj.tour_name,
        availables_cupos: obj.availables_cupos,
        cupos: tours,
        tour_type: obj.type,
        sale_id: obj.sale_id,
        user_id: obj.user_id,
        permission: props.theme.permission
      }
    })

    return events
  }

  const calendarRef = React.createRef();

  const refreshCalendar = () => {
    let calendarApi = calendarRef.current
    calendarApi.getApi().refetchEvents()
    getEvents()
  }

  const changeDates = () => {
    let data = new Date();
    let primeiro = data.getDate() - data.getDay();
    let dataInicial = new Date(data.setDate(primeiro)).toUTCString();
    dataInicial = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
    let dataFinal = new Date(data.setDate(data.getDate() + 6)).toUTCString();
    dataFinal = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
    setDateStart(dataInicial)
    setDateEnd(dataFinal)
  }

  useEffect(() => {
    // Altera estilo dos números dos dias
    document.querySelectorAll(".fc-col-header-cell-cushion").forEach(el => { el.style.fontSize = '14px'; el.style.fontWeight = '100'; })
    document.querySelectorAll(".fc-theme-standard").forEach(el => { el.style.minHeight = '1200px'; el.style.minWidth = '1200px'; })

    if (windowSize.current[0] < 600) {
      document.querySelectorAll(".fc-header-toolbar").forEach(el => { el.style.width = 'max-content'; })
    }
    document.querySelectorAll(".fc-view-harness").forEach(el => { el.style.minheight = '1200px'; })
    document.querySelectorAll(".fc-view-harness-active").forEach(el => { el.style.minHeight = '1200px'; el.style.minWidth = '1200px'; })
    document.querySelectorAll(".fc-daygrid").forEach(el => { el.style.height = '100%'; })
    document.querySelectorAll(".fc-view-container").forEach(el => { el.style.overflowX = 'auto'; })
    document.querySelectorAll(".fc-view-container.view").forEach(el => { el.style.overflowX = 'scroll'; })
    document.querySelectorAll(".fc-view.table").forEach(el => { el.style.height = '100%'; })

    var $hd = document.querySelector(".fc-header-toolbar")
    let diasSemana = '<div class="fc-header-days"><span></span><span>Dom</span><span></span><span>Seg</span><span> </span><span>Ter</span><span> </span><span>Qua</span><span> </span><span>Qui</span><span> </span><span>Sex</span><span> </span><span>Sab</span><span></span></div>'
    $hd.insertAdjacentHTML('afterend', diasSemana);

    changeDates()
  }, []);

  useEffect(() => {
    getEvents()
  }, [props.reservations]);
  useEffect(() => {
    refreshCalendar()
  }, [props.load]);

  return <div className="flex-1 text-center">
    {props?.load &&
      <div style={loadingStyle}>
        <CircularProgress size={30} />
      </div>
    }

    <FullCalendar
      ref={calendarRef}
      locale="pt-br"
      timeZone="local"
      events={getEvents()}
      eventColor="transparent"
      customButtons={{
        prev: {
          click: function () {
            let calendarApi = calendarRef.current
            let data = new Date(calendarApi.getApi().getDate().toUTCString());
            let dataF = new Date(calendarApi.getApi().getDate().toUTCString())
            data.setDate(data.getDate() - 7)
            dataF.setDate(dataF.getDate() - 1)

            let dataInicial = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
            let dataFinal = (dataF.getFullYear() + "-" + ((dataF.getMonth() + 1)) + "-" + (dataF.getDate()));

            setDateStart(dataInicial)
            setDateEnd(dataFinal)

            props.getSeats('/cupos_general', dataInicial, dataFinal);

            calendarApi.getApi().prev();
            calendarApi.getApi().refetchEvents()
          },
        },
        next: {
          click: function () {
            let calendarApi = calendarRef.current
            let data = new Date(calendarApi.getApi().getDate().toUTCString());
            let dataF = new Date(calendarApi.getApi().getDate().toUTCString())
            data.setDate(data.getDate())
            dataF.setDate(dataF.getDate() + 13)

            let dataInicial = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate()));
            let dataFinal = (dataF.getFullYear() + "-" + ((dataF.getMonth() + 1)) + "-" + (dataF.getDate()));

            setDateStart(dataInicial)
            setDateEnd(dataFinal)
            props.getSeats('/cupos_general', dataInicial, dataFinal);

            calendarApi.getApi().next();
            calendarApi.getApi().refetchEvents()
          },
        },
      }}
      headerToolbar={{
        start: 'prev',
        center: 'title',
        end: 'next'
      }}
      dayHeaderFormat={{ day: 'numeric', omitCommas: true }}
      plugins={[
        dayGridPlugin,
        interactionPlugin
      ]}
      initialView="dayGridWeek"
      titleFormat={{ month: 'long', year: 'numeric' }}
      eventContent={renderEventContent}
    />
  </div>
}

export default Calendar;

const cardStyles = {
  marginBottom: '6px',
  padding: '2px',
  fontSize: '12px',
  backgroundColor: '#fff',
  whiteSpace: 'break-spaces',
  borderRadius: '8px 8px 0px 0px',
  borderBottom: '1px solid #ccc',
}

const titleStyles1 = {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px 8px 0px 0px',
  padding: '2px 5px',
  textTransform: 'capitalize',
}

const titleStyles2 = {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px 8px 0px 0px',
  padding: '2px 5px',
  textTransform: 'capitalize',
  backgroundColor: '#FAE469',
}

const titleStyles3 = {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px 8px 0px 0px',
  padding: '2px 5px',
  textTransform: 'capitalize',
  backgroundColor: '#4d98b9',
}

const itensStyles = {
  display: 'flex',
  padding: '2px 5px',
  justifyContent: 'space-between'
}

const company_1 = {
  display: 'flex',
  padding: '3px',
  backgroundColor: '#D6FFF6',
  borderRadius: '5px',
  width: '35px',
  justifyContent: 'center',
  marginInlineEnd: '2px'
}

const company_2 = {
  display: 'flex',
  padding: '3px',
  backgroundColor: '#E4E2FB',
  borderRadius: '5px',
  width: '35px',
  justifyContent: 'center',
  marginInlineEnd: '2px'
}

const language = {
  display: 'flex',
  padding: '1px',
  backgroundColor: '#E4E2FB',
  borderRadius: '50px',
  width: '20px',
  height: '20px',
  fontSize: '11px',
  color: '#009976',
  justifyContent: 'center'
}

const titleMonth = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2px 5px',
  textTransform: 'capitalize',
}

const titleDays = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2px 5px',
  border: '1px solid #8080806e',
  fontSize: '14px'
}

const loadingStyle = {
  zIndex: '30000',
  position: 'relative',
  top: '200px',
  marginLeft: 'auto',
  marginRight: 'auto',
}