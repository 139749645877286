import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import TextField from '@components/TextField';
import DatePicker from '@components/DatePicker';
import Select from '@components/Select';

const ModalCupos = (props) => {
  let ref = useRef(null);
  const [simpleDate, setSimpleDate] = useState(true)

  const daysWeek = [
    {
      value: 'monday',
      name: 'Segunda-feira'
    },
    {
      value: 'tuesday',
      name: 'Terça-feira'
    },
    {
      value: 'wednesday',
      name: 'Quarta-feira'
    },
    {
      value: 'thursday',
      name: 'Quinta-feira'
    },
    {
      value: 'friday',
      name: 'Sexta-feira'
    },
    {
      value: 'saturday',
      name: 'Sábado'
    },
    {
      value: 'sunday',
      name: 'Domingo'
    },
  ]
  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '600px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        {simpleDate ?
          <div className="flex flex-col">
            <span className="mb-3 text-xl text-blue">Adicione uma data simples</span>
            <small className="text-gray">
              Preencha o abaixo com a data da saída ou, se preferir,&nbsp;
              <span className="underline text-lightblue cursor-pointer" onClick={() => setSimpleDate(false)}>adicione um intervalo de data</span>
            </small>
          </div>
        :
          <div className="flex flex-col">
            <span className="mb-3 text-xl text-blue">Adicionar intervalo de datas</span>
            <small className="text-gray">
              Preencha os campos abaixo com o intervalo de datas ou, se preferir,&nbsp;
              <span className="underline text-lightblue cursor-pointer" onClick={() => setSimpleDate(true)}>adicione uma data simples</span>
            </small>
          </div>
        }
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    {simpleDate ?
      <>
        <form onSubmit={(ev) => props.onCreate(ev)}>
          <div className="flex mt-4 space-x-3">
            <div className="flex-1">
              <DatePicker
                label="Data inícial"
                value={props.period.initial !== null ? `${props.period.initial} 00:00:00` : null}
                onChange={date => props.changeDate(date, "initial")}
                required
              />
            </div>
            <div className="flex-1">
              <TextField
                className="inputRounded"
                label="Qtde de cupos"
                variant="outlined"
                type="number"
                name="availables_cupos"
                value={props.cupos.availables_cupos}
                onChange={(ev) => props.changeState(ev)}
                required
              />
            </div>
            <div className="flex-1" />
          </div>
          <div className="justify-end mt-4 mb-2 md:flex w-100 space-x-3">
            <Button
              textcolor="#212121"
              padding="5px 15px"
              margintop="0px"
              width="100px"
              variant="outlined"
              type="button"
              onClick={props.close}
              bgColor="transparent"
            >
              Cancelar
            </Button>
            <Button
              textcolor="#fff"
              padding="5px 15px"
              margintop="0px"
              width="100px"
              type="submit"
              customcolor={props.theme.color}
            >
                Salvar
            </Button>
          </div>
        </form>
      </>
    :
      <form onSubmit={(ev) => props.onCreate(ev)}>
        <div className="flex mt-4 space-x-3">
          <div className="flex-1">
            <DatePicker
              label="Data inícial"
              value={props.period.initial !== null ? `${props.period.initial} 00:00:00` : null}
              onChange={date => props.changeDate(date, "initial")}
              required
            />
          </div>
          <div className="flex-1">
            <DatePicker
              label="Data Final"
              value={props.period.final !== null ? `${props.period.final} 00:00:00` : null}
              onChange={date => props.changeDate(date, "final")}
              minDate={`${props.period.initial} 00:00:00`}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Qtde de cupos"
              variant="outlined"
              type="number"
              name="availables_cupos"
              value={props.cupos.availables_cupos}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
        </div>
        <div className="flex mt-4 space-x-3">
          <div className="flex-1">
            <Select
              withoutAll
              label="Dias da semana"
              multiple
              name="days"
              value={props.cupos.days}
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'value',
                  text: 'name'
                },
                data: daysWeek
              }}
              required
            />
          </div>
        </div>
        <div className="justify-end mt-4 mb-2 md:flex w-100 space-x-3">
          <Button
            textcolor="#212121"
            padding="5px 15px"
            margintop="0px"
            width="100px"
            variant="outlined"
            type="button"
            onClick={props.close}
            bgColor="transparent"
          >
            Cancelar
          </Button>
          <Button
            textcolor="#fff"
            padding="5px 15px"
            margintop="0px"
            width="100px"
            type="submit"
            customcolor={props.theme.color}
          >
              Salvar
          </Button>
        </div>
      </form>
    }
  </Modal>
}

export default ModalCupos;
