import SvgIcon from '@material-ui/core/SvgIcon';

const PercentIcon = () => {
  return <SvgIcon>
    <path d="M18.2308 2.9997L3 18.2305L5.76923 20.9997L21 5.76893L18.2308 2.9997Z"/>
    <path d="M7.10423 10.0425C8.72643 10.0425 10.0415 8.7274 10.0415 7.10521C10.0415 5.48302 8.72643 4.16797 7.10423 4.16797C5.48204 4.16797 4.16699 5.48302 4.16699 7.10521C4.16699 8.7274 5.48204 10.0425 7.10423 10.0425Z"/>
    <path d="M16.8952 19.8335C18.5174 19.8335 19.8325 18.5184 19.8325 16.8962C19.8325 15.274 18.5174 13.959 16.8952 13.959C15.2731 13.959 13.958 15.274 13.958 16.8962C13.958 18.5184 15.2731 19.8335 16.8952 19.8335Z"/>
  </SvgIcon>
}

export default PercentIcon;
