import TextField from '@components/TextField';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import PhoneMaskInput from '@components/PhoneMaskInput';

const Form = (props) => {
  const partnerCommissionOptions = [
    {
      value: false,
      label: 'Sem desconto do custo operacional'
    },
    {
      value: true,
      label: "Com desconto do custo operacional"
    }
  ]

  const radioButtonData = [
    {
      value: true,
      label: 'Ativo'
    },
    {
      value: false,
      label: "Inativo"
    }
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Nome do vendedor"
              variant="outlined"
              type="text"
              name="name"
              value={props.partnerSeller.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              value={props.partnerSeller.email}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <PhoneMaskInput
              name="telephone"
              value={props.partnerSeller.telephone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({ target: { name: 'telephone', value: target.target.value } })}
            />
          </div>
        </div>
        <span className="text-xl text-black">Comissão do vendedor</span>
        <div className="flex mb-4 space-x-3">
          <TextField
            className="inputRounded"
            width="150px"
            label="Comissão"
            variant="outlined"
            type="number"
            name="commission"
            value={props.partnerSeller.commission}
            onChange={(ev) => props.changeState(ev)}
            required
          />
          <RadioButton
            {...props}
            name="partner_commission"
            data={partnerCommissionOptions}
            change={(ev) => props.changeState(ev)}
            value={props.partnerSeller.partner_commission}
          />
        </div>
        {props.isEditing && <>
          <span className="text-xl text-black">Status</span>
          <div className="flex mb-4 space-x-3">
            <RadioButton
              {...props}
              name="status"
              data={radioButtonData}
              change={(ev) => props.changeState(ev)}
              value={props.partnerSeller.status}
            />
          </div>
        </>
        }
      </div>
      <div className="text-right flex justify-end space-x-3">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
        {props.isEditing && !props.partnerSeller?.user_id &&
          <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            type="submit"
          >
            Criar Usuário
          </Button>
        }
      </div>
    </form>
  </>
}

export default Form
