import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import DeleteModal from '@components/DeleteModal';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import RequestTable from './partials/RequestTable';
import PaymentsDetail from './partials/PaymentsDetail';
import SendVoucherModal from './partials/SendVoucherModal';

const Show = (props) => {
  document.title = `Detalhes do orçamento - ${process.env.REACT_APP_NAME}`
  const approve = useLocation().state?.approve || false;
  const { presales_id } = useParams();
  const [presales, setPreSale] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendVoucherModal, setSendVoucherModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false)
  const [urlLink, setUrlLink] = useState(null)
  const history = useHistory();
  const url = `/presales/${presales_id}`

  const getSalesDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setPreSale(response.data);
        if (response.data.url_link) {
          setUrlLink(response.data.url_link)
        } else {
          getClientById(response.data.customer_id)
        }
        setLoading(false);
      }
    })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage("Esse orçamento não existe");
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
          setLoading(true);
        }
      });
  }

  const getClientById = (id) => {
    apiClient.httpInstance.get(`customers/${id}`).then(response => {
      if (response.status === 200) {
        setUrlLink(response.data.url_link)
        setLoading(false);
      }
    })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage("Cliente não encontrado");
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      });
  }
  const getBudgets = url => {
    apiClient.httpInstance.get(`${url}/budgets`).then(response => {
      if (response.status === 200) {
        setBudgets(response.data);
        setLoading(false)
      }
    }).catch(error => {
      if (error) {
        setError(true);
        setErrorMessage("Erro inesperado, tente novamente mais tarde")
        setTimeout(() => { setError(false) }, 4000);
        setTimeout(() => { setErrorMessage(null) }, 4000);
      }
    });
  }

  const cancelPresale = url => {
    apiClient.httpInstance.put(url)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/orcamentos`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }
  const approvePresale = () => {
    apiClient.httpInstance.post(`/presales/${presales_id}/approve`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/aprovacoes`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  const disaprovePresale = () => {
    apiClient.httpInstance.post(`/presales/${presales_id}/disapprove`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/aprovacoes`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  const saleConvert = (presaleId) => {
    apiClient.httpInstance.post(`/presales/${presaleId}/convert-in-sale`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/carrinho/orcamento`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  const bradecrumbsConfig = [
    {
      title: `Início`,
      active: true,
      route: approve ? '/admin/aprovacoes' : '/admin/orcamentos'
    },
    {
      title: `Orçamento ${presales.references || ''}`,
    }
  ]

  const status = {
    pending: {
      text: 'Aguardando aprovação',
      icon: 'access_time'
    },
    refused: {
      text: 'Negado',
      icon: 'running_with_errors'
    },
    overdue: {
      text: 'Vencido',
      icon: 'running_with_errors'
    },
    open: {
      text: 'Em aberto',
      icon: 'access_time'
    },
    approved: {
      text: 'Aprovado',
      icon: 'check_circle_outline'
    },
    sold: {
      text: 'Convertido em venda',
      icon: 'access_time'
    },
    canceled: {
      text: 'Cancelado',
      icon: 'access_time'
    },
  }

  useEffect(() => {
    getSalesDetails(url);
    getBudgets(url);
  }, [url]);

  return <>
    {!loading &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <BreadCrumbs items={bradecrumbsConfig} />
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Orçamento {presales.references || ''}</span>
            {!approve && <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/orcamentos/editar/${presales_id}`)}
                customcolor={props.theme.color}
                disabled={presales?.status != 'canceled' ? false : true}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="250px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
                disabled={presales?.status != 'canceled' ? false : true}
              >
                <Icon className="mr-2">delete</Icon>
                Cancelar orçamento
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                delete={() => cancelPresale(`/presales/${presales_id}/cancel`)}
                title="Cancelar orçamento?"
                redirect={'/admin/orcamentos'}
                description="Você realmente deseja cancelar este orçamento? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>}
          </div>
          {!approve && <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl">
              <span>Status do orçamento</span>
            </div>
            <div className='grid grid-cols-1 gap-4 p-4'>
              <div className="flex text-base font-normal items-center">
                <div className="flex">
                  {status[presales?.status_discount_approve]?.text} <Icon className="ml-2">{status[presales?.status_discount_approve]?.icon}</Icon>
                </div>
                {presales?.status_discount_approve === 'sold' && <Link color="inherit" to={`/admin/reservas/detalhe/${presales.sale_id}`}>
                  <Button
                    textcolor="#fff"
                    padding="2px"
                    width="100px"
                    marginleft="10px"
                    margintop="0px"
                    customcolor={props.theme.color}
                  >
                    Ver venda
                  </Button>
                </Link>}
              </div>
            </div>
          </div>}
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl">
              <span>Informações do cliente</span>
            </div>
            <div className='grid grid-cols-3 gap-4 p-4'>
              <div >
                <div className="grid grid-cols-1 gap-4 text-base font-normal">
                  <div>
                    {presales.name}
                  </div>
                  <div>
                    {presales.email}
                  </div>
                  <div>
                    Documento: {presales.document}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 text-xl border-t border-opacity-75 text-blue font-poppins">
              <span>Informações do pedido</span>
            </div>
            {budgets &&
              <div className="p-4">
                <RequestTable {...props} dataBudgets={budgets} show={true} total={presales.total} urlLink={urlLink} />
              </div>
            }
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl border-b border-opacity-75 text-blue font-poppins">
              <span>Informações do vendedor</span>
            </div>
            <div className="flex p-4">
              <div className="flex-1">
                Vendedor responsável
              </div>
              <div className="flex-1">
                {presales.seller_name} ({presales.seller_email})
              </div>
            </div>
          </div>
          <div className="flex justify-end mb-5 space-x-5">
            {approve ?
              <>
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  bgColor="transparent"
                  onClick={disaprovePresale}
                  disabled={presales?.status != 'canceled' ? false : true}
                >
                  Recusar desconto
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  customcolor={props.theme.color}
                  onClick={approvePresale}
                  disabled={presales?.status != 'canceled' ? false : true}
                >
                  Aprovar desconto
                </Button>
              </>
              :
              <>
                <Link color="inherit" to={`/voucher-presale/${presales_id}/${urlLink}`} target='_blank'>
                  <Button
                    textcolor="#fff"
                    padding="6px 15px"
                    width="200px"
                    customcolor={props.theme.color}
                    disabled={presales?.status != 'canceled' ? false : true}
                  >
                    Ver documento
                  </Button>
                </Link>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="200px"
                  disabled={presales?.status != 'canceled' && presales?.status_discount_approve == 'open' || presales?.status_discount_approve == 'approved' ? false : true}
                  onClick={() => saleConvert(presales_id)}
                  customcolor={props.theme.color}
                >
                  Converter em compra
                </Button>
              </>
            }
            {/* <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => setSendVoucherModal(true)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                email
              </Icon>
              Reenviar voucher
            </Button> */}
            <SendVoucherModal
              saleId={presales_id}
              open={sendVoucherModal}
              close={() => setSendVoucherModal(false)}
              {...props}
            />
          </div>
        </div>
      </>
    }
  </>
}

export default Show;
