import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {apiClient} from '@services/api';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Table from '@components/Table';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print'
import { makeStyles } from '@material-ui/core'

const permissions = ['administrador', 'logistica', 'operacao'];

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: 15
  }
}))

const FlowCashTable = (props) => {
  const componentRef = useRef()
  const [rows] = useState([]);

  const { type, id } = useParams();
  const [seller, setSeller] = useState(null)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(false);
  const url = `cash_flow`;
  const classes = useStyles()

  const sellerType = {
    seller: {
      text: 'Vendedor',
    },
    partner: {
      text: 'Vendedor parceiro',
    },
    agency: {
      text: 'Agência parceira',
    }
  }

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/fluxo-de-caixa'
    },
    {
      title: `${seller?.name} (${sellerType[type].text || null})`,
    }
  ];

  const getCashFlow = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setLoading(false)
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    //getCashFlow(url);
  }, [url]);


  return <>
  <style type="text/css" media="print">{"\
   @page {\ size: landscape;\ }\
  "}</style>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="py-7 px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue font-poppins">
        <span>{seller?.name} ({sellerType[type].text || null})</span>
        <ReactToPrint
          trigger={() => <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            width="150px"
          >
            Baixar em PDF
          </Button>
          }
          content={() => componentRef.current}
        />
      </div>

      <Table
        path="/cash_flow"
        params={{
          'search': search
        }}
        columns={[
          {
            headerName: 'Referência',
            headerClassName: 'super-app-theme--header',
            field: 'references',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.references}
              </div>);
            }
          },
          {
            headerName: 'Vendedor',
            headerClassName: 'super-app-theme--header',
            field: 'seller_name',
            flex: 1,
          },
          {
            headerName: 'Cliente',
            headerClassName: 'super-app-theme--header',
            field: 'customer_name',
            flex: 1,
          },
          {
            headerName: 'Documento',
            headerClassName: 'super-app-theme--header',
            field: 'document',
            flex: 1,
          },
          {
            headerName: 'Valor Pago',
            headerClassName: 'super-app-theme--header',
            field: 'customer_paid_amount',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return <NumberFormat
                value={Number(row.customer_paid_amount) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />;
            }
          },
        ]}
        rows={rows} />
    </div>
  </>
}

export default FlowCashTable;
