import Button from '@components/Button';
import EmptyCartIcon from '@assets/images/icons/undraw_empty_card.svg';

const EmptyCart = (props) => {

  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{borderRadius: '10px'}}>
      <div className="p-4 text-xl text-blue font-poppins text-center">
        <span>Seu carrinho está vazio</span>
      </div>
      <div className="p-4 text-xl text-blue font-poppins text-center justify-center flex">
        <img
          alt="Select file"
          src={EmptyCartIcon}
          width="334"
        />
      </div>
      <div className="p-4 text-xl text-blue font-poppins text-center">
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          marginright="20px"
          width="200px"
          onClick={() => props.openModal(true)}
          customcolor={props.theme.color}
        >
          Fazer uma reserva
        </Button>
      </div>
    </div>
  </>
}

export default EmptyCart
