import Moment from 'react-moment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import TableFooter from '@material-ui/core/TableFooter';

const TableBodyCart = (props) => {
  const calcTotal = () => {
    const total = props.data.cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return Number(total)
  }

  return <>
    <TableBody>
      {props.data.cart.map((cartValue) => (
        <TableRow>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            <div className="flex items-center">
              <div className="flex-none">
                <img src={cartValue.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${cartValue.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
              </div>
              <div className="text-lg font-medium">
                {cartValue.travel_name} {cartValue.transfer_type ? `(${cartValue.transfer_type})` : null}
              </div>
            </div>
          </TableCell>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            {cartValue.cupos}
          </TableCell>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            <Moment format="DD/MM/YYYY" date={cartValue.tour_date} />
          </TableCell>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            <NumberFormat
              value={Number(cartValue.total_amount).toFixed(2)}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <span>{value}</span>}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
          Total
        </TableCell>
        <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
          <NumberFormat
            value={calcTotal().toFixed(2)}
            displayType={'text'}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalPrecision={2}
            thousandSeparator={"."}
            isNumericString
            prefix={`${process.env.REACT_APP_CURRENCY} `}
            renderText={(value) => <span>{value}</span>}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  </>
}

export default TableBodyCart;
