import { useState, useEffect } from 'react';
import Modal from '@components/Modal';
import DiscountInput from '@components/DiscountInput'
import TextField from '@components/TextField';
import Button from '@components/Button';
import { useRef } from 'react';
import { apiClient } from '@services/api';
import Icon from '@material-ui/core/Icon';
import { format, isValid } from 'date-fns';
import DatePicker from '@components/DatePicker';

const DiscountModal = (props) => {
  let ref = useRef(null);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Solicitar desconto</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex justify-center mt-4">
        <DiscountInput
          className="pr-2 inputRounded"
          width="200px"
          label="Desconto"
          name="discount_total_sale"
          value={props.discount}
          onChange={(ev) => props.setSaleDiscount(ev)}
        />
      </div>
      <div className="flex justify-center mt-4">
        <TextField
          className="inputRounded"
          label="Justificativa"
          placeholder="Justificativa"
          variant="outlined"
          multiline
          rows={5}
          name="justify_discount"
          value={props.justify}
          onChange={(e) => props.setSaleDiscount(e)}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => {props.setToApprove(true)
                          props.close()}}
          disabled={props?.justify != null && props?.justify != '' && props?.justify != undefined && props?.discount != 0 ? false : true}
        >
          Solicitar aprovação
        </Button>
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#212121"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          variant="outlined"
          bgColor="transparent"
          onClick={() => {props.setToApprove(false)
                          props.close()}}
        >
          Cancelar
        </Button>
      </div>
    </form>
  </Modal >
}

export default DiscountModal;
