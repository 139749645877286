import RadioButton from '@components/RadioButton';
import TextField from '@components/TextField';
import TimePicker from '@components/TimePicker';

const ContentStep1 = (props) => {
  const radioButtonData = [
    {
      value: 'Transfer In',
      label: 'Transfer In'
    },
    {
      value: 'Transfer Out',
      label: 'Transfer Out'
    }
  ];
  return <>
    <div className="p-4 font-bold text-sm text-blue font-poppins flex space-x-3">
      <div className="flex-none">
        <TextField
          className="inputRounded"
          width="150"
          label="Código do voo"
          variant="outlined"
          type="text"
          name="codFly"
          defaultValue={props.reservationTransfer.codFly}
          onChange={(ev) => props.changeValue(ev)}
        />
      </div>
      <div className="flex-none">
        <TimePicker
          width="200px"
          label="Horário do voo"
          name="timeFly"
          value={props.reservationTransfer.timeFly}
          onChange={(ev) => props.changeHour(ev)}
        />
      </div>
    </div>
    <div className="p-4 text-xl text-blue font-poppins flex">
      <div className="flex-1 space-y-1">
        <RadioButton
          {...props}
          data={radioButtonData}
          name="typeTransfer"
          change={(ev) => props.changeValue(ev)}
          value={props.reservationTransfer.typeTransfer}
        />
      </div>
    </div>
  </>
}

export default ContentStep1;
