import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@components/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@components/TextField';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import InputCurrency from '@components/InputCurrency';
import DiscountInput from '@components/DiscountInput';

const GreenRadio = withStyles({
  root: {
    color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const Form = (props) => {
  return <>
      <form onSubmit={props.submit}>
        <div className="p-4 pb-6 mt-6 bg-white pt-7 rounded-xl text-blue font-poppins border-1">
          <span className="text-2xl">Valor ({`${process.env.REACT_APP_CURRENCY}`})</span>
          <div className="flex flex-wrap mt-3 space-x-3 lg:flex-nowrap">
            <InputCurrency
              className="inputRounded"
              label="Valor"
              width="165px"
              name="price"
              variant="outlined"
              error={props.errors?.value_dolars}
              value={props.transfer.price}
              type="text"
              onChange={(e) => props.changeState(e)}
            />
            <InputCurrency
              className="inputRounded"
              label="Custo"
              width="165px"
              name="cost"
              variant="outlined"
              error={props.errors?.value_dolars_cost}
              value={props.transfer.cost}
              onChange={(e) => props.changeState(e)}
              required
            />
            <InputCurrency
              className="inputRounded"
              label="Commissão"
              width="165px"
              variant="outlined"
              name="commission"
              value={props.transfer.commission}
              error={props.errors?.commission}
              onChange={(e) => props.changeState(e)}
              type="text"
              required
            />
          </div>
          <div className="flex flex-wrap mt-5 space-x-3 lg:flex-nowrap">
            <TextField
              className="inputRounded"
              label="Min. de pessoas"
              width="165px"
              error={props.errors?.value_of}
              value={props.transfer.minPeople}
              variant="outlined"
              name="minPeople"
              type="text"
              onChange={(e) => props.changeState(e)}
              inputProps={
                { readOnly: true}
              }
              required
            />
            <TextField
              className="inputRounded"
              label="Max. de pessoas"
              width="165px"
              name="maxPeople"
              error={props.errors?.value_to}
              value={props.transfer.maxPeople}
              variant="outlined"
              onChange={(e) => props.changeState(e)}
              type="text"
              inputProps={
                { readOnly: props.isEditing ? true : props.transfer.maxReadOnly}
              }
              required
            />
            <div className="flex-none">
              <DiscountInput
                label="Margem de desconto"
                width="260px"
                name="discountMargin"
                error={props.transfer.errors?.margin_discount}
                value={props.transfer.discountMargin}
                onChange={(e) => props.changeState(e)}
                required
              />
            </div>
          </div>
          <div className="mt-3 d-flex text-gray">
            <FormControl component="fieldset">
              <RadioGroup row aria-label="gender" name="transferType" value={props.transfer.transferType} onChange={(e) => props.changeTypeTransfer(e)}>
                <FormControlLabel disabled={props.isEditing} value="in" control={<GreenRadio />} label="Transfer in" />
                <FormControlLabel disabled={props.isEditing} value="out" control={<GreenRadio />} label="Transfer out" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className="text-right">
          <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
}

export default Form;
