import { ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import { Button as MaterialButton } from '@material-ui/core';

const CustomButton = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: '20px',
    border: props?.border || null,
    padding: props?.padding || "8px 22px",
    width: props?.width || "100%",
    color: props?.textcolor || '#545454',
    marginTop: props?.margintop || "15px",
    marginLeft: props?.marginleft || "0px",
    marginRight: props?.marginright || "0px",
    fontFamily: props?.fontfamily || 'Poppins',
    textTransform: "initial",
    backgroundColor: props?.bgColor || '#212121',
    borderColor: props?.bgColor === 'transparent' ? props?.textcolor : props?.bgColor || '#212121',
    '&:hover': {
      backgroundColor: props?.bgColor || '#212121',
      borderColor: props?.bgColor === 'transparent' ? props?.textcolor : props?.bgColor || '#212121',
      boxShadow: 'box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: props?.bgColor || '#212121',
      borderColor: props?.bgColor === 'transparent' ? props?.textcolor : props?.bgColor || '#212121',
    },
  }),
}))(MaterialButton);

let Button = props => {
  let ref = useRef(null);

  return <ThemeProvider>
    <CustomButton className="filter hover:brightness-90" ref={ref} variant={props.variant || "contained"} {...props}>
      {props.children}
    </CustomButton>
  </ThemeProvider>;
}

export default Button;
