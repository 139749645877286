import Table from '@components/Table';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import DeleteModal from '@components/DeleteModal';
import { apiClient } from '@services/api';
import Alert from '@material-ui/lab/Alert';

const permissions = ['administrador', 'logistica'];

const SendVoucher = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [buttonSend, setButtonSend] = useState(false)

  const sendVoucher = () => {
    props.setSuccessMessage('Realizando o envio, aguarde.')
    props.setSuccess(true)
    setButtonSend(true)
    apiClient.httpInstance.post(props.urlLink)
      .then(response => {
        if (response.status === 200) {
          props.setSuccess(true)
          props.setSuccessMessage('Vouchers enviados com sucesso.')
          setOpenModal(false)
          setButtonSend(false)
          setTimeout(() => {
            props.setSuccess(false)
            props.setSuccessMessage(null)
          }, 4000);
        }
      }).catch((error) => {
        props.setError(true)
        props.setErrorMessage(error.response.data.error)
        setOpenModal(false)
        setButtonSend(false)
        setTimeout(() => {
          props.setError(false)
          props.setErrorMessage(null)
        }, 4000);
      });
  }

  return <>
    <div className="flex items-center">
      <Button
        textcolor="#212121"
        padding="6px 15px"
        margintop="0px"
        disabled={buttonSend}
        variant="outlined"
        onClick={() => setOpenModal(true)}
        bgColor="transparent"
      >
        Enviar voucher
      </Button>
      <DeleteModal
        open={openModal}
        close={() => setOpenModal(false)}
        delete={() => {setOpenModal(false)
                        sendVoucher()}}
        title="Enviar vouchers?"
        refresh
        description="Tem certeza que deseja enviar os vouchers? Essa ação não poderá ser desfeita."
        btnText="Ok"
      />
    </div>
  </>
}

const TableVehicles = (props) => {
  const history = useHistory();
  const exit = props.exit;
  const rows = useState([]);
  const [error, setError] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [urlDeleteVehicle, setUrlDeleteVehcle] = useState(null)

  const openDeleteModal = (e, url) => {
    e.preventDefault();
    setDeleteModal(true)
    setUrlDeleteVehcle(url)
  }

  const refreshTable = () => {
    setUrlDeleteVehcle(null)
    setRefresh(true)
    setTimeout(() => { setRefresh(false) }, 4000);
  }
  return <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {success &&
      <Alert className="mt-5" severity="success" color="success">
        {successMessage}
      </Alert>
    }
    <div className="flex items-center justify-between p-4 text-xl text-blue font-poppins">
      <span>Veículos</span>
      {permissions.includes(props?.theme?.permission) && <Button
        textcolor="#FFF"
        padding="6px 15px"
        width="200"
        customcolor={props.theme.color}
        type="submit"
        onClick={props.onClick}
      >
        <Icon className="mr-2">
          add
        </Icon>
        Novo veículo
      </Button>}
    </div>

    <DeleteModal
      open={deleteModal}
      close={() => setDeleteModal(false)}
      url={urlDeleteVehicle}
      refresh={refreshTable}
      title="Excluir veículo?"
      description="Tem certeza que deseja excluir esse veículo? Essa ação não poderá ser desfeita."
      btnText="Deletar"
    />
    <div className="flex justify-center pb-3 text-center">
      <Table
        path={props.path}
        onRowClick={(props) => {
          const vehicle = props.row;
          history.push(`/admin/saidas/detalhe/${exit.id}/veiculos/detalhe/${vehicle.id}`);
        }}
        refresh={refresh}
        columns={[
          {
            headerName: 'Apelido',
            headerClassName: 'super-app-theme--header',
            field: 'nickname',
            width: 200
          },
          {
            headerName: 'Vagas',
            headerClassName: 'super-app-theme--header',
            field: 'seats',
            width: 100,
            renderCell: (params) => {
              const row = params.row;
              return (
                <div className="flex items-center">
                  <span className="text-green-500">{row.total_allocated || 0}</span>/{row.seats}
                </div>
              )
            },
          },
          {
            headerName: 'Motorista',
            headerClassName: 'super-app-theme--header',
            field: 'driver_name',
            width: 150,
          },
          {
            headerName: '-',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'right',
            field: 'finished',
            align: 'right',
            width: 250,
            preventRedirect: true,
            renderCell: (params) => {
              const row = params.row;
              const urlLink = `/reservations/${exit.id}/exit_voucher/vehicle/${row.id}/send_email`
              const updateLink = `/admin/saidas/detalhe/${exit.id}/veiculos/editar/${row.id}`
              return <>
                {row.finished && (
                  <SendVoucher
                    urlLink={urlLink}
                    setError={setError}
                    setErrorMessage={setErrorMessage}
                    setSuccess={setSuccess}
                    setSuccessMessage={setSuccessMessage}
                  />
                )}
                {permissions.includes(props?.theme?.permission) && <>
                  <Icon className="mr-2 text-lightblue" onClick={() => history.push(updateLink)}>
                    edit
                  </Icon>
                  <Icon className="ml-2 mr-2 text-red-500" onClick={(e) => openDeleteModal(e, `exits/${exit.id}/exit_vehicle/${row.id}/remove`)}>
                    delete
                  </Icon>
                </>}
              </>
            },
          }
        ]}
        rows={rows}
      />
    </div>
  </div>


}

export default TableVehicles;
