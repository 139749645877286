import TextField from '@components/TextField';
import { apiClient } from '@services/api';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Select from '@components/Select';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import BreadCrumbs from '@components/Breadcrumbs';
import '@assets/css/Admin/components/table.scss';
import { makeStyles } from '@material-ui/styles';

const ToursTable = (props) => {
  document.title = `Lista de passeios - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rowsCupos, setRowsCupos] = useState([]);
  let [rows] = useState([]);
  const [q, setQ] = useState(null)
  const [cupos, setCupos] = useState(0)
  const [from_date, setFromDate] = useState(null)
  const [to_date, setToDate] = useState(null)
  let [tableReservations, setTableReservations] = useState(false);
  let [region, setRegion] = useState("all");
  let [regionsData, setRegionsData] = useState([]);
  let urlRegions = '/regions';

  const columnsGrid = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: true
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
      editable: true
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/reservas'
    },
    {
      title: 'Passeios',
    }
  ];

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const getRegionData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })

        setRegionsData(regions);
        setTableReservations(true)
      }
    });
  }

  useEffect(() => {
    getRegionData(urlRegions);
  }, [urlRegions]);

  return <>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Passeios</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap">
          <div className="flex flex-wrap items-center md:space-x-3 flex-1">
            <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
              <TextField
                className="inputRounded"
                placeholder="Busque por um passeio"
                variant="outlined"
                type="text"
                name="q"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                required
              />
            </div>
            <div className="flex-none md:p-0 m-2 md:m-0 sm:w-40 min-w-max">
              <Select
                label="Filtrar por Região"
                defaultValue="all"
                gender="f"
                width="110px"
                onChange={(e) => setRegion(e.target.value)}
                items={{
                  field: {
                    value: 'id',
                    text: 'name'
                  },
                  data: regionsData
                }}
              ></Select>
            </div>
            <div className="flex-none sm:p-2 md:p-0 m-2 md:m-0 sm:w-20 min-w-max">
              <DatePicker
                width="170px"
                label="Data inicial"
                value={from_date !== null ? `${from_date} 00:00:00` : null}
                onChange={date => changeFromDate(date)}
              />
            </div>
            <div className="flex-none sm:p-2 md:p-0 m-2 md:m-0 sm:w-20 min-w-max">
              <DatePicker
                width="170px"
                label="Data final"
                value={to_date !== null ? `${to_date} 00:00:00` : null}
                minDate={`${from_date} 00:00:00`}
                onChange={date => changeToDate(date)}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center md:space-x-3 mt-2 md:mt-0">
            <div className="flex items-center justify-end col-start-4 col-end-6">
              {cupos > 0 &&
                <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) - 1)}>remove</Icon>
              }
              <TextField
                className="mx-2 inputRounded"
                placeholder="Vagas"
                width="100px"
                variant="outlined"
                type="number"
                name="cupos"
                value={cupos}
                onChange={(e) => setCupos(e.target.value)}
                required
              />
              <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) + 1)}>add</Icon>
            </div>
          </div>
        </div>
      </div>

      {tableReservations && <Table
        path="/tours?reservations=true"
        params={{
          q, region, from_date, to_date, cupos
        }}
        onRowClick={null}
        columns={[
          {
            headerName: 'Passeio',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            //flex: 1,
            width: 250,
          },
          {
            headerName: 'Pax',
            headerClassName: 'super-app-theme--header',
            field: 'price',
            //flex: 1,
            width: 150,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={Number(row.price)}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: 'Pax criança',
            headerClassName: 'super-app-theme--header',
            field: 'kids_price',
            //flex: 1,
            width: 150,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={row.kids_price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: 'Próxima data',
            headerClassName: 'super-app-theme--header',
            field: 'from_date',
            flex: 0.5,
            type: 'date',
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                <Moment format="DD/MM/YYYY" date={row.from_date} />
              </div>);
            }
          },
          {
            headerName: 'Cupos Disponíveis',
            headerClassName: 'super-app-theme--header',
            field: 'cupos',
            //flex: 1,
            width: 200,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.availables_cupos}
              </div>);
            }
          },
          {
            headerName: ' ',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            //flex: 1,
            width: 150,
            renderCell: (params) => {
              const row = params.row;
              const link = `passeios/${row.id}/comprar`

              return (<div className="flex items-center pr-4 mb-3">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(link, { cupos: cupos, dateTour: row.from_date })
                  }
                  bgColor="transparent"
                >
                  Comprar
                </Button>
              </div>);
            }
          },
        ]}
        rows={rows}
      />}
    </div>
  </>
}

export default ToursTable;