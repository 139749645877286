import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Moment from 'react-moment';
import Button from '@components/Button';
import TextField from '@components/TextField';
import AdminConfig from '@config/admin';
import '@css/Admin/index.scss';
import Alert from '@material-ui/lab/Alert';
import { display, visibility } from '@material-ui/system';
import { apiClient } from '@services/api';
import { dateLocale, slugfy, translator } from '@utils/helpers';
import NumberFormat from 'react-number-format';
import Modal from '@components/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import calendar from '@assets/images/icons/calendar.png';
import clock from '@assets/images/icons/clock.png';
import person from '@assets/images/icons/person.png';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
  Route,
  Switch,
  useHistory,
  // Redirect,
  useParams, useRouteMatch
} from "react-router-dom";
import Header from './partials/Header';

const SignatureReservation = props => {
  let { path } = useRouteMatch();
  const history = useHistory();

  const [loadingCode, setLoadingCode] = useState(false);
  const [successCode, setSuccessCode] = useState(false);

  const [validationCode, setValidationCode] = React.useState("");
  const [privateCode, setPrivateCode] = React.useState("");
  const [validationReturn, setValidationReturn] = React.useState("");
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState("");

  let theme = AdminConfig.themes.default;

  const sendCode = () => {
    setLoadingCode(true)
    apiClient.httpInstance.post(`sale_signature/validate_signature`, { "validation_code": validationCode, "private_code": privateCode }).then(response => {
      if (response.status === 200) {
        console.log(response.data)
        setValidationReturn(response.data)
        setSuccessCode(true)
      }
    });
  }

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} />
        <div className="flex flex-row bg-lightgray">
          <Route path={`${path}`}>
            <div className="w-full flex justify-center mx-0 mb-20 pt-14 md:mx-28">
              <div className="wrapper">
                <img className="mx-auto mb-10 logo" src={`${process.env.REACT_APP_LOGO_BLACK}`} alt={process.env.REACT_APP_NAME}></img>
                <div className="p-6 text-left rounded content bg-white">
                  <div className="flex text-3xl justify-content-start mb-7">
                    <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>Insira a chave de validação</span>
                  </div>
                  {success && (<Alert className="mb-5" variant="filled" severity="success">
                    {success}
                  </Alert>)}
                  {error && (<Alert className="mb-5" variant="filled" severity="error">
                    Chave(s) inválida(s)!
                  </Alert>)}
                  {/* <form onSubmit={sendCode}>
                  </form> */}
                  <TextField
                    className="inputRounded p-3"
                    placeholder="Chave Pública"
                    variant="outlined"
                    id="validation_code"
                    type="text"
                    name="validation_code"
                    value={validationCode}
                    onChange={(e) => setValidationCode(e.target.value)}
                    required></TextField>
                  <TextField
                    className="inputRounded p-3"
                    placeholder="Chave Privada"
                    variant="outlined"
                    id="private_code"
                    type="text"
                    name="private_code"
                    value={privateCode}
                    onChange={(e) => setPrivateCode(e.target.value)}
                    required></TextField>
                  <Button textcolor="#545454" type="button" bgColor={process.env.REACT_APP_PRIMARY_COLOR} onClick={sendCode}>Validar</Button>
                  <hr className="mt-6 mb-4" style={{ color: "#E0E0E0" }} />
                  <div className="flex justify-content-start m-5">
                    {validationReturn?.error && <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>{validationReturn?.error}</span>}
                  </div>
                  <div className="flex justify-content-start m-5">
                    {validationReturn?.name && <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>Assinado por {validationReturn?.name}</span>}
                  </div>
                  <div className="flex justify-content-start m-5">
                    {validationReturn?.signature_date && <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>em <Moment format="DD/MM/YYYY" date={validationReturn?.signature_date} /></span>}
                  </div>
                  <div className="flex justify-content-start m-5">
                    {validationReturn?.file && <a href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${validationReturn?.file}`} style={{ color: process.env.REACT_APP_SECONDARY_COLOR }} target='_blank'>Abrir arquivo</a>}
                  </div>
                </div>
              </div>
            </div>
          </Route>
        </div>
      </div>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(
  mapStateToProps
)(SignatureReservation);