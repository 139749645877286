import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import Table from '@components/Table';
import { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';
import DeleteModal from '@components/DeleteModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';
import { Tooltip } from '@material-ui/core';

const Show = (props) => {
  document.title = `Detalhes do cliente - ${process.env.REACT_APP_NAME}`
  const { customer_id } = useParams();
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const [rows] = useState([]);
  const [widthType, setWidthType] = useState(112)
  const url = `/customers/${customer_id}`;

  const getCustomerDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCustomer(response.data);
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/clientes'
    },
    {
      title: `${customer.name}`,
    }
  ]

  useEffect(() => {
    getCustomerDetails(url);
  }, [url]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue flex-wrap">
          <div className="sm:w-full md:w-80">{customer.name}</div>
          <div className="w-100 mt-2 md:mt-0">
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => history.push(`/admin/clientes/editar/${customer_id}`)}
              customcolor={props.theme.color}
            >
              <Icon className="mr-2">
                edit
              </Icon>
              Editar
            </Button>
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => setDisableModal(true)}
              bgColor="#F44336"
            >
              <Icon className="mr-2">
                delete
              </Icon>
              Excluir
            </Button>
            <DeleteModal
              open={disableModal}
              close={() => setDisableModal(false)}
              url={`/customers/${customer_id}`}
              title="Excluir cliente?"
              redirect={'/admin/clientes'}
              description="Você realmente deseja excluir este cliente? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
            />
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do cliente</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do cliente
            </div>
            <div className="flex-1">
              {customer.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {customer.phone}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {customer.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Documento de identificação
            </div>
            <div className="flex-1">
              {customer.document}
            </div>
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Reservas</span>
          </div>
          <Table
            path={`/sales`}
            onRowClick={null}
            params={{
              customer_id: customer.id
            }}
            columns={[
              {
                headerName: 'Referência',
                headerClassName: 'super-app-theme--header',
                field: 'references',
                width: 160,
                renderCell: (params) => {
                  const row = params.row;
                  return (<div className="flex items-center pr-4 space-x-5">
                    {row.status === 'canceled' &&
                      <Icon className="mr-2 text-red-500">block</Icon>
                    }
                    {row.references}
                  </div>);
                }
              },
              {
                headerName: 'Criado em',
                headerClassName: 'super-app-theme--header',
                field: 'created_at',
                flex: 1,
                renderCell: (params) => {
                  const row = params.row;
                  return (<div className="flex items-center pr-4 space-x-5">
                    <Moment format="DD/MM/YYYY" date={row.created_at} />
                  </div>);
                }
              },
              {
                headerName: 'Vendedor',
                headerClassName: 'super-app-theme--header',
                field: 'user_name',
                flex: 1
              },
              {
                headerName: 'Tipo de reserva',
                headerClassName: 'super-app-theme--header',
                field: 'types',
                width: widthType,
                renderCell: (params) => {
                  const row = params.row;
                  const typeProduct = {
                    tour: {
                      bg: '#D8212FCC',
                      text: 'Passeio'
                    },
                    translate: {
                      bg: '#4480A8E5',
                      text: 'Traslado'
                    },
                    'translate-out': {
                      bg: '#4480A8E5',
                      text: 'Out'
                    },
                    'translate-in': {
                      bg: '#009C86E5',
                      text: 'In'
                    }
                  }

                  const rawTypes = row.types || ""
                  const typesArray = Array.from(new Set(rawTypes.split(", ")))
                  if (typesArray.length === 2 && widthType < 240) {
                    setWidthType(240)
                  } else if (typesArray.length === 3 && widthType < 350) {
                    setWidthType(350)
                  }
                  return <div className="flex items-center pr-4 space-x-5">
                    {typesArray.map((type) => {
                      if (type === 'tour' || type === 'translate' || type === 'translate-out' || type === 'translate-in' || type === 'combo' || type === 'transfer') {
                        return <div className="flex items-center justify-center w-24 h-8 text-white rounded-full" style={{ background: typeProduct[type].bg }}>
                          {typeProduct[type].text}
                        </div>
                      } else {
                        return <>{type}</>
                      }
                    })}
                    {row.has_combo && <><Icon className="mr-2">card_travel</Icon> Combo</>}
                  </div>
                }
              },
              {
                headerName: 'Próximo passeio',
                headerClassName: 'super-app-theme--header',
                field: 'next_date',
                flex: 1,
                renderCell: (params) => {
                  const row = params.row;
                  return (<div className="flex items-center pr-4 space-x-5">
                    {row.next_date ?
                      <Moment format="DD/MM/YYYY" date={row.next_date} /> :
                      <Moment format="DD/MM/YYYY" date={row.last_date} className='text-gray' />
                    }
                  </div>);
                }
              },
              {
                headerName: 'Produtos',
                headerClassName: 'super-app-theme--header',
                field: 'id',
                width: 125,
                renderCell: (params) => {
                  const row = params.row;
                  let toursNames = row.tours_names || ''
                  toursNames = toursNames.replace(/["{}]/g, '')
                  toursNames = toursNames.split(",")
                  let transferNames = row.transfer_names || ''
                  transferNames = transferNames.replace(/["{}]/g, '')
                  transferNames = transferNames.split(",")
                  let allNames = toursNames.concat(transferNames).sort()

                  return (<div className="flex flex-wrap items-center pr-4 space-x-2">
                    <Tooltip
                      arrow
                      title={allNames.map((el) => {
                        return <div className="flex flex-wrap items-center pr-4 space-x-2">{el}</div>
                      })}
                    >
                      <span className="flex items-center justify-center pl-6">Ver <Icon className="mr-2">
                        preview
                      </Icon>
                      </span>
                    </Tooltip>
                  </div>);
                }
              },
              {
                headerName: 'Assinatura',
                headerClassName: 'super-app-theme--header',
                field: 'signed_by_customer',
                width: 150,
                headerAlign: 'right',
                align: 'right',
                renderCell: (params) => {
                  const row = params.row;
                  const status = {
                    signed: {
                      text: 'Assinado',
                      icon: 'check_circle'
                    },
                    unsigned: {
                      text: 'Pendente',
                      icon: 'do_disturb_on'
                    }

                  }
                  return <span className="flex items-center">
                    {
                      <>{status[(row.signed_by_customer === true ? 'signed' : 'unsigned')].text} <Icon className="ml-2">{status[(row.signed_by_customer === true ? 'signed' : 'unsigned')].icon}</Icon> </>
                    }
                  </span>;
                }
              },
              {
                headerName: 'Reserva',
                headerClassName: 'super-app-theme--header',
                field: 'payment_status',
                width: 120,
                headerAlign: 'right',
                align: 'right',
                renderCell: (params) => {
                  const row = params.row;
                  return <Link to={`/admin/reservas/detalhe/${row.id}`} className="flex items-center justify-center pl-6" style={{ textDecoration: 'underline' }}>
                    Abrir
                  </Link>
                }
              },
            ]}
            rows={rows} />
        </div>
      </div>
    </>
  } else {
    return <div className="grid w-full h-full justify-items-center">
      <CircularProgress size={50} />
    </div>
  }

}

export default Show;