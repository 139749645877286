import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Form from './Form'
import { format, isValid } from 'date-fns';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar detalhes do combo - ${process.env.REACT_APP_NAME}`
  const { combo_id } = useParams();
  const [combo, setCombo] = useState({
    from_date: null,
    to_date: null,
    name: null,
    discount: null,
    status: 'Ativo',
    tours: []
  })
  const [isEditing, setIsEditing] = useState(false)
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/combos'
    },
    {
      title: `${combo.name !== undefined ? combo.name : ''}`,
      active: true,
      route: `/admin/combos/detalhe/${combo_id}`
    },
    {
      title: 'Edição',
    }
  ]
  const getComboById = () => {
    apiClient.httpInstance.get(`combos/${combo_id}`)
      .then(response => {
        if (response.status === 200) {
          let toursIds = []
          response.data?.tours.forEach(element => {
            toursIds.push(element.id)
          });
          setCombo({
            from_date: response.data.from_date.slice(0, 10),
            to_date: response.data?.to_date ? response.data.to_date.slice(0, 10) : null,
            name: response.data.name,
            discount: response.data.discount,
            status: response.data.status,
            tours: toursIds
          })
          setIsEditing(true)
        }
      }
      )
      .catch((error) => console.log(error));
  }

  const changeDate = (date, name) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setCombo({
      ...combo,
      [name]: value
    })
  }

  const changeState = (ev) => {
    setCombo({
      ...combo,
      [ev.target.name]: ev.target.value
    })
  }

  const submitComboEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/combos/${combo_id}`, combo)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/combos');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }
  useEffect(() => {
    getComboById();
  }, []);

  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      {permissions.includes(props?.theme?.permission) ?
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
              onClick={() => history.push(`/admin/combos/detalhe/${combo_id}`)}
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            isEditing={isEditing}
            combo={combo}
            changeState={changeState}
            changeDate={changeDate}
            submit={submitComboEdit}
          />
        </div>
        :
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              bgColor="transparent"
              onClick={() => history.push(`/admin/combos/detalhe/${combo_id}`)}
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      }
    </>
  } else {
    return <>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
