import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

const DeleteTransferModal = (props) => {
  let ref = useRef(null);
  const history = useHistory();

  const handleDelete = (e) => {
    e.preventDefault();

    apiClient.httpInstance.delete(props.url)
      .then(response => {
        if (response.status === 200 && response.data) {
          ref.current.click();

          if (props.redirect) {
            history.push(props.redirect);
          }

          if (props.refresh) {
            props.refresh()
            //Change counter Cart
            let url = '/user/cart';
            apiClient.httpInstance.get(url).then(response => {
              if (response.status === 200) {
                let counter = response.data.length
                if (counter && counter > 0) {
                  document.getElementById("countItensCart").innerHTML = counter < 10 ? '0' + counter : counter
                  document.getElementById("countItensCart").classList.remove("hidden")
                } else {
                  document.getElementById("countItensCart").classList.add("hidden")
                }
              }
            })
              .catch((error) => console.log(error));
          }
        }
      })
      .catch((error) => console.log(error));;
  };

  return <Modal open={props.open} close={props.close} style={{ width: '85%', maxWidth: '500px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex items-start justify-center mr-3">
          <span className="flex items-center p-1.5 pb-0 rounded-3xl bg-red-100">
            <div>
              <Icon className="text-red-500">warning</Icon>
            </div>
          </span>
        </div>
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">{props.title}</span>
          <small className="text-gray">{props.description}</small>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>

    <div className="justify-center text-center mt-4 md:flex w-100">
      <div className="md:flex md:mr-3">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200px"
          variant="outlined"
          margintop="15px"
          onClick={props.close}
          bgColor="transparent"
        >
          Cancelar
        </Button>
      </div>
      <div className="md:flex">
        <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="15px"
          width="200px"
          onClick={props.url ? handleDelete : props.delete}
          bgColor="#F44336"
        >
          {props.btnText ? props.btnText : 'Excluir'}
        </Button>
      </div>
    </div>
  </Modal>
}

export default DeleteTransferModal;
