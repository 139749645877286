import BreadCrumbs from '@components/Breadcrumbs'
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import Form from './Form'
import { useHistory } from "react-router-dom";

const Update = (props) => {
  document.title = `Editar detalhes do cliente - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const { customer_id } = useParams();
  const [client, setClient] = useState({
    name: null,
    email: null,
    phone: null,
    document: null
  })
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const url = `customers/${customer_id}`
  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/clientes'
    },
    {
      title: `${client.name !== undefined ? client.name : ''}`,
      active: true,
      route: `/admin/clientes/detalhe/${customer_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getClientById = url => {
    apiClient.httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setClient(response.data)
          setIsEditing(true)
        }
      }
      )
      .catch((error) => console.log(error));
  }

  const changeState = (ev) => {
    const value = ev.target.value
    setClient({
      ...client,
      [ev.target.name]: value
    })
  }

  const submitClientEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/customers/${customer_id}`, client)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/clientes');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }
  useEffect(() => {
    getClientById(url);
  }, [url]);

  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Edição</span>
        </div>
        <Form
          {...props}
          isEditing={isEditing}
          client={client}
          changeState={changeState}
          submit={submitClientEdit}
          backLink={() => history.push(`/admin/clientes/detalhe/${customer_id}`)}
        />
      </div>
    </>
  } else {
    return <>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
