import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import DeleteModal from '@components/DeleteModal';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { Link, useHistory, useParams } from 'react-router-dom';
import RequestTable from './partials/RequestTable';
import PaymentsList from './partials/PaymentsList';
import SendVoucherModal from './partials/SendVoucherModal';

const permissions = ['administrador', 'gerente', 'supervisor', 'operacao'];

const Show = (props) => {
  document.title = `Detalhes da reserva - ${process.env.REACT_APP_NAME}`

  const lang = {
    'pt': 'Português',
    'en': 'Inglês',
    'es': 'Espanhol'
  }

  const { sales_id } = useParams();
  const [sales, setSale] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendVoucherModal, setSendVoucherModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false)
  const [urlLink, setUrlLink] = useState(null)
  const [totalPayment, setTotalPayment] = useState(0)
  const hoteis = [];
  const history = useHistory();
  const url = `/sales/${sales_id}`

  const getSalesDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setSale(response.data);
        setTotalPayment(response.data.payments.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0))
        if (response.data.url_link) {
          setUrlLink(response.data.url_link)
        } else {
          getClientById(response.data.customer_id)
        }
        setLoading(false);
      }
    })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage("Erro ao carregar o orçamento");
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
          setLoading(true);
        }
      });
  }

  const getClientById = (id) => {
    apiClient.httpInstance.get(`customers/${id}`).then(response => {
      if (response.status === 200) {
        setUrlLink(response.data.url_link)
        setLoading(false);
      }
    })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage("Cliente não encontrado");
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      });
  }
  const getReservations = url => {
    apiClient.httpInstance.get(`${url}/reservations`).then(response => {
      if (response.status === 200) {
        setReservations(response.data);
        setLoading(false)
      }
    }).catch(error => {
      if (error) {
        setError(true);
        setErrorMessage("Erro inesperado, tente novamente mais tarde")
        setTimeout(() => { setError(false) }, 4000);
        setTimeout(() => { setErrorMessage(null) }, 4000);
      }
    });;
  }

  const cancelReserve = url => {
    apiClient.httpInstance.put(url)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/reservas`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  const bradecrumbsConfig = [
    {
      title: `Início`,
      active: true,
      route: '/admin/reservas'
    },
    {
      title: `Reserva ${sales.references || ''}`,
    }
  ]

  useEffect(() => {
    getSalesDetails(url);
    getReservations(url);
  }, [url]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue flex-wrap">
            <div className="w-auto">Reserva {sales.references || ''}</div>
            {props?.theme?.permission !== 'supervisor' && permissions.includes(props?.theme?.permission) &&
              <div className="w-100 mt-2 md:mt-0">
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="100px"
                  onClick={() => history.push(`/admin/reservas/editar/${sales_id}`)}
                  customcolor={props.theme.color}
                >
                  <Icon className="mr-2">
                    edit
                  </Icon>
                  Editar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="140px"
                  onClick={() => setDisableModal(true)}
                  bgColor="#F44336"
                >
                  <Icon className="mr-2">delete</Icon>
                  Cancelar
                </Button>
                <DeleteModal
                  open={disableModal}
                  close={() => setDisableModal(false)}
                  delete={() => cancelReserve(`/sales/${sales_id}/cancel`)}
                  title="Cancelar reserva?"
                  redirect={'/admin/reservas'}
                  description="Você realmente deseja cancelar esta reserva? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
                />
              </div>
            }
            {props?.theme?.permission === 'supervisor' && props.user?.data?.id === sales.user_id && permissions.includes(props?.theme?.permission) &&
              <div className="w-100">
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="100px"
                  onClick={() => history.push(`/admin/reservas/editar/${sales_id}`)}
                  customcolor={props.theme.color}
                >
                  <Icon className="mr-2">
                    edit
                  </Icon>
                  Editar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  margintop="0px"
                  marginright="20px"
                  width="140px"
                  onClick={() => setDisableModal(true)}
                  bgColor="#F44336"
                >
                  <Icon className="mr-2">delete</Icon>
                  Cancelar
                </Button>
                <DeleteModal
                  open={disableModal}
                  close={() => setDisableModal(false)}
                  delete={() => cancelReserve(`/sales/${sales_id}/cancel`)}
                  title="Cancelar reserva?"
                  redirect={'/admin/reservas'}
                  description="Você realmente deseja cancelar esta reserva? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
                />
              </div>
            }
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl">
              <span>Informações do cliente</span>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 p-4'>
              <div className="">
                <div className="grid grid-cols-1 gap-3 text-base font-normal">
                  <div className="flex-none sm:w-20 min-w-max">
                    {sales.name}
                  </div>
                  {props?.theme?.permission !== 'parceiro' && <>
                    <div className="flex-none sm:w-20 min-w-max">
                      Telefone: {sales.phone}
                    </div>
                    <div className="flex-none sm:w-20 min-w-max">
                      Email: {sales.email}
                    </div>
                    <div className="flex-none sm:w-20 min-w-max">
                      Documento: {sales.document}
                    </div>
                  </>}
                </div>
              </div>
              <div className="">
                <div className="grid grid-cols-1 gap-3 text-base font-normal">
                  <div>
                    {sales?.customer_addresses?.map((address, index) => {
                      let addressesClient = ''
                      reservations.forEach(reserve => {
                        if (reserve.tour_id !== null) {
                          if (reserve.region_id == address.region_id) {
                            addressesClient = <p>Hospedagem ({address.name}): {address.hotel_address}</p>
                          }
                        }
                      })
                      return addressesClient
                    })
                    }
                    {
                      reservations.map((reserve, index) => {
                        let addressesClient = ''
                        let hotel = '..'
                        //reservations.forEach(reserve => {
                        if (reserve.type === null && !hoteis.includes(reserve.hotel_name)) {
                          addressesClient = <p>Hospedagem: {reserve.hotel_name}, {reserve.address_hotel}</p>
                        }
                        //})
                        hoteis.push(reserve.hotel_name)
                        return addressesClient
                      })
                    }
                  </div>
                  {props?.theme?.permission !== 'parceiro' && <div>
                    Observações: {reservations[0]?.customer_observations}
                  </div>}
                </div>
              </div>
              <div >
                <div className="grid grid-cols-1 gap-3text-base font-normal">
                  <div>
                    Idioma: {lang[sales.language]}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 text-xl border-t border-opacity-75 text-blue font-poppins">
              <span>Informações do pedido</span>
            </div>
            {reservations &&
              <div className="p-4">
                <RequestTable {...props} dataReservations={reservations} show={true} total={sales.total} urlLink={urlLink} />
              </div>
            }
            {props?.theme?.permission !== 'parceiro' && <>
              <div className="p-4 text-xl border-t border-opacity-75 text-blue font-poppins">
                <span>Detalhes do pagamento</span>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-5 gap-4 p-4'>
                <div className="">
                  <div className="grid grid-cols-1 gap-3 text-base font-normal">
                    Total:
                    <NumberFormat
                      value={Number(sales.total) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="ml-2">{value}</span>}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-cols-1 gap-3 text-base font-normal">
                    Pago:
                    <NumberFormat
                      value={Number(totalPayment) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="ml-2">{value}</span>}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-cols-1 gap-3 text-base font-normal">
                    Restante:
                    <NumberFormat
                      value={Number(sales.total - totalPayment) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="ml-2">{value}</span>}
                    />
                  </div>
                </div>
                {/* <div className="">
                  <div className="grid grid-cols-1 gap-3 text-base font-normal">
                    Forma de pagamento:
                    <NumberFormat
                      value={Number(sales.total - totalPayment) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="ml-2">{value}</span>}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="grid grid-cols-1 gap-3 text-base font-normal">
                    Data do pagamento:
                    <NumberFormat
                      value={Number(sales.total - totalPayment) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="ml-2">{value}</span>}
                    />
                  </div>
                </div> */}
              </div>
            </>}
          </div>

          <div className="bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="px-4 mt-2 text-sm text-base font-normal text-blue font-poppins">
              <span>Histórico:</span>
            </div>
            <div>
              <PaymentsList {...props} dataPayments={sales.payments} show={true} total={sales.total} />
            </div>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl border-b border-opacity-75 text-blue font-poppins">
              <span>Informações do vendedor</span>
            </div>
            <div className="flex p-4">
              <div className="flex-1">
                Vendedor responsável
              </div>
              <div className="flex-1">
                {sales.seller_name}
              </div>
            </div>
            <div className="flex p-4">
              <div className="flex-1">
                Vendedor parceiro
              </div>
              {(reservations[0]?.partner_name || reservations[0]?.agency_name) &&
                <div className="flex-1">
                  {reservations[0]?.partner_name}
                  {reservations[0]?.agency_name}
                </div>}
            </div>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl border-b border-opacity-75 text-blue font-poppins">
              <span>Informações adicionais</span>
            </div>
            <div className="flex p-4">
              <div className="flex-1">
                Assinatura
              </div>
              <div className="flex-1">
                {sales?.signed_by_customer ?
                  <>
                    Assinatura realizada em <Moment format="DD/MM/YYYY HH:MM" date={sales?.signature_date} />h por {sales?.name}.
                    <p>
                      Hash público: <span style={{ fontSize: '0.8rem' }}>{sales?.signature?.public_key}</span>
                    </p>
                    <p>
                      <Link className="mr-2" color="inherit" to={`/sign/${sales?.id}`} target='_blank'>
                        <Button
                          textcolor="#FFF"
                          padding="2px 10px"
                          width="200"
                        >
                          <Icon className="mr-2">
                            open_in_new
                          </Icon>
                          Abrir assinatura
                        </Button>
                      </Link>
                      <a href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${sales?.signature?.file}`} target='_blank'>
                        <Button
                          textcolor="#FFF"
                          padding="2px 10px"
                          width="200"
                        >
                          <Icon className="mr-2">
                            open_in_new
                          </Icon>
                          Contrato enviado
                        </Button>
                      </a>
                    </p>
                  </>
                  :
                  <>Aguardando assinatura</>}
              </div>
            </div>
          </div>
          <div className="flex mb-5 space-x-5">
            {props?.theme?.permission !== 'parceiro' && <Link color="inherit" to={`/voucher/${sales_id}/${urlLink}`} target='_blank'>
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  open_in_new
                </Icon>
                Voucher da Reserva
              </Button>
            </Link>}
            {permissions.includes(props?.theme?.permission) && <div className="flex-row">
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                onClick={() => setSendVoucherModal(true)}
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  email
                </Icon>
                Reenviar voucher
              </Button>
              {reservations[0]?.exit_vehicle && <Link color="inherit" to={`/voucher-exit/${sales_id}/${urlLink}?tourDate=${reservations[0]?.tour_date}&exitId=${reservations[0]?.exit_id}`} target='_blank'>
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  marginleft="20px"
                  width="200"
                  variant="outlined"
                  bgColor="transparent"
                >
                  <Icon className="mr-2">
                    open_in_new
                  </Icon>
                  Voucher da Saída
                </Button>
              </Link>}
            </div>}
            <SendVoucherModal
              saleId={sales_id}
              open={sendVoucherModal}
              close={() => setSendVoucherModal(false)}
              {...props}
            />
          </div>
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Show);
