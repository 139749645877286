let language = {
    "PT": {
        "voucher": {
            "address": "Endereço",
            "rut": "RUT",
            "phone": "Telefone",
            "voucher": "Voucher",
            "dates": "Datas",
            "products": "Produtos",
            "passagers": "Passageiros",
            "customers_data": "Informações do cliente",
            "customer_name": "Nome do cliente",
            "document": "Documento",
            "about_reservation": "Sobre a reserva",
            "about_budget": "Sobre o orçamento",
            "about_exit": "Sobre a saída",
            "driver": "Motorista",
            "guide": "Guia",
            "date": "Data",
            "value_by_adult": "Valor por adulto",
            "value_by_kid": "Valor por criança",
            "value_by_passagers": "Valor por passageiro",
            "schedule": "Horário",
            "flight_code": "Número do vôo",
            "adults": "Adultos",
            "kids": "Crianças",
            "subtotal": "Subtotal",
            "discount": "Desconto",
            "total": "Total",
            "email": "Email",
            "payment_about": "Informações do pagamento",
            "references": "Referência",
            "purchase_date": "Data da compra",
            "last_purchase_date": "Data do último pagamento",
            "total_value": "Valor Total",
            "paid_value": "Valor Pago",
            "pending_value": "Valor Pendente",
            "payment_status": "Status de pagamento",
            "signature": "Assinatura realizada em"
        }
    },
    "EN": {
        "voucher": {
            "address": "Address",
            "rut": "RUT",
            "phone": "Phone",
            "voucher": "Voucher",
            "dates": "Dates",
            "products": "Products",
            "passagers": "Passagers",
            "customers_data": "Customer's data",
            "customer_name": "Customer's name",
            "document": "Document",
            "about_reservation": "About reservation",
            "about_budget": "About the budget",
            "about_exit": "About exit",
            "driver": "Driver",
            "guide": "Guide",
            "date": "Date",
            "value_by_adult": "Value by adult",
            "value_by_kid": "Value by kid",
            "value_by_passagers": "Value by passager",
            "schedule": "Schedule",
            "flight_code": "Flight Code",
            "adults": "Adults",
            "kids": "Kids",
            "subtotal": "Subtotal",
            "discount": "Discount",
            "total": "Total",
            "email": "Email",
            "payment_about": "About payment",
            "references": "References",
            "purchase_date": "Purchase date",
            "last_purchase_date": "Last payment date",
            "total_value": "Total value",
            "paid_value": "Paid value",
            "pending_value": "Pending value",
            "payment_status": "Payment status",
            "signature": "Signature performed on"
        }
    },
    "ES": {
        "voucher": {
            "address": "Dirección",
            "rut": "RUT",
            "phone": "Teléfono",
            "voucher": "Voucher",
            "dates": "Fechas",
            "products": "Productos",
            "passagers": "Pasajeros",
            "customers_data": "Datos del cliente",
            "customer_name": "Nombre del cliente",
            "document": "Documento",
            "about_reservation": "Sobre la reserva",
            "about_budget": "Sobre el presupuesto",
            "about_exit": "Sobre la salida",
            "driver": "Conductor",
            "guide": "Guía",
            "date": "Fecha",
            "value_by_adult": "Valor por adulto",
            "value_by_kid": "Valor por niño",
            "value_by_passagers": "Valor por pasajero",
            "schedule": "Horario",
            "flight_code": "Número de Vuelo",
            "adults": "Adultos",
            "kids": "Niños",
            "subtotal": "Subtotal",
            "discount": "Descuento",
            "total": "Total",
            "email": "Email",
            "payment_about": "Datos del pago",
            "references": "Referencias",
            "purchase_date": "Fecha de compra",
            "last_purchase_date": "Última fecha de pago",
            "total_value": "Valor total",
            "paid_value": "Valor pagado",
            "pending_value": "Valor pendiente",
            "payment_status": "Estado de pago",
            "signature": "Firma realizada en"
        }
    }
}

export default language;
