import { USER_PROFILE } from '../actions/actionsTypes'

let userInit = JSON.parse(localStorage.getItem('user')) || {};

export default function profile (state = userInit, action) {
  switch(action.type) {
    case USER_PROFILE:
      let user = action.payload
      localStorage.setItem('user', JSON.stringify(user))
      return user;
    default:
      return state;
  }
}
