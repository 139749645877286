import Button from '@components/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';
import Modal from '@components/Modal';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/template_messages/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
      .catch((error) => console.log(error));
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Mensagens - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [q, setQ] = useState("");
  const [license_type, setLicenseType] = useState("all")
  const [openModalMessages, setOpenModalMessages] = useState(false)
  const [messageDetails, setMessageDetails] = useState([])

  const verifyMessages = () => {
    apiClient.httpInstance.post(`/central/send_messages`).then(response => {
      if (response.status === 200) {
      }
    }).catch(function (response) {
    });
  }

  useEffect(() => {
    //verifyMessages();
  }, []);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const changePageDetails = (e, id) => {
    e.preventDefault();
    history.push(`mensagens/detalhe/${id}`)
  }
  const showMessageDetails = (e, send) => {
    e.preventDefault();
    apiClient.httpInstance.get(`/central/message_details?template_id=${send.id}&user_sender=${send.user_sender}&created_at=${send.created_at}`, {})
      .then((response) => {
        setMessageDetails(response.data.data);
      })
      .catch((error) => console.log(error));
    setOpenModalMessages(true)
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            accessibility
          </Icon>
          <span>Lista de mensagens</span>
        </div>
        <div className="flex items-center">
          <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            marginright="10px"
            width="200"
            onClick={() => verifyMessages()}
            customcolor={props.theme.color}
          >
            Realizar Envios
          </Button>
          {permissions.includes(props?.theme?.permission) && <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            width="200"
            onClick={() => history.push('/admin/central/mensagens/cadastro')}
            customcolor={props.theme.color}
          >
            <Icon className="mr-2">add</Icon>
            Novo
          </Button>}
        </div>
      </div>
      <Table
        path="/template_messages"
        onRowClick={null}
        columns={[
          {
            headerName: 'Template',
            headerClassName: 'super-app-theme--header',
            field: 'template_name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => showMessageDetails(e, row)} >
                {row.template_name}
              </div>);
            }
          },
          {
            headerName: 'Enviado por',
            headerClassName: 'super-app-theme--header',
            field: 'user_name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => showMessageDetails(e, row)} >
                {row.user_name}
              </div>);
            }
          },
          {
            headerName: 'Envio',
            headerClassName: 'super-app-theme--header',
            field: 'sent',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => showMessageDetails(e, row)} >
                {row.sent === true ? 'Enviado' : 'Aguardando'}
              </div>);
            }
          },
        ]}
        rows={rows}
      />
      <Modal open={openModalMessages} style={{ width: '90%', maxWidth: '650px' }}>
        <div className="flex mt-4">
          <p>Clientes:</p>
        </div>
        {messageDetails.map(message => (
          <div className="flex mt-4">
            <p>- {message.customer_name}</p>
          </div>
        ))}
        < div className="justify-center mt-4 md:flex w-100" >
          <Button
            textcolor="#fff"
            padding="10px 15px"
            margintop="0px"
            width="300px"
            onClick={(ev) => setOpenModalMessages(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    </div>
  </>
}

export default Index;
