import { useState, useEffect } from 'react';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Table from '@components/Table';
import TextField from '@components/TextField';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Select from '@components/Select';
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const Index = (props) => {
  document.title = `Lista de vendedores - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [modality, setModality] = useState('');
  const seller = true
  const [q, setQ] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]


  const sellersModalities = [
    {
      value: 'freelancer',
      name: 'Freelancer'
    },
    {
      value: 'fix',
      name: 'Salário Fixo'
    },
  ]

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            storefront
          </Icon>
          <span>Lista de vendedores</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => history.push('vendedores/cadastro')}
          customcolor={props.theme.color}><Icon className="mr-2">add</Icon> Novo</Button>}
      </div>
      <div className="flex p-4 mt-4 space-x-3 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center md:flex-nowrap">
          <TextField
            className="inputRounded"
            placeholder="Busque por um vendedor"
            variant="outlined"
            type="text"
            name="q"
            onChange={(e) => setQ(e.target.value)} />
        </div>
        <div>
          <Select
            label="Modalidade"
            width="200px"
            defaultValue="all"
            onChange={(e) => setModality(e.target.value)}
            items={{
              field: {
                value: 'value',
                text: 'name'
              },
              data: sellersModalities
            }}
            />
        </div>
      </div>
      <Table
        path="/users"
        params={{
          q, seller, modality
        }}
        columns={[
            {
              headerName: 'Nome',
              headerClassName: 'super-app-theme--header',
              field: 'name',
              flex: 1,
            },
            {
              headerName: 'Email',
              headerClassName: 'super-app-theme--header',
              field: 'email',
              flex: 1,
            },
            {
              headerName: 'Modalidade',
              field: 'freelancer',
              headerAlign: 'right',
              align: 'right',
              headerClassName: 'super-app-theme--header',
              flex: 1,
              valueFormatter: (params) => {
                const row = params.row;
                return `${row.freelancer === true ? 'Freelancer' : 'Possui salário fixo'}`;
              },
            },
            {
              headerName: 'Status',
              field: 'status',
              headerAlign: 'right',
              align: 'right',
              headerClassName: 'super-app-theme--header',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;
                const text = `${row.status === false ? 'Inativo' : 'Ativo'}`;
                return <span className={row.status === false ? "text-red-500" : "text-green-500"}>
                  {text}
                </span>;
              },
            }
          ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);
