import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ClientForm from './partials/form/ClientForm';
import PaymentForm from './partials/form/PaymentForm';
import SellerForm from './partials/form/SellerForm';
import RequestTable from './partials/RequestTable';

const permissions = ['administrador', 'gerente', 'supervisor', 'operacao'];

const Update = (props) => {
  document.title = `Editar detalhes da reserva - ${process.env.REACT_APP_NAME}`;

  const { sales_id } = useParams();
  const history = useHistory();
  const url = `sales/${sales_id}`;

  const [emailValidate, setEmailValidate] = useState(false)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [regions, setRegions] = useState([]);
  const [regionsTravel, setRegionsTravel] = useState([]);
  const [reserve, setReserve] = useState({
    payment_status: null,
    payment_type: null,
    payment_method: null,
    customer_paid_amount: null,
    payment_proof: null,
    user_id: null,
    seller_name: null,
    seller_email: null,
    proof: null,
    proof_name: null,
    proofs: [],
    payments: [],
    totalPayment: 0,
    hotel: '',
    hotel_address: '',
    room_number: '',
  })
  const [isEditing, setIsEditing] = useState(false)
  const [client, setClient] = useState({
    addresses: [{}],
    customer_id: null,
    email: null,
    document: null,
    name: null,
    phone: null,
    hotel_name: null,
    hotel_address: null,
    room_number: null,
    customer_language: null,
    observations: null,
    havePartner: false,
  })

  const changeClient = (ev) => {
    let value = ev.target.value

    setClient({
      ...client,
      [ev.target.name]: value
    })
  }
  const changeObservations = (ev) => {
    let value = ev.target.value

    setClient({
      ...client,
      ['observations']: value
    })
    setReserve({
      ...reserve,
      ['customer_observations']: value
    })
  }

  const changeClientAddress = (ev) => {
    let value = ev.target.value
    let addresses = client.addresses

    if (ev.target.index !== undefined && addresses[ev.target.index] !== null) {
      addresses[ev.target.index][ev.target.name] = value

      if (ev.target.region_id && ev.target.region_id !== '') {
        addresses[ev.target.index]['region_id'] = ev.target.region_id
      }
      if (ev.target.region_name && ev.target.region_name !== '') {
        addresses[ev.target.index]['region_name'] = ev.target.region_name
      }
    }

    setClient({
      ...client,
      addresses: addresses
    })
  }

  const changeClientCheckbox = (ev) => {
    let value = null
    if (ev.target.value === "true") {
      value = true
    } else {
      value = false
    }

    setClient({
      ...client,
      partner: null,
      [ev.target.name]: value
    })
  }

  const changeClientByPartner = (data) => {
    let typePartner = data.partner_type === "agency" ? "App\\Models\\Agency" : "App\\Models\\Seller"

    const clientData = {
      ...client,
      partner: data.partner,
      partnerEmail: data.partnerEmail,
      partner_seller_id: data.partner_seller_id,
      partner_type: data.partner_type,
    }
    setClient(clientData)
    setReserve({
      ...reserve,
      partner_seller_id: data.partner_seller_id,
      partner_seller_type: typePartner,
      partner_email: data.partnerEmail,
    })
  }

  const changeReserve = (ev) => {
    let value = ev.target.value
    if (ev.target.name === "havePartner") {
      value = ev.target.value === "true" ? true : false;
    }
    setReserve({
      ...reserve,
      [ev.target.name]: value
    })
  }

  const changeProof = (file, name, proofId) => {
    let newProofs = reserve.proofs;
    newProofs.push({
      proof: file,
      proof_name: name,
      payment_id: proofId && proofId !== undefined ? parseInt(proofId) : '',
    })
    setReserve({
      ...reserve,
      proofs: newProofs
    })
  }

  const removeProofPayment = (paymentId, proofId, idx) => {
    const proofs = reserve.proofs

    proofs.splice(idx, 1);
    setReserve({
      ...reserve,
      proofs: proofs
    })
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/reservas'
    },
    {
      title: `Reserva ${client.name !== null ? client.name : ''}`,
      active: true,
      route: `/admin/reservas/detalhe/${sales_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getReserveById = url => {
    apiClient.httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          const totalPayment = response.data.payments.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0)
          //Pega nome da região
          response.data.customer_addresses.forEach((address, index) => {
            if (address?.region_id === null) {
              response.data.customer_addresses[index].region_name = 'TRASLADO'
            } else {
              address.region_name = address.name
              response.data.customer_addresses[index] = address
            }
          });

          let partnerSellerId = response.data.partners && response.data.partners[0] ? response.data.partners[0]?.id : response.data.agencies[0]?.id
          let partnerSellerEmail = response.data.partners && response.data.partners[0] ? response.data.partners[0]?.email : response.data.agencies[0]?.email
          let partnerSellerType = response.data.partners && response.data.partners[0] ? 'seller' : 'agency'

          setClient({
            customer_id: response.data.customer_id,
            addresses: response.data.customer_addresses,
            name: response.data.name,
            email: response.data.email,
            phone: response.data.phone,
            document: response.data.document,
            hotel_name: response.data.hotel,
            hotel_address: response.data.hotel_address,
            room_number: response.data.room_number,
            customer_language: response.data.language,
            observations: response.data.customer_observations,
            havePartner: partnerSellerId ? true : false
          })

          setReserve({
            total: response.data.total,
            user_id: response.data.user_id,
            seller_name: response.data.seller_name,
            seller_email: response.data.seller_email,
            partner_id: partnerSellerId,
            partner_type: partnerSellerType,
            partner_email: partnerSellerEmail,
            agency: response.data.agencies,
            partner: response.data.partners,
            payments: response.data.payments,
            totalPayment: totalPayment,
            proofs: [],
            hotel: response.data.hotel,
            hotel_address: response.data.hotel_address,
            room_number: response.data.room_number,
            customer_observations: response.data.customer_observations,
          })
          setIsEditing(true)
        }
      }
      );
  }

  const getReservations = url => {
    apiClient.httpInstance.get(`${url}/reservations`).then(response => {
      if (response.status === 200) {
        response.data.map((element, index) => {
          //Recolhe ids das regiões para lista de endereços do cliente
          if (element && element.type === 'tour') {
            if (!regionsTravel.includes(String(element.region_id) || element.region_id)) {
              regionsTravel.push(element.region_id)
            }
          } else if (element && element.type === 'transfer') {
            if (!regionsTravel.includes('transfer')) {
              regionsTravel.push('transfer')
            }
            response.data[index].name = 'TRASLADO'
            response.data[index].region_name = 'TRASLADO'
          } else if (element && element.type === 'combo') {
            regions.forEach(element => {
              regionsTravel.push(element.id)
            });
          }
        })

        setReservations(response.data);

      }
    });
  }

  const changeDate = (index, date) => {
    let formattedDate = format(date, "yyyy-MM-dd")
    let newArr = [...reservations]
    newArr[index].tour_date = formattedDate
    setReservations(newArr)
  }

  const changeFlight = (index, flight) => {
    let newArr = [...reservations]
    newArr[index].flight_code = flight
    setReservations(newArr)
  }

  const changeFlightTime = (index, flightTime) => {
    let newArr = [...reservations]
    newArr[index].flight_time = flightTime.substr(-8)
    setReservations(newArr)
  }

  const editSale = () => {
    let paymmentsTotal = 0;
    if (reserve.payments) {
      reserve.payments.forEach(element => {
        paymmentsTotal += parseFloat(element.total)
      });
    }

    const data = {
      customer_id: client.customer_id,
      customer_email: client.email,
      customer_name: client.name,
      customer_document: client.document,
      phone: client.phone,
      customer_hotel: reserve.hotel,
      customer_hotel_address: reserve.hotel_address,
      customer_room_number: reserve.room_number,
      customer_language: client.customer_language,
      customer_address: client.addresses,
      customer_observations: client.observations,
      payment_status: reserve.payment_status,
      payment_method: reserve.payment_method,
      customer_paid_amount: reserve.payment_type === 'total' ? parseFloat(reserve.total) - paymmentsTotal : reserve.customer_paid_amount,
      references: reserve.payment_proof,
      seller_name: reserve.seller_name,
      seller_email: reserve.seller_email,
      user_id: reserve.user_id,
      partner_seller_id: reserve?.partner_seller_id,
      partner_seller_type: reserve?.partner_seller_type,
      have_partner: client.havePartner,
    }

    apiClient
      .httpInstance.put(`/sales/${sales_id}`, data)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          sendProof(sales_id, reserve.proofs)
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      })
  }

  const sendProof = (id, proofs) => {
    if (!proofs.length) {
      editDate()
    }
    proofs.forEach(proof => {
      let formData = new FormData()
      formData.append('payment_id', proof.payment_id)
      formData.append('proof', proof.proof)
      formData.append('proof_name', proof.proof_name)

      apiClient.httpInstance.post(`/sales/${id}/proof`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            editDate()
          }
        })
        .catch(error => {
          if (error) {
            setError(true);
            setErrorMessage(error.response.message || "Erro ao adicionar o comprovante")
            setTimeout(() => { setError(false) }, 4000);
            setTimeout(() => { setErrorMessage(null) }, 4000);

          }
        });
    });
  }

  const editDate = () => {
    reservations.map((obj, idx) => {
      apiClient
        .httpInstance.put(`/reservations_change_date_flight/${obj.id}`, {
          tour_date: obj.tour_date,
          flight_time: obj.flight_time,
          flight_code: obj.flight_code,
        })
        .catch(error => {
          if (error) {
            setError(true);
            setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
            setTimeout(() => { setError(false) }, 4000);
            setTimeout(() => { setErrorMessage(null) }, 4000);

          }
        })
      if (reservations.length === idx + 1) {
        history.push('/admin/reservas');
      }
    })
  }

  const onlyLetterAndNumber = (e) => {
    e.preventDefault();
    const regex = new RegExp("^[a-zA-Z0-9]+$");
    const value = e.target.value
    if (value.match(regex)) {
      setClient({
        ...client,
        document: value
      })
    } else if (value === "") {
      setClient({
        ...client,
        document: null
      })
    }
  }

  const changeClientByEmail = (data) => {

    //Pega nome da região
    data.addresses.forEach((address, index) => {
      if (address?.region_id === null) {
        data.addresses[index].region_name = 'TRASLADO'
      } else {
        let result = regions?.find(({ id }) => id === address.region_id)
        address.region_name = result?.name
        data.addresses[index] = address
      }
    });

    const clientData = {
      ...client,
      customer_id: data.customer_id,
      customer_language: data.customer_language,
      document: data.document,
      email: data.email,
      addresses: data.addresses,
      hotel_address: data.hotel_address,
      hotel_name: data.hotel_name,
      name: data.name,
      observations: data.observations,
      phone: data.phone,
      room_number: data.room_number,
    }
    setClient(clientData)

    data.addresses.forEach((address, index) => {
      if (address?.region_id === null) {
        address.region_name = 'TRASLADO'
        client.addresses[0] = address
      } else {
        if (client.addresses[index].region_id === address.region_id) {
          let result = regions?.find(({ id }) => id === address.region_id)
          address.region_name = result.name
          client.addresses[index] = address
        }
      }
    });
  }

  const getRegionData = () => {
    apiClient.httpInstance.get('/regions').then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        setRegions(regions);

        regions.forEach((region, idx) => {
          if (regionsTravel.includes(String(region.id) || region.id)) {
            //if(client.addresses.region_id)

            let result = client?.addresses?.find(({ region_id }) => region_id === region.id);

            if (result === undefined) {
              client.addresses.push({
                id: '',
                region_id: region.id,
                region_name: region.name,
                customer_id: '',
                hotel: '',
                hotel_address: '',
                room_number: ''
              })
            }
          }
        });
      }
    });
  }

  useEffect(() => {
    getReserveById(url);
    getReservations(url)
  }, [url]);

  useEffect(() => {
    getRegionData();
  }, [reservations]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {isEditing &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        {props?.theme?.permission !== 'supervisor' ?

          permissions.includes(props?.theme?.permission) ?
            <div className="px-7 pt-6">
              <div className="flex items-center justify-between mt-2 text-2xl text-blue flex-wrap">
                <div className="sm:w-full md:w-80">Edição</div>
                <div className="mb-2">
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    width="200"
                    variant="outlined"
                    onClick={() => history.push(`/admin/reservas/detalhe/${sales_id}`)}
                    bgColor="transparent"
                  >
                    <Icon className="mr-2">
                      arrow_back
                    </Icon>
                    Cancelar
                  </Button>
                </div>
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                {reservations &&
                  <div className="p-4">
                    <RequestTable
                      {...props}
                      dataReservations={reservations}
                      isEditing={isEditing}
                      changeDate={changeDate}
                      changeFlight={changeFlight}
                      changeFlightTime={changeFlightTime}
                      total={reserve.total}
                    />
                  </div>
                }
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <ClientForm
                  setClient={setClient}
                  reserve={reserve}
                  client={client}
                  changeClient={changeClient}
                  changeReserve={changeReserve}
                  changeClientAddress={changeClientAddress}
                  changeClientCheckbox={changeClientCheckbox}
                  changeClientByPartner={changeClientByPartner}
                  changeObservations={changeObservations}
                  isEditing={isEditing}
                  regionsTravel={regionsTravel}
                  onlyLetterAndNumber={onlyLetterAndNumber}
                  changeClientByEmail={changeClientByEmail}
                  changeSetEmail={setEmailValidate}
                />
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <PaymentForm
                  reserve={reserve}
                  changeReserve={changeReserve}
                  changeProof={changeProof}
                  removeProofPayment={removeProofPayment}
                  isEditing={isEditing}
                />
              </div>
              <div className="my-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <SellerForm
                  {...props}
                  reserve={reserve}
                  setReserve={setReserve}
                  isEditing={isEditing}
                />
              </div>
              <div className="flex justify-end mb-4 space-x-5">
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="260px"
                  onClick={() => editSale()}
                  customcolor={props.theme.color}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
            :
            <div className="px-7 pt-6">
              <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                <span>Edição</span>
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(`/admin/reservas/detalhe/${sales_id}`)}
                  bgColor="transparent"
                >
                  <Icon className="mr-2">
                    arrow_back
                  </Icon>
                  Cancelar
                </Button>
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                Você não possui permissão para acessar este recurso.
              </div>
            </div>
          :
          permissions.includes(props?.theme?.permission) && props.user?.data?.id === reserve.user_id ?
            <div className="px-7 pt-6">
              <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                <span>Edição</span>
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(`/admin/reservas/detalhe/${sales_id}`)}
                  bgColor="transparent"
                >
                  <Icon className="mr-2">
                    arrow_back
                  </Icon>
                  Cancelar
                </Button>
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                {reservations &&
                  <div className="p-4">
                    <RequestTable
                      {...props}
                      dataReservations={reservations}
                      isEditing={isEditing}
                      changeDate={changeDate}
                      changeFlight={changeFlight}
                      changeFlightTime={changeFlightTime}
                      total={reserve.total}
                    />
                  </div>
                }
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <ClientForm
                  setClient={setClient}
                  reserve={reserve}
                  client={client}
                  changeClient={changeClient}
                  changeReserve={changeReserve}
                  changeClientCheckbox={changeClientCheckbox}
                  changeClientByPartner={changeClientByPartner}
                  changeClientAddress={changeClientAddress}
                  changeObservations={changeObservations}
                  isEditing={isEditing}
                  regionsTravel={regionsTravel}
                  onlyLetterAndNumber={onlyLetterAndNumber}
                  changeClientByEmail={changeClientByEmail}
                  changeSetEmail={setEmailValidate}
                />
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <PaymentForm
                  reserve={reserve}
                  changeReserve={changeReserve}
                  changeProof={changeProof}
                  removeProofPayment={removeProofPayment}
                  isEditing={isEditing}
                />
              </div>
              <div className="my-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                <SellerForm
                  {...props}
                  reserve={reserve}
                  setReserve={setReserve}
                  isEditing={isEditing}
                />
              </div>
              <div className="flex justify-end mb-4 space-x-5">
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="260px"
                  onClick={() => editSale()}
                  customcolor={props.theme.color}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
            :
            <div className="px-7 pt-6">
              <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                <span>Edição</span>
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(`/admin/reservas/detalhe/${sales_id}`)}
                  bgColor="transparent"
                >
                  <Icon className="mr-2">
                    arrow_back
                  </Icon>
                  Cancelar
                </Button>
              </div>
              <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                Você não possui permissão para acessar este recurso.
              </div>
            </div>
        }
      </>
    }
  </>
}

export default Update;