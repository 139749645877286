import Table from '@components/Table';
import Button from '@components/Button';
import TimePicker from '@components/TimePicker';
import { apiClient } from '@services/api';
import { useState } from 'react';
import { format, isValid } from 'date-fns';

const permissions = ['administrador', 'logistica'];

const TimeTextField = (params) => {
  const reservation = params.reservation;
  const hourArrar = reservation.time ? reservation.time.split(":") : null
  const [time, setTime] = useState(hourArrar ? new Date().setHours(hourArrar[0], hourArrar[1]) : null);
  const derived = params.derived;

  const updateDerivedTime = (date) => {
    if (isValid(date)) {
      apiClient.httpInstance.put(`/derived/${derived.id}/time`, {
        time: format(date, "HH:mm"),
        reservation_id: derived.reservation_id,
        derived_id: derived.id
      }).then(response => {
        if (response.status === 200) {
          setTime(date)
        }
      });
    }
  }

  return <div className="pt-3">
    <TimePicker
      width="120px"
      value={time}
      onChange={(date) => updateDerivedTime(date)}
    />
  </div>;
}

const TableDerivedReservation = (props) => {
  const rows = useState([]);
  const q = props.q;
  const derived = props.derived;

  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <div className="flex items-center justify-between p-4 font-poppins">
        <div className="flex flex-col">
          <span className="text-xl text-blue">Derivados</span>
          <span className="text-sm text-gray">Última alteração realizada há (tempo)</span>
        </div>
        <div className="items-center">
          {permissions.includes(props?.theme?.permission) && <Button
            textcolor="#212121"
            padding="6px 15px"
            margintop="0px"
            marginleft="15px"
            width="200"
            variant="outlined"
            onClick={props.onClick}
            bgColor="transparent"
          >
            Derivar
          </Button>}
        </div>
      </div>
      <div className="flex justify-center pb-3 text-center">
        <Table
          path={props.path}
          params={{
            q
          }}
          onRowClick={null}
          columns={[
            {
              headerName: 'Clientes',
              headerClassName: 'super-app-theme--header',
              field: 'customer_name',
              flex: 1,
            },
            {
              headerName: 'Cupos',
              headerClassName: 'super-app-theme--header',
              field: 'cupos',
              flex: 1,
            },
            {
              headerName: 'Nome do guia',
              headerClassName: 'super-app-theme--header',
              field: 'guide_name',
              flex: 1,
            },
            {
              headerName: 'Horário',
              headerClassName: 'super-app-theme--header',
              field: 'time',
              headerAlign: 'right',
              align: 'right',
              flex: 1,
              renderCell: (params) => {
                const row = params.row;

                return <TimeTextField
                  derived={row}
                  reservation={row}
                />;
              }
            },
          ]}
          rows={rows}
        />
      </div>
    </div>
  </>
}

export default TableDerivedReservation;
