import { makeStyles } from '@material-ui/core/styles';
import {Card, CardHeader, CardContent, CardActions, Box} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Chart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15
  },
  header: props => ({
    backgroundColor: props.color,
    padding: 7
  }),
  content: {
    paddingBottom: 0
  },
  footer: {
    padding: '8px 16px',
    fontSize: 14
  },
  icon: props => ({
    color: props.color
  })
}));

const DashboardCard = (props) => {
  const classes = useStyles(props);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: props.data.series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props.data.categories
    },
    labels: props.data.series.map((item) => item.label),
    legend: {
      show: false
    },
  };
  const chartSeries = props.typeChart === 'pie' ? props.data.series.map((item) => item.data) : props.data.series


  return <>
      <Card className={classes.root}>
        <CardHeader className={classes.header} />
        <CardContent className={classes.content}>
          <p className="text-lg text-blue">{props.title}</p>
          <p className="text-lg text-blue pt-4">{props.value}</p>
          <div className="flex space-y-3 h-44">
            {props.data.total > 0 &&
              <>
                <Chart
                  height={props.height}
                  options={chartOptions}
                  series={chartSeries}
                  type={props.typeChart}
                />
                {props.typeChart === 'pie' &&
                  <div className="flex flex-col justify-center space-y-2 pb-5 pl-2">
                    {props.data.series.map((item) => (
                      <Box
                        key={item.label}
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: item.color,
                            borderRadius: '50%',
                            height: 8,
                            width: 8
                          }}
                        />
                        <span className="pl-2 text-sm">{item.label}</span>
                      </Box>
                    ))}
                  </div>
                }
              </>
            }
          </div>
        </CardContent>
        <CardActions className={classes.footer}>
          <Icon className={`${classes.icon} mr-2`} fontSize="small">
            {props.icon}
          </Icon>
          {props.textFooter}
        </CardActions>
      </Card>
  </>
}

export default DashboardCard;
