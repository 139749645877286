import { useState, useEffect } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import { apiClient } from '@services/api';
import Icon from '@material-ui/core/Icon';
import { format, isValid } from 'date-fns';
import DatePicker from '@components/DatePicker';
import TextField from '@components/TextField';
import TimePicker from '@components/TimePicker';

const ChangeDateTransfer = (props) => {
  const [date, setDate] = useState(props.date)
  const [dateAvaliable, setDateAvaliable] = useState([])
  const today = format(new Date(), "yyyy-MM-dd");

  const [flight, setFlight] = useState(props.flight_code)
  const [flightTime, setFlightTime] = useState(props.flight_time)

  const addZero = (i) => {
    if (i < 10) {i = "0" + i}
    return i;
  }

  let ref = useRef(null);

  const changeDate = (idx) => {
    props.setViewDate(true)
    props.setDateReservation(idx, new Date(`${date} 00:00:00`))
    props.setFlight(idx, flight)
    props.setFlightTime(idx, addZero(flightTime.getHours()) +':'+addZero(flightTime.getMinutes())+':00')
    props.close()
  }

  const changeDateValue = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setDate(value)
  }

  const getDateAvaliable = (id) => {
    apiClient.httpInstance.get('reservations_dates/available', {
      params: {
        tour_id: id
      }
    }).then(response => {
      if (response.status === 200) {
        setDateAvaliable(response.data);
      }
    }).catch(error => { });
  }

  const disableDates = (date) => {
    let dates = null

    if (dateAvaliable.length > 0) {
      dateAvaliable.map((dateT, index) => {
        if (index === 0) {
          dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        } else {
          dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        }
      })
    } else {
      dates = false;
    }
    return dates
  }

  useEffect(() => {
    changeDateValue(new Date(`${props.date} 00:00:00`))
    getDateAvaliable(props.tourId);
  }, [props.date, props.tourId]);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Alterar data da reserva</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex justify-center mt-4">
        {date &&
          <DatePicker
            label="Data"
            value={date !== null ? `${date} 00:00:00` : null}
            minDate={`${today} 00:00:00`}
            onChange={date => changeDateValue(date)}
          />
        }
      </div>
      <div className="flex justify-center mt-4">
        <TextField
          className="inputRounded"
          label="Código do voo"
          variant="outlined"
          type="text"
          name="codFly"
          defaultValue={flight}
          onChange={(ev) => setFlight(ev.target.value)}
        />
      </div>
      <div className="flex justify-center mt-4">
        <TimePicker
          label="Horário do voo"
          name="timeFly"
          value={flightTime}
          onChange={(ev) => setFlightTime(ev)}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => {changeDate(props.idx)
                          props.verifyFinished()}}
          customcolor={props.theme.color}
        >
          Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeDateTransfer;
