import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import TableVehicleReservation from '../partials/TableVehicleReservation';
import AllocationModal from '../partials/AllocationModal';
import Moment from 'react-moment';

const Show = (props) => {
  document.title = `Detalhes do veículo - ${process.env.REACT_APP_NAME}`
  const { exit_id } = useParams();
  const { vehicle_id } = useParams();
  const [exit, setExit] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const exitUrl = `/exits/${exit_id}`;
  const vehicleUrl = `/exits/${exit_id}/vehicles/${vehicle_id}`;
  const finishUrl = `/reservations/${exit_id}/exit_voucher/vehicle/${vehicle_id}/finish`;

  const types = {
    single: 'Privado',
    group: 'Grupo',
    in: 'In',
    out: 'Out'
  };

  const getExitsDetails = exitUrl => {
    apiClient.httpInstance.get(exitUrl).then(response => {
      if (response.status === 200) {
        setExit(response.data);
        setLoading(false);
      }
    });
  }

  const getVehicleDetails = vehicleUrl => {
    apiClient.httpInstance.get(vehicleUrl).then(response => {
      if (response.status === 200) {
        setVehicle(response.data);
        setLoading(false);
      }
    });
  }

  const getType = (exit) => {
    if (exit.travel_type === "App\\Models\\Tour") {
      return types[exit.type] || null
    } else {
      return types[exit.type]
    }
  }

  const bradecrumbsConfig = [
    {
      title: `Início`,
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `${exit?.travel_name || ''}`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}`
    },
    {
      title: `${vehicle?.nickname}`,
    }
  ]

  const finishVehicle = () => {
    apiClient.httpInstance.put(finishUrl).then(response => {
      if (response.status === 200) {
        setVehicle(response.data);
      }
    });
  }

  useEffect(() => {
    getExitsDetails(exitUrl);
  }, [exitUrl]);

  useEffect(() => {
    getVehicleDetails(vehicleUrl);
  }, [vehicleUrl]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{vehicle.nickname}</span>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-none md:w-8/12 mt-4 mr-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="p-4 text-xl text-blue font-poppins">
              <span>{vehicle.nickname} #{vehicle_id}</span>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Motorista
              </div>
              <div className="flex-1 text-gray">
                {vehicle.driver_name}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Guia
              </div>
              <div className="flex-1 text-gray">
                {vehicle.guide_name}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Total de vagas
              </div>
              <div className="flex-1 text-gray">
                {vehicle.seats} vagas
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Vagas utilizadas
              </div>
              <div className="flex-1 text-gray">
                {vehicle.seats_used} vagas
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Criado por
              </div>
              <div className="flex-1 text-gray">
                {vehicle.user_name}
              </div>
            </div>
          </div>
          <div className="flex-none md:w-4/12 mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="text-lg text-center text-blue font-poppins">
              <img src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${exit.banner}`} width="100%" alt={`${exit?.travel_name}`} className="rounded-t-lg" />
            </div>
            <div className="p-4 text-lg text-center text-blue font-poppins">
              <span>{exit?.travel_name}</span>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Tipo
              </div>
              <div className="flex-1 text-gray">
                {getType(exit)}
              </div>
            </div>
            <div className="flex p-4 border-t border-b border-solid border-lightgray">
              <div className="flex-1">
                Data
              </div>
              <div className="flex-1 text-gray">
                <Moment format="DD/MM/YYYY" date={exit?.tour_date} />
              </div>
            </div>
          </div>
        </div>
        <TableVehicleReservation
          {...props}
          path={`exit_vehicle/${vehicle_id}/reservations`}
          vehicle={vehicle}
          onClick={() => setOpen(true)}
          travelType={exit.travel_type}
          flightTime={exit.flight_time}
          flightCode={exit.flight_code}
          tourDate={exit.tour_date}
          finishVehicle={() => finishVehicle()}
          disabledFinishBtn={!vehicle.finished && vehicle.seats_used > 0 ? false : true}
        />
        <AllocationModal
          theme={props.theme}
          vehicle={vehicle}
          vehicleDetail={() => getVehicleDetails(vehicleUrl)}
          exit={exit}
          open={open}
          close={() => setOpen(false)}
        />
      </div>
    </>
  } else {
    return <>
    </>
  }
}

export default Show;
