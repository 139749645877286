import Button from '@components/Button';
import PaymentForm from '../../partials/form/PaymentForm';

const ContentStep2 = (props) => {
  const idDisabled = () => {
    const reserve = props.reserve
    if (reserve.payment_status === "accomplished" && reserve.payment_type !== null && reserve.payment_method !== null && !props.loading) {
      return false
    } else if (reserve.payment_status === "pending" && !props.loading) {
      return false
    }
    return true
  }

  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <PaymentForm
        reserve={props.reserve}
        changeReserve={props.changeReserve}
        addProof={props.addProof}
        removeProofPayment={props.removeProof}
      />
    </div>
    <div className="flex justify-end mb-4 space-x-5">
      <Button
        textcolor="#212121"
        padding="6px 15px"
        width="100px"
        variant="outlined"
        onClick={() => props.changeStep(1)}
        bgColor="transparent"
      >
        Voltar
      </Button>
      <Button
        disabled={idDisabled()}
        textcolor="#fff"
        padding="6px 15px"
        width="200px"
        onClick={() => props.create()}
        customcolor={props.theme.color}
      >
        Finalizar reserva
      </Button>
    </div>
  </>
}

export default ContentStep2;

