import { useState, useEffect } from 'react';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Table from '@components/Table';
import TextField from '@components/TextField';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import NumberFormat from 'react-number-format';
import ChooseProductModal from './product/ChooseProductModal';
import BreadCrumbs from '@components/Breadcrumbs';
import { apiClient } from '@services/api';
import Select from '@components/Select';
import SelectSearch from 'react-select';

const permissions = ['administrador', 'gerente', 'supervisor', 'operacao'];

const Index = (props) => {
  document.title = `Reservas - ${process.env.REACT_APP_NAME}`

  const history = useHistory();
  const [rows] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [reservationsStats, setReservationsStats] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [q, setQ] = useState(null);
  const [v, setV] = useState('');
  const [open, setOpen] = useState(false);
  const [initial, setInitial] = useState(format(new Date(), "yyyy-MM-dd"));
  const [final, setFinal] = useState(null);
  const [widthType, setWidthType] = useState(112);
  const { urlCompanies } = '/companies';
  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const getSellers = () => {
    apiClient.httpInstance.get(`/users?seller=true&status=true&pageSize=500`).then(response => {
      if (response.status === 200) {
        let options = [{ value: '', label: 'Todos' }]
        response.data.data.map((element) => {
          options.push({ value: element.name, label: element.name })
        })
        setSellers(options)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  const getCompanies = url => {
    apiClient.httpInstance.get(`/companies?pageSize=500`).then(response => {
      if (response.status === 200) {
        let companies = response.data.data.sort(function (a, b) {
          return a.surname < b.surname ? -1 : a.surname > b.surname ? 1 : 0;
        })

        setCompanies(companies);
      }
    })
      .catch(error => {
        if (error) {
          console.log(error)
        }
      });
  }

  const getReservationsStats = () => {
    apiClient.httpInstance.get(`/reservations_stats`).then(response => {
      if (response.status === 200) {
        setReservationsStats(response.data)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  useEffect(() => {
    getSellers();
    getReservationsStats();
    getCompanies(urlCompanies);
  }, []);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const changeDate = (date, name) => {
    name === 'initial' ?
      setInitial(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
      :
      setFinal(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }
  return <>
    {open=='open' && (
      <Alert className="mb-5" variant="filled" severity="success">
        {'successMessage'}
      </Alert>
    )}
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            shopping_cart
          </Icon>
          <span>Reservas</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => setOpen(true)}
          customcolor={props.theme.color}
        >
          <Icon className="mr-2">add</Icon> Nova reserva
        </Button>}
        <ChooseProductModal
          open={open}
          close={() => setOpen(false)}
          changeTourPage={() => history.push('/admin/reservas/passeios')}
          changeTransferPage={() => history.push('/admin/reservas/traslados')}
          changeCombosPage={() => history.push('/admin/reservas/combos')}
          {...props}
        />
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center md:space-x-3">
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <TextField
              className="inputRounded"
              label="Busque por produtos"
              width="250px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(ev) => setQ(ev.target.value)}
            />
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max">
            <Select
              label="Filtrar por empresa"
              defaultValue="all"
              gender="f"
              width="100px"
              onChange={(e) => setCompany(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'surname',
                  key: 'key'
                },
                data: companies
              }}
            ></Select>
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-40 min-w-max" style={{ borderRadius: '20px', border: '1px solid #8080806e', padding: '9px 0px' }}>
            <SelectSearch
              styles={customStyles}
              options={sellers}
              width='250px'
              value={v === '' ? { name: 'Vendedor', label: 'Vendedor' } : { name: v, label: v }}
              onChange={(ev) => setV(ev.value)}
              name="seller"
            />
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <DatePicker
              width="170px"
              label="Data inicial"
              value={initial !== null ? `${initial} 00:00:00` : null}
              onChange={date => changeDate(date, 'initial')}
            />
          </div>
          <div className="flex-none sm:p-2 m-2 md:m-0 sm:w-20 min-w-max">
            <DatePicker
              width="170px"
              label="Data final"
              value={final !== null ? `${final} 00:00:00` : null}
              minDate={`${initial} 00:00:00`}
              onChange={date => changeDate(date, 'final')}
            />
          </div>
        </div>
      </div>
      <Table
        path="/sales"
        params={{
          q, v, start_at: initial, end_at: final, company
        }}
        columns={[
          {
            headerName: 'Referência',
            headerClassName: 'super-app-theme--header',
            field: 'references',
            //flex: 1,
            width: 180,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.status === 'canceled' &&
                  <Icon className="mr-2 text-red-500">block</Icon>
                }
                {row.references}
              </div>);
            }
          },
          {
            headerName: 'Empresa',
            headerClassName: 'super-app-theme--header',
            field: 'company_id',
            flex: 0.5,
            renderCell: (params) => {
              const row = params.row;
              let surname
              companies.forEach(element => {
                if (element.id == row.company_id)
                  return surname = element.surname
              });
              return (<>
                {surname}
              </>);
            }
          },
          {
            headerName: 'Cliente',
            headerClassName: 'super-app-theme--header',
            field: 'customer_name',
            //flex: 1,
            width: 250,
          },
          {
            headerName: 'Tipo de produto',
            headerClassName: 'super-app-theme--header',
            field: 'types',
            width: widthType,
            renderCell: (params) => {
              const row = params.row;
              const typeProduct = {
                tour: {
                  bg: '#D8212FCC',
                  text: 'Passeio'
                },
                translate: {
                  bg: '#4480A8E5',
                  text: 'Traslado'
                },
                'translate-out': {
                  bg: '#4480A8E5',
                  text: 'Out'
                },
                'translate-in': {
                  bg: '#009C86E5',
                  text: 'In'
                }
              }

              let rowCurrent = ""
              {
                reservationsStats.map((item) => {
                  if (row.sale_id === item.sale_id) {
                    rowCurrent = item.total
                  }
                })
              }

              const rawTypes = rowCurrent || ""
              const typesArray = Array.from(new Set(rawTypes.split(", ")))
              if (typesArray.length === 2 && widthType < 240) {
                setWidthType(240)
              } else if (typesArray.length === 3 && widthType < 350) {
                setWidthType(350)
              }
              return <div className="flex items-center pr-4 space-x-5">
                {typesArray.map((type) => {
                  if (type === 'tour' || type === 'translate' || type === 'translate-in' || type === 'translate-out') {
                    return <div className="flex items-center justify-center rounded-full h-8 w-24 text-white" style={{ background: typeProduct[type].bg }}>
                      {typeProduct[type].text}
                    </div>
                  } else {
                    return <>{type}</>
                  }
                })}
                {row.has_combo && <><Icon className="mr-2">card_travel</Icon> Combo</>}
              </div>
            }
          },
          {
            headerName: 'Quantidade',
            headerClassName: 'super-app-theme--header',
            field: 'quantity',
            flex: 0.5,
            //width: 100,
            renderCell: (params) => {
              const row = params.row;
              return <>
                {reservationsStats.map((item) => {
                  if (row.sale_id === item.sale_id) {
                    return <>
                      {item.count}
                    </>
                  }
                })}
              </>
            }
          },
          {
            headerName: 'Valor',
            headerClassName: 'super-app-theme--header',
            field: 'total',
            flex: 0.5,
            //width: 120,
            renderCell: (params) => {
              const row = params.row;
              return <NumberFormat
                value={Number(row.total) || 0}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />;
            }
          },
          {
            headerName: 'Pagamento',
            headerClassName: 'super-app-theme--header',
            field: 'payment_status',
            //flex: 0.5,
            width: 130,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
              const row = params.row;
              const status = {
                accomplished: {
                  text: 'Realizado',
                  icon: 'check_circle'
                },
                pending: {
                  text: 'Pendente',
                  icon: 'do_disturb_on'
                },
                partial: {
                  text: 'Parcial',
                  icon: 'access_time'
                }

              }
              return <span className="flex items-center">
                {row.payment_status &&
                  <><Icon className="ml-2">{status[row.payment_status].icon}</Icon> </>
                }
              </span>;
            }
          },
          {
            headerName: 'Assinatura',
            headerClassName: 'super-app-theme--header',
            field: 'signed_by_customer',
            //flex: 1,
            width: 130,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
              const row = params.row;
              const status = {
                signed: {
                  text: 'Assinado',
                  icon: 'check_circle'
                },
                unsigned: {
                  text: 'Pendente',
                  icon: 'do_disturb_on'
                }

              }
              return <span className="flex items-center">
                {
                  <><Icon className="ml-2">{status[(row.signed_by_customer === true ? 'signed' : 'unsigned')].icon}</Icon> </>
                }
              </span>;
            }
          },
        ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 20,
    display: 'flex',
    fontSize: '14px'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  }
}