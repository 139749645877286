import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const Update = (props) => {
  const { user_id } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({
    name: null,
    status: true,
  })
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let url = `/users/${user_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Lista de usuários',
      active: true,
      route: '/admin/usuarios'
    },
    {
      title: `Detalhes do ${user.name !== undefined ? user.name : ''}`,
      active: true,
      route: `/admin/usuarios/detalhe/${user_id}`
    },
    {
      title: 'Edição da usuários',
    }
  ]

  const getUserById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setUser(response.data);
          setIsEditing(true)
        }
      }
      );
  }

  const changeState = (ev) => {
    let value = ev.target.value;
    setUser(
      {
        ...user,
        [ev.target.name]: value
      }
    )

  }

  const submitUserEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/users/${user_id}`, {
        ...user,
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/usuarios');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getUserById(url);
  }, [url]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {isEditing &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Nova região</span>
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/usuarios/detalhe/${user_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitUserEdit}
            user={user}
            isEditing={isEditing}
            changeState={changeState}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
