import { USER_LOGIN, USER_LOGOUT } from '../actions/actionsTypes'

let userInit = JSON.parse(localStorage.getItem('user')) || {};

export default function auth (state = userInit, action) {
  switch(action.type) {
    case USER_LOGIN:
      let user = action.payload
      //Tempo pra expiração
      let now = new Date()
		  user['expiration'] = now.getTime() + 14400000 //milisegundos -> 4 horas de inatividade

      localStorage.setItem('user', JSON.stringify(user))
      return user;
    case USER_LOGOUT:
      localStorage.setItem('user', JSON.stringify({}));
      return {};
    default:
      return state;
  }
}
