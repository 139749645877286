import { useState } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Create = (props) => {
  document.title = `Cadastrar mensagem - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [message, setMessage] = useState({});
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [exitDate, setExitDate] = useState(null);
  const [customersIds, setCustomersIds] = useState([]);
  const [exitId, setExitId] = useState('');
  const [reservations, setReservations] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/central/mensagens'
    },
    {
      title: 'Nova mensagem',
    }
  ]

  const changeState = (ev) => {
    if (ev.target.name === "template_id") {
      setSelectedVariables([])
    }
    if (ev.target.name === "status" && ev.target.value === "true") {
      setMessage(
        {
          ...message,
          status: true
        }
      )
    } else if (ev.target.name === "status" && ev.target.value === "false") {
      setMessage(
        {
          ...message,
          status: false
        }
      )
    } else {
      setMessage(
        {
          ...message,
          [ev.target.name]: ev.target.value
        }
      )
    }
  }

  const changeValueRadio = (ev) => {
    setInitialDate(null)
    setFinalDate(null)
    setExitDate(null)
    setExitId('')
    setReservations(false)
    setCustomersIds([])
    setMessage(
      {
        ...message,
        type: ev.target.value
      }
    )
  }

  const messagesVariables = {
    "nome_cliente": "customer_name",
    "endereço": "customer_address",
    "consultor": "seller_name",
    "horário_saída": "exit_time",
    "os/referência": "reference",
    "nome_passeio": "tour_name",
    "data_passeio": "tour_date",
    "tipo_transfer": "transfer_type",
    "horário_vôo": "transfer_flight_time",
    "nome_guia": "guide_name",
    "nome_motorista": "driver_name",
    "link_voucher": "link_voucher"
  }

  const submitMessage = (ev) => {
    ev.preventDefault();
    if (initialDate == null && finalDate == null && exitDate == null && customersIds.length == 0) {
      setError(true);
      setErrorMessage("Preencha os campos necessários")
      setTimeout(() => { setError(false) }, 4000);
    } else {
      let variablesValues = []
      selectedVariables.forEach((element, index) => {
        variablesValues.push(messagesVariables[element])
      });
      if (message.type === 'saida') {
        apiClient
          .httpInstance.post("/central/save_message_customers", {
            template_id: message.template_id,
            exit_id: message.exit_id,
            customers_ids: customersIds,
            variables: variablesValues
          })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              setOpenModal(true)
              //history.push('/admin/central/mensagens');
            }
          })
          .catch(error => {
            if (error) {
              setError(true);
              setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
              setTimeout(() => { setError(false) }, 4000);
            }
          });
      }
      if (message.type === 'clientes') {
        apiClient
          .httpInstance.post("/central/save_message_customers", {
            template_id: message.template_id,
            customers_ids: customersIds,
            variables: variablesValues
          })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              setOpenModal(true)
              //history.push('/admin/central/mensagens');
            }
          })
          .catch(error => {
            if (error) {
              setError(true);
              setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
              setTimeout(() => { setError(false) }, 4000);
            }
          });
      }
      if (message.type === 'reservas') {
        apiClient
          .httpInstance.post("/central/save_message_period", {
            template_id: message.template_id,
            from_date: initialDate,
            to_date: finalDate,
            variables: variablesValues,
            reservations: true
          })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              history.push('/admin/central/mensagens');
            }
          })
          .catch(error => {
            if (error) {
              setError(true);
              setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
              setTimeout(() => { setError(false) }, 4000);
            }
          });
      }
    }
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Nova mensagem</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/central/mensagens')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        submit={submitMessage}
        message={message}
        isEditing={false}
        changeState={changeState}
        changeValueRadio={changeValueRadio}
        initialDate={initialDate}
        finalDate={finalDate}
        exitDate={exitDate}
        setExitId={setExitId}
        setReservations={setReservations}
        customersIds={customersIds}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setExitDate={setExitDate}
        setCustomersIds={setCustomersIds}
        selectedVariables={selectedVariables}
        setSelectedVariables={setSelectedVariables}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
