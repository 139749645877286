import Index from './Index';
import Show from './Show';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Drivers = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;
  const permission = props.permission;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} permission={permission} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/:exit_id`}>
        <Show theme={theme} permission={permission}/>
      </Route>
    </Switch>
  </>
}

export default Drivers;
