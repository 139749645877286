import BreadCrumbs from '@components/Breadcrumbs'
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import FormService from './partials/FormService';

const EditCompanyIntegration = (props) => {
  document.title = `Editar serviços de integração - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [service, setService] = useState({
    name: null,
    access_token: null,
    manual_action: false,
    send_feedback_to_client: false,
  })
  const { service_id } = useParams();
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const url = `/company_service/${props.user.data.company_id}/${service_id}`

  const bradecrumbsConfig = [
    {
      title: 'Configurações',
      active: true,
      route: '/admin/configuracoes'
    },
    {
      title: 'Edição',
    }
  ]

  const getCompanyById = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setService(response.data[0]);
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }
  const changeState = (ev) => {
    let value = ev.target.value

    setService(
      {
        ...service,
        [ev.target.name]: value
      }
    )
  }

  const submitService = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/service_integrations/${service.id}`,
        {
          'name': service.name,
          'access_token': service.access_token,
          'manual_action': service.manual_action,
          'send_feedback_to_client': service.send_feedback_to_client,
          'company_id': props.user.data.company_id,
          '_method': 'PUT'
        }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/configuracoes');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response?.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);
        }
      });
  }

  useEffect(() => {
    getCompanyById(url);
  }, [url]);
  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
          </div>
          <FormService
            {...props}
            submit={submitService}
            service={service}
            changeState={changeState}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(EditCompanyIntegration);
