import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DeleteModal from '@components/DeleteModal';

const Show = (props) => {
  const { user_id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/users/${user_id}`

  const getUserDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setUser(response.data);
        setLoading(true)
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: 'Lista de usuários',
      active: true,
      route: '/admin/usuarios'
    },
    {
      title: `Detalhes do ${user.name}`,
    }
  ]

  useEffect(() => {
    getUserDetails(url);
  }, [url]);

  if (loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Detalhes do {user.name}</span>
          <div className="w-100">
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => history.push(`/admin/usuarios/editar/${user_id}`)}
              customcolor={props.theme.color}
            >
              <Icon className="mr-2">
                edit
              </Icon>
              Editar
            </Button>
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="140px"
              onClick={() => setDisableModal(true)}
              bgColor="#F44336"
            >
              <Icon className="mr-2">delete</Icon>
              Desativar
            </Button>
            <DeleteModal
              open={disableModal}
              close={() => setDisableModal(false)}
              url={`/users/${user_id || ''}`}
              title="Excluir Usuário?"
              redirect={'/admin/usuarios'}
              description="Você realmente deseja excluir este usuário? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
            />
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do usuários</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome
            </div>
            <div className="flex-1">
              {user.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nível
            </div>
            <div className="flex-1">
              {user.role}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {user.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {user.phone}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Comissão
            </div>
            <div className="flex-1">
              {user.commission ? 'Sim' : 'Não'}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Acesso
            </div>
            <div className="flex-1">
              {user.status}
            </div>
          </div>
        </div>
      </div>
    </>
  } else {
    return <>
    </>
  }
}

export default Show;
