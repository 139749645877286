import { useState, useEffect } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import EmptyCart from './partials/EmptyCart';
import TableCreate from './partials/TableCart'
import {apiClient} from '@services/api';
import ChooseProductModal from '../reservation/product/ChooseProductModal';
import { useHistory } from "react-router-dom";
import FinishBuy from '../reservation/product/FinishBuy';

const Show = (props) => {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([])
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(1)
  const [travels, setTravels] = useState([])
  const history = useHistory();
  let url = '/user/cart';

  const bradecrumbsConfig = [
    {
      title: 'Carrinho',
    }
  ]

  const getCartProducts = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCart(response.data);
        setLoading(false)
      }
    })
    .catch((error) => console.log(error));
  }

  const finishBuy = () => {
    cart.map((cartValue, idx) => {
      let data = {}
      if(cartValue.type === 'transfer') {
        data = {
          travel_id: cartValue.travel_id,
          type: 'transfer',
          cupos: cartValue.cupos,
          value: cartValue.value,
          value_cost: cartValue.value_cost,
          value_to: cartValue.value_to,
          value_of: cartValue.value_of,
          value_dolars: cartValue.value_dolars,
          value_dolars_cost: cartValue.value_dolars_cost,
          tour_date: cartValue.tour_date,
          discount_total_amount: cartValue.discount_total_amount,
          total_amount: Number(cartValue.total_amount),
          is_group: cartValue.is_group,
          combo_id: cartValue.combo_id,
          customer_email: cartValue.customer_email
        }
      } else {
        data = {
          travel_id: cartValue.travel_id,
          tour_id: cartValue.tour_id,
          type: cartValue.type,
          cupos: cartValue.cupos,
          tour_date: cartValue.tour_date,
          adults_total: cartValue.adults_total,
          adults_total_amount: cartValue.adults_total_amount,
          adults_operational_cost: cartValue.adults_operational_cost,
          adults_discount: cartValue.adults_discount,
          kids_total: cartValue.kids_total,
          kids_total_amount: cartValue.kids_total_amount,
          kids_operational_cost: cartValue.kids_operational_cost,
          kids_discount: cartValue.kids_discount,
          discount_total_amount: cartValue.discount_total_amount,
          total_amount: Number(cartValue.total_amount),
          is_group: cartValue.is_group,
          combo_id: cartValue.combo_id,
          customer_email: cartValue.customer_email
        }
      }
      setTravels(
        oldArray => [...oldArray, data])
      if(cart.length === idx + 1) {
        setStep(3)
      }
    })
  }

  useEffect(() => {
    getCartProducts(url);
  }, [url]);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  if (!loading) {
    return <>
      {step === 1 ?
        <div className="px-7">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
            <span>Carrinho</span>
          </div>
          {cart ? <TableCreate {...props} rows={cart} openModal={setOpen} refresh={getCartProducts} finishBuy={finishBuy} /> : <EmptyCart {...props} openModal={setOpen}/>}
          <ChooseProductModal
            open={open}
            close={() => setOpen(false)}
            changeTourPage={() => history.push('reservas/passeios')}
            changeTransferPage={() => history.push('reservas/traslados')}
            changeCombosPage={() => history.push('reservas/combos')}
            {...props}
          />
        </div>
      :
        <FinishBuy
          {...props}
          type="cart"
          reservationData={{cart, travels}}
          backPage={() => setStep(1)}
        />
      }
    </>
  } else {
    return <>
    </>
  }
}

export default Show;
