import {FormControlLabel, Checkbox} from '@material-ui/core'

let CheckboxComponent = (props) => {
  const color = props.color ?  `text-${props.color}` : 'text-primary'
  return <FormControlLabel
        control={
          <Checkbox
            className={`${props.className} ${props.value ? color : null}`}
            value={props.value}
            checked={props.value}
            onChange={props.onChange}
            name={props.name}
          />
        }
        label={props.label}
      />
}

export default CheckboxComponent;
