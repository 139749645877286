import { createStore, combineReducers } from 'redux';
import userReducer from './reducers/auth';
import userProfile from './reducers/profile';
import showCalendarModalReducer from './reducers/calendarModalState';


const reducers = combineReducers({
  user: userReducer,
  userProfile: userProfile,
  showCalendarModal: showCalendarModalReducer
});

const storeConfig = createStore(reducers);

export default storeConfig;
