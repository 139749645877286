import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import Icon from '@material-ui/core/Icon';
import Select from '@components/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

const ChangeStateModal = (props) => {
  let ref = useRef(null);

  const statusOptions = [
    {
      value: 'pending',
      title: 'Aguardando'
    },
    {
      value: 'sended',
      title: 'Realizado'
    },
    {
      value: 'canceled',
      title: 'Cancelado'
    }
  ]

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Status de pagamento</span>
          <small className="text-gray">Informe no campo abaixo o status da comissão</small>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex mt-4">
        <Select
          withoutAll
          label="Status"
          width="200px"
          value={props.status}
          onChange={(e) => props.setStatus(e.target.value)}
          items={{
            field: {
              value: 'value',
              text: 'title'
            },
            data: statusOptions
          }}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={(ev) => props.onCreate(ev)}
        >
          {props.loading ?
            <div className="flex-1 text-center">
              <CircularProgress size={20} />
            </div>
            :
            'Salvar'
          }
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeStateModal;
