import '@assets/css/Admin/components/breadcrumb.scss'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from "react-router-dom";

const Breadcrumb = ({items}) => {
  const history = useHistory();
  return <div className="px-7">
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {items.map((breadcrumb, index) => (
        <Typography
          key={index}
          className={breadcrumb.active ? 'text-breadcrumb active' : 'text-breadcrumb'}
          onClick={breadcrumb.active ? () => history.push(`${breadcrumb.route}`) : null}
        >
          <span className="font-poppins font-breadcrumb">{breadcrumb.title}</span>
        </Typography>
      ))}
    </Breadcrumbs>
  </div>
}
export default Breadcrumb;
