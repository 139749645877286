import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const Show = (props) => {
  document.title = `Meu perfl - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  if (props.user.data) {
    return <>
      <div className="px-7">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Perfil</span>
          <div className="w-100">
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => history.push('/admin/perfil/editar')}
              customcolor={props.theme.color}
            >
              <Icon className="mr-2">
                edit
              </Icon>
              Editar
            </Button>
          </div>
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{borderRadius: '10px'}}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome
            </div>
            <div className="flex-1">
              {props.user.data.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {props.user.data.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nível de acesso
            </div>
            <div className="flex-1">
              {props.user.data.permission}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Idioma da plataforma
            </div>
            <div className="flex-1">
              {props.user.data.lang}
            </div>
          </div>
        </div>
      </div>
    </>
  } else {
    return <div className="grid w-full h-full justify-items-center">
      <CircularProgress size={50} />
    </div>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Show);
