import '@assets/css/Admin/components/inputFile.scss';
import {Box, Button, Card, CardContent}from '@material-ui/core';
import FileDropzone from './utils/FileDropzone';
import { useState, useEffect } from 'react';

const InputFile = (props) => {
  const [cover, setCover] = useState(null);

  const getBanner = () => {
    if(props.banner) {
      const url = `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.banner}`
      setCover(url)
    }
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleDropCover = async ([file]) => {
    const data = await toBase64(file);
    setCover(data);
    props.changeFile(file)
  };

  const handleRemoveCover = () => {
    setCover(null);
  };

  useEffect(() => {
    getBanner()
  }, [])

  return <>
    <Card variant="outlined">
      <CardContent>
        {cover
          ? (
            <div>
              <Box
                sx={{
                  backgroundImage: `url(${cover})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: 420
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 2
                }}
              >
                <Button
                  color="primary"
                  onClick={handleRemoveCover}
                  variant="text"
                >
                  Mudar imagem
                </Button>
              </Box>
            </div>
          )
          : (
            <FileDropzone
              accept="image/*"
              maxFiles={1}
              onDrop={handleDropCover}
            />
          )}
      </CardContent>
    </Card>
  </>
}

export default InputFile
