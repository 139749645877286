import { useState, useEffect } from 'react';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Table from '@components/Table';
import TextField from '@components/TextField';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import ChooseProductModal from './product/ChooseProductModal';
import BreadCrumbs from '@components/Breadcrumbs';
import { apiClient } from '@services/api';
import SelectSearch from 'react-select';

const Approves = (props) => {
  document.title = `Aprovações - ${process.env.REACT_APP_NAME}`

  const history = useHistory();
  const [rows] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [reservationsStats, setReservationsStats] = useState([]);
  const [sellerSelected, setSellerSelected] = useState('');
  const [q, setQ] = useState(null);
  const [v, setV] = useState('');
  const [open, setOpen] = useState(false)
  const [initial, setInitial] = useState(null)
  const [final, setFinal] = useState(null)
  const [widthType, setWidthType] = useState(112)
  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const getSellers = () => {
    apiClient.httpInstance.get(`/users?seller=true&status=true&pageSize=500`).then(response => {
      if (response.status === 200) {
        let options = [{ value: '', label: 'Todos' }]
        response.data.data.map((element) => {
          options.push({ value: element.name, label: element.name })
        })
        setSellers(options)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  const getReservationsStats = () => {
    apiClient.httpInstance.get(`/reservations_stats`).then(response => {
      if (response.status === 200) {
        setReservationsStats(response.data)
      }
    })
      .catch(error => {
        if (error) {

        }
      });
  }

  useEffect(() => {
    getSellers();
    getReservationsStats()
  }, []);

  const changeDate = (date, name) => {
    name === 'initial' ?
      setInitial(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
      :
      setFinal(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }
  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <div className="flex items-center">
          <Icon className="mr-2">
            pending_actions
          </Icon>
          <span>Aprovações</span>
        </div>
        <ChooseProductModal
          open={open}
          close={() => setOpen(false)}
          changeTourPage={() => history.push('/admin/orcamentos/passeios')}
          changeTransferPage={() => history.push('/admin/orcamentos/traslados')}
          changeCombosPage={() => history.push('/admin/orcamentos/combos')}
          {...props}
        />
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center space-x-3">
          <div className="flex-none">
            <TextField
              className="inputRounded"
              label="Busque por produtos"
              width="280px"
              variant="outlined"
              type="text"
              name="q"
              onChange={(ev) => setQ(ev.target.value)}
            />
          </div>
          <div className="flex-none" style={{ borderRadius: '20px', border: '1px solid #8080806e', padding: '9px 0px' }}>
            <SelectSearch
              styles={customStyles}
              options={sellers}
              width='280px'
              value={v === '' ? { name: 'Vendedor', label: 'Vendedor' } : { name: v, label: v }}
              onChange={(ev) => setV(ev.value)}
              name="seller"
            />
          </div>
        </div>
      </div>
      <Table
        path="/budgets"
        params={{
          q, v, start_at: initial, end_at: final, to_approve: true
        }}
        onRowClick={(props) => {
          const budget = props.row;
          history.push({ pathname: `/admin/orcamentos/detalhe/${budget.id}`, state: { approve: true } });
        }}
        columns={[
          {
            headerName: 'Referência',
            headerClassName: 'super-app-theme--header',
            field: 'references',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="flex items-center pr-4 space-x-5">
                {row.status === 'canceled' &&
                  <Icon className="mr-2 text-red-500">block</Icon>
                }
                {row.references}
              </div>);
            }
          },
          {
            headerName: 'Cliente',
            headerClassName: 'super-app-theme--header',
            field: 'customer_name',
            flex: 1,
          },
          {
            headerName: 'Tipo de produto',
            headerClassName: 'super-app-theme--header',
            field: 'types',
            width: widthType,
            renderCell: (params) => {
              const row = params.row;
              const typeProduct = {
                tour: {
                  bg: '#D8212FCC',
                  text: 'Passeio'
                },
                translate: {
                  bg: '#4480A8E5',
                  text: 'Traslado'
                },
                'translate-out': {
                  bg: '#4480A8E5',
                  text: 'Out'
                },
                'translate-in': {
                  bg: '#009C86E5',
                  text: 'In'
                }
              }

              let rowCurrent = ""
              {
                reservationsStats.map((item) => {
                  if (row.presale_id === item.presale_id) {
                    rowCurrent = item.total
                  }
                })
              }

              const rawTypes = rowCurrent || ""
              const typesArray = Array.from(new Set(rawTypes.split(", ")))
              if (typesArray.length === 2 && widthType < 240) {
                setWidthType(240)
              } else if (typesArray.length === 3 && widthType < 350) {
                setWidthType(350)
              }
              return <div className="flex items-center pr-4 space-x-5">
                {typesArray.map((type) => {
                  if (type === 'tour' || type === 'translate' || type === 'translate-in' || type === 'translate-out') {
                    return <div className="flex items-center justify-center rounded-full h-8 w-24 text-white" style={{ background: typeProduct[type].bg }}>
                      {typeProduct[type].text}
                    </div>
                  } else {
                    return <>{type}</>
                  }
                })}
                {row.has_combo && <><Icon className="mr-2">card_travel</Icon> Combo</>}
              </div>
            }
          },
          {
            headerName: 'Valor',
            headerClassName: 'super-app-theme--header',
            field: 'total',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return <NumberFormat
                value={Number(row.total)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />;
            }
          },
          {
            headerName: 'Vendedor',
            headerClassName: 'super-app-theme--header',
            field: 'user_name',
            flex: 1,
          },
          {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'payment_status',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
              const row = params.row;
              const status = {
                pending: {
                  text: 'Aguardando aprovação',
                  icon: 'access_time'
                },
                refused: {
                  text: 'Negado',
                  icon: 'running_with_errors'
                },
                overdue: {
                  text: 'Vencido',
                  icon: 'running_with_errors'
                },
                open: {
                  text: 'Em aberto',
                  icon: 'access_time'
                },
                approved: {
                  text: 'Aprovado',
                  icon: 'check_circle_outline'
                },
                sold: {
                  text: 'Convertido',
                  icon: 'access_time'
                },
                canceled: {
                  text: 'Cancelado',
                  icon: 'access_time'
                },
              }
              return <span className="flex items-center">
                {row.status_discount_approve &&
                  <><Icon className="ml-2">{status[row.status_discount_approve].icon}</Icon> </>
                }
              </span>;
            }
          },
        ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Approves);


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 20,
    display: 'flex',
    fontSize: '14px'
  }),

  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  }
}