import '@assets/css/Admin/components/datePicker.scss'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from "date-fns/locale/pt-BR"

const DatePickerComp = (props) => {


  return <div style={{ width: props.width || '100%' }}>
  <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...props}
      className="w-full"
      variant="dialog"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  </MuiPickersUtilsProvider>
  </div>
}

export default DatePickerComp;
