import { useState } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Create = (props) => {
  document.title = `Cadastrar template - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [template, setTemplate] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/central/templates'
    },
    {
      title: 'Novo template',
    }
  ]

  const changeState = (ev) => {
    if (ev.target.name === "status" && ev.target.value === "true") {
      setTemplate(
        {
          ...template,
          status: true
        }
      )
    } else if (ev.target.name === "status" && ev.target.value === "false") {
      setTemplate(
        {
          ...template,
          status: false
        }
      )
    } else {
      setTemplate(
        {
          ...template,
          [ev.target.name]: ev.target.value
        }
      )
    }
  }

  const submitTemplate = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/templates", {
        name: template.name,
        utalk_id: template.utalk_id,
        template_type_id: template.template_type_id,
        permissions: JSON.stringify(`${permissions}`)
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/central/templates');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo template</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/central/templates')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        submit={submitTemplate}
        template={template}
        isEditing={false}
        changeState={changeState}
        permissions={permissions}
        setPermissions={setPermissions}
      />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
