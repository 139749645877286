import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import BreadCrumbs from '@components/Breadcrumbs';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import Table from '@components/Table';
import Select from '@components/Select';
import DeleteModal from '@components/DeleteModal';
import EditRegionModal from './partials/EditRegionModal';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const breadCrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/traslados'
  },
  {
    title: `${props.name || ''}`,
  },
]);

const types = [
  {
    key: 'all',
    type: 'Todos',
  },
  {
    key: 'in',
    type: 'in',
  },
  {
    key: 'out',
    type: 'out',
  },
];

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/translados/values/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Show = (props) => {
  document.title = `Detalhes da zona - ${process.env.REACT_APP_NAME}`
  const { transfer_id } = useParams();
  const [rows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteRegionModal, setDeleteRegionModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [type, setType] = useState(null);
  const [transfer, setTransfer] = useState({});
  const path = `translados/${transfer_id}/values`;
  let url = `/translados/${transfer_id}`;

  const getTransferData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTransfer(response.data);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getTransferData(url);
  }, [url]);

  const openDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteId(id);
    setDeleteModal(true);
  }

  return <>
    <BreadCrumbs items={breadCrumbsConfig({ name: transfer.region_name })} />
    {!loading && <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>{transfer.region_name}</span>
        {permissions.includes(props?.theme?.permission) &&
          <div className="w-100">
            <Button
              textcolor="#212121"
              padding="6px 55px"
              margintop="0px"
              marginright="25px"
              width="100px"
              onClick={(e) => { setOpenEditModal(true) }}
              variant="outlined"
              bgColor="transparent"
            >
              <Icon className="mr-2">edit</Icon>
              Editar
            </Button>
            <Link to={`/admin/traslados/${transfer_id}/cadastro`}>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="25px"
                width="190px"
                customcolor={props.theme.color || '#212121'}><Icon className="mr-2">add</Icon> Novo Traslado</Button>
            </Link>
            <Button
              textcolor="#fff"
              padding="6px 15px"
              margintop="0px"
              marginright="20px"
              width="100px"
              onClick={() => setDeleteRegionModal(true)}
              bgColor="#F44336"
            >
              <Icon className="mr-2">delete</Icon>
              Excluir
            </Button>
          </div>
        }
      </div>
      <div className="flex flex-wrap items-center mb-3 mt-14">
        <span className="text-lg text-blue">Lista de traslados</span>
      </div>
      <DeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        url={`translados/values/${deleteId}/delete`}
        title="Excluir traslado?"
        description="Você realmente deseja excluir este traslado? Essa ação é irreversível,
        mas você poderá cadastrá-lo no futuro novamente." />
      <DeleteModal
        open={deleteRegionModal}
        close={() => setDeleteRegionModal(false)}
        url={`translados/${transfer.id || ''}`}
        title="Excluir região?"
        redirect={'/admin/traslados'}
        description="Você realmente deseja excluir este região? Essa ação é irreversível,
        mas você poderá cadastrá-lo no futuro novamente." />
      <EditRegionModal
        region={transfer}
        theme={props.theme}
        open={openEditModal}
        close={() => { setOpenEditModal(false); getTransferData(url) }} />
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div>
          <Select
            label="Tipo de traslado"
            width="300px"
            defaultValue="all"
            onChange={(e) => setType(e.target.value)}
            items={{
              field: {
                value: 'key',
                text: 'type',
              },
              data: types
            }}
          ></Select>
        </div>
      </div>
      <Table
        params={{
          type
        }}
        onRowClick={null}
        path={path}
        columns={[
          {
            headerName: 'Tipo de transfer',
            headerClassName: 'super-app-theme--header',
            field: 'mode_transfer',
            flex: 1,
            valueFormatter: (params) => {
              const row = params.row;
              return `Transfer ${row.mode_transfer}`;
            },
          },
          {
            headerName: 'Valor',
            headerClassName: 'super-app-theme--header',
            field: 'value',
            renderCell: (params) => {
              return (<NumberFormat
                value={Number(params.row.value)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
              />);
            },
            flex: 1,
          },
          {
            headerName: 'Qtde de pessoas',
            field: 'value_to',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            valueFormatter: (params) => {
              const row = params.row;
              return `${row.value_of} - ${row.value_to}`;
            },
          },
          {
            headerName: 'Comissão',
            field: 'commission',
            headerClassName: 'super-app-theme--header',
            flex: 1,
          },
          {
            headerName: 'Status',
            field: 'status',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
              return (
                permissions.includes(props?.theme?.permission) ? <RowSwitch
                  status={params.row.status}
                  id={params.row.id}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                  :
                  <>{params.row.status ? 'Ativo' : 'Inativo'}</>
              );
            },
            flex: 1,
          },
          {
            headerName: 'Ações',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              const editLink = `/admin/traslados/editar/${row.id}`;

              return (
                permissions.includes(props?.theme?.permission) && <div className="flex items-center pr-4 space-x-5">
                  <Link to={editLink} className="flex items-center">
                    <Icon className="text-lightblue">edit</Icon>
                  </Link>
                  <Icon className="text-red-500" onClick={(e) => openDeleteModal(e, row.id)}>delete</Icon>
                </div>);
            }
          },
        ]}
        rows={rows}></Table>
    </div>}
  </>
}

export default Show;
