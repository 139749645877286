import Index from './Index';
import Update from './Update';
import Show from './Show'
import CreateCommisson from './commission/Create'
import UpdateCommission from './commission/Update'
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Regions = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/editar/:region_id`}>
        <Update theme={theme} />
      </Route>
      <Route exact path={`${path}/detalhe/:region_id`}>
        <Show theme={theme} />
      </Route>
      <Route exact path={`${path}/detalhe/:region_id/matriz-de-comissao`}>
        <CreateCommisson theme={theme} />
      </Route>
      <Route exact path={`${path}/detalhe/:region_id/matriz-de-comissao/:commission_id`}>
        <UpdateCommission theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Regions;
