import { useState, useEffect } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import Icon from '@material-ui/core/Icon';
import TextField from '@components/TextField';
import TimePicker from '@components/TimePicker';

const ChangeFlight = (props) => {
  const [flight, setFlight] = useState(props.flight)
  const [flightTime, setFlightTime] = useState(props.flightTime)
  let ref = useRef(null);

  const changeFlight = (idx) => {
    props.setFlight(idx, flight || props.flight)
    props.setFlightTime(idx, flightTime.getYear()+'-'+flightTime.getMonth()+'-'+flightTime.getDay()+' '+addZero(flightTime.getHours()) +':'+addZero(flightTime.getMinutes())+':00')
    props.close()
  }

  const addZero = (i) => {
    if (i < 10) {i = "0" + i}
    return i;
  }

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Alterar dados do voo</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex justify-center mt-4">
        <TextField
          className="inputRounded"
          width="150"
          label="Código do voo"
          variant="outlined"
          type="text"
          name="codFly"
          defaultValue={flight}
          onChange={(ev) => setFlight(ev.target.value)}
        />
      </div>
      <div className="flex justify-center mt-4">
        <TimePicker
          width="200px"
          label="Horário do voo"
          name="timeFly"
          value={flightTime}
          onChange={(ev) => setFlightTime(ev)}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => changeFlight(props.idx)}
          customcolor={props.theme.color}
        >
          Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeFlight;
