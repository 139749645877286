import { useState, useEffect } from 'react';
import CreateVehiclesModal from './partials/CreateVehiclesModal';
import Button from '@components/Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@components/Table';
import Select from '@components/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteModal from '@components/DeleteModal';
import Switch from '@material-ui/core/Switch';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/vehicles/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Lista de veículos - ${process.env.REACT_APP_NAME}`
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [order, setOrder] = useState('asc');
  const [deleteId, setDeleteId] = useState(null);
  const [rows] = useState([]);
  const [update, setUpdate] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [errorsVehicle, setErrorsVehicle] = useState({});
  const [seats, setSeats] = useState(null)

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const openModalCreate = () => {
    setCreateModal(true);
    setSeats("");
  }

  const handleCreate = (ev) => {
    ev.preventDefault();
    const body = {
      seats: seats,
      status: true,
      company_id: props.user.data.company_id
    }
    const request = update ? apiClient.httpInstance.put(`/vehicles/${vehicleId}`, body) : apiClient.httpInstance.post("/vehicles", body)
    request
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setCreateModal(false);
        }
      })
      .catch(error => {
        if (error) {
          setErrorsVehicle(error.response.data || "Erro inesperado, tente novamente mais tarde");
          setTimeout(() => { setErrorsVehicle("") }, 4000);
        }
      })
  };

  const openDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteId(id);
    setDeleteModal(true);
  }

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            directions_bus_filled
          </Icon>
          <span>Lista de veículos</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={openModalCreate}
          customcolor={props.theme.color}><Icon className="mr-2">add</Icon> Novo</Button>}
        <CreateVehiclesModal
          open={createModal}
          close={() => setCreateModal(false)}
          onCreate={handleCreate}
          error={errorsVehicle}
          setSeats={setSeats}
          seats={seats}
          {...props}
          update={update}
        />
      </div>
      <DeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        url={`vehicles/${deleteId}`}
        title="Excluir veículo?"
        description="Você realmente deseja excluir este veículo? Essa ação é irreversível,
        mas você poderá cadastrá-lo no futuro novamente." />
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="flex flex-wrap items-center">
          <Select
            label="Ordernar por"
            width="300px"
            defaultValue="asc"
            onChange={(e) => setOrder(e.target.value)}
            withoutAll={true}
            items={{
              field: {
                value: 'value',
                text: 'text'
              },
              data: [
                {
                  text: 'Crescente (0 - 9)',
                  value: 'asc',
                },
                {
                  text: 'Decrescente (9 - 0)',
                  value: 'desc',
                },
              ]
            }}
          />
        </div>
      </div>
      <Table
        params={{
          order,
        }}
        path="/vehicles"
        onRowClick={null}
        columns={[
          {
            headerName: 'Qtde de passageiros',
            headerClassName: 'super-app-theme--header',
            field: 'seats',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              const onClick = (e, id) => {
                e.preventDefault();
                if(permissions.includes(props?.theme?.permission)) {
                  setSeats(row.seats)
                  setVehicleId(id);
                  setUpdate(true);
                  setCreateModal(true);
                }
              }
              return (<div className="w-full h-full" onClick={(e) => onClick(e, row.id)} >
                {row.seats}
              </div>);
            }
          },
          {
            headerName: 'Ações',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;

              return (<div className="flex items-center pr-4 space-x-5">
                {permissions.includes(props?.theme?.permission) ? <>
                  <RowSwitch
                    status={row.status}
                    id={row.id}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Icon
                    className="text-red-500"
                    onClick={(e) => openDeleteModal(e, row.id)}>delete</Icon>
                </>
                  :
                  <>{row.status ? 'Ativo' : 'Inativo'}</>
                }
              </div>);
            }
          }
        ]}
        rows={rows} />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Index);
