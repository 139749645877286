import { useRef, useState, useEffect } from 'react'
import Icon from '@material-ui/core/Icon';
import { Popover, Box, MenuItem, ListItemText, Typography, TableFooter } from '@material-ui/core';
import Button from '@components/Button'
import { Link, useHistory, useLocation } from "react-router-dom";
import { apiClient } from '@services/api';
import DeleteModal from '@components/DeleteModal';
import NumberFormat from 'react-number-format';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  borderNone: {
    border: 'none'
  }
});

const HeaderDropdown = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [cartItens, setCartItens] = useState([]);
  const [counterItens, setCounterItens] = useState(0);
  const [open, setOpen] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [selectedId, setSelectedId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const anchorRef = useRef(null);
  let url = '/user/cart';

  const classes = useStyles();

  const getCartProducts = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCartItens(response.data)
        let counter = response.data.length
        if (counter && counter > 0) {
          setCounterItens(counter)
          document.getElementById("countItensCart").innerHTML = counter < 10 ? '0' + counter : counter
          document.getElementById("countItensCart").classList.remove("hidden")
        } else {
          document.getElementById("countItensCart").classList.add("hidden")
        }
      }
    })
      .catch((error) => console.log(error));
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenCart = () => {
    setOpenCart(true);
  }

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const handleDelete = (id) => {
    setSelectedId(id)
    setDeleteModal(true)
  }

  useEffect(() => {
    getCartProducts(url)
  }, [location.pathname]);

  const lettersName = (name) => {
    if (name) {
      var arr = name?.split(' ');
      if (arr[1] && (arr[1]?.toLowerCase() === 'de' || arr[1]?.toLowerCase() === 'da' || arr[1]?.toLowerCase() === 'do')) {
        return arr[0][0].toUpperCase() + arr[2][0].toUpperCase()
      } else if (arr[0] && arr[1]) {
        return arr[0][0].toUpperCase() + arr[1][0].toUpperCase()
      } else {
        return arr[0] ? arr[0][0].toUpperCase() : ''
      }
    }
  }

  return <>
    <Box className="flex items-center justify-center mr-4 menu">
      {props.dropdownInfo !== null && <Box className="flex items-center cursor-pointer hidden md:flex lg:flex xl:flex">
        <Box className="flex items-center justify-center mr-4 menu">
          {props.dropdownInfo !== null &&
            <Box
              className="cursor-pointer"
              onClick={props.dropdownInfo !== null ? handleOpenCart : null}
            >
              <Icon>shopping_cart</Icon>
              <span className="avatar items-center hidden" id="countItensCart" style={{ padding: "1px 3px", background: "yellow", color: "darkblue", fontSize: "12px", fontWeight: 600, top: "-20px", left: "-8px", position: "relative" }}></span>
            </Box>
          }
        </Box>
      </Box>
      }
      <Box
        className="flex items-center cursor-pointer hidden md:flex lg:flex xl:flex"
        onClick={props.dropdownInfo !== null ? handleOpen : null}
        ref={anchorRef}
      >
        <div className="flex mx-4 user">
          {props.image ?
            <img className="mr-2 bg-gray avatar" src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${props.image}`} alt={lettersName(props.name)}></img>
            :
            <div className="mr-2 pt-3 bg-gray avatar items-center">{lettersName(props.name)}</div>
          }
          <div className="flex flex-col text-left">
            <span className={props.color === 'black' ? 'text-blue' : ''}>{props.name}</span>
            <small className={props.color === 'black' ? 'text-blue' : ''}>{props.permission}</small>
          </div>
        </div>
        {props.dropdownInfo !== null &&
          <Icon>arrow_drop_down</Icon>
        }
      </Box>
    </Box>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 20,
        vertical: 55
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          'borderRadius': '16px'
        }
      }}
    >
      {props.dropdownInfo !== null &&
        <Box sx={{ mt: 2 }}>
          {props.dropdownInfo.map((opt, index) => {
            if (opt.type === 'list') {
              return (
                <MenuItem key={index} onClick={opt.onClick}>
                  <Icon>{opt.icon}</Icon>
                  <Box sx={{ ml: 2, mr: 5 }}>
                    <ListItemText
                      primary={(
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"

                        >
                          {opt.title}
                        </Typography>
                      )}
                    />
                  </Box>
                </MenuItem>
              )
            } else if (opt.type === 'btn') {
              return (
                <Box key={index} sx={{ p: 2, pt: 0 }}>
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    variant="outlined"
                    onClick={opt.onClick}
                    bgColor="transparent"
                  >
                    {opt.title}
                  </Button>
                </Box>
              )
            } else {
              return (<></>)
            }
          })}
        </Box>
      }
    </Popover>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: -400,
        vertical: 55,
      }}
      keepMounted
      onClose={handleCloseCart}
      open={openCart}
      PaperProps={{
        style: {
          'borderRadius': '16px',
          'width': '400px',
        },
        elevation: 10,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        }
      }}
    >
      <Box>
        <Box className="flex items-center justify-center pt-1 px-1">
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="font-bold leading-5 border-0 text-gray p-1">
                    {counterItens == 1 ? counterItens + ' Produto' : counterItens + ' Protudos'}
                  </TableCell>
                  <TableCell className="border-0">
                  </TableCell>
                  <TableCell align="right" className="px-2 font-bold py-0 border-0 text-gray cursor-pointer" onClick={handleCloseCart}>
                    x
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItens.map((cartValue, index) => (
                  <TableRow key={cartValue.id}>
                    <TableCell className="leading-5 text-blue px-1 py-2" component="th" scope="row">
                      <div className="text-sm">
                        {cartValue.travel_name} {cartValue.transfer_type ? `(${cartValue.transfer_type})` : null}
                      </div>
                    </TableCell>
                    <TableCell className="leading-5 text-blue px-1 py-2" component="th" scope="row" width={90}>
                      <div className="text font-bold">
                        <NumberFormat
                          value={Number(cartValue.total_amount) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right" className="text-gray px-1 py-2" component="th" scope="row">
                      <Icon
                        className="text-sm cursor-pointer"
                        onClick={() => handleDelete(cartValue.id)}
                      >
                        delete
                      </Icon>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className="px-1" sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableFooter>
                <TableRow bgColor={ process.env.REACT_APP_PRIMARY_COLOR }>
                  <TableCell className="text-blue px-1 py-2 text-lg font-medium leading-5 border-0">
                    Total
                  </TableCell>
                  <TableCell align="right" className="text-blue px-1 py-2 leading-5 border-0" width={150}>
                    <div className="text-sm font-bold">
                      <NumberFormat
                        value={Number(cartItens.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Link color="inherit" to={`/admin/carrinho`} onClick={handleCloseCart} className="flex items-center justify-center">
            <Button
              size="small"
              textcolor="#fff"
              padding="6px 15px"
              width="200px"
              customcolor={props.color}
              className="m-0 mb-3"
            >
              Visualizar carrinho
            </Button>
          </Link>
        </Box>
      </Box>
    </Popover >
    <DeleteModal
      open={deleteModal}
      close={() => setDeleteModal(false)}
      url={`/user/cart/products/${selectedId}/delete`}
      title="Excluir item?"
      refresh={() => getCartProducts(url)}
      description="Você realmente deseja excluir este item do carrinho? Essa ação é irreversível,
        mas você poderá adicionar no futuro novamente."
    />
  </>
}


export default HeaderDropdown
