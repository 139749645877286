import Button from '@components/Button';
import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import ContentStep1 from './steps/ContentStep1';
import ContentStep2 from './steps/ContentStep2';
import Alert from '@material-ui/lab/Alert';
import FinishBuy from '../FinishBuy';

const BuyTransfer = (props) => {
  document.title = `Comprar traslados - ${process.env.REACT_APP_NAME}`
  const location = useLocation()
  const quantityList = location.state?.cupos !== undefined ? location.state?.cupos : 0
  const { transfer_id } = useParams();
  const [transfer, setTransfer] = useState({});
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false);
  const [reservationTransfer, setReservationTransfer] = useState({
    name: null,
    date: null,
    typeTransfer: 'Transfer In',
    timeFly: null,
    codFly: null,
    quantity: 0,
    discount: 0,
    total: 0,
    totalQuantity: 0,
  })
  const [transferValues, setTransferValues] = useState([])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const history = useHistory();
  const url = `/translados/${transfer_id}`

  const getTrasladosById = (url) => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTransfer(response.data);
        setReservationTransfer({
          ...reservationTransfer,
          name: response.data.region_name,
          banner: response.data.banner
        })
        setLoading(true)
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: 'Traslados',
      active: true,
      route: '/admin/orcamentos/traslados'
    },
    {
      title: `Comprar`,
    }
  ]

  const setQuantities = (value, math, idx) => {
    let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
    setReservationTransfer({
      ...reservationTransfer,
      quantity: valueQuantity,
    })
  }

  const changeDate = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setReservationTransfer({
      ...reservationTransfer,
      date: value
    })
  }

  const changeHour = (date) => {
    setReservationTransfer({
      ...reservationTransfer,
      timeFly: date
    })
  }

  const changeValue = (ev) => {
    const value = ev.target.value
    setReservationTransfer({
      ...reservationTransfer,
      [ev.target.name]: value
    })
  }

  const changeStep = () => {
    const type = reservationTransfer.typeTransfer === 'Transfer In' ? 'in' : 'out'
    apiClient.httpInstance.get(`${url}/values?type=${type}`).then(response => {
      if (response.status === 200 && response.data.data.length > 0) {
        const idx = response.data.data.length - 1
        const quantity = quantityList > response.data.data[idx].value_to ? response.data.data[idx].value_to : quantityList
        const totalQuantity = response.data.data.find((transfer, idx) => {
          if (response.data.data.length - 1 !== idx) {
            return Math.abs(parseInt(transfer.value_to) - parseInt(response.data.data[idx + 1].value_of)) > 1
          } else {
            return transfer.value_to
          }
        })
        setReservationTransfer({
          ...reservationTransfer,
          quantity: quantity === 0 ? 1 : quantity,
          totalQuantity: totalQuantity.value_to
        })
        setTransferValues(response.data.data)
        setStep(2)
      } else {
        setError(true);
        setErrorMessage("Este transaldo não contem esse tipo de Transfer")
        setReservationTransfer({
          ...reservationTransfer,
          totalQuantity: 0
        })
        setTimeout(() => {
          setError(false)
          setErrorMessage(null)
        }, 4000);
      }
    });
  }

  const setFindTransfer = (transfer, quantity) => {
    if (quantity >= transfer.value_of && quantity <= transfer.value_to) {
      return true
    }
    return false
  }

  const addCart = (type) => {
    const result = transferValues.find(transfer => setFindTransfer(transfer, reservationTransfer.quantity))
    const dicountTotal = (Number(reservationTransfer.total) * (Number(reservationTransfer.discount) / 100))
    const transferType = reservationTransfer.typeTransfer === 'Transfer In' ? 'in' : 'out'
    let flight_time = isValid(reservationTransfer.timeFly) ? format(reservationTransfer.timeFly, "hh:mm a") : null
    if (!flight_time) {
      setError(true);
      setErrorMessage("Erro inesperado, tente novamente mais tarde")
      setTimeout(() => { setError(false) }, 4000);
    } else {
      apiClient
        .httpInstance.post("/user/cart", {
          travel_id: result.translate_id,
          travel_type: 'transfer',
          transfer_type: transferType,
          cupos: reservationTransfer.quantity,
          value: result.value,
          value_cost: result.value_cost,
          value_to: result.value_to,
          value_of: result.value_of,
          value_dolars: result.value_dolars,
          value_dolars_cost: result.value_dolars_cost,
          tour_date: reservationTransfer.date,
          discount_total_amount: dicountTotal,
          total_amount: reservationTransfer.total - (dicountTotal),
          flight_code: reservationTransfer.codFly,
          flight_time: flight_time,
          is_group: false,
          is_budget: true
        })
        .then(response => {
          //Change counter Cart
          let url = '/user/cart';
          apiClient.httpInstance.get(url).then(response => {
            if (response.status === 200) {
              let counter = response.data.length
              if (counter && counter > 0) {
                document.getElementById("countItensCart").innerHTML = counter < 10 ? '0' + counter : counter
                document.getElementById("countItensCart").classList.remove("hidden")
              } else {
                document.getElementById("countItensCart").classList.add("hidden")
              }
            }
          })
            .catch((error) => console.log(error));

          if (response.status === 200 || response.status === 201) {
            if (type === 'cart') {
              history.push('/admin/orcamentos/traslados');
            } else {
              setStep(3)
            }
          }
        })
        .catch(error => {
          if (error) {
            setError(true);
            setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
            setTimeout(() => { setError(false) }, 4000);
          }
        });
    }
  }

  const setDisabled = (props) => {
    return transferValues.some(obj => obj.quantity === 0)
  }

  const setDefaultValues = () => {
    let date = new Date
    date.setDate(date.getDate() + 5)
    changeDate(date)
    changeHour(date)
  }

  useEffect(() => {
    getTrasladosById(url);
  }, [url]);

  useEffect(() => {
    setDefaultValues()
  }, [transfer]);

  if (loading) {
    return <>
    {console.log('OHOIGG',step)}
      {step !== 3 ?
        <>
          <BreadCrumbs items={bradecrumbsConfig} />
          {error && (
            <Alert className="mb-5" variant="filled" severity="error">
              {errorMessage}
            </Alert>
          )}
          <div className="px-7 pt-6">
            <div className="flex items-center justify-between mt-2 text-2xl text-blue">
              <span>Comprar</span>
            </div>
            <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
              <div className="p-4 text-xl text-blue font-poppins flex items-center">
                <div className="flex-1">
                  <div className="font-medium text-base">
                    {transfer.region_name}
                  </div>
                </div>
                <div className="flex-1 text-right">
                  <DatePicker
                    label="Data"
                    disabled={step === 2 ? true : false}
                    value={reservationTransfer.date !== null ? `${reservationTransfer.date} 00:00:00` : null}
                    onChange={date => changeDate(date)}
                  />
                </div>
              </div>
              {step === 1 ?
                <ContentStep1
                  {...props}
                  reservationTransfer={reservationTransfer}
                  changeValue={changeValue}
                  changeHour={changeHour}
                />
                :
                <ContentStep2
                  {...props}
                  setQuantities={setQuantities}
                  transferValues={transferValues}
                  reservationTransfer={reservationTransfer}
                  changeValue={changeValue}
                />
              }
            </div>
            {step === 1 ?
              <div className="flex space-x-5 justify-end">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="100px"
                  variant="outlined"
                  onClick={() => history.push('/admin/orcamentos/traslados')}
                  bgColor="transparent"
                >
                  Voltar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="200px"
                  disabled={reservationTransfer.date !== null ? false : true}
                  onClick={() => changeStep()}
                  customcolor={props.theme.color}
                >
                  Próximo passo
                </Button>
              </div>
              :
              <>
                <div className="flex space-x-5 py-3 justify-end">
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    width="260px"
                    disabled={setDisabled()}
                    variant="outlined"
                    onClick={() => addCart('cart')}
                    bgColor="transparent"
                  >
                    Adicionar orçamento ao carrinho
                  </Button>
                </div>
                <div className="flex space-x-5 pb-3 justify-end">
                  <Button
                    textcolor="#fff"
                    padding="6px 15px"
                    width="200px"
                    disabled={setDisabled()}
                    onClick={() => addCart('finish')}
                    customcolor={props.theme.color}
                  >
                    Finalizar orçamento
                  </Button>
                </div>
              </>
            }
          </div>
        </>
        :
        <>
          <FinishBuy
            {...props}
            type="transfer"
            backPage={() => setStep(2)}
          />
        </>
      }
    </>
  } else {
    return <>
    </>
  }

}

export default BuyTransfer;
