import Button from '@components/Button';
import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import { format, isValid } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import ContentStep1 from './steps/ContentStep1';
import ContentStep2 from './steps/ContentStep2';
import FinishBuy from '../FinishBuy';

const BuyCombo = (props) => {
  document.title = `Comprar combos - ${process.env.REACT_APP_NAME}`
  const location = useLocation()
  const quantityList = location.state?.cupos !== undefined ? location.state.cupos : 0
  const { combo_id } = useParams();
  const [combo, setCombo] = useState({});
  const [tours, setTours] = useState([])
  const [dateAvailable, setDateAvailable] = useState({})
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const history = useHistory();
  const url = `/combos/${combo_id}`

  const getComboById = (url) => {
    apiClient.httpInstance.get(url).then(async response => {
      if (response.status === 200) {
        setCombo(response.data);
        setLoading(true)
        const tours = response.data.tours.map(tour => {
          return {
            ...tour,
            typeTour: 'Em grupo',
            date: null,
            quantityAdult: 0,
            discountAdult: 0,
            totalAdult: 0,
            quantityKid: 0,
            discountKid: 0,
            totalKid: 0,
          }
        })
        const availableDates = await Promise.all(response.data.tours.map(async (tour) => {
          const dates = await getDateUnvaliable(`reservations_dates/available?tour_id=${tour.id}`)
          return { id: tour.id, dates }
        }))
        setDateAvailable(availableDates.reduce((acc, dates) => ({ ...acc, [dates.id]: dates.dates }), {}))
        setTours(tours)
      }
    });
  }

  const getDateUnvaliable = (url) => {
    return apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        return response.data
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: 'Combos',
      active: true,
      route: '/admin/orcamentos/combos'
    },
    {
      title: `Comprar`,
    }
  ]

  const changeDate = (index, date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    let newArr = [...tours]
    newArr[index].date = value
    setTours(newArr)
  }

  const changeValue = (index, ev) => {
    const value = ev.target.value
    const name = ev.target.name
    let newArr = [...tours]
    newArr[index][name] = value
    setTours(newArr)
  }

  const setQuantities = (index, value, type, math) => {
    let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
    let totalName = null
    let totalValue = 0

    if (type === 'quantityAdult') {
      totalName = 'totalAdult'
      totalValue = (Number(tours[index].price) * Number(valueQuantity))
    } else {
      totalName = 'totalKid'
      totalValue = (Number(tours[index].kids_price) * Number(valueQuantity))
    }

    let newArr = [...tours]
    newArr[index][type] = valueQuantity
    newArr[index][totalName] = totalValue
    setTours(newArr)
  }

  const isDateAvailable = (date, id) => {
    let dates = null

    if (dateAvailable[id].length > 0) {
      dateAvailable[id].map((dateT, index) => {
        if (index === 0) {
          dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        } else {
          dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
        }
      })
    } else {
      dates = false;
    }
    return dates
  }

  const addCart = (type) => {
    tours.map((tour, idx) => {
      const subTotal = Number(tour.totalAdult) + Number(tour.totalKid)
      const valueAdult = (Number(tour.totalAdult) * (Number(combo.discount) / 100))
      const valueKid = (Number(tour.totalKid) * (Number(combo.discount) / 100))
      const dicountTotal = (valueAdult + valueKid)

      apiClient
        .httpInstance.post("/user/cart", {
          travel_id: tour.id,
          travel_type: 'combo',
          tour_date: tour.date,
          operational_cost: tour.partner_seller_id ? tour.operational_cost : tour.operational_cost_sale,
          adults_total: tour.quantityAdult,
          adults_total_amount: tour.totalAdult,
          adults_operational_cost: tour.partner_seller_id ? tour.operational_cost : tour.operational_cost_sale,
          adults_discount: combo.discount,
          kids_total: tour.quantityKid,
          kids_total_amount: tour.totalKid,
          kids_operational_cost: tour.partner_seller_id ? tour.kid_operational_cost : tour.kid_operational_cost_sale,
          kids_discount: combo.discount,
          discount_total_amount: dicountTotal,
          //value: reservationTour.value,
          //value_cost: reservationTour.value_cost,
          //value_to: reservationTour.value_to,
          //value_of: reservationTour.value_of,
          //value_dolars: reservationTour.value_dolars,
          total_amount: subTotal - (valueAdult + valueKid),
          cupos: tour.quantityAdult + tour.quantityKid,
          is_group: tour.typeTour === "Em grupo" ? true : false,
          combo_id: combo.id,
          is_budget: true
        })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            //Change counter Cart
            let url = '/user/cart';
            apiClient.httpInstance.get(url).then(response => {
              if (response.status === 200) {
                let counter = response.data.length
                if (counter && counter > 0) {
                  document.getElementById("countItensCart").innerHTML = counter < 10 ? '0' + counter : counter
                  document.getElementById("countItensCart").classList.remove("hidden")
                } else {
                  document.getElementById("countItensCart").classList.add("hidden")
                }
              }
            })
              .catch((error) => console.log(error));

            if (tours.length === idx + 1) {
              if (type === 'cart') {
                history.push('/admin/orcamentos/combos');
              } else {
                setStep(3)
              }
            }
          }
        })
        .catch(error => {
          if (error) {
            setError(true);
            setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
            setTimeout(() => { setError(false) }, 4000);
          }
        });
    })
  }

  const getCupos = (tour) => {
    return apiClient.httpInstance.get(`tours/${tour.id}`, {
      params: {
        date: tour.date
      }
    }).then(response => {
      if (response.status === 200) {
        const latestTour = {
          ...tour,
          availables_cupos: response.data.availables_cupos,
          cupos: response.data.cupos
        }
        return latestTour
      }
    });
  }

  const changeStepAndAddQuantity = () => {
    tours.map(async (tour, idx) => {
      const newTour = await getCupos(tour)
      let newArr = [...tours]
      newArr[idx].availables_cupos = newTour.availables_cupos
      newArr[idx].cupos = newTour.cupos
      setTours(newArr)
      const quantity = Math.min(Number(quantityList), newTour.availables_cupos)
      setQuantities(idx, Number(quantity) - 1, 'quantityAdult', 'add')
    })
    if (tours.some(obj => obj.availables_cupos > 0)) {
      setStep(2)
    } else {
      setError(true);
      setErrorMessage("Existem passeios com data indisponível")
      setTimeout(() => {
        setError(false)
        setErrorMessage(null)
      }, 4000);
    }
  }

  const setDisabledDate = () => {
    return tours.some(obj => obj.date === null)
  }

  const setDisabledQuantity = () => {
    return tours.some(obj => obj.quantityAdult === 0)
  }

  useEffect(() => {
    getComboById(url);
  }, [url]);

  if (loading) {
    return <>
      {step !== 3 ?
        <>
          {error && (
            <Alert className="mb-5" variant="filled" severity="error">
              {errorMessage}
            </Alert>
          )}
          <BreadCrumbs items={bradecrumbsConfig} />
          <div className="px-7 pt-6">
            <div className="flex items-center justify-between mt-2 text-2xl text-blue">
              <span>Comprar</span>
            </div>
            <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
              <div className="p-4 text-xl text-blue font-poppins flex items-center">
                <div className="flex-none">
                  <img src={combo.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${combo.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
                </div>
                <div className="flex-auto space-y-1">
                  {combo.name}
                </div>
              </div>
              {step === 1 ?
                <ContentStep1
                  tours={tours}
                  changeDate={changeDate}
                  changeValue={changeValue}
                  disableDates={isDateAvailable}
                />
                :
                <ContentStep2
                  tours={tours}
                  setQuantities={setQuantities}
                  discount={combo.discount}
                />
              }
            </div>
            {step === 1 ?
              <div className="flex space-x-5 pb-4 justify-end">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="100px"
                  variant="outlined"
                  onClick={() => history.push('/admin/orcamentos/combos')}
                  bgColor="transparent"
                >
                  Voltar
                </Button>
                <Button
                  textcolor="#fff"
                  padding="6px 15px"
                  width="200px"
                  disabled={setDisabledDate()}
                  onClick={() => changeStepAndAddQuantity()}
                >
                  Próximo passo
                </Button>
              </div>
              :
              <>
                <div className="flex space-x-5 py-3 justify-end">
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    width="260px"
                    variant="outlined"
                    disabled={setDisabledQuantity()}
                    onClick={() => addCart('cart')}
                    bgColor="transparent"
                  >
                    Adicionar orçamento ao carrinho
                  </Button>
                </div>
                <div className="flex space-x-5 pb-3 justify-end">
                  <Button
                    textcolor="#fff"
                    padding="6px 15px"
                    width="200px"
                    disabled={setDisabledQuantity()}
                    onClick={() => addCart('finish')}
                  >
                    Finalizar orçamento
                  </Button>
                </div>
              </>
            }
          </div>
        </>
        :
        <FinishBuy {...props} type="combo" backPage={() => setStep(2)} />
      }
    </>
  } else {
    return <>
    </>
  }
}

export default BuyCombo;
