import { useState, useEffect } from 'react';
import PhoneMaskInput from '@components/PhoneMaskInput';
import TextField from '@components/TextField';
import Select from '@components/Select';
import Checkbox from '@components/Checkbox';
import { apiClient } from '@services/api';

const ClientForm = (props) => {
  const [partnerType, setTypePartner] = useState('')
  const [partners, setPartners] = useState([])
  const [copyAddress, setCopyAddress] = useState(false)
  const [showCopy, setShowCopy] = useState(false)
  const [addressToCopy, setAddressToCopy] = useState([])
  let [regions, setRegions] = useState([]);
  const getRegionData = () => {
    apiClient.httpInstance.get('/regions').then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        setRegions(regions);
      }
    });
  }

  const setValuePartner = (type, ev) => {
    setTypePartner(type)
    getPartner(ev)
  }
  const getPartners = () => {
    apiClient
      .httpInstance.get('/agencies_sellers').then(response => {
        if (response.status === 200) {
          const partners = response.data.filter(obj => obj.email !== null && obj.email !== "").map(obj => ({
            ...obj,
            nameSelect: `${obj.name ? obj.name : obj.email}`
          }))
          setPartners(partners);
        }
      });
  }

  const getPartner = (ev) => {
    let email = ev.target.value
    const partner = partners.find((obj) => obj.email === email)
    const url = partner.type === 'agency' ? `/agencies/${partner.id}` : `/partner_sellers/${partner.id}`
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        if (partner.type === 'seller' || partner.type === 'agency' || partner.type === 'partner') {
          const data = {
            partner: response.data.id,
            partnerEmail: response.data.email,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          props.changeClientByPartner(data)
        } else if (props.client.email && props.client.email !== props.client.partnerEmail) {
          const data = {
            partner: response.data.id,
            partnerEmail: response.data.email,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          props.changeClientByPartner(data)
        } else {
          const data = {
            addresses: response.data.address,
            email: response.data.email,
            name: props.client.name,
            phone: props.client.phone,
            document: props.client.document,
            customer_id: null,
            observations: props.client.observations,
            hotel_name: props.client.hotel_name,
            hotel_address: props.client.hotel_address,
            customer_language: null,
            room_number: props.client.room_number,
            partner: response.data.id,
            partnerEmail: response.data.email,
            havePartner: props.client.havePartner,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          setCopyAddress(false)
          setAddressToCopy([])
          props.changeClientByEmail(data)
          
        }
      }
    });
  }

  /*Função para validar campo de email*/
  const validateEmail = (ev) => {

    let email = ev.target.value
    let usuario = email.substring(0, email.indexOf("@"));
    let dominio = email.substring(email.indexOf("@") + 1, email.length);

    if ((usuario.length >= 1) && (dominio.length >= 3) && (usuario.search("@") == -1) && (dominio.search("@") == -1) &&
      (usuario.search(" ") == -1) && (dominio.search(" ") == -1) && (dominio.search(".") != -1) && (dominio.indexOf(".") >= 1) &&
      (dominio.lastIndexOf(".") < dominio.length - 1)) {

      document.getElementById("msgemail").innerHTML = "";

      props.changeSetEmail(true);

    } else {

      props.changeSetEmail(false);

      document.getElementById("msgemail").innerHTML = "<font color='red'>E-mail inválido </font>";

    }

  }

  const changeCopyAddress = (ev) => {
    setCopyAddress(!copyAddress)
    if (!copyAddress) {
      addressToCopy.forEach(address => {
        if (address.region_name === 'SANTIAGO' && address.hotel !== '' && address.hotel !== undefined && address.hotel !== null) {
          let idx = props.client?.addresses.findIndex(address => address.region_name === 'TRASLADO')
          props.changeClientAddress({ target: { name: 'hotel', value: address.hotel, index: idx, region_id: address.region_id } })
          props.changeClientAddress({ target: { name: 'hotel_address', value: address.hotel_address, index: idx } })
          props.changeClientAddress({ target: { name: 'room_number', value: address.room_number, index: idx } })
        }
        if (address.region_name === 'TRASLADO' && address.hotel !== '' && address.hotel !== undefined && address.hotel !== null) {
          let idx = props.client?.addresses.findIndex(address => address.region_name === 'SANTIAGO')
          props.changeClientAddress({ target: { name: 'hotel', value: address.hotel, index: idx, region_id: address.region_id } })
          props.changeClientAddress({ target: { name: 'hotel_address', value: address.hotel_address, index: idx } })
          props.changeClientAddress({ target: { name: 'room_number', value: address.room_number, index: idx } })
        }
      })
    }
  }

  const getClientByEmail = (ev) => {
    const value = ev.target.value
    props.changeClient(ev)
    if (value.length && value.length > 0) {
      apiClient
        .httpInstance.get(`/customer_by_email?email=${value}`)
        .then(response => {
          if (response.status === 200) {
            //Pega nome da região
            regions.forEach((region, index) => {
              let existAddress = null
              if (response.data?.address) {
                existAddress = response.data.address.find(address => address.region_id === region.id)
              } else {
                response.data['address'] = []
              }

              if (!existAddress) {
                response.data.address.push({ "region_id": region.id, "region_name": region.name })
              } else {
                let reg = response.data.address.findIndex(({ region_id }) => region_id === region.id)
                response.data.address[reg].region_name = region.name
              }
            });

            let transfer = props.regionsTravel.find(reg => reg === 'transfer')
            if (transfer === 'transfer') {
              let existAddress = null
              if (response.data?.address) {
                existAddress = response.data.address.find(address => address.region_id === null)
              } else {
                response.data['address'] = []
              }

              if (!existAddress) {
                response.data.address.push({ "region_id": null, "region_name": 'TRASLADO' })
              }
            }

            const data = {
              addresses: response.data.address,
              email: response.data.email,
              name: response.data.name,
              phone: response.data.phone,
              document: response.data.document,
              customer_id: response.data.id,
              //observations: response.data.observations,
              hotel_name: response.data.hotel,
              hotel_address: response.data.hotel_address,
              room_number: response.data.room_number,
              customer_language: response.data.language,
              partner: props.client.partner,
              partnerEmail: props.client.partnerEmail,
              havePartner: props.client.havePartner
            }
            props.changeClientByEmail(data)

            let t, p = false
            let adds = []
            response.data?.address?.forEach((element, index) => {
              let add
              if (element.region_name === 'TRASLADO' && props.regionsTravel.includes('transfer')) {
                add = addressToCopy.find(address => address.region_id === element.region_id && !element.hotel)
                if (!add) {
                  adds.push(element)
                }
                t = true
              }
              if (element.region_name === 'SANTIAGO' && (props.regionsTravel.includes(String(80)) || (props.regionsTravel.includes(80)))) {
                add = addressToCopy.find(address => address.region_id === element.region_id && !element.hotel)
                if (!add) {
                  adds.push(element)
                }
                p = true
              }
              if (t && p) {
                setShowCopy(true)
              }
            })
            setAddressToCopy(adds)

          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    let t, p = false
    let adds = []
    props.client?.addresses?.forEach((element, index) => {
      let add
      if (element.region_name === 'TRASLADO' && props.regionsTravel.includes('transfer')) {
        add = addressToCopy.find(address => address.region_id === element.region_id && !element.hotel)
        if (!add) {
          adds.push(element)
        }
        t = true
      }
      if (element.region_name === 'SANTIAGO' && (props.regionsTravel.includes(String(80)) || (props.regionsTravel.includes(80)))) {
        add = addressToCopy.find(address => address.region_id === element.region_id && !element.hotel)
        if (!add) {
          adds.push(element)
        }
        p = true
      }
      if (t && p) {
        setShowCopy(true)
      }
    })
    setAddressToCopy(adds)
  }, [props.client, props.clientEmail])

  useEffect(() => {
    getPartners()
    getRegionData()
    if (props.clientEmail && props.clientEmail != undefined) {
      getClientByEmail({ target: { value: props.clientEmail } })
      props.changeSetEmail(true);
    }
  }, [])

  return <>
    <div className="p-4 text-lg border-b border-solid text-blue font-poppins border-lightgray">
      <span>Cadastro</span>
    </div>
    <div className="px-4 py-2 border-b border-solid font-poppins border-lightgray">
      <p className="pb-4 font-bold">Informações do cliente</p>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3 md:col-span-2 order-3 md:order-none">
          <TextField
            className="inputRounded"
            label="E-mail"
            variant="outlined"
            type="email"
            name="email"
            value={props.client.email}
            onChange={(ev) => getClientByEmail(ev)}
            onBlur={(ev) => validateEmail(ev)}
            required
            InputLabelProps={{
              shrink: props.client.email ? true : false,
              ...{}
            }}
          />
          <small id="msgemail"></small>
        </div>
        <div className="col-span-3 md:col-span-1 order-2 md:order-none">
          <TextField
            className="inputRounded"
            label="Documento de identificação"
            variant="outlined"
            type="text"
            name="document"
            value={props.client.document}
            onChange={(ev) => props.onlyLetterAndNumber(ev)}
            required
            InputLabelProps={{
              shrink: props.client.document ? true : false,
              ...{}
            }}
          />
        </div>
        <div className="col-span-3 md:col-span-2 order-1 md:order-none">
          <TextField
            className="inputRounded"
            label="Nome completo"
            variant="outlined"
            type="text"
            name="name"
            value={props.client.name}
            onChange={(ev) => props.changeClient(ev)}
            required
            InputLabelProps={{
              shrink: props.client.name ? true : false,
              ...{}
            }}
          />
        </div>
        <div className="col-span-3 md:col-span-1 order-4 md:order-none">
          <PhoneMaskInput
            name="phone"
            inputClass="inputRounded"
            specialLabel="Telefone *"
            required={true}
            masks={{ cl: '. .... ....' }}
            value={props.client.phone}
            onChange={(valueNoFormated, maskInfo, target) => props.changeClient({ target: { name: 'phone', value: target.target.value } })}
          />
        </div>
      </div>
    </div>
    <div className="px-4 py-2 border-b border-solid font-poppins border-lightgray">
      <p className="pb-4 font-bold">Informações da hospedagem</p>
      {showCopy && <div className="col-span-2">
        <Checkbox
          label="Copiar endereço (Santiago ↔ Traslado)"
          name="copyAddress"
          value={copyAddress}
          onChange={(ev) => changeCopyAddress(ev)}
        />
      </div>}
      {props.client?.addresses?.map((element, index) => {
        let regionName = null
        let regionId = null
        //Pode verificar endereço aqui para cópia
        return <>{(element.region_name === 'TRASLADO') && (props.regionsTravel.includes('transfer')) && <>
          <h5 className="pb-4">Endereço - {element.region_name}</h5>
          <div className="grid grid-cols-3 gap-4 pb-4">
            <div className="col-span-3 md:col-span-2">
              <TextField
                className="inputRounded"
                label="Nome do hotel ou hospedagem"
                variant="outlined"
                type="text"
                name="hotel"
                value={element.hotel}
                onChange={(ev) => props.changeClientAddress({ target: { name: 'hotel', value: ev.target.value, index: index, region_id: regionId, region_name: regionName } })}
                required
                InputLabelProps={{
                  shrink: element.hotel ? true : false,
                  ...{}
                }}
              />
            </div>
            <div className="col-span-1" />
            <div className="col-span-3 md:col-span-2">
              <TextField
                className="inputRounded"
                label="Endereço"
                variant="outlined"
                type="text"
                name="hotel_address"
                value={element.hotel_address}
                onChange={(ev) => props.changeClientAddress({ target: { name: 'hotel_address', value: ev.target.value, index: index, } })}
                required
                InputLabelProps={{
                  shrink: element.hotel_address ? true : false,
                  ...{}
                }}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <TextField
                className="inputRounded"
                label="Número do quarto"
                variant="outlined"
                type="text"
                name="room_number"
                value={element.room_number}
                onChange={(ev) => props.changeClientAddress({ target: { name: 'room_number', value: ev.target.value, index: index, } })}
                InputLabelProps={{
                  shrink: element.room_number ? true : false,
                  ...{}
                }}
              />
            </div>
          </div>
        </>}
          {element.region_name !== 'TRASLADO' && (props.regionsTravel.includes(String(element.region_id)) || (props.regionsTravel.includes(element.region_id))) && <><h5 className="pb-4">Endereço - {element.region_name}</h5>
            <div className="grid grid-cols-3 gap-4 pb-4">
              <div className="col-span-3 md:col-span-2">
                <TextField
                  className="inputRounded"
                  label="Nome do hotel ou hospedagem"
                  variant="outlined"
                  type="text"
                  name="hotel"
                  value={element.hotel}
                  onChange={(ev) => props.changeClientAddress({ target: { name: 'hotel', value: ev.target.value, index: index, region_id: regionId, region_name: regionName } })}
                  required
                  InputLabelProps={{
                    shrink: element.hotel ? true : false,
                    ...{}
                  }}
                />
              </div>
              <div className="col-span-1" />
              <div className="col-span-3 md:col-span-2">
                <TextField
                  className="inputRounded"
                  label="Endereço"
                  variant="outlined"
                  type="text"
                  name="hotel_address"
                  value={element.hotel_address}
                  onChange={(ev) => props.changeClientAddress({ target: { name: 'hotel_address', value: ev.target.value, index: index, } })}
                  required
                  InputLabelProps={{
                    shrink: element.hotel_address ? true : false,
                    ...{}
                  }}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <TextField
                  className="inputRounded"
                  label="Número do quarto"
                  variant="outlined"
                  type="text"
                  name="room_number"
                  value={element.room_number}
                  onChange={(ev) => props.changeClientAddress({ target: { name: 'room_number', value: ev.target.value, index: index, } })}
                  InputLabelProps={{
                    shrink: element.room_number ? true : false,
                    ...{}
                  }}
                />
              </div>
            </div>
          </>}
        </>
      })}
    </div>

    {!props.isEditing &&
      <div className="px-4 py-2 border-b border-solid font-poppins border-lightgray">
        <p className="pb-4 font-bold">Informações de parceiros de vendas</p>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1">
            <Select
              withoutAll
              disabled={!props.client.havePartner}
              label="Vendedor"
              name="partner"
              value={props.client.partner_type !== 'agency' ? props.client.partnerEmail : ''}
              onChange={(ev) => getPartner(ev)}
              items={{
                field: {
                  value: 'email',
                  text: 'nameSelect'
                },
                data: partners.filter(function (element) {
                  if (element.type === "seller")
                    return element;
                })
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <Select
              withoutAll
              disabled={!props.client.havePartner}
              label="Agência"
              name="partner"
              value={props.client.partner_type === 'agency' ? props.client.partnerEmail : ''}
              onChange={(ev) => getPartner(ev)}
              items={{
                field: {
                  value: 'email',
                  text: 'nameSelect'
                },
                data: partners.filter(function (element) {
                  if (element.type === "agency")
                    return element;
                })
              }}
            />
          </div>


          <div className="col-span-2">
            <Checkbox
              label="Não existe um parceiro de venda para essa reserva"
              name="havePartner"
              value={!props.client.havePartner}
              onChange={(ev) => props.changeClientCheckbox(ev)}
            />
          </div>
        </div>
      </div>
    }

    {props.isEditing &&
      <div className="px-4 py-2 border-b border-solid font-poppins border-lightgray">
        <p className="pb-4 font-bold">Informações de parceiros de vendas</p>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Select
              withoutAll
              disabled={!props.client.havePartner}
              label="Vendedor"
              name="partner"
              value={partnerType === 'seller' ? props.client.partnerEmail : props.reserve.partner_type === 'seller' ? props.reserve.partner_email : ''}
              onChange={(ev) => setValuePartner('seller', ev)}
              items={{
                field: {
                  value: 'email',
                  text: 'nameSelect'
                },
                data: partners.filter(function (element) {
                  if (element.type === "seller")
                    return element;
                })
              }}
            />
          </div>
          <div className="col-span-1">
            <Select
              withoutAll
              disabled={!props.client.havePartner}
              label="Agência"
              name="partner"
              value={partnerType === 'agency' ? props.client.partnerEmail : props.reserve.partner_type === 'agency' ? props.reserve.partner_email : ''}
              onChange={(ev) => setValuePartner('agency', ev)}
              items={{
                field: {
                  value: 'email',
                  text: 'nameSelect'
                },
                data: partners.filter(function (element) {
                  if (element.type === "agency")
                    return element;
                })
              }}
            />
          </div>
          <div className="col-span-2">
            <Checkbox
              label="Não existe um parceiro de venda para essa reserva"
              name="havePartner"
              value={!props.client.havePartner}
              onChange={(ev) => props.changeClientCheckbox(ev)}
            />
          </div>
        </div>
      </div>
    }

    <div className="px-4 py-2 font-poppins">
      <p className="pb-4 font-bold">Informações adicionais</p>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <Select
            withoutAll
            label="Idioma"
            name="customer_language"
            items={{
              field: {
                value: 'value',
                text: 'name'
              },
              data: [
                {
                  value: 'pt',
                  name: 'Português'
                },
                {
                  value: 'en',
                  name: 'Inglês'
                },
                {
                  value: 'es',
                  name: 'Espanhol'
                },
              ]
            }}
            value={props.client.customer_language}
            onChange={(ev) => props.changeClient(ev)}
            shrink={true}
          // shrink={props.client.customer_language ? true : false}
          />
        </div>
        <div className="col-span-1" />
        <div className="col-span-3">
          <TextField
            className="inputRounded"
            label="Observações do cliente"
            variant="outlined"
            type="text"
            name="observations"
            value={props.client.observations}
            onChange={(ev) => props.changeObservations(ev)}
            InputLabelProps={{
              shrink: props.client.observations ? true : false,
              ...{}
            }}
          />
        </div>
      </div>
    </div>
  </>
}

export default ClientForm;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 20,
    display: 'flex',
    fontSize: '14px'
  }),

  container: (provided, state) => {
    const left = '-6px';
    return {
      ...provided,
      left,
    };
  },

  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  },

  valueContainer: (provided, state) => {
    const right = '190px';
    return {
      ...provided,
      right,
    };
  },

  indicatorSeparator: (provided, state) => {
    const display = 'none';
    return {
      ...provided,
      display,
    };
  }
}

const SearchStyle1 = {
  display: 'flex',
  alignItems: 'center',
  width: '580px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10005',
}
const SearchStyle2 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10004'
}
const SearchStyle3 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10003'
}

const LabelStyle = {
  transform: 'translate(6px, -6px) scale(0.75)',
  top: '-22px',
  left: '0',
  position: 'relative',
  transformOrigin: 'top left',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '0px 4px',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1',
  letterSpacing: '0.00938em',
  background: '#fff',
  zIndex: '10005',
}

const SpanSelect = {
  marginLeft: '-250px',
}