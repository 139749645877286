import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

const CustomSelect = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: "40px",
    width: props.width || "100%"
  }),
}))(MaterialSelect);

const Select = (props) => {

  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={props.name} className="bg-white" shrink={props.shrink}>{props.label}</InputLabel>
      <CustomSelect
        labelId={props.name}
        style={{
          borderRadius: "20px",
        }}
        {...props}
      >
        {!(props?.items?.data[0]?.key === 'all') && (!props.withoutAll) && <MenuItem key="all" value="all">
          <em>{props?.gender === 'f' ? 'Todas' : 'Todos'}</em>
        </MenuItem>}
        {props?.items?.data.map((item, index) =>
          <MenuItem
            value={item[props?.items.field.value]}
            key={index}>
              {item[props?.items.field.text]}
          </MenuItem>
        )}
      </CustomSelect>
    </FormControl>
  );
}
export default Select;
