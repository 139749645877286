import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import TextField from '@components/TextField';
import Alert from '@material-ui/lab/Alert';

const DeleteTransferModal = (props) => {
  let ref = useRef(null);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Quantidade de vagas</span>
          <small className="text-gray">Informe no campo abaixo a quantidade de vagas do veículo.</small>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex flex-col mt-4">
        {props.error.message && (
          <Alert className="mb-5" variant="filled" severity="error">
            {props.error.message}
          </Alert>
        )}
        <TextField
          className="inputRounded"
          label="Qtde de vagas"
          width="300px"
          variant="outlined"
          type="number"
          value={props.seats}
          onChange={(e) => props.setSeats(e.target.value)}
        />
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={(ev) => props.onCreate(ev)}
          customcolor={props.theme.color}
        >
            Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default DeleteTransferModal;
