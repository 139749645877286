import TextField from '@components/TextField';
import Button from '@components/Button';
import Select from '@components/Select';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { apiClient } from '@services/api';
import TimePicker from '@components/TimePicker';
import Icon from '@material-ui/core/Icon';

const Form = (props) => {
  const [agencyOptions, setAgencyOptions] = useState([])
  const history = useHistory();

  const getAgencies = () => {
    apiClient
    .httpInstance.get('/agencies?array=true')
    .then(response => {
      if (response.status === 200) {
        setAgencyOptions(response.data);
      }
    });
  }

  useEffect(() => {
    getAgencies()
  }, [])

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <Select
              withoutAll
              label="Agência parceira"
              value={props.derived.agency_id}
              name="agency_id"
              width="500px"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: agencyOptions
              }}
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome do guia"
              variant="outlined"
              type="text"
              name="guide_name"
              value={props.derived.guide_name}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Placa do veículo"
              variant="outlined"
              type="text"
              name="license_plate"
              value={props.derived.license_plate}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-1">
            <TimePicker
              width="120px"
              placeholder="Horário"
              value={props.derived.schedule}
              onChange={(date) => props.changeDate(date)}
            />
          </div>
        </div>
      </div>
      <div className="space-x-3 text-right">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push(`/admin/saidas/detalhe/${props.exitId}`)}
          bgColor="transparent"
        >
          Cancelar
        </Button>
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
          </Button>
      </div>
    </form>
  </>
}

export default Form
