import Show from './Show';
import Update from './Update';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Drivers = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Show theme={theme} />
      </Route>
      <Route exact path={`${path}/editar`}>
        <Update theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Drivers;
