import { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import {apiClient} from '@services/api';
import Moment from 'react-moment';
import Button from '@components/Button';
import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import '@assets/css/Admin/components/table.scss';

const TableBodyData = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const [tours, setTours] = useState([])
  const [totalSeats, setTotalSeats] = useState(0)
  const url = `/combos/`

  const getComboById = (url) => {
    apiClient.httpInstance.get(`${url}${props.combo.id}`).then(response => {
      if (response.status === 200) {
        setTotalSeats(response.data.totalSeats)
        setTours(response.data.tours);
      }
    });
  }

  useEffect(() => {
    getComboById(url)
  }, [url])
  return (
    <>
      <TableRow key={props.combo.id}>
        <TableCell className="py-2.5">
          {props.combo.name}
        </TableCell>
        <TableCell className="py-2.5">
        <div className="flex items-center pr-4 space-x-5">
          <Moment format="DD/MM/YYYY" date={props.combo.next_travel} />
        </div>
        </TableCell>
        <TableCell className="items-center flex justify-end py-2.5">
          <Button
            textcolor="#212121"
            padding="6px 15px"
            margintop="0px"
            width="200"
            variant="outlined"
            onClick={() => history.push(`combos/${props.combo.id}/comprar`, {cupos: props.cupos})}
            bgColor="transparent"
          >
            Comprar
          </Button>
          <Icon
            className="ml-2 cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {open === true ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </Icon>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={`py-0 ${!open ? 'border-0' : ''}`} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="flex py-4">
              <div className="flex-1 font-semibold">
                Passeios
              </div>
              <div className="flex-1 font-semibold text-right">
                Vagas
              </div>
            </div>
            {tours.map((tour) => (
              <div className="flex py-4 items-center">
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="flex-none">
                      <img
                        src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null}
                        width="50px"
                        className="rounded-full"
                        alt=""
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <span>{tour.name}</span>
                    </div>
                  </div>
                </div>
                <div className={`flex-1 text-right ${tour.availables_cupos === totalSeats ? 'text-red-500' : ''}`}>
                  {tour.availables_cupos}/{totalSeats}
                </div>
              </div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ComboTable = (props) => {
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  let params = props.params

  const handleChangePage = (event, newPage) => {
    getCombos(props.path, newPage, pageSize, params)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    getCombos(props.path, page, event.target.value, params)
  };

  const getCombos = (path, page, pageSize, params) => {
    apiClient.httpInstance.get(path, {
      params: {
        ...params,
        page: page + 1,
        pageSize: pageSize
      }
    }).then(response => {
      if (response.status === 200) {
        setResult(response.data);
        setLoading(false)
      }
    });
  }

  useEffect(() => {
    getCombos(props.path, page, pageSize, params);
  }, [props.path, page, pageSize, params]);
  return <>
    <Paper style={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell className="font-bold bg-white">
                  Combos
                </TableCell>
                <TableCell className="font-bold bg-white">
                  Próxima data
                </TableCell>
                <TableCell className="bg-white"/>
            </TableRow>
          </TableHead>
          <TableBody>
          {!loading &&
            <>
              {result.data.map((obj) => (
                <TableBodyData result={result} combo={obj} />
              ))}
            </>
          }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={result.total}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  </>
}

export default ComboTable;
