import TextField from '@components/TextField';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import Select from '@components/Select'
import PhoneMaskInput from '@components/PhoneMaskInput';

const Form = (props) => {
  const radioButtonData = [
    {
      value: 'Liberado',
      label: 'Liberado'
    },
    {
      value: 'Bloqueado',
      label: "Bloqueado"
    }
  ]

  const roleTypeOptions = [
    {
      value: 1,
      name: 'Administrador'
    },
    {
      value: 2,
      name: 'Gerente'
    },
    {
      value: 3,
      name: 'Supervisor'
    },
    {
      value: 4,
      name: 'Operação'
    },
    {
      value: 5,
      name: 'Parceiro'
    },
    {
      value: 6,
      name: 'Logística'
    },
  ]

  const commissionOptions = [
    {
      value: true,
      name: 'Sim'
    },
    {
      value: false,
      name: 'Não'
    }
  ]


  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome"
              variant="outlined"
              type="text"
              name="name"
              defaultValue={props.user.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              defaultValue={props.user.email}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <PhoneMaskInput
              name="phone"
              value={props.user.phone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({target: {name: 'phone', value: target.target.value}})}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-none">
            <Select
              withoutAll
              label="Nível"
              width="200px"
              value={props.user.role}
              name="role"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'value',
                  text: 'name'
                },
                data: roleTypeOptions
              }}
              />
          </div>
          <div className="flex-none">
            <Select
              withoutAll
              label="Recebe comissão"
              width="200px"
              value={props.user.commission}
              name="commission"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'value',
                  text: 'name'
                },
                data: commissionOptions
              }}
              />
          </div>
        </div>
          <span className="text-xl text-black">Acesso</span>
          <div className="flex mb-4 space-x-3">
            <RadioButton
              {...props}
              data={radioButtonData}
              name="status"
              change={(ev) => props.changeState(ev)}
              value={props.user.status}
            />
          </div>
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form>
  </>
}

export default Form;
