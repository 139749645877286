import { useState } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';

const Create = (props) => {
  document.title = `Cadastro de vendedores parceiros - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [partnerSeller, setPartnerSeller] = useState({
    name: null,
    email: null,
    telephone: null,
    commission: null,
    partner_commission: false,
    status: true,
  })
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/parceiros/vendedores'
    },
    {
      title: 'Novo vendedor parceiro',
    }
  ]

  const changeState = (ev) => {
    let value = ev.target.value;
    if (ev.target.name === "partner_commission") {
      value = ev.target.value === "true" ? true : false;
    }
    setPartnerSeller(
      {
        ...partnerSeller,
        [ev.target.name]: value
      }
    )
  }

  const submitPartnerSeller = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.post("/partner_sellers", {
        ...partnerSeller,
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/parceiros/vendedores');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo vendedor parceiro</span>
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/parceiros/vendedores')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
      </div>
      <Form
        {...props}
        submit={submitPartnerSeller}
        partnerSeller={partnerSeller}
        isEditing={false}
        changeState={changeState}
      />
    </div>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
