import { useState, useEffect} from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/lab/TabPanel';
import CompanyProfile from './partials/CompanyProfile';
import ConditionsTerms from './partials/ConditionsTerms';
import ServiceIntegrationsTable from './partials/ServiceIntegrationsTable';
import {apiClient} from '@services/api';
import { connect } from 'react-redux';
import ChooseButton from './partials/ChooseButton';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    textTransform: 'none'
  },
  indicator: {
    backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`
  }
});


const Index = (props) => {
  document.title = `Gerenciar as configurações - ${process.env.REACT_APP_NAME}`
  const [value, setValue] = useState('1');
  const [company, setCompany] = useState(null)
  const [terms, setTerms] = useState({
    pt: false,
    termsPt: null,
    en: false,
    termsEn: null,
    es: false,
    termsEs: null
  })
  const [lang, setLang] = useState(null)
  const [typeTerm, setTypeTerm] = useState('sale')
  const [loading, setLoading] = useState(true)
  const url = `company/${process.env.REACT_APP_COMPANY_KEY}`
  const [urlTerms, setUrlTerms] = useState(`/company_terms?type=${typeTerm}`)

  const classes = useStyles();

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCompany = (url) => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCompany(response.data);
        setLoading(false)
      }
    })
    .catch((error) => console.log(error));
  }

  const getConditionsTerms = (urlTerms) => {
    apiClient.httpInstance.get(urlTerms).then(response => {
      if (response.status === 200) {
        let data = {}
        if(response.data) {
          data = {
            ...terms,
            pt: response.data.pt.description ? true : false,
            ptTerms: {terms1: response.data.pt.description, terms2: response.data.pt.description2, terms3: response.data.pt.description3},
            en: response.data.en.description ? true : false,
            enTerms: {terms1: response.data.en.description, terms2: response.data.en.description2, terms3: response.data.en.description3},
            es: response.data.es.description ? true : false,
            esTerms: {terms1: response.data.es.description, terms2: response.data.es.description2, terms3: response.data.pt.description3}
          }
        }
        setTerms(data)
        setLoading(false)
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    getCompany(url);
    getConditionsTerms(urlTerms);
  }, [url, urlTerms]);

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);
  
  return <>
    {!loading &&
      <div className="px-7">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
          <span>Configurações</span>
          <ChooseButton {...props} screen={value} terms={terms} lang={lang} typeTerm={typeTerm}/>
        </div>
        <div className={classes.root}>
          <TabContext value={value}>
            <AppBar position="static" color="default" className={classes.appBar}>
              <Tabs
                value={value}
                classes={{
                  indicator: classes.indicator
                }}
                textcolor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab
                  className="normal-case font-normal text-lg text-blue font-poppins"
                  label="Perfil da empresa"
                  value="1"
                  indicatorColor="primary"
                />
                <Tab
                  className="normal-case font-normal text-lg text-blue font-poppins"
                  label="Termos e condições"
                  value="2"
                />
                <Tab
                  className="normal-case font-normal text-lg text-blue font-poppins"
                  label="Integrações"
                  value="3"
                />
              </Tabs>
            </AppBar>
            <TabPanel value="1">
              <CompanyProfile company={company} />
            </TabPanel>
            <TabPanel value="2">
              <ConditionsTerms
                terms={terms}
                setLang={setLang}
                setUrlTerms={setUrlTerms}
                setTypeTerm={setTypeTerm}
                lang={lang}
              />
            </TabPanel>
            <TabPanel value="3">
              <ServiceIntegrationsTable />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Index);


