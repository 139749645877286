import Button from '@components/Button';
import TextField from '@components/TextField';
import Table from '@components/Table';
import CreateRegionModal from './partials/CreateRegionModal';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { apiClient } from '@services/api';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbs from '@components/Breadcrumbs';

const permissions = ['administrador'];

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/translados/${params.id}/status`, {
      status: !status,
    })
    .then((response) => {
      setLoading(false);
      setStatus(response.data);
    }).catch(error => {
      setLoading(false);
    })
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Lista de translados - ${process.env.REACT_APP_NAME}`
  let [rows] = useState([]);
  let [q, setQ] = useState("");
  const [open, setOpen] = useState(false);

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            flight
          </Icon>
          <span>Lista de zonas</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <Button
          textcolor="#fff"
          padding="6px 15px"
          margintop="0px"
          width="200"
          onClick={() => setOpen(true)}
          customcolor={props.theme.color}><Icon className="mr-2">add</Icon> Novo</Button>}
        <CreateRegionModal
          theme={props.theme}
          open={open}
          close={() => setOpen(false)} />
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="flex flex-wrap items-center">
          <TextField
            className="inputRounded"
            placeholder="Busque por uma região"
            variant="outlined"
            type="text"
            name="q"
            onChange={(e) => setQ(e.target.value)}
            required></TextField>
        </div>
      </div>
      <Table
        params={{
          q
        }}
        path="/translados"
        columns={[
            {
              headerName: 'Zona',
              headerClassName: 'super-app-theme--header',
              field: 'region_name',
              width: 560,
            },
            {
              headerName: 'Status',
              headerClassName: 'super-app-theme--header',
              field: 'status',
              headerAlign: 'right',
              align: 'right',
              flex: 0.5,
              preventRedirect: true,
              renderCell: (params) => {
                const row = params.row;

                return (
                  <div className="flex items-center pr-4 space-x-5">
                    {permissions.includes(props?.theme?.permission) ?
                    <RowSwitch
                      status={row.status}
                      id={row.id}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    :
                    <>{row.status ? 'Ativo' : 'Inativo'}</>
              }
                  </div>
                );
              }
            }
          ]}
        rows={rows}></Table>
    </div>
  </>
}

export default Index;
