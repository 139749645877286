import { useParams } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import BreadCrumbs from '@components/Breadcrumbs';
import CardNoVehicles from './partials/CardNoVehicles';
import TableVehicles from './partials/TableVehicles';
import TableDeriveds from './partials/TableDeriveds';
import DeleteModal from '@components/DeleteModal'
import Moment from 'react-moment';

const permissions = ['administrador', 'logistica'];

const Show = (props) => {
  document.title = `Detalhes da saída - ${process.env.REACT_APP_NAME}`
  const { exit_id } = useParams();
  const [exit, setExit] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const history = useHistory();
  const types = {
    single: 'Privado',
    group: 'Grupo',
    in: 'In',
    out: 'Out'
  };

  const getExitsDetails = () => {
    apiClient.httpInstance.get(`/exits/${exit_id}`).then(response => {
      if (response.status === 200) {
        setExit(response.data);
        setLoading(true)
      }
    });
  }

  const cancelExit = () => {
    apiClient.httpInstance.put(`/exits/${exit_id}/cancel`).then(response => {
      if (response.status === 200) {
        history.push(`/admin/saidas`)
      }
    });
  }
  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `${exit.travel_name}`,
    }
  ]

  const getType = (exit) => {
    if (exit.travel_type === "App\\Models\\Tour") {
      return types[exit.type] || null
    } else {
      return types[exit.type]
    }
  }

  useEffect(() => {
    getExitsDetails();
  }, []);

  if (loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span style={{ fontSize: 'calc(10px + 2vmin)' }}>{exit.travel_name} {exit.travel_type === "App\\Models\\Translate" ? '- ' + getType(exit) : ''}</span>
          {permissions.includes(props?.theme?.permission) && <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            marginright="20px"
            width="140px"
            onClick={() => setDisableModal(true)}
            bgColor="#F44336"
          >
            <Icon className="mr-2">delete</Icon>
            Cancelar
          </Button>}
          <DeleteModal
            open={disableModal}
            close={() => setDisableModal(false)}
            delete={() => cancelExit()}
            title="Cancelar saída?"
            description="Você quer mesmo cancelar essa saída? Essa ação é irreversível."
          />
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Saída #{exit.id}</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Data da saída
            </div>
            <div className="flex-1 text-gray">
              <Moment format="DD/MM/YYYY" date={exit.tour_date} />
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Local
            </div>
            <div className="flex-1 text-gray">
              {exit.travel_name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Tipo
            </div>
            <div className="flex-1 text-gray">
              {getType(exit)}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Pendente de alocação
            </div>
            <div className="flex-1 text-gray">
              {exit.to_allocate !== null ? exit.to_allocate : 0}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Observações
            </div>
            <div className="flex-1 text-gray">

            </div>
          </div>
        </div>
        {
          exit.total_vehicle > 0
            ? <>
              <TableVehicles
                {...props}
                exit={exit}
                path={`/exits/${exit_id}/vehicles`}
                onClick={() => history.push(`/admin/saidas/detalhe/${exit_id}/veiculos/cadastro`)}
              />
              <div className="flex mb-6 space-x-3">
                <span className="flex items-center space-x-3">
                  <div className="bg-green-500 rounded-full font-reponsive" style={{ width: '10px', height: '10px' }}></div> <span>Vagas ocupadas</span>
                </span>
                <span class="flex items-center space-x-3">
                  <div className="rounded-full bg-admin-default font-reponsive" style={{ width: '10px', height: '10px' }}></div> <span>Vagas disponibilizadas</span>
                </span>
              </div>
              <TableDeriveds
                {...props}
                exit={exit}
                path={`/exits/${exit_id}/deriveds`}
                onClick={() => history.push(`/admin/saidas/detalhe/${exit_id}/derivados/cadastro`)}
              />
            </>
            : <CardNoVehicles
              {...props}
              onClick={() => history.push(`/admin/saidas/detalhe/${exit_id}/veiculos/cadastro`)}
            />
        }
      </div>
    </>
  } else {
    return <>
    </>
  }
}

export default Show;