import '@assets/css/Admin/components/timePicker.scss'
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const TimePickerComponent = (props) => {
  return <div style={{ width: props.width || '100%' }}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        {...props}
        ampm={false}
        className="w-full"
        mask="HH:mm"
        variant="dialog"
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
  </div>
}

export default TimePickerComponent
