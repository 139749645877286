import { useState, useEffect } from 'react';
import TextField from '@components/TextField';
import Icon from '@material-ui/core/Icon';
import ChangeSeller from '../ChangeSeller';

const SellerForm = (props) => {

  const [changeSeller, setChangeSeller] = useState(false)

  return <>
    <div className="px-4 py-2 font-poppins border-b border-solid border-lightgray">
      <p className="pb-4 font-bold">Informações do vendedor</p>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3 md:col-span-1">
          <TextField
            disabled
            className="inputRounded"
            label="Nome do vendedor"
            variant="outlined"
            type="text"
            name="seller_name"
            defaultValue=" "
            value={props.reserve.seller_name}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            disabled
            className="inputRounded"
            label="E-mail"
            variant="outlined"
            type="text"
            name="seller_email"
            defaultValue=" "
            value={props.reserve.seller_email}
          />
        </div>
        <div className="col-span-1" />
        <div className="col-span-2 items-center flex cursor-pointer" onClick={() => setChangeSeller(true)}>
          <Icon className="mr-2">
            cached
          </Icon>
          <span className="text-gray">Alterar vendedor</span>
        </div>
        <ChangeSeller
          open={changeSeller}
          close={() => setChangeSeller(false)}
          {...props}
        />
      </div>
    </div>
  </>
}

export default SellerForm;
