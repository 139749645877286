import TextField from '@components/TextField';
import DatePicker from '@components/DatePicker';
import Icon from '@material-ui/core/Icon';
import { useState } from 'react';
import { format, isValid } from 'date-fns';
import BreadCrumbs from '@components/Breadcrumbs';
import ComboTable from '../../partials/ComboTable';

const ToursTable = (props) => {
  document.title = `Lista de combos - ${process.env.REACT_APP_NAME}`
  const [q, setQ] = useState(null)
  const [cupos, setCupos] = useState(0)
  const [from_date, setFromDate] = useState(null)
  const [to_date, setToDate] = useState(null)

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: 'Combos',
    }
  ];

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Combos</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{borderRadius: '10px 10px 0px 0px'}}>
        <div className="grid grid-cols-5 gap-4 items-center">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por um combo"
              variant="outlined"
              type="text"
              name="q"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <DatePicker
              width="170px"
              label="Data inícial"
              value={from_date !== null ? `${from_date} 00:00:00` : null}
              onChange={date => changeFromDate(date)}
            />
          </div>
          <div>
            <DatePicker
              width="170px"
              label="Data final"
              value={to_date !== null ? `${to_date} 00:00:00` : null}
              minDate={`${from_date} 00:00:00`}
              onChange={date => changeToDate(date)}
            />
          </div>
          <div className="flex col-start-4 col-end-6 justify-end items-center">
            {cupos > 0 &&
              <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) - 1)}>remove</Icon>
            }
            <TextField
              className="inputRounded mx-4"
              placeholder="Vagas"
              width="100px"
              variant="outlined"
              type="number"
              name="cupos"
              value={cupos}
              onChange={(e) => setCupos(e.target.value)}
              required
            />
            <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) + 1)}>add</Icon>
          </div>
        </div>
      </div>
      <ComboTable
        path="/combos"
        params={{
          q, from_date, to_date
        }}
        cupos={cupos}
      />
    </div>
  </>
}

export default ToursTable;
