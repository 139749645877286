import TextField from '@components/TextField';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { format, isValid } from 'date-fns';
import BreadCrumbs from '@components/Breadcrumbs';

const ToursTable = (props) => {
  document.title = `Lista de traslados - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  let [rows] = useState([]);
  const [q, setQ] = useState(null)
  const [cupos, setCupos] = useState(0)
  const [reservations, setReservations] = useState(true)
  const [date, setDate] = useState(null)

  const breadcrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos'
    },
    {
      title: 'Traslados',
    }
  ];

  const changeDate = (date) => {
    setDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Traslados</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="grid grid-cols-4 gap-4 items-center">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por um passeio"
              variant="outlined"
              type="text"
              name="q"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <DatePicker
              width="170px"
              label="Data"
              value={date !== null ? `${date} 00:00:00` : null}
              onChange={date => changeDate(date)}
            />
          </div>
          <div className="flex col-start-3 col-end-5 justify-end items-center">
            {cupos > 0 &&
              <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) - 1)}>remove</Icon>
            }
            <TextField
              className="inputRounded mx-4"
              placeholder="Vagas"
              width="100px"
              variant="outlined"
              type="number"
              name="cupos"
              value={cupos}
              onChange={(e) => setCupos(e.target.value)}
              required
            />
            <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) + 1)}>add</Icon>
          </div>
        </div>
      </div>
      <Table
        path="/translados"
        params={{
          q, date, cupos, reservations
        }}
        onRowClick={null}
        columns={[
          {
            headerName: 'Traslados',
            headerClassName: 'super-app-theme--header',
            field: 'region_name',
            flex: 1,
          },
          {
            headerName: ' ',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 0.5,
            renderCell: (params) => {
              const row = params.row;
              const link = `traslados/${row.id}/comprar`
              return (<div className="flex items-center pr-4 mb-3">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={() => history.push(link, { cupos: cupos })}
                  bgColor="transparent"
                >
                  Comprar
                </Button>
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default ToursTable;
