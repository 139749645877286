import HighFiveIcon from '@assets/images/icons/undraw_High_five.svg';
import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import FinishTable from './FinishTable';

const bradecrumbsConfig = [
  {
    title: 'Início',
    active: true,
    route: '/admin/reservas'
  },
  {
    title: 'Passeio',
    active: true,
    route: '/admin/reservas/passeios'
  },
  {
    title: `Comprar`,
    active: true,
  },
  {
    title: 'Reserva'
  }
]

const ReviewPage = (props) => {
  const history = useHistory();
  const [proofs, setProofs] = useState([])
  const payment_method = {
    card: 'Cartão',
    money: 'Dinheiro'
  }

  const lang = {
    'pt': 'Português',
    'en': 'Inglês',
    'es': 'Espanhol'
  }

  const status = {
    accomplished: {
      text: 'Realizado'
    },
    pending: {
      text: 'Pendente'
    },
    partial: {
      text: 'Parcial'
    }
  }

  const showDerivedInfo = (info) => {
    if (props.client.derived && info) {
      return false
    } else if (props.client.derived && !info) {
      return true
    }
    return false
  }

  const getReserveById = () => {
    apiClient.httpInstance.get(`sales/${props.saleId}`)
      .then(response => {
        if (response.status === 200) {
          if (response.data?.payments && response.data?.payments.lenght) {
            setProofs(response.data?.payments[0]?.proofs)
          }
        }
      }
      );
  }

  useEffect(() => {
    getReserveById()
  }, [])
  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Reserva</span>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="flex justify-center pt-3 text-xl font-medium">
          Reserva realizada com sucesso!
        </div>
        <div className="flex justify-center pt-3">
          <img
            alt="Select file"
            src={HighFiveIcon}
            width="450"
          />
        </div>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="p-4 text-xl text-blue font-poppins">
          <span>Informações do cliente</span>
        </div>
        <div className='grid grid-cols-3 gap-4 p-4 border-b border-solid border-lightgray'>
          <div >
            <div className="grid grid-cols-1 gap-4 p-4 text-base font-normal">
              <div className={showDerivedInfo(props.client.name) ? 'hidden' : ''}>
                {props.client.name}
              </div>
              <div className={showDerivedInfo(props.client.emai) ? 'col-span-2' : ''}>
                {props.client.email}
              </div>
              <div className={showDerivedInfo(props.client.document) ? 'hidden' : ''}>
                Documento: {props.client.document}
              </div>
            </div>
          </div>
          <div >
            <div className="grid grid-cols-1 gap-4 p-4 text-base font-normal">
              {props.client.addresses.map((address, indexc) => {
                let addressClient = ''
                props.reservationData?.travels?.forEach((element, index) => {
                  if (parseInt(address.region_id) === parseInt(element.region_id)) {
                    addressClient = <>
                      <div>
                        Hospedagem ({address.name !== undefined ? address.name : address.region_name}): {address.hotel}
                      </div>
                      <div className={showDerivedInfo(props.client.observations) ? 'hidden' : 'row-span-3'}>
                        Observações: {props.client.observations}
                      </div>
                      <div>
                        Quarto: {address.room_number}
                      </div>
                    </>
                  } else if (element.region_id === null && address.region_id === null && element.type === 'transfer') {
                    addressClient = <>
                      <div>
                        Hospedagem ({address.name !== undefined ? address.name : address.region_name}): {address.hotel}
                      </div>
                      <div className={showDerivedInfo(props.client.observations) ? 'hidden' : 'row-span-3'}>
                        Observações: {props.client.observations}
                      </div>
                      <div>
                        Quarto: {address.room_number}
                      </div>
                    </>
                  }
                });

                return (addressClient)
              })}
            </div>
          </div>
          <div >
            <div className="grid grid-cols-1 gap-4 p-4 text-base font-normal">
              <div className={showDerivedInfo(lang[props.client.customer_language]) ? 'hidden' : ''}>
                Idioma: {lang[props.client.customer_language]}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 p-4 text-base font-normal border-b border-solid border-lightgray">
        </div>
        <div className="p-4 text-xl text-blue font-poppins">
          <FinishTable data={props.reservationData} type={props.type} />
        </div>
        <div className="p-4 text-xl border-t border-solid text-blue font-poppins border-lightgray">
          <span>Detalhes do pagamento</span>
        </div>
        <div className="flex p-4">
          <div className="flex-1">
            Pagamento
          </div>
          <div className="flex-1 text-gray">
            {props.paymentStatus &&
              <>{status[props.paymentStatus].text}</>
            }
          </div>
        </div>
        <div className="flex p-4">
          <div className="flex-1">
            Modalidade
          </div>
          <div className="flex-1 text-gray">
            {payment_method[props.reserve.payment_method] || null}
          </div>
        </div>
        <div className="flex p-4">
          <div className="flex-1">
            {props.reserve.payment_method === "money" ? "Número da boleta" : "Número de autorização do cartão"}
          </div>
          <div className="flex-1 text-gray">
            {props.reserve.payment_proof}
          </div>
        </div>

        <div className="flex p-4">
          <div className="flex-1 font-medium">
            Comprovantes
          </div>
          <div className="flex flex-col space-y-3 flex-1 cursor-pointer">
            {proofs?.map(proof => {
              return <Link className="cursor-pointer" to={{ pathname: `${process.env.REACT_APP_BASE_IMAGE_URL}/${proof.path}` /* Aqui vem o link da imagem do comprovante  */ }} target="_blank">
                {proof.name}
              </Link>
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-4 space-x-5">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200px"
          variant="outlined"
          onClick={() => history.push('/admin/reservas')}
          bgColor="transparent"
        >
          Voltar para o painel
        </Button>
        <Button
          textcolor="#fff"
          padding="6px 15px"
          width="160px"
          onClick={() => history.push(`voucher/${props.saleId}`)}
          customcolor={props.theme.color}
        >
          Ver voucher
        </Button>
      </div>
    </div>
  </>
}

export default ReviewPage;