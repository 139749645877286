import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';
import DeleteModal from '@components/DeleteModal';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do vendedor parceiro - ${process.env.REACT_APP_NAME}`
  const host = window.location.host
  const { partner_seller_id } = useParams();
  const [partnerSellers, setPartnerSellers] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/partner_sellers/${partner_seller_id}`

  const getGuideDetails = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setPartnerSellers(response.data);
        setLoading(false)
      }
    });
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/parceiros/vendedores'
    },
    {
      title: `${partnerSellers.name}`,
    }
  ]

  useEffect(() => {
    getGuideDetails(url);
  }, [url]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{partnerSellers.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/parceiros/vendedores/editar/${partner_seller_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Desativar
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/partner_sellers/${partner_seller_id}`}
                title="Excluir vendedor parceiro?"
                redirect={'/admin/parceiros/vendedores'}
                description="Você realmente deseja excluir este vendedor parceiro? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do vendedor parceiro</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome do vendedor parceiro
            </div>
            <div className="flex-1">
              {partnerSellers.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              E-mail
            </div>
            <div className="flex-1">
              {partnerSellers.email}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Telefone
            </div>
            <div className="flex-1">
              {partnerSellers.telephone}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Comissão (%)
            </div>
            <div className="flex-1">
              {partnerSellers.commission}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Link de acesso
            </div>
            <div className="flex-1 underline text-primary truncate -mx-4">
              <Link to={`/parceiro/${partnerSellers.url_link}`} target="_blank" >
                {host}/parceiro/{partnerSellers.url_link}
              </Link>
            </div>
          </div>
        </div>
      </div>
    }
  </>
}

export default Show;
