import { useState, useEffect } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import { apiClient } from '@services/api';
import Icon from '@material-ui/core/Icon';
import { format, isValid } from 'date-fns';
import DatePicker from '@components/DatePicker';

const ChangeDate = (props) => {
  const [date, setDate] = useState(props.date)
  const [dateAvaliable, setDateAvaliable] = useState([])
  const today = format(new Date(), "yyyy-MM-dd");
  let ref = useRef(null);

  const changeDate = (idx) => {
    const data = {
      tour_date: date,
      travel_id: props.rows[idx].travel_id,
      cart_id: props.rows[idx].cart_id,
    }

    let cartId = props.rows[idx].cart_id
    apiClient
      .httpInstance.put(`/user/cart/${cartId}/update-product`, data)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          props.setViewDate(true)
          props.setDateReservation(idx, new Date(`${date} 00:00:00`))
          props.close()
        }
      })
      .catch(error => {
        if (error) {
          console.log(error.response.message || "Erro inesperado, tente novamente mais tarde")
        }
      })
  }

  const changeDateValue = (date) => {
    const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
    setDate(value)
  }

  const getDateAvaliable = (id) => {
    apiClient.httpInstance.get('reservations_dates/available', {
      params: {
        tour_id: id
      }
    }).then(response => {
      if (response.status === 200) {
        setDateAvaliable(response.data);
      }
    }).catch(error => { });
  }

  const disableDates = (date) => {
    let dates = null

    if (dateAvaliable.length > 0) {
      dateAvaliable.map((dateT, index) => {
        if (index === 0) {
          dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos >= props.rows[props.idx].cupos;
        } else {
          dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos >= props.rows[props.idx].cupos;
        }
      })
    } else {
      dates = false;
    }
    return dates
  }

  useEffect(() => {
    changeDateValue(new Date(`${props.date} 00:00:00`))
    getDateAvaliable(props.tourId);
  }, [props.date, props.tourId]);

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '350px' }}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Escolher data da reserva</span>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <form>
      <div className="flex justify-center mt-4">
        {date &&
          <DatePicker
            label="Data"
            value={date !== null ? `${date} 00:00:00` : null}
            shouldDisableDate={(date) => props.type === 'transfer' ? null : !disableDates(date)}
            minDate={`${today} 00:00:00`}
            onChange={date => changeDateValue(date)}
          />
        }
      </div>
      <div className="justify-center mt-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="10px 15px"
          margintop="0px"
          width="300px"
          onClick={() => {
            changeDate(props.idx)
            props.verifyFinished()
          }}
          customcolor={props.theme.color}
        >
          Salvar
        </Button>
      </div>
    </form>
  </Modal>
}

export default ChangeDate;
