import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef } from 'react';
import Icon from '@material-ui/core/Icon';

const ModalDerived = (props) => {
  let ref = useRef(null);

  const closeModal = (isDerived) => {
    props.isDerived(isDerived)
    props.close()
  }

  return <Modal open={props.open} close={props.close} style={{ width: '100%', maxWidth: '550px'}}>
    <div className="flex justify-between font-poppins">
      <div className="flex">
        <div className="flex flex-col">
          <span className="mb-3 text-xl text-blue">Confirmar derivado?</span>
          <small className="text-gray text-sm">Você quer mesmo confirmar derivado? Se não quiser, informe o e-mail do cliente.</small>
        </div>
      </div>
      <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
    </div>
    <div className="justify-end mt-4 space-x-3 md:flex w-100">
      <Button
        textcolor="#212121"
        padding="6px 15px"
        width="100px"
        variant="outlined"
        onClick={() => closeModal(false)}
        bgColor="transparent"
      >
        Cancelar
      </Button>
      <Button
        textcolor="#fff"
        padding="5px 15px"
        width="100px"
        onClick={() => closeModal(true)}
      >
        Confirmar
      </Button>
    </div>
  </Modal>
}

export default ModalDerived;
