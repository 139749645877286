import { useState } from 'react';
import { apiClient } from '@services/api';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import ChangeDate from './ChangeDate';
import ChangeDateTransfer from './ChangeDateTransfer';
import ChangeFlight from './ChangeFlight';
import { Link } from 'react-router-dom';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import DeleteModal from '@components/DeleteModal'

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const RequestTable = (props) => {
  const [changeDate, setChangeDate] = useState(false)
  const [changeDateTransfer, setChangeDateTransfer] = useState(false)
  const [date, setDate] = useState(null)
  const [modalFlight, setModalFlight] = useState(false)
  const [flight, setFlight] = useState(null)
  const [flightTime, setFlightTime] = useState(null)
  const [tourId, setTourId] = useState(null)
  const [index, setIndex] = useState(null)
  const classes = useStyles();
  const [disableModal, setDisableModal] = useState(false);
  const [idReservation, setIdReservation] = useState('');
  const [idxRow, setIdxRow] = useState('');
  const [discount, setDiscount] = useState(0);
  const [justify, setJustify] = useState('');
  const [toApprove, setToApprove] = useState(false);

  const history = useHistory();

  const handleModal = (row, idx) => {
    setIdReservation(row.id)
    setIdxRow(idx)
    setDisableModal(true)
  }

  const cancelReservation = () => {
    /* apiClient.httpInstance.post(`/reservations/${idReservation}/cancel`).then(response => {
      if (response.status === 200) {
        props.dataBudgets[idxRow].status = 0
        setDisableModal(false)
      }
    }); */
  }

  const discountTotal = () => {
    const total = props.dataBudgets.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return total * discount / 100
  }

  const calcSubtotal = () => {
    const total = props.dataBudgets.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return Number(total)
  }

  const calcTotal = () => {
    const total = props.dataBudgets.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return !toApprove ? Number(total) : total.toFixed(2) - (total.toFixed(2) * Number(discount / 100))
  }

  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Minhas reservas
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Passageiros
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Valor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataBudgets.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <div className="flex items-center">
                  <div className="flex-none">
                    <img src={row.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${row.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
                  </div>
                  <div className="flex-auto space-y-3">
                    <div className="font-medium text-lg">
                      {row.tour_name}
                    </div>
                    <div>
                      {row.status === 0 ? <Icon className="mr-1 text-red-500" fontSize="small">block</Icon> : ''}{row.travel_name} {row.transfer_type ? `(${row.transfer_type})` : null}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.type === 'transfer' ?
                  row.adults_total
                  :
                  parseInt(row.adults_total ? row.adults_total : 0) + parseInt(row.kids_total ? row.kids_total : 0)
                }
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <NumberFormat
                  value={Number(row.price) || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span>{value}</span>}
                />
              </TableCell>
              {props.isEditing && <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.status !== 0 ? <><Button
                  textcolor="red"
                  padding="4px"
                  width="50"
                  variant="outlined"
                  bgColor="transparent"
                  onClick={() => handleModal(row, idx)}
                >
                  X
                </Button>
                </>
                  : ''}
              </TableCell>
              }
              {row.type === 'tour' && <ChangeDate
                type={row.type}
                open={changeDate}
                close={() => setChangeDate(false)}
                setDate={props.changeDate}
                date={date}
                idx={index}
                tourId={tourId}
                {...props}
              />}
              {row.type === 'transfer' && <ChangeDateTransfer
                type={row.type}
                open={changeDateTransfer}
                close={() => setChangeDateTransfer(false)}
                setDate={props.changeDate}
                date={date}
                idx={index}
                tourId={tourId}
                {...props}
              />}
              <ChangeFlight
                type={row.type}
                open={modalFlight}
                close={() => setModalFlight(false)}
                setFlight={props.changeFlight}
                setFlightTime={props.changeFlightTime}
                flight={flight}
                flightTime={flightTime}
                idx={index}
                tourId={tourId}
                {...props}
              />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
              Subtotal
            </TableCell>
            <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
              <NumberFormat
                value={calcSubtotal().toFixed(2)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span>{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
              Descontos solicitados
            </TableCell>
            <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
              <NumberFormat
                value={((parseInt(props?.dataBudgets[0]?.discount_total_sale || 0) * calcTotal() / 100).toFixed(2))}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span>{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
              Justificativa
            </TableCell>
            <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
              {props?.dataBudgets[0]?.justify_discount}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
              Total
            </TableCell>
            <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
              <NumberFormat
                value={(calcTotal() - (parseInt(props?.dataBudgets[0]?.discount_total_sale || 0) * calcTotal() / 100)).toFixed(2)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span>{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteModal
        open={disableModal}
        close={() => setDisableModal(false)}
        delete={() => cancelReservation()}
        title="Cancelar orçamento?"
        description="Você quer mesmo cancelar esse orçamento? Essa ação é irreversível."
      />
    </TableContainer>
  </>
}

export default RequestTable;
