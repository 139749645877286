import TextField from '@components/TextField';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';

const Form = (props) => {

  const radioButtonData = [
    {
      value: true,
      label: 'Ativo'
    },
    {
      value: false,
      label: "Inativo"
    }
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Nome da região"
              variant="outlined"
              type="text"
              name="name"
              value={props.region.name}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
        </div>
        {props.isEditing && <>
            <span className="text-xl text-black">Status</span>
            <div className="flex mb-4 space-x-3">
              <RadioButton
                {...props}
                name="status"
                data={radioButtonData}
                change={(ev) => props.changeState(ev)}
                value={props.region.status}
              />
            </div>
          </>
        }
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
          </Button>
      </div>
    </form>
  </>
}

export default Form
