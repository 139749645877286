import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '@assets/css/Admin/components/quillEditor.scss';

const QuillEditor = (props) => {
  return <>
    <Quill
      {...props}
      theme="snow"
      className="w-full h-80 font-poppins"
    />
  </>
}

export default QuillEditor;
