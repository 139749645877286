import { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import { apiClient } from '@services/api';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

const CustomSelect = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: "40px",
    width: props.width || "100%"
  }),
}))(MaterialSelect);

const PaginateSelect = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [apiList, setApiList] = useState({});
  const [finalList, setFinalList] = useState({});
  const [resultSelect, setResultSelect] = useState({})

  const getResult = (path, page) => {
    setLoading(true)
    apiClient
    .httpInstance.get(path, {
      params: {
        page
      }
    })
    .then(response => {
      if (response.status === 200) {
        setApiList(response.data)
        setLoading(false)
      }
    }).catch((error) => setLoading(false));
  }

  const changeValue = (ev) => {
    props.changeState(ev)
    apiClient.httpInstance.get(`${props.pathChange}/${ev.target.value}`)
    .then(response => {
      if (response.status === 200) {
        setResultSelect({
          ...response.data,
          selected: true
        })
      }
    })
    .catch((error) => console.log(error));
  }

  const getFinalList = (apiList, resultSelect) => {
    let result = {}
    result = apiList.data.filter(el => !el.selected && el.id !== resultSelect.id)
    result.push(resultSelect)
    setFinalList({
      ...apiList,
      data: result
    })
  }

  const checkSelected = () => {
    return finalList.data.find(el => el.selected)
  }

  useEffect(() => {
    getResult(props.path, page)
  }, [props.path, page])

  useEffect(() => {
    if(props.value) {
      apiClient.httpInstance.get(`${props.pathChange}/${props.value}`)
        .then(response => {
          if (response.status === 200) {
            setResultSelect({
              ...response.data,
              selected: true
            })
          }
      })
      .catch((error) => console.log(error));
    }
  }, [])

  useEffect(() => {
    if(Object.keys(apiList).length > 0 && apiList.constructor === Object) {
      getFinalList(apiList, resultSelect)
    }
  }, [apiList, resultSelect])

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={props.name} className="bg-white" shrink={props.shrink}>{props.label}</InputLabel>
        <CustomSelect
          labelId={props.name}
          style={{
            borderRadius: "20px",
          }}
          onChange={(ev) => changeValue(ev)}
          {...props}
          >
              {finalList.data && finalList.data.map((item, index) =>
                  <MenuItem
                    className={item.selected ? 'hidden' : null}
                    value={item[props?.items.field.value]}
                    key={index}>
                      {item[props?.items.field.text]}
                  </MenuItem>
              )}
              {finalList.data &&
                <>
                <div className="flex-col justify-center items-center pt-2 space-y-3">
                  {checkSelected() &&
                    <div className="flex-1">
                      <p className="px-2">Selecionado</p>
                      <div className="h-9 p-4 flex items-center" style={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                        {checkSelected()[props?.items.field.text]}
                      </div>
                    </div>
                  }
                  <div className="flex-1 flex">
                    {!loading ?
                      <>
                        <div className="flex-none flex items-center text-center pr" onClick={finalList.prev_page_url ? () => setPage(page - 1) : null}>
                          <Icon className={`${finalList.prev_page_url ? "cursor-pointer" : "text-gray" } ml-2`}>
                            chevron_left
                          </Icon>
                        </div>
                        <div className="flex-1 text-center">
                          {finalList.from} - {finalList.to} de {finalList.total}
                        </div>
                        <div className="flex-none flex items-center text-center" onClick={finalList.next_page_url ? () => setPage(page + 1) : null}>
                          <Icon className={`${finalList.next_page_url ? "cursor-pointer" : "text-gray" } mr-2`}>
                            chevron_right
                          </Icon>
                        </div>
                      </>
                    :
                      <div className="flex-1 text-center">
                        <CircularProgress size={30} />
                      </div>
                    }
                  </div>
                </div>
              </>
            }
        </CustomSelect>
    </FormControl>
  );
}
export default PaginateSelect;
