import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar detalhes do template - ${process.env.REACT_APP_NAME}`
  const { template_id } = useParams();
  const history = useHistory();
  const [template, setTemplate] = useState({});
  const [permissionsTemplate, setPermissionsTemplate] = useState([]);
  const [error, setError] = useState(false);
  const [isEditing, setIsEditing] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  let url = `/templates/${template_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/central/templates'
    },
    {
      title: `${template.name !== undefined ? template.name : ''}`,
      active: true,
      route: `/admin/central/templates/detalhe/${template_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getTypeById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          let perm = JSON.parse(response.data.permissions).split(',')
          setTemplate(response.data);
          setPermissionsTemplate(perm);
          setIsEditing(true)
        }
      }
      )
      .catch((error) => console.log(error));
  }

  const changeState = (ev) => {
    if (ev.target.name === "status" && ev.target.value === "true") {
      setTemplate(
        {
          ...template,
          status: true
        }
      )
    } else if (ev.target.name === "status" && ev.target.value === "false") {
      setTemplate(
        {
          ...template,
          status: false
        }
      )
    } else {
      if (ev.target.name) {
        setTemplate(
          {
            ...template,
            [ev.target.name]: ev.target.value
          }
        )
      }
    }
  }

  const submitTypeEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/templates/${template_id}`, {
        name: template.name,
        utalk_id: template.utalk_id,
        template_type_id: template.template_type_id,
        permissions: JSON.stringify(`${permissionsTemplate}`),
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/central/templates');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }
  useEffect(() => {
    getTypeById(url);
  }, [url]);

  if (isEditing) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      {error && (
        <Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}
      {permissions.includes(props?.theme?.permission) ?
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              extcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/central/templates/detalhe/${template_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <Form
            {...props}
            submit={submitTypeEdit}
            template={template}
            isEditing={isEditing}
            changeState={changeState}
            permissions={permissionsTemplate}
            setPermissions={setPermissionsTemplate}
          />
        </div>
        :
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Edição</span>
            <Button
              extcolor="#212121"
              padding="6px 15px"
              width="200"
              variant="outlined"
              onClick={() => history.push(`/admin/central/templates/detalhe/${template_id}`)}
              bgColor="transparent"
            >
              <Icon className="mr-2">
                arrow_back
              </Icon>
              Cancelar
            </Button>
          </div>
          <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            Você não possui permissão para acessar este recurso.
          </div>
        </div>
      }
    </>
  } else {
    return <>
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
