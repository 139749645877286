import TextField from '@components/TextField';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import Checkbox from '@components/Checkbox';
import PhoneMaskInput from '@components/PhoneMaskInput';

const Form = (props) => {
  const radioButtonData = [
    {
      value: true,
      label: 'Ativo'
    },
    {
      value: false,
      label: "Inativo"
    }
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Nome do guia"
              variant="outlined"
              type="text"
              name="name"
              value={props.guide.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="E-mail"
              variant="outlined"
              type="email"
              name="email"
              value={props.guide.email}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <PhoneMaskInput
              name="phone"
              value={props.guide.phone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({target: {name: 'phone', value: target.target.value}})}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <div className="flex-auto">
            <TextField
              className="inputRounded"
              label="Endereço"
              variant="outlined"
              type="text"
              name="address"
              value={props.guide.address}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Doc. de identificação"
              variant="outlined"
              type="text"
              name="identification_document"
              value={props.guide.identification_document}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
        </div>
        <div className="flex mb-4 space-x-3">
          <TextField
            className="inputRounded"
            label="Observação"
            variant="outlined"
            type="text"
            name="observation"
            multiline
            rows={2}
            value={props.guide.observation}
            onChange={(ev) => props.changeState(ev)}
          />
        </div>
        <div className="flex mb-4 space-x-3">
          <Checkbox
            {...props}
            label="Permitir apenas o acesso nos dias de saída"
            value={props.guide.access_days}
            name="access_days"
            onChange={(ev) => props.changeCheckbox(ev)}
          />
        </div>
        {props.isEditing && <>
            <span className="text-xl text-black">Status</span>
            <div className="flex mb-4 space-x-3">
              <RadioButton
                {...props}
                name="status"
                data={radioButtonData}
                change={(ev) => props.changeState(ev)}
                value={props.guide.status}
              />
            </div>
          </>
        }
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
          </Button>
      </div>
    </form>
  </>
}

export default Form
