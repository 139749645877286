import axios from 'axios';

class HttpInstance {
  httpInstance = null
  token = null
  constructor() {
    this.token = JSON.parse(localStorage.getItem('user'))?.token
    this.httpInstance = this.createAxiosInstance()
  }
  createAxiosInstance() {
    let headers = {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "company": `${process.env.REACT_APP_COMPANY_KEY}`
      }
    };

    if (this.token) {
      headers.headers['Authorization'] = `Bearer ${this.token}`;
    }
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      withCredentials: true,
      ...headers
    });
  }

  setToken(token) {
    this.token = token
    this.httpInstance = this.createAxiosInstance()
  }
}

export const apiClient = new HttpInstance()
