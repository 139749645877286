import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { useParams, Link } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import DeleteModal from '@components/DeleteModal'
import CircularProgress from '@material-ui/core/CircularProgress';

const permissions = ['administrador'];

const Show = (props) => {
  document.title = `Detalhes do template - ${process.env.REACT_APP_NAME}`
  const host = window.location.host
  const { template_id } = useParams();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false)
  const history = useHistory();
  const url = `/templates/${template_id}`;

  let [templateTypes, setTemplateTypes] = useState([]);

  const getTemplateTypesData = () => {
    apiClient.httpInstance.get('/template_types').then(response => {
      if (response.status === 200) {
        let types = response.data.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        setTemplateTypes(types);
      }
    });
  }

  const getTypeDetais = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setTemplate(response.data);
        setLoading(false)
      }
    })
      .catch((error) => console.log(error));
  }

  const userLogged = JSON.parse(localStorage.getItem('user'));

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/central/templates'
    },
    {
      title: `${template.name}`,
    }
  ]

  useEffect(() => {
    getTypeDetais(url);
    getTemplateTypesData()
  }, [url]);

  if (!loading) {
    return <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>{template.name}</span>
          {permissions.includes(props?.theme?.permission) &&
            <div className="w-100">
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="100px"
                onClick={() => history.push(`/admin/central/templates/editar/${template_id}`)}
                customcolor={props.theme.color}
              >
                <Icon className="mr-2">
                  edit
                </Icon>
                Editar
              </Button>
              <Button
                textcolor="#fff"
                padding="6px 15px"
                margintop="0px"
                marginright="20px"
                width="140px"
                onClick={() => setDisableModal(true)}
                bgColor="#F44336"
              >
                <Icon className="mr-2">delete</Icon>
                Excluir
              </Button>
              <DeleteModal
                open={disableModal}
                close={() => setDisableModal(false)}
                url={`/templates/${template_id}`}
                title="Excluir template?"
                redirect={'/admin/central/templates'}
                description="Você realmente deseja excluir este template? Essa ação é irreversível,
              mas você poderá cadastrá-lo no futuro novamente."
              />
            </div>
          }
        </div>
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          <div className="p-4 text-xl text-blue font-poppins">
            <span>Informações do template</span>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Nome
            </div>
            <div className="flex-1">
              {template.name}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Utalk ID
            </div>
            <div className="flex-1">
              {template.utalk_id}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Tipo
            </div>
            <div className="flex-1">
              {templateTypes.map((element) => {
                if (element.id === template.template_type_id) {
                  return element.name
                }
              })}
            </div>
          </div>
          <div className="flex p-4 border-t border-b border-solid border-lightgray">
            <div className="flex-1">
              Permissão
            </div>
            <div className="flex-1">
              {template.permissions}
            </div>
          </div>
        </div>
      </div>
    </>
  } else {
    return <div className="grid w-full h-full justify-items-center">
      <CircularProgress size={50} />
    </div>
  }

}

export default Show;
