import { useState } from 'react';
import BreadCrumbs from '@components/Breadcrumbs';
import QuillEditor from '@components/QuillEditor';
import { apiClient } from '@services/api';
import Alert from '@material-ui/lab/Alert';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';

const CreateTerms = (props) => {
  document.title = `Termos e condiçoes - ${process.env.REACT_APP_NAME}`
  const { lang, type } = useParams();
  const location = useLocation()
  const history = useHistory();

  const oldTerms = location.state?.terms !== undefined ? location.state.terms : null
  const oldTerms2 = location.state?.terms2 !== undefined ? location.state.terms2 : null
  const oldTerms3 = location.state?.terms3 !== undefined ? location.state.terms3 : null
  const [terms, setTerms] = useState({
    pt: oldTerms.ptTerms?.terms1,
    en: oldTerms.enTerms?.terms1,
    es: oldTerms.esTerms?.terms1,
  })

  const [terms2, setTerms2] = useState({
    pt: oldTerms.ptTerms?.terms2,
    en: oldTerms.enTerms?.terms2,
    es: oldTerms.esTerms?.terms2,
  })

  const [terms3, setTerms3] = useState({
    pt: oldTerms.ptTerms?.terms3,
    en: oldTerms.enTerms?.terms3,
    es: oldTerms.esTerms?.terms3,
  })

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const bradecrumbsConfig = [
    {
      title: 'Configurações',
      active: true,
      route: '/admin/configuracoes'
    },
    {
      title: 'Termos de condições'
    }
  ]

  const changeTerms = (ev) => {
    const value = ev
    setTerms({
      ...terms,
      [lang]: value,
    });
  }

  const changeTerms2 = (ev) => {
    const value = ev
    setTerms2({
      ...terms2,
      [lang]: value,
    });
  }

  const changeTerms3 = (ev) => {
    const value = ev
    setTerms3({
      ...terms3,
      [lang]: value,
    });
  }

  const editTerms = () => {

    apiClient
      .httpInstance.put("/company_terms", {

        terms: {
          pt: {
            description: terms.pt,
          },
          en: {
            description: terms.en,
          },
          es: {
            description: terms.es,
          }
        },

        terms2: {
          pt: {
            description2: terms2.pt,
          },
          en: {
            description2: terms2.en,
          },
          es: {
            description2: terms2.es,
          }
        },

        terms3: {
          pt: {
            description3: terms3.pt,
          },
          en: {
            description3: terms3.en,
          },
          es: {
            description3: terms3.es,
          }
        },

        type: type
      })

      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/configuracoes');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Termos de condição</span>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="flex p-4">
          As seguintes variáveis serão substituídas pelas informações do cliente: %NOME_CLIENTE%, %EMAIL_CLIENTE%, %DOCUMENTO_CLIENTE% <br />
          Exemplo de uso: Texto do contrato em nome de %NOME_CLIENTE%.
        </div>
      </div>
      <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
        <div className="flex p-4">
          <QuillEditor
            placeholder="Escreva os termos e condições da sua empresa"
            value={terms[lang]}
            onChange={(ev) => changeTerms(ev)}
          />
        </div>

        <div className="flex p-4">
          <QuillEditor
            placeholder="Informações de conta bancária"
            value={terms2[lang]}
            onChange={(ev) => changeTerms2(ev)}
          />
        </div>

        <div className="flex p-4">
          <QuillEditor
            placeholder="Escreva os termos e condições da sua empresa"
            value={terms3[lang]}
            onChange={(ev) => changeTerms3(ev)}
          />
        </div>

      </div>
      <div className="text-right space-x-3">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/configuracoes')}
          bgColor="transparent"
        >
          <Icon className="mr-2">
            arrow_back
          </Icon>
          Cancelar
        </Button>
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          onClick={() => editTerms()}
        >
          Salvar
        </Button>
      </div>
    </div>
  </>
}

export default CreateTerms;
