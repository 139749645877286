import { useState, useEffect } from 'react';
import PhoneMaskInput from '@components/PhoneMaskInput';
import TextField from '@components/TextField';
import Select from '@components/Select';
import Checkbox from '@components/Checkbox';
import { apiClient } from '@services/api';
import SelectSearch from 'react-select'

const ClientForm = (props) => {
  const [partners, setPartners] = useState([])
  let [regions, setRegions] = useState([]);

  const getRegionData = () => {
    apiClient.httpInstance.get('/regions').then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        setRegions(regions);
      }
    });
  }

  const getPartners = () => {
    apiClient
      .httpInstance.get('/agencies_sellers').then(response => {
        if (response.status === 200) {
          const partners = response.data.filter(obj => obj.email !== null && obj.email !== "").map(obj => ({
            ...obj,
            nameSelect: `${obj.name ? obj.name : obj.email}`
          }))
          setPartners(partners);
        }
      });
  }

  const getPartner = (ev) => {
    let email = ev.target.value
    const partner = partners.find((obj) => obj.email === email)
    const url = partner.type === 'agency' ? `/agencies/${partner.id}` : `/partner_sellers/${partner.id}`
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        if (partner.type === 'seller' || partner.type === 'agency' || partner.type === 'partner') {
          const data = {
            partner: response.data.id,
            partnerEmail: response.data.email,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          props.changeClientByPartner(data)
        } else if (props.client.email && props.client.email !== props.client.partnerEmail) {
          const data = {
            partner: response.data.id,
            partnerEmail: response.data.email,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          props.changeClientByPartner(data)
        } else {
          const data = {
            addresses: response.data.address,
            email: response.data.email,
            name: props.client.name,
            phone: props.client.phone,
            document: props.client.document,
            customer_id: null,
            observations: props.client.observations,
            hotel_name: props.client.hotel_name,
            hotel_address: props.client.hotel_address,
            customer_language: null,
            room_number: props.client.room_number,
            partner: response.data.id,
            partnerEmail: response.data.email,
            havePartner: props.client.havePartner,
            partner_seller_id: response.data.id,
            partner_type: partner.type
          }
          props.changeClientByEmail(data)
        }
      }
    });
  }

  const getClientByEmail = (ev) => {
    const value = ev.target.value
    props.changeClient(ev)
    if (value.length > 0) {
      apiClient
        .httpInstance.get(`/customer_by_email?email=${value}`)
        .then(response => {
          if (response.status === 200) {
            const data = {
              addresses: response.data.address,
              email: response.data.email,
              name: response.data.name,
              phone: response.data.phone,
              document: response.data.document,
              customer_id: response.data.id,
              observations: response.data.observations,
              hotel_name: response.data.hotel,
              hotel_address: response.data.hotel_address,
              room_number: response.data.room_number,
              customer_language: response.data.language,
              partner: props.client.partner,
              partnerEmail: props.client.partnerEmail,
              havePartner: props.client.havePartner
            }
            props.changeClientByEmail(data)
          } else {
            //props.clearAdresses()
          }
        })
        .catch(error => {
          //props.clearAdresses()
        })
    } else {
      props.changeClientByEmail({
        addresses: [{
          id: '',
          region_id: '',
          region_name: '',
          customer_id: '',
          hotel: '',
          hotel_address: '',
          room_number: ''
        },
        {
          id: '',
          region_id: '',
          region_name: '',
          customer_id: '',
          hotel: '',
          hotel_address: '',
          room_number: ''
        }],
        customer_id: null,
        email: null,
        document: null,
        name: null,
        phone: null,
        hotel_name: null,
        hotel_address: null,
        room_number: null,
        customer_language: null,
        observations: null,
        havePartner: false,
        partner: null,
        partnerEmail: null,
        derived: false,
        partner_type: null,
        partner_seller_id: null
      })
    }
  }

  useEffect(() => {
    getPartners()
    getRegionData()
  }, [])
  return <>
    <div className="p-4 text-lg border-b border-solid text-blue font-poppins border-lightgray">
      <span>Cadastro</span>
    </div>
    <div className="px-4 py-2 border-b border-solid font-poppins border-lightgray">
      <p className="pb-4 font-bold">Informações do cliente</p>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <TextField
            className="inputRounded"
            label="E-mail"
            variant="outlined"
            type="email"
            name="email"
            value={props.client.email}
            onChange={(ev) => getClientByEmail(ev)}
            required
            InputLabelProps={{
              shrink: props.client.email ? true : false,
              ...{}
            }}
          />
        </div>
        <div className="col-span-1">
          <TextField
            className="inputRounded"
            label="Documento de identificação"
            variant="outlined"
            type="text"
            name="document"
            value={props.client.document}
            onChange={(ev) => props.onlyLetterAndNumber(ev)}
            required
            InputLabelProps={{
              shrink: props.client.document ? true : false,
              ...{}
            }}
          />
        </div>
        <div className="col-span-2">
          <TextField
            className="inputRounded"
            label="Nome completo"
            variant="outlined"
            type="text"
            name="name"
            value={props.client.name}
            onChange={(ev) => props.changeClient(ev)}
            required
            InputLabelProps={{
              shrink: props.client.name ? true : false,
              ...{}
            }}
          />
        </div>
        <div className="col-span-1">
          <PhoneMaskInput
            name="phone"
            inputClass="inputRounded"
            specialLabel="Telefone *"
            required={true}
            masks={{ cl: '. .... ....' }}
            value={props.client.phone}
            onChange={(valueNoFormated, maskInfo, target) => props.changeClient({ target: { name: 'phone', value: target.target.value } })}
          />
        </div>
      </div>
    </div>
  </>
}

export default ClientForm;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 20,
    display: 'flex',
    fontSize: '14px'
  }),

  container: (provided, state) => {
    const left = '-6px';
    return {
      ...provided,
      left,
    };
  },

  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      fontSize: '14px'
    };
  },
  
  valueContainer: (provided, state) => {
    const right= '190px';
    return {
      ...provided,
      right,
    };
  },

  indicatorSeparator: (provided, state) => {
    const display = 'none';
    return {
      ...provided,
      display,
    };
  }
}

const SearchStyle1 = {
  display: 'flex',
  alignItems: 'center',
  width: '580px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10005',
}
const SearchStyle2 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10004'
}
const SearchStyle3 = {
  display: 'flex',
  alignItems: 'center',
  width: '320px',
  justifyContent: 'space-between',
  borderRadius: '20px',
  border: '1px solid #8080806e',
  padding: '9px 10px',
  zIndex: '10003'
}

const LabelStyle = {
  transform: 'translate(6px, -6px) scale(0.75)',
  top: '-22px',
  left: '0',
  position: 'relative',
  transformOrigin: 'top left',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '0px 4px',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1',
  letterSpacing: '0.00938em',
  background: '#fff',
  zIndex: '10005',
}

const SpanSelect = {
  marginLeft: '-250px',
}