import { useState } from 'react';
import { apiClient } from '@services/api';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import ChangeDate from './ChangeDate';
import ChangeDateTransfer from './ChangeDateTransfer';
import ChangeFlight from './ChangeFlight';
import { Link } from 'react-router-dom';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import DeleteModal from '@components/DeleteModal';
import { format, isValid } from 'date-fns';

const permissions = ['administrador', 'gerente', 'supervisor', 'operacao'];

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
  borderNone: {
    border: 'none'
  }
});

const RequestTable = (props) => {
  const [changeDate, setChangeDate] = useState(false)
  const [changeDateTransfer, setChangeDateTransfer] = useState(false)
  const [date, setDate] = useState(null)
  const [modalFlight, setModalFlight] = useState(false)
  const [flight, setFlight] = useState(null)
  const [flightTime, setFlightTime] = useState(null)
  const [tourId, setTourId] = useState(null)
  const [index, setIndex] = useState(null)
  const classes = useStyles();
  const [disableModal, setDisableModal] = useState(false);
  const [idReservation, setIdReservation] = useState('');
  const [idxRow, setIdxRow] = useState('');

  const getVoucherExit = (url, date) => {
    apiClient.httpInstance.get(`${url}`).then(response => {
      if (response.status === 200) {
        let exit = response.data.exit_id
        apiClient.httpInstance.get(`sales/${response.data.sale_id}`).then(response => {
          if (response.status === 200) {
            window.open(`/voucher-exit/${response.data.id}/${response.data.url_link}?tourDate=${date}&exitId=${exit}`)
          }
        })
          .catch(error => {
            if (error) {
              console.log(error)
            }
          });
      }
    })
  }

  const handleModal = (row, idx) => {
    setIdReservation(row.id)
    setIdxRow(idx)
    setDisableModal(true)
  }

  const cancelReservation = () => {
    apiClient.httpInstance.post(`/reservations/${idReservation}/cancel`).then(response => {
      if (response.status === 200) {
        props.dataReservations[idxRow].status = 0
        setDisableModal(false)
      }
    });
  }

  const openDateModal = (row, idx) => {
    setDate(row.tour_date)
    setTourId(row.tour_id)
    setIndex(idx)
    row.type === 'tour' ? setChangeDate(true) : setChangeDateTransfer(true)
  }
  const openFlightModal = (row, idx) => {
    setFlight(row.flight_code)
    setFlightTime(row.tour_date + 'T' + row.flight_time + '.000000Z')
    setIndex(idx)
    setModalFlight(true)
  }
  const showTime = (time) => {
    if (time) {
      let hourArrar = time.split(":")
      let hour = new Date().setHours(hourArrar[0], hourArrar[1])
      return format(hour, "HH:mm")
    }
  }
  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Minhas reservas
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Passageiros
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Data da reserva
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Voo / Hora
            </TableCell>
            <TableCell className="font-bold leading-5 text-blue border-0">
              Valor
            </TableCell>
            {props?.theme?.permission !== 'parceiro' && <TableCell className="font-bold leading-5 text-blue border-0">
              Voucher
            </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataReservations.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <div className="flex items-center">
                  {row?.banner && <div className="flex-none">
                    <img src={row.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${row.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
                  </div>}
                  <div className="flex-auto space-y-3">
                    <div className="font-medium text-lg">
                      {row.tour_name}
                    </div>
                    <div>
                      {row.status === 0 ? <Icon className="mr-1 text-red-500" fontSize="small">block</Icon> : ''}{row.travel_name} {row.transfer_type ? `(${row.transfer_type})` : null}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.type === 'transfer' ?
                  row.adults_total
                  :
                  row.total
                }
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <Moment format="DD/MM/YYYY" date={row.tour_date} />
                {permissions.includes(props?.theme?.permission) && row.type === 'transfer' && props.isEditing && <>
                  <p
                    className="text-red-500 cursor-pointer"
                    onClick={() => openDateModal(row, idx)}
                  >
                    Alterar a data
                  </p>
                </>
                }
                {permissions.includes(props?.theme?.permission) && row.type === 'tour' && props.isEditing && <>
                  <p
                    className="text-red-500 cursor-pointer"
                    onClick={() => openDateModal(row, idx)}
                  >
                    Alterar a data
                  </p>
                </>
                }
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.type === 'transfer' && <>
                  {row.flight_code} / {showTime(row.flight_time)}
                  {props.isEditing && <>
                    <p
                      className="text-red-500 cursor-pointer"
                      onClick={() => openFlightModal(row, idx)}
                    >
                      Alterar
                    </p>
                  </>
                  }
                </>
                }
              </TableCell>
              <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                <NumberFormat
                  value={Number(row.price) || 0}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span>{value}</span>}
                />
              </TableCell>
              {props?.theme?.permission !== 'parceiro' && <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row?.status_saida === true &&
                  <Button
                    textcolor="#212121"
                    padding="4px 12px"
                    width="200"
                    variant="outlined"
                    bgColor="transparent"
                    onClick={() => getVoucherExit(`reservations/${row.id}`, row.tour_date)}
                  >
                    <Icon className="mr-2">
                      open_in_new
                    </Icon>
                    Voucher
                  </Button>
                }
              </TableCell>}
              {props.isEditing && <TableCell className="leading-5 text-blue border-0" component="th" scope="row">
                {row.status !== 0 ? <><Button
                  textcolor="red"
                  padding="4px"
                  width="50"
                  variant="outlined"
                  bgColor="transparent"
                  onClick={() => handleModal(row, idx)}
                >
                  X
                </Button>
                </>
                  : ''}
              </TableCell>
              }
              {row.type === 'tour' && <ChangeDate
                type={row.type}
                open={changeDate}
                close={() => setChangeDate(false)}
                setDate={props.changeDate}
                date={date}
                idx={index}
                tourId={tourId}
                {...props}
              />}
              {row.type === 'transfer' && <ChangeDateTransfer
                type={row.type}
                open={changeDateTransfer}
                close={() => setChangeDateTransfer(false)}
                setDate={props.changeDate}
                date={date}
                idx={index}
                tourId={tourId}
                {...props}
              />}
              <ChangeFlight
                type={row.type}
                open={modalFlight}
                close={() => setModalFlight(false)}
                setFlight={props.changeFlight}
                setFlightTime={props.changeFlightTime}
                flight={flight}
                flightTime={flightTime}
                idx={index}
                tourId={tourId}
                {...props}
              />
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="font-medium leading-5 text-blue text-lg border-0">
              Total
            </TableCell>
            <TableCell className="font-medium leading-5 text-blue text-lg border-0">
            </TableCell>
            <TableCell className="font-medium leading-5 text-blue text-lg border-0">
            </TableCell>
            <TableCell align="right" colSpan={3} className="font-medium leading-5 text-blue text-lg border-0">
              <NumberFormat
                value={Number(props.total)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span>{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DeleteModal
        open={disableModal}
        close={() => setDisableModal(false)}
        delete={() => cancelReservation()}
        title="Cancelar reserva?"
        description="Você quer mesmo cancelar essa reserva? Essa ação é irreversível."
      />
    </TableContainer>
  </>
}

export default RequestTable;
