import Table from '@components/Table';
import Button from '@components/Button';
import TimePicker from '@components/TimePicker';
import { apiClient } from '@services/api';
import { useState } from 'react';
import { format, isValid } from 'date-fns';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

const permissions = ['administrador', 'logistica']

const TimeTextField = (params) => {
  const reservation = params.reservation;
  const hourArrar = reservation.time ? reservation.time.split(":") : null
  const hourArrarFinal = reservation.time_final ? reservation.time_final.split(":") : null
  const [time, setTime] = useState(hourArrar ? new Date().setHours(hourArrar[0], hourArrar[1]) : null);
  const [timeFinal, setTimeFinal] = useState(hourArrarFinal ? new Date().setHours(hourArrarFinal[0], hourArrarFinal[1]) : null);
  const vehicle = params.vehicle;

  const updateReservationVehicleTime = (date, type) => {
    if (isValid(date)) {
      apiClient.httpInstance.put(`/exit_vehicle/${vehicle.id}/time`, {
        time: format(date, "HH:mm"),
        reservation_id: reservation.reservation_id,
        type_time: type
      }).then(response => {
        if (response.status === 200) {
          type === 'initial' ? setTime(date) : setTimeFinal(date)
        }
      });
    }
  }

  return <div className="pt-3">
    {reservation?.type_time === 'interval' ? <>
      <div className="flex items-center space-x-2">
        <TimePicker
          width="70px"
          value={time}
          onChange={(date) => updateReservationVehicleTime(date, 'initial')}
        />
        <TimePicker
          width="70px"
          value={timeFinal}
          onChange={(date) => updateReservationVehicleTime(date, 'final')}
        /></div>
    </>
      :
      <TimePicker
        //width="120px"
        width="90px"
        value={time}
        onChange={(date) => updateReservationVehicleTime(date, 'initial')}
      />}
  </div>;
}

const TableVehicleReservation = (props) => {
  const rows = useState([]);
  const q = props.q;
  const [url, setUrl] = useState(null);
  const vehicle = props.vehicle;
  const [lastUpdate, setLastUpdate] = useState(null);

  const updateTransfervehicleTime = (row, vehicle, date) => {
    if (isValid(date) && !row.time) {
      apiClient.httpInstance.put(`/exit_vehicle/${vehicle.id}/time`, {
        time: format(date, "HH:mm"),
        reservation_id: row.reservation_id
      }).then(response => { });
    }
  }

  const getVoucherExit = (url, exitId) => {
    apiClient.httpInstance.get(`${url}`).then(response => {
      if (response.status === 200) {
        apiClient.httpInstance.get(`sales/${response.data.sale_id}`).then(response => {
          if (response.status === 200) {
            window.open(`/voucher-exit/${response.data.id}/${response.data.url_link}?tourDate=${props.tourDate}&exitId=${exitId}`)
          }
        })
          .catch(error => {
            if (error) {
              console.log(error)
            }
          });
      }
    })
  }

  return <>
    <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
      <div className="flex flex-col md:flex-row items-center justify-between p-4 font-poppins">
        <div className="flex flex-col">
          <span className="text-xl text-blue">Reservas</span>
          <span className="text-sm text-gray">Última alteração realizada em (
            <Moment format="DD/MM/YYYY HH:mm:ss" date={lastUpdate} />)</span>
        </div>
        {permissions.includes(props?.theme?.permission) && <div className="flex items-center space-x-3 mt-4">
          <Button
            disabled={props.disabledFinishBtn}
            textcolor="#212121"
            padding="6px 15px"
            margintop="0px"
            marginleft="15px"
            width="200"
            variant="outlined"
            onClick={props.finishVehicle}
            bgColor="transparent"
          >
            Finalizar
          </Button>
          <Button
            textcolor="#212121"
            padding="6px 15px"
            margintop="0px"
            marginleft="15px"
            width="200"
            variant="outlined"
            onClick={props.onClick}
            bgColor="transparent"
          >
            Alocar reserva
          </Button>
        </div>}
      </div>
      <div className="flex justify-center pb-3 text-center">
        <Table
          path={props.path}
          params={{
            q
          }}
          onRowClick={null}
          columns={[
            {
              headerName: 'Clientes',
              headerClassName: 'super-app-theme--header',
              field: 'customer',
              width: 180,
              renderCell: (params) => {
                const row = params.row;
                let customer = row.customer

                if (row.partner !== null) {
                  customer = customer + ` (${row.partner})`
                }

                return <>{customer}</>;
              }
            },
            {
              headerName: 'Cupos',
              headerClassName: 'super-app-theme--header',
              field: 'total_people',
              flex: 1,
            },
            {
              headerName: 'Endereço',
              headerClassName: 'super-app-theme--header',
              field: 'address',
              width: 150,
            },
            {
              headerName: 'Horário',
              headerClassName: 'super-app-theme--header',
              field: 'time',
              width: 160,
              renderCell: (params) => {
                const row = params.row;
                let flightTime = null
                if (props.travelType !== "App\\Models\\Tour" && row.flight_time) {
                  const hourArrar = row.flight_time ? row.flight_time.split(":") : props.flightTime.split(":");
                  const hour = new Date().setHours(hourArrar[0], hourArrar[1])
                  flightTime = format(hour, "HH:mm")
                  updateTransfervehicleTime(row, vehicle, hour)
                }
                setLastUpdate(row.last_update)
                return props.travelType !== "App\\Models\\Tour" && props.flightTime || row.flight_time ?
                  flightTime
                  :
                  <TimeTextField
                    vehicle={vehicle}
                    reservation={row}
                  />
              }
            },
            {
              headerName: 'Num. Voo',
              headerClassName: 'super-app-theme--header',
              field: 'flight_code',
              flex: 1,
            },
            {
              headerName: 'Voucher',
              headerClassName: 'super-app-theme--header',
              field: '',
              width: 150,
              renderCell: (params) => {
                const row = params.row;
                return <>
                  <Button
                    textcolor="#212121"
                    padding="6px 15px"
                    width="200"
                    variant="outlined"
                    bgColor="transparent"
                    margintop="0"
                    onClick={() => getVoucherExit(`reservations/${row.reservation_id}`, props.vehicle.exit_id)}
                  >
                    <Icon className="mr-1">
                      open_in_new
                    </Icon>
                    Visualizar
                  </Button>
                </>
              }
            },
            {
              headerName: 'Email',
              headerClassName: 'super-app-theme--header',
              field: 'email_sent',
              renderCell: (params) => {
                const row = params.row;
                return <>
                  {row.email_sent === true ?
                    <Icon
                      className="ml-2 cursor-pointer"
                      fontSize="small"
                    >check_circle
                    </Icon>
                    :
                    <Icon
                      className="ml-2 cursor-pointer text-red-500"
                      fontSize="small"
                    >do_disturb_on
                    </Icon>
                  }
                </>
              }
            },
            {
              headerName: 'Mensagem',
              headerClassName: 'super-app-theme--header',
              field: 'message_sent',
              renderCell: (params) => {
                const row = params.row;
                return <>
                  {row.message_sent === true ?
                    <Icon
                      className="ml-2 cursor-pointer"
                      fontSize="small"
                    >check_circle
                    </Icon>
                    :
                    <Icon
                      className="ml-2 cursor-pointer text-red-500"
                      fontSize="small"
                    >do_disturb_on
                    </Icon>
                  }
                </>
              }
            },
          ]}
          rows={rows}
        />
      </div>
    </div>
  </>
}

export default TableVehicleReservation;
