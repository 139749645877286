import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import Form from './Form';
import Alert from '@material-ui/lab/Alert';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { apiClient } from '@services/api';

const today = _ => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

const breadcrumbsConfig = (props) => ([
  {
    title: 'Início',
    active: true,
    route: '/admin/vendedores'
  },
  {
    title: 'Novo vendedor',
  }
]);

const Create = (props) => {
  document.title = `Cadastro de vendedores - ${process.env.REACT_APP_NAME}`
  const [error] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage] = useState("");
  const history = useHistory();
  const [salespeople, setSalespeople] = useState({
    name: '',
    email: '',
    birth_date: today(),
    freelancer: false,
    address: '',
    identification_document: '',
    passport_number: '',
    role_id: '',
    phone: '',
    observations: '',
    hab_venda_parceiros: 'true',
    status: false
  });
  const url = `/admin/vendedores`;

  const changeState = (e) => {
    setSalespeople(
      {
        ...salespeople,
        [e.target.name]: e.target.value
      }
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient.httpInstance.post("/sellers", {
      ...salespeople
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            history.push(url);
          }, 2000);
        }
      })
      .catch(error => {
        if (error) {
          setErrors(error.response.data);
          setTimeout(() => { setErrors("") }, 4000);
        }
      });
  }

  return <>
    <BreadCrumbs items={breadcrumbsConfig()} />
    {error && (
      <Alert className="mb-5" variant="filled" severity="error">
        {errorMessage}
      </Alert>
    )}
    {!loading && <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Novo vendedor</span>
        <Link color="inherit" to={url}>
          <Button
            textcolor="#212121"
            padding="6px 15px"
            width="200"
            variant="outlined"
            bgColor="transparent"
          >
            <Icon className="mr-2">
              arrow_back
            </Icon>
            Cancelar
          </Button>
        </Link>
      </div>
      {success && <Alert className="mt-5" severity="success" color="success">
        Vendedor cadastrado com sucesso
      </Alert>}
      <Form
        {...props}
        submit={handleSubmit}
        errors={errors}
        salespeople={salespeople}
        isEditing={false}
        changeState={changeState} />
    </div>}
  </>
}

export default Create;
