import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@components/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@components/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import PhoneMaskInput from '@components/PhoneMaskInput';
import Select from '@components/Select'

const GreenRadio = withStyles({
  root: {
    color: `${process.env.REACT_APP_SELLER_COLOR}`,
    '&$checked': {
      color: `${process.env.REACT_APP_SELLER_COLOR}`,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const freelancerOptions = [
  { value: true, text: 'Sem salário fixo' },
  { value: false, text: 'Com salário fixo' },
]

const roleTypeOptions = [
  {
    value: 1,
    name: 'Administrador'
  },
  {
    value: 2,
    name: 'Gerente'
  },
  {
    value: 3,
    name: 'Supervisor'
  },
  {
    value: 4,
    name: 'Operação'
  },
  {
    value: 6,
    name: 'Logística'
  },
]

const Form = (props) => {
  return <>
      <form onSubmit={props.submit}>
        <div className="p-4 pt-3 pb-6 mt-6 bg-white rounded-xl text-blue font-poppins border-1">
          <div className="flex flex-wrap mt-3 space-x-3 lg:flex-nowrap">
            <TextField
              className="inputRounded"
              label="Nome do vendedor"
              name="name"
              variant="outlined"
              error={props.errors?.name}
              value={props.salespeople?.name}
              type="text"
              onChange={(e) => props.changeState(e)}
              required />
            <TextField
              className="inputRounded"
              label="E-mail"
              name="email"
              variant="outlined"
              error={props.errors?.email}
              value={props.salespeople?.email}
              type="email"
              onChange={(e) => props.changeState(e)}
              required />
            <TextField
              className="inputRounded"
              label="Data de nascimento"
              variant="outlined"
              name="birth_date"
              error={props.errors?.birth_date}
              value={props.salespeople?.birth_date}
              onChange={(e) => props.changeState(e)}
              type="date"
              required />
          </div>
          <div className="flex flex-wrap mt-5 space-x-3 lg:flex-nowrap">
            <TextField
              className="inputRounded"
              label="Modalidade de comissão"
              select
              variant="outlined"
              name="freelancer"
              error={props.errors?.freelancer}
              value={props.salespeople?.freelancer || false}
              onChange={(e) => props.changeState(e)}
              required>
                {freelancerOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              className="flex-grow inputRounded"
              label="Endereço"
              maxwidth="375px"
              error={props.errors?.address}
              value={props.salespeople?.address}
              variant="outlined"
              name="address"
              type="text"
              onChange={(e) => props.changeState(e)} />
            <TextField
              className="inputRounded"
              label="Doc. de identificação"
              maxwidth="375px"
              name="identification_document"
              error={props.errors?.identification_document}
              value={props.salespeople?.identification_document}
              variant="outlined"
              onChange={(e) => props.changeState(e)}
              type="text" />
            <TextField
              className="inputRounded"
              label="Passaporte"
              variant="outlined"
              name="passport_number"
              error={props.errors?.passport_number}
              value={props.salespeople?.passport_number}
              onChange={(e) => props.changeState(e)}
              type="text" />
          </div>
          <div className="flex flex-wrap w-full mt-3 space-x-3 lg:flex-nowrap">
            <div className="w-full">
              <PhoneMaskInput
                name="phone"
                value={props.salespeople?.phone}
                onChange={(valueNoFormated, maskInfo, target) => props.changeState({target: {name: 'phone', value: target.target.value}})}
              />
            </div>
            <div className="w-full">
              <Select
                withoutAll
                label="Nível"
                width="100%"
                value={props.salespeople?.role_id}
                name="role_id"
                onChange={(ev) => props.changeState(ev)}
                items={{
                  field: {
                    value: 'value',
                    text: 'name'
                  },
                  data: roleTypeOptions
                }}
              />
            </div>
          </div>
          <div className="mt-5">
            <TextField
              className="inputRounded"
              label="Observações"
              placeholder="Observações"
              variant="outlined"
              multiline
              rows={2}
              name="observations"
              error={props.errors?.observations}
              value={props.salespeople?.observations}
              onChange={(e) => props.changeState(e)}
            />
          </div>
          <div className="mt-10">
            <label className="text-dark">Venda para parceiros</label>
            <div className="flex-col d-flex text-gray">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="hab_venda_parceiros" value={props.salespeople?.hab_venda_parceiros + '' || 'false'} onChange={(e) => props.changeState(e)}>
                  <FormControlLabel value="true" control={<GreenRadio />} label="Permitido" />
                  <FormControlLabel value="false" control={<GreenRadio />} label="Não permitido" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-10">
            <label className="text-dark">Acesso</label>
            <div className="flex-col d-flex text-gray">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="access" value={props.salespeople?.access + '' || 'false'} onChange={(e) => props.changeState(e)}>
                  <FormControlLabel value="true" control={<GreenRadio />} label="Desbloqueado" />
                  <FormControlLabel value="false" control={<GreenRadio />} label="Bloqueado" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-10">
            <label className="text-dark">Listado</label>
            <div className="flex-col d-flex text-gray">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="status" value={props.salespeople?.status + '' || 'false'} onChange={(e) => props.changeState(e)}>
                  <FormControlLabel value="true" control={<GreenRadio />} label="Sim" />
                  <FormControlLabel value="false" control={<GreenRadio />} label="Não" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="text-right">
          <Button
            textcolor="#FFF"
            padding="6px 15px"
            width="200"
            customcolor={props.theme.color}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
}

export default Form;
