import React from 'react';
import Button from '@components/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '@components/TextField';
import {apiClient} from '@services/api';
import { useParams, useHistory } from 'react-router-dom';

function validateToken (token, history) {
  return apiClient
    .httpInstance.post("/password/reset/token_validation", {
      token,
    })
    .then(response => {
      if (response.status === 200 && !response?.data?.success) {
        history.push('/login');
      }
    })
};

const NewPassword = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const history = useHistory();
  const { token } = useParams();

  React.useEffect(() => {
    const run = async function () {
      await validateToken(token, history);
    }
    run();
  }, [token, history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    apiClient
        .httpInstance.post("/password/reset", {
          token,
          email,
          password,
          password_confirmation: newPassword,
        })
        .then(response => {
          if (response.status === 200) {
            history.push({
              pathname: '/login',
              state: { success: response.data.msg }
            });
          }
        })
        .catch(error => {
          if(error.response.status === 422) {
            setErrors(error.response);
            setTimeout(() => {setErrors({})}, 4000);
          }
        });
    };

  return <>
        <div className="flex text-3xl justify-content-start">
            <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>Crie uma nova senha</span>
        </div>
        <p className="my-5 text-gray">
          Insira o código enviado no seu e-mail para mudar sua senha
        </p>
        {errors?.data && (<Alert className="mb-5" variant="filled" severity="error">
          Houve um problema com os dados informados
        </Alert>)}
        <form onSubmit={handleSubmit}>
          <TextField
                className="inputRounded"
                placeholder="E-mail"
                variant="outlined"
                type="email"
                name="email"
                error={errors?.data?.errors?.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required></TextField>
          <div className="my-3 mt-5">
            <TextField
                className="inputRounded"
                type="password"
                placeholder="Senha"
                variant="outlined"
                name="password"
                error={errors?.data?.errors?.password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required></TextField>
            <div className="mt-5">
              <TextField
                  className="inputRounded"
                  type="password"
                  placeholder="Confirme a senha"
                  variant="outlined"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required></TextField>
            </div>
          </div>
          <Button textcolor="#545454" type="submit" bgColor={process.env.REACT_APP_PRIMARY_COLOR}>Redefinir senha</Button>
        </form>
    </>;
}

export default NewPassword;
