import Button from '@components/Button';
import AdminConfig from '@config/admin';
import '@css/Admin/index.scss';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { dateLocale, slugfy, translator } from '@utils/helpers';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  // Redirect,
  useParams, useRouteMatch
} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Header from './partials/Header';
import calendar from '@assets/images/icons/calendar.png';
import clock from '@assets/images/icons/clock.png';
import person from '@assets/images/icons/person.png';

class Content extends React.Component {
  render() {
    const props = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const tourDate = queryParams.get('tourDate');
    const exitId = queryParams.get('exitId');
    const idiom = (props.voucher.customerData.language || 'pt').toUpperCase();
    const payment_status = {
      accomplished: 'Pago',
      pending: 'Pendente',
      partial: 'Parcial'
    };

    const formatHour = time => {
      if (time) {
        const hourArrar = time.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        return format(hour, "HH:mm")
      }
      return null
    }

    const calcAdultsValue = (v) => {
      const adultValue = Number(v?.adults_total_amount)
      const adultQuantity = Number(v?.adults_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const calcKidsValue = (v) => {
      const adultValue = Number(v?.kids_total_amount)
      const adultQuantity = Number(v?.kids_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const language = {
      pt: 'Português',
      en: 'Inglês',
      es: 'Espanhol'
    };

    const getTimes = (v) => {
      if (v?.vehicle?.time_final) {
        return 'Entre ' + v?.vehicle?.time.substr(0, 5) + ' e ' + v?.vehicle?.time_final.substr(0, 5)
      }
      return v?.vehicle?.time
    }
    return <>
      <div id='desktop' className="p-10 bg-white border-1 text-blue hidden sm:hidden md:hidden lg:block xl:block" style={{ borderRadius: '10px 10px 0px 0px', maxWidth: '1350px' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '120px' }} />
              </div>
            </div>
            <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de saída</p>
                </div>
                <p>{props.voucher.company.name}</p>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
              </div>
              <div className="text-right mt-5 pt-5" style={{ fontSize: '0.7rem' }}>
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address} - {props.voucher.company.district} - {props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex flex-row text-left">
            <div className="flex flex-col text-left">
              <div className="flex my-5 text-xl">
                {translator(idiom, 'voucher', 'customers_data')}
              </div>
              <div className="flex flex-col text-left">
                <p><span className="font-bold">{translator(idiom, 'voucher', 'customer_name')}:</span> {props.voucher.reservationData.customer_name}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'phone')}:</span> {props.voucher.reservationData.customer_telephone}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'email')}:</span> {props.voucher.customerData.email}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'document')}:</span> {props.voucher.customerData.document}</p>
                <p><span className="font-bold">Idioma:</span> {language[props.voucher.customerData.language]}</p>
                {props.voucher?.reservationData?.combo_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
                {props.voucher?.reservationData?.translate_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
                {props.voucher.customerData?.customer_addresses?.map((address, indexc) => {
                  let addressClient = ''
                  props.voucher.reservations?.forEach((element, index) => {
                    if (parseInt(address.region_id) === parseInt(element.travel.region)) {
                      addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} ({address.name}):</span> {address.hotel_address}</p></>
                    } else if (element.travel.region === undefined && address.region_id === null) {
                      addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} (TRASLADO):</span> {address.hotel_address}</p></>
                    }
                  });
                  return (addressClient)
                })}
              </div>
            </div>
          </div>

          {props.voucher.status === 'canceled' && <>Reserva cancelada por {props.voucher.logs?.name} em <Moment format="DD/MM/YYYY HH:MM" date={props.voucher.logs?.created_at} /></>}

          <div className="flex justify-center mb-5 mt-12 text-xl ">
            {translator(idiom, 'voucher', 'about_exit')}
          </div>

          {props.voucher.reservations.map((v) => (<>
            {v?.exit_id == exitId && <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="flex flex-col" style={{ width: '100%' }}>
                  {v?.id && v.tour_date.substr(0, 10) == tourDate.substr(0, 10) && <>
                    <p className="my-3 text-lg text-left">
                      {v?.type === 'tour' ? v?.travel.name : v?.travel.region_name}
                      {v?.type === 'transfer' ? `(Transfer ${v?.transfer_type})` : ''}
                    </p>
                    <div className="flex flex-row justify-between">
                      <div class="grid grid-cols-2">

                        <div className="col-span-1">
                          <div className="flex col-span-6 text-left">
                            <div className="flex flex-row justify-between">
                              <span className="mx-2 flex"><span className="mr-2"><img src={calendar} /></span> <Moment format="DD/MM/YYYY" date={v?.tour_date} /></span>
                              <span className="mx-2 flex"><span className="mr-2"><img src={clock} /></span> {v?.times !== null ? getTimes(v) : formatHour(v?.flight_time) || '-'}</span>
                              <span className="mx-2 flex"><span className="mr-2"><img src={person} /></span> {v?.adults_total} adultos, {v?.kids_total} crianças</span>
                            </div>
                          </div>
                          {v?.type === 'transfer' && <div className="flex col-span-6 text-left">
                            <div className="flex flex-row justify-between">
                              <span className="mx-2 my-3 flex"><span className="mr-2 font-bold">{translator(idiom, 'voucher', 'flight_code')}</span>{v.flight_code}</span>
                            </div>
                          </div>}
                        </div>

                        <div className="col-span-1">
                          <div className="flex flex-col text-left">
                            <div className="flex flex-row">
                              <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'references')}: </span>{props.voucher.references}</span></div>
                              <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'address')}: </span>{props.voucher.reservationData.address_hotel}</span></div>
                            </div>
                            <div className="flex flex-row">
                              <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'driver')}: </span>{v?.driver?.name}</span></div>
                            </div>
                            <div className="flex flex-row">
                              <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'guide')}: </span>{v?.guide?.name}</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </div>}
          </>))}

          <div className="flex mt-16">
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
              <p>ID: {props.voucher.id}</p>
              <p>{translator(idiom, 'voucher', 'purchase_date')}: {dateLocale(props.voucher.created_at)}</p>
            </div>
          </div>
        </>
      </div>

      <div className="p-8 bg-white border-1 text-blue block xs:block sm:block md:block lg:hidden xl:hidden" style={{ borderRadius: '10px 10px 0px 0px', fontSize: '14px', display: 'none' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '80px' }} />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de saída</p>
                </div>
                <p className="text-left">{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address}</p>
                <p className="text-left">{props.voucher.company.district}</p>
                <p className="text-left">{props.voucher.company.state} - {props.voucher.company.country}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-4 border-t border-b border-solid border-lightgray"></div>
          {props.voucher.status === 'canceled' && <>Reserva cancelada por {props.voucher.logs?.name} em <Moment format="DD/MM/YYYY HH:MM" date={props.voucher.logs?.created_at} /></>}
          <div className="flex my-5 text-lg ">
            {translator(idiom, 'voucher', 'customers_data')}
          </div>
          <div className="flex flex-col text-left">
            <p><span className="font-bold">{translator(idiom, 'voucher', 'customer_name')}:</span> {props.voucher.reservationData.customer_name}</p>
            <p><span className="font-bold">{translator(idiom, 'voucher', 'phone')}:</span> {props.voucher.reservationData.customer_telephone}</p>
            <p><span className="font-bold">{translator(idiom, 'voucher', 'email')}:</span> {props.voucher.customerData.email}</p>
            <p><span className="font-bold">{translator(idiom, 'voucher', 'document')}:</span> {props.voucher.customerData.document}</p>
            <p><span className="font-bold">Idioma:</span> {language[props.voucher.customerData.language]}</p>
            {props.voucher?.reservationData?.combo_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
            {props.voucher?.reservationData?.translate_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
            {props.voucher.customerData?.customer_addresses?.map((address, indexc) => {
              let addressClient = ''
              props.voucher.reservations?.forEach((element, index) => {
                if (parseInt(address.region_id) === parseInt(element.travel.region)) {
                  addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} ({address.name}):</span> {address.hotel_address}</p></>
                } else if (element.travel.region === undefined && address.region_id === null) {
                  addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} (TRASLADO):</span> {address.hotel_address}</p></>
                }
              });
              return (addressClient)
            })}
          </div>
          <div className="flex justify-center mb-5 mt-12 text-xl ">
            {translator(idiom, 'voucher', 'about_exit')}
          </div>
          <div className="flex flex-col">
            {props.voucher.reservations.map((v) => (
              <div className="flex flex-col" style={{ width: '100%' }}>
                {v?.id && v.tour_date.substr(0, 10) == tourDate.substr(0, 10) &&
                  <>
                    <p className="my-3 text-lg text-left">
                      {v?.type === 'tour' ? v?.travel.name : v?.travel.region_name}
                      {v?.type === 'transfer' ? `(Transfer ${v?.transfer_type})` : ''}
                    </p>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'date')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        <Moment format="DD/MM/YYYY" date={v?.tour_date} />
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'schedule')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {v?.times !== null ? getTimes(v) : formatHour(v?.flight_time) || '-'}
                      </div>
                    </div>
                    {v?.type === 'transfer' && <><div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'flight_code')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {v?.flight_code}
                      </div>
                    </div></>}
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'passagers')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {v?.adults_total} adultos, {v?.kids_total ? v?.kids_total : 0} crianças
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'references')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {props.voucher.references}
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'address')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {props.voucher.reservationData.address_hotel}
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'driver')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {v?.driver?.name}
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'guide')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {v?.guide?.name}
                      </div>
                    </div>
                    <div className="px-4 my-1"></div>
                  </>
                }
                <div className="px-4 my-1"></div>
              </div>
            ))}
          </div>
        </>

        <div className="flex mt-16">
          <div className="flex flex-col flex-1 space-y-1 text-center">
            <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
            <p>ID: {props.voucher.id}</p>
            <p>{translator(idiom, 'voucher', 'purchase_date')}: {dateLocale(props.voucher.created_at)}</p>
          </div>
        </div>
      </div>
    </>
  }
}

const VoucherLink = props => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const componentRef = useRef()
  const { sale_id, exit_id, token } = useParams();
  const [voucher, setVoucher] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendingVoucher, setSendingVoucher] = useState(false);
  let url = `/customer/get_sale_voucher/${sale_id}?access_token=${token}`;
  const [success, setSuccess] = useState(false);
  const sendVoucherUrl = `sales/${sale_id}/send_voucher`;

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false);
      }
    }).catch(function (response) {
      history.push('/');
    });
  }

  const sendVoucher = () => {
    setSendingVoucher(true);
    apiClient.httpInstance.post(sendVoucherUrl).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSendingVoucher(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    });
  }

  useEffect(() => {
    getVoucher(url);
  }, [url]);

  let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.logistica;

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} />
        <div className="flex flex-row bg-lightgray">
          <Route path={`${path}`}>
            <div className="w-full mx-0 mb-20 pt-14 md:mx-28">
              <>
                {!loading && <div className="px-7">
                  <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                    <span>Voucher</span>
                    <div className="space-x-3 w-100">
                      <ReactToPrint
                        trigger={() => <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                        >
                          Visualizar PDF
                        </Button>
                        }
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          document.getElementById('desktop').classList.remove('hidden')
                          document.getElementById('desktop').classList.remove('sm:hidden')
                          document.getElementById('desktop').classList.remove('md:hidden')
                        }}
                        onAfterPrint={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        onPrintError={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}

                      />

                    </div>
                  </div>
                  <div className="my-4 d-flex">
                    {success && <Alert className="mt-5" severity="success" color="success">
                      Voucher enviado com sucesso
                    </Alert>}
                  </div>
                  <Content
                    {...props}
                    voucher={voucher}
                    ref={componentRef}
                  />
                </div>}
              </>
            </div>
          </Route>
        </div>
      </div>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(VoucherLink);