import TextField from '@components/TextField';
import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import Select from '@components/Select';
import InputCurrency from '@components/InputCurrency';

const Form = (props) => {
  const radioButtonData = [
    {
      value: true,
      label: 'Ativo'
    },
    {
      value: false,
      label: "Inativo"
    }
  ]

  const modalityOptions = [
    {
      name: "Com salário fixo + comissão"
    },
    {
      name: "Comissão"
    },
  ]

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="flex mb-4 space-x-3">
          <div className="flex-1">
            <InputCurrency
              className="inputRounded"
              label="Intervalo de valor inicial"
              variant="outlined"
              type="text"
              name="initial_value"
              value={props.commission.initial_value}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <InputCurrency
              className="inputRounded"
              label="Intervalo de valor final"
              variant="outlined"
              type="text"
              name="final_value"
              value={props.commission.final_value}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
            <TextField
              className="inputRounded"
              label="Comissão"
              variant="outlined"
              type="number"
              name="commission"
              value={props.commission.commission}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-1">
          <Select
              withoutAll
              label="Modalidade"
              value={props.commission.modality}
              name="modality"
              onChange={(ev) => props.changeState(ev)}
              items={{
                field: {
                  value: 'name',
                  text: 'name'
                },
                data: modalityOptions
              }}
            />
          </div>
        </div>
        {props.isEditing && <>
            <span className="text-xl text-black">Status</span>
            <div className="flex mb-4 space-x-3">
              <RadioButton
                {...props}
                name="status"
                data={radioButtonData}
                change={(ev) => props.changeState(ev)}
                value={props.commission.status}
              />
            </div>
          </>
        }
      </div>
      <div className="text-right">
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
          </Button>
      </div>
    </form>
  </>
}

export default Form
