import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import Icon from '@material-ui/core/Icon';
import { apiClient } from '@services/api';
import { format, parseJSON } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

class Content extends React.Component {
  render() {
    const exit = this.props.exit
    const tourType = {
      tour: 'Passeio',
      combo: 'Combo',
      transfer: 'Traslado'
    }
    const getPaymmentsTotal = id => {
      return exit?.payments?.reduce((accumulator, currentValue) => accumulator + (currentValue.id = id ? Number(currentValue.total) : 0), 0)
    }
    if (Object.keys(exit).length !== 0) {
      return <div>
        <>
          <div className="bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="px-6 py-4 text-xl text-blue font-poppins">
              <span>Informações</span>
            </div>
            <div className="flex px-6 py-4 border-t border-opacity-75">
              <div className="flex-1 font-bold">
                Produto
              </div>
              <div className="flex-1 text-center font-bold">
                Local
              </div>
              <div className="flex-1 text-right font-bold">
                Guia
              </div>
            </div>
            <div className="flex px-6 py-4 border-t border-opacity-75">
              <div className="flex-1">
                {exit.travel_name} {exit.type === 'transfer' && exit.transfer_type !== null ? `(${exit.transfer_type})` : ''}
              </div>
              <div className="flex-1 text-center">
                {tourType[exit.type]}
              </div>
              <div className="flex-1 text-right">
                {exit.guide_name}
              </div>
            </div>
          </div>
          {exit.customers !== undefined && exit.customers.map((customer) => {
            return <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
              <div className="flex px-6 py-4 text-xl text-blue font-poppins justify-between">
                <span>{customer.name}</span>
                <div className="items-right">
                  <div className="flex items-center">
                    <div className="font-bold text-lg py-1">{exit.type === 'transfer' ? 'Horário do Voo: ' : ''}</div> {(exit.time ? exit.time : exit.time_final) &&
                      <>
                        <Icon className="mr-2">
                          access_time
                        </Icon>
                        {(customer.time_final ? `Entre ${customer.time?.slice(0, 5)} e ${customer.time_final?.slice(0, 5)}` : customer.time?.slice(0, 5))}
                      </>
                    }
                  </div>
                  {exit.type === 'transfer' ?
                    <div className="flex items-center">
                      <div className="font-bold text-lg py-1">Número do Vôo: </div> {exit?.flight_code}
                    </div>
                    : ''}
                </div>
              </div>

              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Hotel
                </div>
                <div className="flex-1 text-gray">
                  {customer.addresses?.find(({ region_id }) => region_id == exit.region)?.hotel}
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Endereço - {exit.type === 'tour' ? 'Passeio' : 'Traslado'}
                </div>
                <div className="flex-1 text-gray">
                  {customer.addresses?.find(({ region_id }) => region_id == exit.region)?.hotel_address}
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Número do quarto
                </div>
                <div className="flex-1 text-gray">
                  {customer.addresses?.find(({ region_id }) => region_id == exit.region)?.room_number}
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Telefone
                </div>
                <div className="flex-1 text-gray">
                  {customer.phone}
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Passageiros
                </div>
                <div className="flex-1 text-gray">
                  {customer.passagers}
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Valor total
                </div>
                <div className="flex-1 text-gray">
                  <NumberFormat
                    value={Number(customer.total) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Valor Pendente
                </div>
                <div className="flex-1 text-gray">
                  <NumberFormat
                    value={Number(customer.total) - Number(customer.paymments?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0)) || 0}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </div>
              </div>
              <div className="flex px-6 py-4 border-t border-opacity-75">
                <div className="flex-1">
                  Vendedor
                </div>
                <div className="flex-1 text-gray">
                  {customer.seller_name}
                </div>
              </div>
            </div>
          })}
        </>
      </div>
    } else {
      return <div>
        <>
          <div className="bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
            <div className="px-6 py-4 text-xl text-blue font-poppins">
              <span>Informações</span>
            </div>
            <div className="flex px-6 py-4 border-t border-opacity-75">
              <div className="flex-1 font-bold">
                Produto
              </div>
              <div className="flex-1 text-center font-bold">
                Local
              </div>
              <div className="flex-1 text-right font-bold">
                Guia
              </div>
            </div>
          </div>
          <div className="flex px-6 py-4 border-t border-opacity-75">
            Nenhuma veículo cadastrado para a saída
          </div>
        </>
      </div>
    }
  }
}

const Show = (props) => {
  const { type_user } = useParams();
  const { driver_id } = useParams();
  const { exit_id } = useParams();
  const { access_token } = useParams();
  const componentRef = useRef()
  const history = useHistory();
  const [exit, setExit] = useState({})
  const url = `driver/exit/${exit_id}`

  const bradecrumbsConfig = [
    {
      title: `Início`,
      active: true,
      route: `/admin/motoristas/saidas/${driver_id}/${access_token}`
    },
    {
      title: `Detalhes ${exit.travel_name !== undefined ? exit.travel_name : ''}`,
    },
    {
      title: `Saida do dia ${exit.tour_date !== undefined ? format(new Date(`${exit.tour_date} 00:00:00`), "dd/MM/yyyy") : ''}`,
    }
  ]

  const getExitDetails = url => {
    apiClient.httpInstance.get(url, {
      params: {
        access_token: access_token
      }
    }).then(response => {
      if (response.status === 200) {
        setExit(response.data);
      }
    });
  }

  useEffect(() => {
    getExitDetails(url);
  }, [url]);

  return <>
    <div className="px-24 pb-4">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Detalhes {exit.travel_name}</span>
        <div className="w-100 space-x-3">
          <Button
            textcolor="#212121"
            padding="6px 15px"
            margintop="0px"
            width="200px"
            variant="outlined"
            onClick={null}
            bgColor="transparent"
          >
            Enviar por e-mail
          </Button>
          <ReactToPrint
            trigger={() => <Button
              textcolor="#FFF"
              padding="6px 15px"
              margintop="0px"
              width="200"
              customcolor={props.theme.color}
            >
              Visualizar PDF
            </Button>
            }
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div className="my-4">
        <BreadCrumbs items={bradecrumbsConfig} />
      </div>
      <div className="mb-4">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          margintop="0px"
          width="100px"
          variant="outlined"
          bgColor="transparent"
          onClick={() => history.push(`/admin/motoristas/saidas/${driver_id}/${access_token}`)}
        >
          Voltar
        </Button>
      </div>
      <Content
        {...props}
        exit={exit}
        ref={componentRef}
      />
    </div>
  </>
}

export default Show;