import { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { dateLocale } from '@utils/helpers'
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { format, isValid } from 'date-fns';

const Update = (props) => {
  const { exit_id, derived_id } = useParams();
  const history = useHistory();
  const [exit, setExit] = useState({})
  const [derived, setDerived] = useState({
    agency_id: null,
    guide_name: null,
    license_plate: null,
    schedule: null
  })
  const [idVehicle, setIdVehicle] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const derivedUrl = `/deriveds/${derived_id}`;
  const url = `/exits/${exit_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/saidas'
    },
    {
      title: `${exit?.travel_name || ''} (${dateLocale(exit?.tour_date)})`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}`
    },
    {
      title: `${derived?.agency_name}`,
      active: true,
      route: `/admin/saidas/detalhe/${exit_id}/derivados/detalhe/${derived?.id}`
    },
    {
      title: 'Editar',
    }
  ]

  const getExitsById = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setExit(response.data);
        setLoading(false)
      }
    });
  }

  const getDerivedDetails = derivedUrl => {
    apiClient.httpInstance.get(derivedUrl).then(response => {
      if (response.status === 200) {
        const hourArrar = response.data.schedule.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        setDerived({
          ...response.data,
          schedule: hour
        });
        setLoading(false);
      }
    });
  }

  const changeDate = (date) => {
    if (isValid(date)) {
      setDerived(
        {
          ...derived,
          schedule: date
        }
      )
    }
  }

  const changeState = (ev) => {
    let value = ev.target.value

    setDerived(
      {
        ...derived,
        [ev.target.name]: value

      }
    )
  }

  const submitDerived = (ev) => {
    ev.preventDefault();

    apiClient
      .httpInstance.put(`/deriveds/${derived.id}`, {
        ...derived,
        user_id: props.user.data.id,
        schedule: format(derived.schedule, "HH:mm"),
        exit_id: exit_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/saidas/detalhe/${exit_id}`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getDerivedDetails(derivedUrl);
    getExitsById(url);
  }, [url, derivedUrl]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Editar</span>
          </div>
          <Form
            {...props}
            submit={submitDerived}
            derived={derived}
            idVehicle={idVehicle}
            exitId={exit_id}
            isEditing={false}
            changeDate={changeDate}
            changeState={changeState}
            changeIdVehicle={setIdVehicle}
          />
        </div>
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
