import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from '@components/Select';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    textTransform: 'none'
  },
  indicator: {
    backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`
  }
});

const ConditionsTerms = (props) => {

  const langs = [
    {
      lang: 'pt',
      text: 'Português'
    },
    {
      lang: 'en',
      text: 'Inglês'
    },
    {
      lang: 'es',
      text: 'Espanhol'
    },
  ]
  const selectLang = langs.filter(lang => props.terms[lang.lang] === true)
  const classes = useStyles();

  const [value, setValue] = useState('1');

  const changeLang = ev => {
    props.setLang(ev)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      props.setUrlTerms('/company_terms?type=sale')
      props.setTypeTerm('sale')
    }
    if (newValue == 2) {
      props.setUrlTerms('/company_terms?type=budget')
      props.setTypeTerm('budget')
    }
  };

  return <>
    <TabContext value={value}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          classes={{
            indicator: classes.indicator
          }}
          textcolor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab
            className="normal-case font-normal text-lg text-blue font-poppins"
            label="Termos vendas"
            value="1"
            indicatorColor="primary"
          />
          <Tab
            className="normal-case font-normal text-lg text-blue font-poppins"
            label="Termos orçamentos"
            value="2"
          />
        </Tabs>
      </AppBar>
      <TabPanel value="1">
        {selectLang.length > 0 &&
          <div className="flex justify-end">
            <div className="flex-none">
              <Select
                withoutAll
                label="Selecione"
                width="200px"
                onChange={ev => changeLang(ev.target.value)}
                value={props.lang}
                items={{
                  field: {
                    value: 'lang',
                    text: 'text'
                  },
                  data: selectLang
                }}
              />
            </div>
          </div>
        }
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          {selectLang.length > 0
            ?
            <>
              {props.lang !== null
                ?
                <div className="text-lg p-4">
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms1'] }} /><br /><br />
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms2'] }} /><br /><br />
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms3'] }} /><br /><br />
                </div>
                :
                <div className="text-lg text-center p-4">
                  Selecione um idioma
                </div>
              }
            </>
            :
            <div className="text-lg text-center p-4">
              Você ainda não cadastrou os termos e condições da sua empresa
            </div>
          }
        </div>
      </TabPanel>
      <TabPanel value="2">
        {selectLang.length > 0 &&
          <div className="flex justify-end">
            <div className="flex-none">
              <Select
                withoutAll
                label="Selecione"
                width="200px"
                onChange={ev => changeLang(ev.target.value)}
                value={props.lang}
                items={{
                  field: {
                    value: 'lang',
                    text: 'text'
                  },
                  data: selectLang
                }}
              />
            </div>
          </div>
        }
        <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
          {selectLang.length > 0
            ?
            <>
              {props.lang !== null
                ?
                <div className="text-lg p-4">
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms1'] }} /><br /><br />
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms2'] }} /><br /><br />
                  <div dangerouslySetInnerHTML={{ __html: props.terms[`${props.lang}Terms`]['terms3'] }} /><br /><br />
                </div>
                :
                <div className="text-lg text-center p-4">
                  Selecione um idioma
                </div>
              }
            </>
            :
            <div className="text-lg text-center p-4">
              Você ainda não cadastrou os termos e condições da sua empresa
            </div>
          }
        </div>
      </TabPanel>
    </TabContext>
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(ConditionsTerms);
