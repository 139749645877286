import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const Create = (props) => {
  document.title = `Detalhes da comissão - ${process.env.REACT_APP_NAME}`
  const { region_id, commission_id } = useParams();
  const history = useHistory();
  const [region, setRegion] = useState({
    name: null,
  })
  const [commission, setCommission] = useState({
    initial_value: null,
    final_value: null,
    commission: null,
    modality: null,
    region_id: null,
    company_id: null,
    status: true,
  })
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let urlRegions = `/regions/${region_id}`;
  let urlCommission = `/region_commissions/team_variable_rate/${commission_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/regioes'
    },
    {
      title: `${region.name !== undefined ? region.name : ''}`,
      active: true,
      route: `/admin/regioes/detalhe/${region_id}`
    },
    {
      title: 'Editar matriz de comissão',
    }
  ]

  const getRegionById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setRegion(response.data);
          setLoading(false)
        }
      }
      );
  }

  const getCommissionById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setCommission(response.data);
          setIsEditing(true)
        }
      }
      );
  }

  const changeState = (ev) => {
    let value = ev.target.value;
    if (ev.target.name === "status") {
      value = ev.target.value === "true" ? true : false;
    }
    setCommission(
      {
        ...commission,
        [ev.target.name]: value
      }
    )
  }

  const submitCommissionEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/region_commissions/team_variable_rate/${commission_id}`, {
        ...commission,
        region_id: Number(region_id),
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push(`/admin/regioes/detalhe/${region_id}`);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getRegionById(urlRegions);
    getCommissionById(urlCommission)
  }, [urlRegions, urlCommission]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {!loading && isEditing &&
      <div className="px-7 pt-6">
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Editar matriz de comissão</span>
          <Button
            textcolor="#212121"
            padding="6px 15px"
            width="200"
            variant="outlined"
            onClick={() => history.push(`/admin/regioes/detalhe/${region_id}`)}
            bgColor="transparent"
          >
            <Icon className="mr-2">
              arrow_back
            </Icon>
            Cancelar
          </Button>
        </div>
        <Form
          {...props}
          submit={submitCommissionEdit}
          commission={commission}
          isEditing={isEditing}
          changeState={changeState}
        />
      </div>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Create);
