import TextField from '@components/TextField'
import Button from '@components/Button'
import InputFile from '@components/InputFile'
import PhoneMaskInput from '@components/PhoneMaskInput';
import { useHistory } from "react-router-dom";

const FormCompany = (props) => {
  const history = useHistory();

  return <>
    <form onSubmit={props.submit}>
      <div className="p-4 mt-4 bg-white rounded-xl border-1">
        <div className="mb-4">
          <InputFile changeFile={props.changeFile} banner={props.company.logo} />
        </div>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Nome da empresa"
              variant="outlined"
              type="text"
              name="name"
              value={props.company.name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Nome curto"
              variant="outlined"
              type="text"
              name="surname"
              value={props.company.surname}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="RUT"
              variant="outlined"
              type="text"
              name="rut"
              value={props.company.rut}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <PhoneMaskInput
              name="phone"
              value={props.company.phone}
              onChange={(valueNoFormated, maskInfo, target) => props.changeState({ target: { name: 'phone', value: target.target.value } })}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Logradouro"
              variant="outlined"
              type="text"
              name="address"
              value={props.company.address}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Número"
              width="110px"
              variant="outlined"
              type="text"
              name="number"
              value={props.company.number}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Complemento"
              variant="outlined"
              type="text"
              name="complement"
              value={props.company.complement}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Bairro"
              variant="outlined"
              type="text"
              name="district"
              value={props.company.district}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Cidade"
              variant="outlined"
              type="text"
              name="city"
              value={props.company.city}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Estado"
              variant="outlined"
              type="text"
              name="state"
              value={props.company.state}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="País"
              variant="outlined"
              type="text"
              name="country"
              value={props.company.country}
              onChange={(ev) => props.changeState(ev)}
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4 space-x-3">
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Email principal"
              variant="outlined"
              type="text"
              name="mail_from_address"
              value={props.company.mail_from_address}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Email alternativo"
              variant="outlined"
              type="text"
              name="mail_from_address_secondary"
              value={props.company.mail_from_address_secondary}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
          <div className="flex-none sm:p-2 mt-3 md:mt-0 min-w-max">
            <TextField
              className="inputRounded"
              label="Nome no email"
              variant="outlined"
              type="text"
              name="mail_from_name"
              value={props.company.mail_from_name}
              onChange={(ev) => props.changeState(ev)}
              required
            />
          </div>
        </div>
        <div className="flex-1" />
      </div>
      <div className="text-right space-x-3 mb-4">
        <Button
          textcolor="#212121"
          padding="6px 15px"
          width="200"
          variant="outlined"
          onClick={() => history.push('/admin/configuracoes')}
          bgColor="transparent"
        >
          Cancelar
        </Button>
        <Button
          textcolor="#FFF"
          padding="6px 15px"
          width="200"
          customcolor={props.theme.color}
          type="submit"
        >
          Salvar
        </Button>
      </div>
    </form >
  </>
}

export default FormCompany
