import React from 'react';
import Button from '@components/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '@components/TextField';
import { useHistory } from "react-router-dom";
import {apiClient} from '@services/api';

const ForgetPassword = props => {
  document.title = `Recupere sua senha - ${process.env.REACT_APP_NAME}`
  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    apiClient
      .httpInstance.post("/password/email", {
        email: email
      })
      .then(response => {
        if (response.status === 200) {
          history.push({
            pathname: '/login',
            state: { success: response.data.msg }
          });
        }

        setLoading(false);
      })
      .catch(error => {
        if(error.response.status === 422) {
          setErrorMessage(error.response.data.errors.email);
          setSuccessMessage("")
          setTimeout(() => {setErrorMessage("")}, 4000);
        }

        setLoading(false);
      });
  };

  return <>
        <div className="flex text-3xl justify-content-start">
            <span style={{ color: process.env.REACT_APP_SECONDARY_COLOR }}>Recupere sua senha</span>
        </div>
        <p className="my-5 text-gray">
          Insira seu e-mail de cadastro e enviaremos um link para você redefinir sua senha
        </p>
        {errorMessage && (<Alert className="mb-5" variant="filled" severity="error">
          {errorMessage}
        </Alert>)}
        {successMessage && (<Alert className="mb-5" variant="filled" severity="success">
          {successMessage}
        </Alert>)}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <TextField
                className="inputRounded"
                placeholder="E-mail"
                variant="outlined"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required></TextField>
          </div>
          <Button textcolor="#545454" type="submit" bgColor={process.env.REACT_APP_PRIMARY_COLOR} disabled={loading}>Recuperar senha</Button>
        </form>
    </>;
}

export default ForgetPassword;
