import logo from '@assets/images/logo/small/white.png';
import { connect } from 'react-redux';
import HeaderDropdown from '@components/HeaderDropdown'
import { apiClient } from '@services/api'
import { logout } from '@actions/auth'
import { useHistory } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
//import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const permissions = ['administrador'];

let Header = (props) => {
  const history = useHistory();
  const logout = () => {
    apiClient.setToken(null)
    props.auth();
    history.push('/login');
  }
  
  const setShow = () => {
    props.setShowMenu(!props.showMenu)
  }

  const dropdownInfo = [
    {
      type: 'list',
      icon: 'person',
      title: 'Perfil',
      onClick: () => history.push('/admin/perfil')
    },
    {},
    {
      type: 'btn',
      title: 'Sair',
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
      onClick: () => { logout() }
    }
  ]

  if (permissions.includes(props?.theme?.permission)) {
    dropdownInfo[1] = {
      type: 'list',
      icon: 'settings',
      title: 'Configurações',
      onClick: () => history.push('/admin/configuracoes')
    }
  }


  return <div className={`flex items-center justify-between p-4 header ${props.theme.class}`}>
    <span className="block cursor-pointer md:hidden lg:hidden xl:hidden" onClick={()=>setShow()}><MenuIcon /></span>
    <img className="logo" src={`${process.env.REACT_APP_LOGO_SMALL_WHITE}`} alt={process.env.REACT_APP_NAME}></img>
    <HeaderDropdown
      name={props?.user?.data?.name}
      permission={props?.user?.data?.permission}
      image={props?.user?.data?.image}
      dropdownInfo={dropdownInfo}
    />
  </div>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProp = dispatch => {
  return {
    auth() {
      const action = logout();
      dispatch(action);
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProp
)(Header);
