import Index from './Index';
import Create from './Create';
import Update from './Update';
import Show from './Show';
import ExitsGuide from './exits/main';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

let Drivers = (props) => {
  let { path } = useRouteMatch();
  const theme = props.theme;

  return <>
    <Switch>
      <Route exact path={`${path}`}>
        <Index theme={theme} setShowMenu={props.setShowMenu} />
      </Route>
      <Route exact path={`${path}/cadastro`}>
        <Create theme={theme} />
      </Route>
      <Route exact path={`${path}/detalhe/:guide_id`}>
        <Show theme={theme} />
      </Route>
      <Route exact path={`${path}/editar/:guide_id`}>
        <Update theme={theme} />
      </Route>
      <Route path={`${path}/saidas/:guide_id/:access_token`}>
        <ExitsGuide theme={theme} />
      </Route>
    </Switch>
  </>
}

export default Drivers;
