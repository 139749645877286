import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Form from './Form';
import { apiClient } from '@services/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const permissions = ['administrador'];

const Update = (props) => {
  document.title = `Editar detalhes do vendedor parceiro - ${process.env.REACT_APP_NAME}`
  const { partner_seller_id } = useParams();
  const history = useHistory();
  const [partnerSeller, setPartnerSeller] = useState({
    name: null,
    email: null,
    telephone: null,
    commission: null,
    partner_commission: false,
    status: true,
  })
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let url = `/partner_sellers/${partner_seller_id}`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/parceiros/vendedores'
    },
    {
      title: `${partnerSeller.name !== undefined ? partnerSeller.name : ''}`,
      active: true,
      route: `/admin/parceiros/vendedores/detalhe/${partner_seller_id}`
    },
    {
      title: 'Edição',
    }
  ]

  const getPartnerSellerById = url => {
    apiClient
      .httpInstance.get(url)
      .then(response => {
        if (response.status === 200) {
          setPartnerSeller(response.data);
          setIsEditing(true)
        }
      }
      );
  }

  const changeState = (ev) => {
    let value = ev.target.value;
    if (ev.target.name === "status" || ev.target.name === "partner_commission") {
      value = ev.target.value === "true" ? true : false;
    }
    setPartnerSeller(
      {
        ...partnerSeller,
        [ev.target.name]: value
      }
    )

  }

  const submitPartnerSellerEdit = (ev) => {
    ev.preventDefault();
    apiClient
      .httpInstance.put(`/partner_sellers/${partner_seller_id}`, {
        ...partnerSeller,
        company_id: props.user.data.company_id
      })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          history.push('/admin/parceiros/vendedores');
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setErrorMessage(error.response.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
        }
      });
  }

  useEffect(() => {
    getPartnerSellerById(url);
  }, [url]);

  return <>
    <BreadCrumbs items={bradecrumbsConfig} />
    {isEditing &&
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        {permissions.includes(props?.theme?.permission) ?
          <div className="px-7 pt-6">
            <div className="flex items-center justify-between mt-2 text-2xl text-blue">
              <span>Edição</span>
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                onClick={() => history.push('/admin/parceiros/vendedores')}
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  arrow_back
                </Icon>
                Cancelar
              </Button>
            </div>
            <Form
              {...props}
              submit={submitPartnerSellerEdit}
              partnerSeller={partnerSeller}
              isEditing={isEditing}
              changeState={changeState}
            />
          </div>
          :
          <div className="px-7 pt-6">
            <div className="flex items-center justify-between mt-2 text-2xl text-blue">
              <span>Edição</span>
              <Button
                textcolor="#212121"
                padding="6px 15px"
                width="200"
                variant="outlined"
                onClick={() => history.push('/admin/parceiros/vendedores')}
                bgColor="transparent"
              >
                <Icon className="mr-2">
                  arrow_back
                </Icon>
                Cancelar
              </Button>
            </div>
            <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
              Você não possui permissão para acessar este recurso.
            </div>
          </div>
        }
      </>
    }
  </>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(Update);
